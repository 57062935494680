import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Courses() {
    const homeOptions = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 0,
        responsiveClass: true,
        responsive: {
            0: { items: 1, nav: false },
            576: { items: 2, nav: true },
            768: { items: 4, nav: true },
            992: { items: 4, nav: true },
            1200: { items: 4, nav: true }
        }
    };

    return (
        <>

            <section id="recommended_course">
                <div class="container">
                    <h1>Our Courses <a href="online_course.html"><span>View all<i class="bi bi-arrow-right"></i></span></a></h1>
                    <h2>Recommended Course</h2>


                    <OwlCarousel className='owl-theme' {...homeOptions}>
                        <div class="item">
                            <div class="flex_reco">
                                <div class="reco-img" style={{ backgroundImage: `url(${"images/intro-img.png"})` }}>

                                </div>

                                <div class="reco_cont">
                                    <img src="images/Logo.png" alt="" />
                                    <h3>Foundations of Vedic Marriage Wisdom</h3>
                                    <p>Gain insights into the spiritual essence of marriage in Vedic culture</p>
                                    <h4>4.8 <i class="bi bi-star-fill"></i> <span>(2.7k reviews)</span></h4>
                                    <p>Module spans 2 weeks,Full course is 12 weeks</p>
                                    <a href="marriage_counselling_registration.html" class="btn">Register Now</a>
                                </div>

                            </div>
                        </div>

                    </OwlCarousel>

                    {/* 
            <div class="owl-carousel owl-recommended owl-theme">

                <div class="item">
                    <div class="flex_reco">
                        <div class="reco-img" style={{ backgroundImage: `url(${"images/intro-img.png"})` }}>
                  
                        </div>

                        <div class="reco_cont">
                            <img src="images/Logo.png" alt=""/>
                            <h3>Foundations of Vedic Marriage Wisdom</h3>
                            <p>Gain insights into the spiritual essence of marriage in Vedic culture</p>
                            <h4>4.8 <i class="bi bi-star-fill"></i> <span>(2.7k reviews)</span></h4>
                            <p>Module spans 2 weeks,Full course is 12 weeks</p>
                            <a href="marriage_counselling_registration.html" class="btn">Register Now</a>
                        </div>

                    </div>
                </div>

            </div> */}
                </div>
            </section>

        </>
    )

}