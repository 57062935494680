import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Qualifications } from "../../Data/content";

export default function
    Education({data, handleChange}) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Prefered Qualification</label>
                            <select class="form-select"
                                aria-label="Default select example" name="education" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {Qualifications.length > 0 && Qualifications.map((item, i) => (
                                    <option selected={data && data.education === item} value={item} >{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}