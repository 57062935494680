import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  AddPreference, GetPreference } from '../api';

export const GetPref = createAsyncThunk('GetPref', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetPreference(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const SavePreference = createAsyncThunk('SavePreference', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddPreference(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const prefernceSlice = createSlice({
  name: 'prefernce',
  initialState: {
    data: null,
    success: false,
    error: null,
    spritual : false
 
  },
  reducers: {

    clearPrefState: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SavePreference.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SavePreference.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SavePreference.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
//       .addCase(GetSpritualInfo.pending, state => {
//         state.success = false;
//         state.error = null;
//       })
//       .addCase(GetSpritualInfo.fulfilled, (state, action) => {
//         state.data = action.payload.data.data;
//         state.success = action.payload;
//         state.error = null;
//       })
//       .addCase(GetSpritualInfo.rejected, (state, action) => {
//         state.success = false;
//         state.error = action.payload;
//       })  
  },
});
export const { clearPrefState } = prefernceSlice.actions;

export default prefernceSlice.reducer;

