// apiSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CheckKundaliMatch } from '../api';


export const CheckMatch = createAsyncThunk('CheckMatch', async (formdata, { rejectWithValue }) => {
    try {
        const response = await CheckKundaliMatch(formdata);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const kundaliSlice = createSlice({
    name: 'kundali',
    initialState: {
        success: false,
        error: null,
        // result: null, 
    },

    reducers: {
        clearKundali: state => {
            state.success = false;
            state.error = null;
        },
    },

    extraReducers: builder => {
        // builder
        //     .addCase(CheckMatch.pending, state => {
        //         state.success = true;
        //         state.error = null;
        //     })
        //     .addCase(CheckMatch.fulfilled, (state, action) => {
        //         state.result = action.payload.data.data;
        //         state.success = action.payload;
        //         state.error = null;
        //     })
        //     .addCase(CheckMatch.rejected, (state, action) => {
        //         state.success = false;
        //         state.error = action.payload;
        //     })
    },
});


export const { clearContactForm } = kundaliSlice.actions;
export default kundaliSlice.reducer;

