import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Introduction from "./Introduction";
import Offerings from "./Offerings";
import Courses from "./Courses";
import Instructors from "../Tools/Instructors";
import FAQ from "./FAQ";
import Privacy from "./Privacy";

export default function MarriageCouncelling() {

    return (
        <>
            <Header />
            <Introduction />
            <Offerings />
            <Courses />
            <Instructors />
            <FAQ />
            <Privacy />
            <Footer />
        </>
    )

}