import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {AddPerosnalityQuetsions, GeterosnalityQuetsions } from '../api';

export const SavePerosnalityQuetsions = createAsyncThunk('SavePerosnalityQuetsions', async (formData, { rejectWithValue }) => {
    try {
      const response = await AddPerosnalityQuetsions(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const GetPersonalityData = createAsyncThunk('GetPersonalityData', async (formData, { rejectWithValue }) => {
    try {
      const response = await GeterosnalityQuetsions(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  



  
const personalitySlice = createSlice({
    name: 'personality',
    initialState: {
        data: null,
        success: false,
        error: null,
        step1: null,

    },
    reducers: {
        clearPersonalityState: state => {
            state.success = false;
            state.error = null;
        },

        stepOne: (state, action) => {
            state.step1 = action.payload;
        }
    },
    extraReducers: builder => {
        builder
          .addCase(SavePerosnalityQuetsions.pending, state => {
            state.success = false;
            state.error = null;
          })
          .addCase(SavePerosnalityQuetsions.fulfilled, (state, action) => {
            state.success = action.payload;
            state.error = null;
          })
          .addCase(SavePerosnalityQuetsions.rejected, (state, action) => {
            state.success = false;
            state.error = action.payload;
          })
          .addCase(GetPersonalityData.pending, state => {
            state.success = false;
            state.error = null;
          })
          .addCase(GetPersonalityData.fulfilled, (state, action) => {
            state.data = action.payload.data.data;
            state.success = action.payload;
            state.error = null;
          })
          .addCase(GetPersonalityData.rejected, (state, action) => {
            state.success = false;
            state.error = action.payload;
          })  
    },
});
export const {clearPersonalityState } = personalitySlice.actions;

export default personalitySlice.reducer;

