import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AstroInfoSave, GetAstro } from '../api';


export const SaveAstro = createAsyncThunk('SaveAstro', async (formData, { rejectWithValue }) => {
  try {
    const response = await AstroInfoSave(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetAstroInfo = createAsyncThunk('GetAstroInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetAstro(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const astroSlice = createSlice({
  name: 'astro',
  initialState: {
    data: null,
    success: false,
    error: null,
    astro : null
  },
  reducers: {

    clearStateAstro: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
  
      .addCase(SaveAstro.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveAstro.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveAstro.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetAstroInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetAstroInfo.fulfilled, (state, action) => {
        state.astro = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetAstroInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })

      
  },
});
export const { clearStateAstro } = astroSlice.actions;

export default astroSlice.reducer;

