import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function PrefMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {


    }, []);


    return (
        <>

            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-lg-6 mt-3">
                        <div class="set">
                            <h2>Partner Preferences</h2>

                            <div class="row">
                                <div class="col-12">
                                    <h4>Basic Preference</h4>
                                </div>
                                <div class="col-6">
                                    <h3>Marital Status</h3>
                                    {user1 && user1.preferences && user1.preferences.prefered_marital_status && user1.preferences.prefered_marital_status.length > 0 ?
                                        <ul>
                                            {user1.preferences.prefered_marital_status.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Preferred Age</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_age_start}-{user1 && user1.preferences && user1.preferences.prefered_age_end} Yrs </p>
                                </div>

                                <div class="col-6">
                                    <h3>Preferred Height</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_height_start}-{user1 && user1.preferences && user1.preferences.prefered_height_end}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Community Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Religion</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_religion}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Caste</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_caste}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Community</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_community}</p>
                                </div>


                                <div class="col-12">
                                    <h4>Location Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Country</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_country}</p>
                                </div>

                                <div class="col-6">
                                    <h3>State</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_state}</p>
                                </div>

                                <div class="col-6">
                                    <h3>City / Location</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_city}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Career Preferences </h4>
                                </div>

                                <div class="col-6">
                                    <h3>Qualification</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_qualification}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Professional Background</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_Proffessional_bkgrd}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Annual Income</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_income}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Other Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Food Preferences</h3>
                                    {user1 && user1.preferences && user1.preferences.prefered_food && user1.preferences.prefered_food.length > 0 ?
                                        <ul>
                                            {user1.preferences.prefered_food.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Astro - Manglik </h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_manglik_status}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Spiritual Preferences</h4>
                                </div>

                                <div class="col-12">
                                    <h3>Preferred Cultural Values and Practices</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_cultr_val}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Partner’s Affiliation</h3>
                                    {user1 && user1.preferences && user1.preferences.prefered_affiliation && user1.preferences.prefered_affiliation.length > 0 ?
                                        <ul>
                                            {user1.preferences.prefered_affiliation.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Initiation Status</h3>
                                    <p>{user1 && user1.preferences && user1.preferences.prefered_initiation_status}</p>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="set">
                            <h2>Partner Preferences</h2>
                            <div class="row">
                                <div class="col-12">
                                    <h4>Basic Preference</h4>
                                </div>
                                <div class="col-6">
                                    <h3>Marital Status</h3>
                                    {user2 && user2.preferences && user2.preferences.prefered_marital_status && user2.preferences.prefered_marital_status.length > 0 ?
                                        <ul>
                                            {user2.preferences.prefered_marital_status.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Preferred Age</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_age_start}-{user2 && user2.preferences && user2.preferences.prefered_age_end} Yrs </p>
                                </div>

                                <div class="col-6">
                                    <h3>Preferred Height</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_height_start}-{user2 && user2.preferences && user2.preferences.prefered_height_end}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Community Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Religion</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_religion}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Caste</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_caste}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Community</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_community}</p>
                                </div>


                                <div class="col-12">
                                    <h4>Location Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Country</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_country}</p>
                                </div>

                                <div class="col-6">
                                    <h3>State</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_state}</p>
                                </div>

                                <div class="col-6">
                                    <h3>City / Location</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_city}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Career Preferences </h4>
                                </div>

                                <div class="col-6">
                                    <h3>Qualification</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_qualification}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Professional Background</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_Proffessional_bkgrd}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Annual Income</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_income}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Other Preferences</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Food Preferences</h3>
                                    {user2 && user2.preferences && user2.preferences.prefered_food && user2.preferences.prefered_food.length > 0 ?
                                        <ul>
                                            {user2.preferences.prefered_food.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Astro - Manglik </h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_manglik_status}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Spiritual Preferences</h4>
                                </div>

                                <div class="col-12">
                                    <h3>Preferred Cultural Values and Practices</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_cultr_val}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Partner’s Affiliation</h3>
                                    {user2 && user2.preferences && user2.preferences.prefered_affiliation && user2.preferences.prefered_affiliation.length > 0 ?
                                        <ul>
                                            {user2.preferences.prefered_affiliation.map((item, i) => (
                                                <li>{item.value}</li>
                                            ))}

                                        </ul>

                                        : "-"}
                                </div>

                                <div class="col-6">
                                    <h3>Initiation Status</h3>
                                    <p>{user2 && user2.preferences && user2.preferences.prefered_initiation_status}</p>
                                </div>

                            </div>


                        </div>
                    </div>



                </div>

            </div>

        </>
    )

}