import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateTerm } from "../../../Reducer/authSlice";

const schema = Yup.object().shape({
   
   
    checkboxx: Yup.boolean().oneOf([true], 'You must agree to the Privacy Policy').required('You must agree to the Privacy Policy'),
});

export default function TermPopup() {
    const dispatch = useDispatch();
  
    const CC = useSelector(state => state.auth.auth);
    const navigate = useNavigate();
    const { success, auth } = useSelector((state) => state.auth);
     useEffect(() => {
    if (success) {
      navigate('/MyMatches');
    }
  }, [success, navigate]);
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (datas) => {
        
        
        let formData = new FormData();
        
        formData.append('acceptedTerm', datas.checkboxx);
        formData.append('email', CC.email);
       
       
       
        dispatch(UpdateTerm(formData));

        


    };
    const onError = (errors) => {
        console.log(errors);
    };
    useEffect(() => {
        // dispatch(AboutPage());
    }, []);

    return (
        <>
            <Header />

            <section id="terms_cond">
                <div class="container">
                <h6>Please read and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                        conditions</a>
                </h6>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div class="form-check mb-2">

                    <input class="form-check-input" type="checkbox" value="true" id="flexCheckDefault"  {...register('checkboxx')} />
                    <label class="form-check-label" for="flexCheckDefault">
                    I confirm that I have read and accepted the terms and conditions
                    </label>
                    {errors.checkboxx && <p className={"error__feedback"}>{errors.checkboxx.message}</p>}
                </div>
                <div class="text-center" style={{width: "10%"}}>
                    <button type="submit" className="btnclass">Submit</button>
                </div>
                </form>

                </div>
            </section>

            <Footer />
        </>
    )

}