import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import { GetListOfUsers } from "../../../../Reducer/MakerDashboardSlices/listUser";
import { API_BASE_URL } from "../../Data/content";

export default function Users() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.userlist.data);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        let dat = { uid: auth._id, page, pageSize, searchQuery };
        dispatch(GetListOfUsers(dat));
    }, [page, pageSize, searchQuery]);


    const goto = (id) => {
        navigate("/UserProfile/" + id);
    }

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <section id="ftm_pro">
                        <h2>List of FTM Devotees</h2>
                        <div className="common_search">
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <input className="form-control" type="text" value={searchQuery} onChange={handleSearch} placeholder="Search by Email and Mobile" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            {data.length > 0 ? data.map((item, i) => (
                                <div class="col-lg-4 col-sm-6 mt-3">
                                    <a onClick={() => goto(item._id)}>
                                        <div class="box">
                                            <div class="img">
                                                <img src={API_BASE_URL + item.profileImage} alt="" />
                                            </div>
                                            <h3>{item.name}</h3>
                                            <h4>Year of Joining: <b>{(new Date(item.created_at)).getFullYear()}</b></h4>

                                            <h4>DOB: <b>{item.astro.dob}</b></h4>
                                            <ul>
                                                <li><img src="/images/pro-5.png" /><span>{item.MatchesCount}</span>Profiles Matched</li>
                                                <li><img src="/images/m-1.png" /><span>{item.RecomdCount}</span>Matches Sent</li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            )) : <div>No Users found.</div>}

                            <div className="common_paging">
                                <div className="d-flex">
                                    <button onClick={prevPage} disabled={page === 1}>Previous</button>
                                    <span>{page}</span>
                                    <button onClick={nextPage}>Next</button>
                                </div>
                                <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )

}