import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PersonalityFilter } from "../../Data/content";

export default function
    Personality({ data, handleChange }) {


    const selectOption = (item, name) => {

        handleChange({ target: { name: "personality", value: item } });

    }

    return (
        <>
            <div class="filter_listing">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Initiation Status</label>
                            <ul>
                                {PersonalityFilter.length > 0 && PersonalityFilter.map((item, i) => (
                                    <li key={i} className={data.personality === item ? "active" : ""} onClick={() => selectOption(item)}>
                                        {item}
                                    </li>
                                ))}
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}