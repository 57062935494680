import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";

export default function
    Location({ data, handleChange }) {
    const dispatch = useDispatch();
    const [countryId, setCountryId] = useState(null);
    const [CountryName, setCountry] = useState(null);
    const [stateId, setstateId] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Country</label>
                            <CountrySelect
                                showFlag={false}
                                inputClassName="form-control"
                                class="form-select ps-5"
                                onChange={(e) => {
                                    if (e && e.id && e.name) {

                                        setCountryId(e.id);
                                        setCountry(e);
                                        setstateId(null);
                                        setCity(null);
                                        setState(null);

                                        handleChange({ target: { name: 'country', value: e.name } }); // Update like this

                                    } else {
                                        handleChange({ target: { name: 'country', value: "" } }); // Update like this

                                    }
                                }}
                                // defaultValue={data.country}
                                placeHolder="Select Country"
                                name="fullCountry"

                            />

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">State</label>
                            <StateSelect
                                showFlag={false}
                                inputClassName="form-control"
                                countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                onChange={(e) => {
                                    // console.log('eeeee', e);
                                    if (e && e.id && e.name) {
                                        setstateId(e.id);
                                        setState(e);
                                        setCity("");
                                        handleChange({ target: { name: 'state', value: e.name } }); // Update like this

                                    } else {
                                        setState(null)
                                        handleChange({ target: { name: 'state', value: "" } }); // Update like this

                                    }
                                }}

                                placeHolder="Select State"
                                // defaultValue={data.state}
                                name="fullState"
                            />

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">City</label>

                            <CitySelect
                                showFlag={false}
                                inputClassName="form-control"

                                countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                stateid={state && state.id ? state.id : stateId}

                                onChange={(e) => {

                                    if (e && e.id && e.name) {
                                        console.log(e);
                                        setCity(e)
                                        handleChange({ target: { name: 'city', value: e.name } }); // Update like this

                                    } else {
                                        setCity(null)
                                        handleChange({ target: { name: 'city', value: "" } }); // Update like this

                                    }
                                }}
                                // defaultValue={data.city}
                                placeHolder="Select City"
                                name="fullCity"

                            />

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}