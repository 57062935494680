import React, { Component, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { MatchingMaker } from "../../../Data/content";
import AllMatches from "./AllMatches";
import PendingRecomended from "./PendingRecomended";
import AcceptedRecommendation from "./AcceptedRecommendation";

export default function Matches() {
    const params = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };



    useEffect(() => {

    }, []);

    const goto = () => {
        navigate("/UserHistory/" + params.id);
    }



    return (
        <>


            <div class="col-xl-7 mt-3">
                <div class="set_two">
                    <a onClick={() => goto()} class="btn btn_history">History</a>
                    <Tabs>
                        <TabList className={"nav nav-pills mb-4"} id="pills-tab" role="tablist">
                            {MatchingMaker.length > 0 && MatchingMaker.map((item, i) => (
                                <Tab key={i}
                                    className={"nav-link" + (activeTab === i ? " active" : "")}
                                    onClick={() => handleTabClick(i)}
                                >
                                    {item}
                                </Tab>
                            ))}

                        </TabList>
                        <TabPanel>
                            <AllMatches />
                        </TabPanel>
                        <TabPanel >
                            <PendingRecomended />
                        </TabPanel>
                        <TabPanel >
                            <AcceptedRecommendation />
                        </TabPanel>

                    </Tabs>


                </div>


            </div>

        </>
    )

}