
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SaveUploadedMedia, GetAllChatUsers, UpdateBlockUnblock } from './api';


export const UploadChatMedia = createAsyncThunk('SaveUploadedMedia', async (formData, { rejectWithValue }) => {
    try {
        const response = await SaveUploadedMedia(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetChatUsers = createAsyncThunk('GetChatUsers', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetAllChatUsers(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


export const updateBlockStatus = createAsyncThunk('updateBlockStatus', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateBlockUnblock(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        data: null,
        success: false,
        error: null,

    },
    reducers: {
        clearChatStatus: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(UploadChatMedia.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(UploadChatMedia.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = false;
                state.error = null;
            })
            .addCase(UploadChatMedia.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(updateBlockStatus.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(updateBlockStatus.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(updateBlockStatus.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});

export const { clearChatStatus } = chatSlice.actions;
export default chatSlice.reducer;

