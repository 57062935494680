import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import Header from "../Header";


export default function Support() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);


    useEffect(() => {
    }, []);




    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    {/* <Header /> */}

                    <section id="support_sec">
                        <h1>Support Tickets <a href="" className="btn">Add New Support Ticket <i class="bi bi-plus-lg"></i></a></h1>
                    </section>

                </div>
            </section>

        </>
    )

}