import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import { API_BASE_URL } from "../../Data/content";
import { ApproveProfileStatus, GetFtmUsers, clearftmUsers } from "../../../../Reducer/MakerDashboardSlices/ftmUsersSlice";
import Swal from 'sweetalert2';


export default function ProfileApprovals() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const users = useSelector(state => state.ftmUsers.data);
    const success = useSelector(state => state.ftmUsers.success);
    const error = useSelector(state => state.ftmUsers.error);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearftmUsers());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearftmUsers());
            getData();

        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
        }
    }



    useEffect(() => {
        getData();
    }, [page, pageSize, searchQuery]);

    function getData() {
        let dat = { uid: auth._id, page, pageSize, searchQuery };
        dispatch(GetFtmUsers(dat));
    }


    const Approve = (id) => {
        let data = { uid: auth._id, id: id };
        dispatch(ApproveProfileStatus(data));

    }


    return (


        <section id="full_page_dashboard">
            <div class="container-fluid">
                <Sidebar />
                <section id="ftm_user">
                    <h2>FTM Users List</h2>
                    {/* <input type="text" value={searchQuery} onChange={handleSearch} placeholder="Search by Email and Mobile" /> */}
                    <div className="common_search">
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <input className="form-control" type="text" value={searchQuery} onChange={handleSearch} placeholder="Search by Email and Mobile" />
                            </div>
                        </div>

                    </div>

                    <div class="mt-4">
                        <table class="table">
                            <thead>
                                <th>Sr No.</th>
                                <th>Image</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Action</th>
                                <th>Profile Status</th>
                                {/* <th>Convert to Normal User</th> */}
                            </thead>
                            {users.length > 0 ? users.map((item, i) => (
                                <tbody >
                                    <td>{i + 1}</td>
                                    <td><img src={API_BASE_URL + item.profileImage} alt="img" height="50px" /></td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile}</td>
                                    <td><Link to={`/PendingProfiles/${item._id}`}>
                                        <i className="fa fa-eye"></i>
                                    </Link></td>
                                    <td>
                                        {item.profile_status && item.profile_status === "Approved" ?
                                            <button>Approved</button>
                                            :
                                            <button onClick={() => Approve(item._id)}>Approve</button>
                                        }
                                    </td>
                                    {/* <td><button>Convert</button></td> */}
                                </tbody>
                            )) : <tbody>No users found.</tbody>}

                        </table>
                        <div className="common_paging">
                            <div className="d-flex">
                                <button onClick={prevPage} disabled={page === 1}>Previous</button>
                                <span>{page}</span>
                                <button onClick={nextPage}>Next</button>
                            </div>
                            <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                    </div>
                </section>
            </div>
        </section>


    )



}
