import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPwd, clearStatus } from "../../../Reducer/authSlice";
const schema = Yup.object().shape({
    password: Yup
        .string()
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});




export default function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const success = useSelector(state => state.auth.success);
    const error = useSelector(state => state.auth.error);

    // Retrieve the value of a specific query parameter
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    if (success && success.status === 200) {

        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearStatus());

    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());

        }
    }

    useEffect(() => {

    }, []);

    const onSubmit = async (datas) => {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('token', token);
        formData.append('password', datas.password);
        dispatch(ResetPwd(formData));

    }

    const onError = (errors) => {
        console.log(errors);
    };

    return (
        <>
            <section id="login_sec">
                <div class="container-fluid p-0 h-100">
                    <div class="row h-100">


                        <div class="col-lg-12 ">
                            <div class="box-two h-100">

                                {email && token ?
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <div class="text-center">
                                            <a href="index.html"><img class="logo_login" src="images/Logo.png" alt="" /></a>
                                        </div>
                                        <h2>Reset Your Password</h2>
                                        <p>Welcome to Vedic Marriage</p>
                                        <div class="form-group">
                                            <label class="form-label">Password</label>
                                            <input type="password" class="form-control" name="password"  {...register('password')} />
                                            {errors.password && <p className={"error__feedback"}>{errors.password.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Confirm Password</label>
                                            <input type="password" class="form-control" name="confirm_password"  {...register('confirm_password')} />
                                            {errors.confirm_password && <p className={"error__feedback"}>{errors.confirm_password.message}</p>}
                                        </div>
                                        <button type="submit">Submit</button>
                                    </form>
                                    :
                                    <form><div class="text-center">
                                        <a><img class="logo_login" src="images/Logo.png" alt="" /></a>
                                    </div>
                                        <p>Something went wrong</p>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )

}