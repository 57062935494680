import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetNewUsers } from '../makerApi';


export const GetNewProfiles = createAsyncThunk('GetNewProfiles', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetNewUsers(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const newProfilesSlice = createSlice({
    name: 'newProfiles',
    initialState: {
        data: [],
        success: false,
        error: null,
    },
    reducers: {
        clearNewProfiles: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetNewProfiles.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetNewProfiles.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetNewProfiles.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })

    },
});
export const { clearNewProfiles } = newProfilesSlice.actions;

export default newProfilesSlice.reducer;

