import { NavLink, useNavigate, Link, useLocation,  } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Results from "./Results";
import Points from "./Points";
export default function AfterMatching({ uerData }) {
    const navigate = useNavigate();


    console.log('uerData', uerData);

    return (
     <>
     {/* <Header /> */}
     {/* <Matching /> */}
     <Results uerData={uerData}/>
     <Points uerData={uerData}/>
     {/* <Footer /> */}
     </>
    )

}