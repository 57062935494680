import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Plans() {

    useEffect(() => {


    }, []);


    return (
        <>
            <div class="price_plane home_price">
                <div class="container">

                    <div class="box">
                        <div class="table-responsive">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th>Pricing Plans</th>
                                        <th><span>Free</span></th>
                                        <th><span>Premium</span></th>
                                        <th><span>Premium Plus</span></th>
                                        <th><span>Vedic Recommender</span> </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>No. of Connections <span>per month</span></td>
                                        <td>3</td>
                                        <td>50</td>
                                        <td>100</td>
                                        <td>Unlimited</td>
                                    </tr>

                                    <tr>
                                        <td>Personal Messages <span>per month</span></td>
                                        <td>3</td>
                                        <td>50</td>
                                        <td>100</td>
                                        <td>Unlimited</td>
                                    </tr>

                                    <tr>
                                        <td>View Contact Details <span>per month</span></td>
                                        <td>3</td>
                                        <td>50</td>
                                        <td>100</td>
                                        <td>Unlimited</td>
                                    </tr>

                                    <tr>
                                        <td>Matchmaker’s Recommendations <span>per month</span></td>
                                        <td><i class="bi bi-x-lg"></i></td>
                                        <td><i class="bi bi-x-lg"></i></td>
                                        <td><i class="bi bi-x-lg"></i></td>
                                        <td><i class="bi bi-check2"></i></td>
                                    </tr>

                                    <tr>
                                        <td>Validity <span>Months</span></td>
                                        <td>3</td>
                                        <td>3</td>
                                        <td>6</td>
                                        <td>6</td>
                                    </tr>

                                    <tr>
                                        <th>Price</th>
                                        <th>Free</th>
                                        <th>Rs. 1000</th>
                                        <th>Rs. 2000</th>
                                        <th>Rs. 5000</th>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )

}