import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { SendEmail, SendOtp, clearOtp, verifyOtp } from "../../../Reducer/authSlice";

export default function ForgetPwd({ setOpen1, setOpen2, setEmailForget }) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState('');
    const [otpbackend, setBackendOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerify, setIsOtpVerify] = useState(false);
    // const success = useSelector(state => state.auth.otp);
    const success = useSelector(state => state.auth.email);

    useEffect(() => {
        if (success) {
            // setBackendOtp(success);
            // dispatch(clearOtp());
            setOtp("");
            setIsOtpSent(true);
        }
    }, [success]);

    const handleCloseModal = () => {
        setOpen1(false);
    }

    const handleSendOtp = () => {
        if (!validateEmail(email)) {
            alert("Please enter a valid email address");
        } else {
            dispatch(SendEmail({ type: "email", data: email }));
        }
    }

    const handleVerifyOtp = async () => {
        let dataaa = { otp: otp, email: email };
        let res = await dispatch(verifyOtp(dataaa));
        if (res.payload.data) {
            Swal.fire({
                title: 'Congratulation!',
                text: res.payload.data.message,
                icon: 'success'
            });
            setIsOtpVerify(true);
            console.log('sdsdsdadssadsada', res);
        }else{
            alert("OTP mismatch");
        }

        // if (otpbackend == otp) {
        //     setIsOtpVerify(true);
        //     Swal.fire({
        //         title: 'Congratulation!',
        //         text: "OTP verified successfully",
        //         icon: 'success'
        //     });
        // } else {
        //     alert("OTP mismatch");
        // }
    }

    const opdanNext = () => {
        setOpen1(false);
        setOpen2(true);
        setEmailForget(email);
        setOtp("");
    }

    const validateEmail = (email) => {
        // Basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    return (
        <div className="modal fade show forgot_popup" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ display: "block" }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="Logo">
                            <img src="/images/Logo.png" alt="" />
                        </div>
                        <h2>Forgot Password</h2>
                        <div className="form-group">
                            <label className="form-label">Email ID</label>
                            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <span onClick={handleSendOtp}>Send OTP</span>
                        </div>
                        <div className="form-group">
                            <label className="form-label">OTP</label>
                            <input type="text" className="form-control" value={otp} onChange={(e) => setOtp(e.target.value)} disabled={!isOtpSent} />
                            <span onClick={handleVerifyOtp}>Verify OTP</span>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCloseModal}>Back to Login</button>
                        <button type="button" disabled={!isOtpVerify} className="btn btn-primary" onClick={opdanNext}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
