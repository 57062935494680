import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Introduction() {

    return (
        <>
            <section id="vedic_sec">
                <div class="container">
                    <div class="row align-items-center">

                        <div class="col-xl-6 col-lg-7 col-md-7 pe-0 pe-lg-5 order_sec">
                            <h1>Introducing Marriage Counselling and Courses</h1>
                            <h2>We blend ancient Vedic wisdom with contemporary counseling techniques to provide a holistic
                                approach to marriage counseling. Our team of experienced counselors, well-versed in both
                                traditional scriptures and modern psychology, is here to assist you on your path to a blissful
                                and spiritually aligned marriage.</h2>


                        </div>

                        <div class="col-xl-6 col-lg-5 col-md-5">
                            <div class="vedic_img">
                                <img src="images/intro-img.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )

}