import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Filtertabs } from "../../Data/content";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Age from "./Age";
import Height from "./Height";
import Education from "./Education";
import Location from "./Location";
import Income from "./Income";
import Language from "./Language";
import Manglik from "./Manglik";
import MaritalStatus from "./MaritalStatus";
import Initiation from "./Initiation";
import Community from "./Community";
import Affiliation from "./Affiliation";
import OccupationFilter from "./OccupationFilter";
import Food from "./Food";
import Personality from "./Personality";
import { FilterSearch, clearFilter, saveSearchFilters, saveSearchResults } from "../../../../Reducer/UserDashboardSlices/filterSlice";
import Swal from 'sweetalert2';


export default function
    Filters({ openModal, setOpenModal }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const [FilterForm, setFilterForm] = useState({
        age_start: "",
        age_end: "",
        height_start: "",
        height_end: "",
        language: "",
        education: "",
        income: "",
        religion: "",
        community: "",
        manglik: "",
        initiation: "",
        affiliation: "",
        marital_status: "",
        food: "",
        occupation: "",
        country: "",
        state: "",
        city: "",
        personality: ""
    });
    const apidata = useSelector(state => state.filter.data);
    const success = useSelector(state => state.filter.success);
    const error = useSelector(state => state.filter.error);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };


    if (success && success.status === 200) {
        dispatch(clearFilter());
        dispatch(saveSearchFilters(FilterForm));
        dispatch(saveSearchResults(apidata));
        setTimeout(() => {
            setOpenModal(false);
            navigate("/FilterResults");
        }, 1000);

    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearFilter());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearFilter());
                break;
        }
    }


    useEffect(() => {
        dispatch(clearFilter());
    }, []);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const handleCloseModal = () => {
        setOpenModal(false); // Update the state in the Header component
    }


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log('e.target', e.target);
        setFilterForm(prevState => ({
            ...prevState,
            [name]: value,

        }));
    }

    const onApply = () => {
        // console.log('FilterForm', FilterForm);
        let formData = new FormData();
        formData.append('age_start', FilterForm.age_start);
        formData.append('age_end', FilterForm.age_end);
        formData.append('height_start', FilterForm.height_start);
        formData.append('height_end', FilterForm.height_end);
        formData.append('language', FilterForm.language);
        formData.append('education', FilterForm.education);
        formData.append('income', FilterForm.income);
        formData.append('religion', FilterForm.religion);
        formData.append('community', FilterForm.community);
        formData.append('manglik', FilterForm.manglik);
        formData.append('initiation', FilterForm.initiation);
        formData.append('affiliation', FilterForm.affiliation);
        formData.append('marital_status', FilterForm.marital_status);
        formData.append('food', FilterForm.food);
        formData.append('occupation', FilterForm.occupation);
        formData.append('country', FilterForm.country);
        formData.append('state', FilterForm.state);
        formData.append('city', FilterForm.city);
        formData.append('personality', FilterForm.personality);
        formData.append('uid', auth._id);
        // const requestData = {
        //     formData: formData,
        //     page: page,
        //     pageSize: pageSize
        // };

        dispatch(FilterSearch(formData));
    }

    return (
        <>
            {openModal &&
                <div class="modal fade filter_popup show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true" style={{ display: 'block' }}>
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"><img src="images/Fitlers.png" alt="" />Filters</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                            </div>
                            <div class="modal-body">
                                <div class="filter_popup_modal">
                                    <Tabs>
                                        <div class="row">
                                            <div class="col-lg-3">

                                                <TabList className={"nav flex-column nav-pills"} id="v-pills-tab" role="tablist">
                                                    {Filtertabs.length > 0 && Filtertabs.map((item, i) => (
                                                        <Tab key={i}
                                                            className={"nav-link" + (activeTab === i ? " active" : "")}
                                                            onClick={() => handleTabClick(i)}
                                                        >
                                                            {item.name}  <i
                                                                class="bi bi-chevron-right"></i>
                                                        </Tab>
                                                    ))}

                                                </TabList>


                                            </div>
                                            <div class="col-lg-9">
                                                <div class="tab-content" id="v-pills-tabContent">
                                                    <TabPanel >
                                                        <Age data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Height data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Language data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Location data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Education data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Income data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Community data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <Manglik data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel >
                                                        <MaritalStatus data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Initiation data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Affiliation data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <OccupationFilter data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Food data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Personality data={FilterForm} handleChange={handleFormChange} />
                                                    </TabPanel>


                                                </div>
                                            </div>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => onApply()}>Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>

    )
}