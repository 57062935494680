import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userSidebar, API_BASE_URL } from "../../Data/content";
import { UserDetail } from "../../../../Reducer/UserDashboardSlices/userSlice";
import { UpdateProfilePic, clearMYProData } from "../../../../Reducer/UserDashboardSlices/myProfileSlice";
import Swal from 'sweetalert2';
import { clearUser } from "../../../../Reducer/authSlice";
import { GetNoti, ReadAll } from "../../../../Reducer/UserDashboardSlices/notificationSlice";
import firebaseConfig from '../../../../Firebase';

export default function Sidebar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [pro, setpro] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.user.data);
    const dataNoti = useSelector(state => state.noti.data);
    const count = useSelector(state => state.noti.count);
    const success = useSelector(state => state.myProfile.successPro);
    const error = useSelector(state => state.myProfile.errorPro);

    if (success) {
        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearMYProData());
        setTimeout(() => {
            getData();
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearMYProData());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearMYProData());
                break;
        }
    }


    useEffect(() => {
        getData();
        getNotifications();

        const intervalId = setInterval(getNotifications, 300000); // Call every 5 minutes (300000 ms)

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const Logout = () => {
        const userStatusDatabaseRef = firebaseConfig.database().ref(`/Online_Users/${auth._id}`);
        // Set the user's status to offline immediately
        userStatusDatabaseRef.update({
            status: 'offline',
            timeStamp: Date.now()
        });

        // Dispatch action to clear user data
        dispatch(clearUser());

        // Navigate to the login page after a delay
        setTimeout(() => {
            navigate("/Login");
        }, 2000);
    }

    function getData() {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
    }

    function getNotifications() {
        let dataaa = { uid: auth._id };
        dispatch(GetNoti(dataaa));
    }

    const readNoti = async () => {
        let dataaa = { uid: auth._id };
        let res = await dispatch(ReadAll(dataaa));
        if (res && res.payload && res.payload.status === 200) {
            getNotifications();
        }
    }

    const onselect = (item) => {
        setpro(item);
    }

    const SaveProfile = () => {
        if (pro === "") {
            alert("Please select profile picture");
        } else {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('profile', pro);
            dispatch(UpdateProfilePic(formData));
        }
    }

    function openComponent(name, link) {
        if (name === "My Profile") {
            // if (data.completion_percentage === 100) {
            navigate(link);
            // }
        } else if (name === "MyMatches") {
            // if (data && data.completion_percentage === 100 && data.gallryVerified === true && data.govtVerified === true && data.selfiverified === true) {
            if (data && data.profile_status === "Approved") {
                navigate(link);
            } else {
                navigate("/Dashboard");
            }
        } else {
            // if (data && data.completion_percentage === 100 && data.gallryVerified === true && data.govtVerified === true && data.selfiverified === true) {
            if (data && data.profile_status === "Approved") {
                navigate(link);
            } else {
                alert("Your profile is not approved yet!");
            }
        }
    }

    return (
        <>
            <div style={{ backgroundColor: '#F4F7FE' }} class="side_bar">
                <Link to="/">
                    <div class="logo closed_bar">
                        <img class="d-lg-block d-none" src="/images/Logo.png" alt="" />
                        <img class="d-lg-none d-block" src="images/favicon.png" alt="" />

                    </div>
                </Link>
                <div class="profile_img">
                    <img src={data && data.profileImage && API_BASE_URL + data.profileImage} alt="" />
                    <span type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="bi bi-pencil-square"></i></span>
                </div>
                {userSidebar &&
                    <ul class="mt-5">
                        {userSidebar.map((item, i) => (
                            <>

                                {/* {item.name == "MyMatches" && data && data.UserLoginType == "" &&
                                    <li key={i} onClick={() => openComponent(item.name, item.link)}><a className={location.pathname === item.link ? 'active' : ''} ><img src={item.icon} alt="" /><label> {item.name}</label></a>
                                    </li>
                                } */}
                                {/* {item.name == "Recommended" && data && data.UserLoginType === "Full-time Temple Devotee" &&
                                    <li key={i} onClick={() => openComponent(item.name, item.link)}><a className={location.pathname === item.link ? 'active' : ''} ><img src={item.icon} alt="" /><label> {item.name}</label></a>
                                    </li>
                                } */}

                                {item.name == "Notification Center" &&
                                    <li ><a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                        aria-controls="offcanvasExample"><img src={item.icon} alt="" />
                                        <label>{item.name}</label> {count > 0 && <span>{count}</span>}</a>
                                    </li>
                                }
                                {item.name == "My Profile" &&
                                    <li key={i} onClick={() => openComponent(item.name, item.link)}><a className={location.pathname === item.link ? 'active' : ''} ><img src={data && data.profileImage && API_BASE_URL + data.profileImage} alt="" /><label> {item.name}</label></a>
                                    </li>
                                }
                                {item.name !== "My Profile" && item.name !== "Notification Center" &&
                                    <li key={i} onClick={() => openComponent(item.name, item.link)}><a className={location.pathname === item.link ? 'active' : ''} ><img src={item.icon} alt="" /><label> {item.name}</label></a>
                                    </li>}
                            </>

                        ))
                        }


                    </ul>
                }

                <div class="sign_out_btn" onClick={() => Logout()}>
                    <a><i class="bi bi-box-arrow-right"></i> <label>Sign Out</label> </a>
                </div>


            </div>
            <div class="offcanvas offcanvas-start notification_sec" tabindex="-1" id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img src="images/Notification-2.png"
                        alt="" />Notifications</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <a onClick={() => readNoti()} class="mark_read">Mark all as Read</a>
                    {dataNoti && Object.keys(dataNoti).length > 0 && Object.keys(dataNoti).map(category => (
                        <div key={category}>
                            <h2>{category}</h2>
                            <ul>
                                {dataNoti[category].map((item, i) => (

                                    (() => {
                                        switch (item.title) {
                                            case "Shortlisted Notification":
                                                return <Link to="/MyProfile"><li className={item.read === false && "active"} key={item._id}>
                                                    <span class="img"><img src={API_BASE_URL + item.profileImage} alt="" /></span>
                                                    <span class="title">{item.title}</span>
                                                    <span>{item.description}</span></li></Link>
                                            case "Profile View":
                                                return <Link to="/MyProfile"><li className={item.read === false && "active"} key={item._id}>
                                                    <span class="img"><img src={API_BASE_URL + item.profileImage} alt="" /></span>
                                                    <span class="title">{item.title}</span>
                                                    <span>{item.description}</span></li></Link>
                                            case "Connection Request Accepted":
                                                return <Link to="/AcceptedMatches"><li className={item.read === false && "active"} key={item._id}>
                                                    <span class="img"><img src={API_BASE_URL + item.profileImage} alt="" /></span>
                                                    <span class="title">{item.title}</span>
                                                    <span>{item.name + " " + item.description}</span></li></Link>
                                            case "New Connection Request":
                                                return <Link to="/Requests"><li className={item.read === false && "active"} key={item._id}>
                                                    <span class="img"><img src={API_BASE_URL + item.profileImage} alt="" /></span>
                                                    <span class="title">{item.title}</span>
                                                    <span>{item.name + " " + item.description}</span></li></Link>
                                            default:
                                                return <li className={item.read === false && "active"} key={item._id}>
                                                    <span class="img"><img src={API_BASE_URL + item.profileImage} alt="" /></span>
                                                    <span class="title">{item.title}</span>
                                                    <span>{item.description}</span></li>
                                        }
                                    })()

                                ))}
                            </ul>
                        </div>
                    ))}


                </div>
            </div>

            {/* <!-- Modal --> */}
            <div class="modal fade edit_profile_modal_new" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Profile Pic </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x"></i></button>
                        </div>
                        <div class="modal-body">

                            <div class="box">
                                {data && data.images && data.images.photos && data.images.photos.length > 0 ?


                                    <div className="row">

                                        {data.images.photos.map((item, i) => (
                                            <div className="col-md-4 col-6 mt-3" onClick={() => onselect(item)}>
                                                <div className="img" style={{ backgroundImage: `url(${API_BASE_URL + item})` }} >
                                                    {pro && pro === item && <span><i class="bi bi-check2"></i></span>}

                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                    : <p>No photos found</p>}
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => SaveProfile()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
