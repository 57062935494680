import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyDZ2zoYm5HkfmirUxvHLBe9Wyf1ehGutt0",
    authDomain: "vedicmarriage-223ba.firebaseapp.com",
    projectId: "vedicmarriage-223ba",
    storageBucket: "vedicmarriage-223ba.appspot.com",
    messagingSenderId: "383102962422",
    appId: "1:383102962422:web:4c9449d94c3d11e4c6bad7",
    measurementId: "G-692L8XM6M3"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
