import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";
import { GetPricingPlans, UpdatePricing, clearPriceState } from "../../../Reducer/StepForms/pricingSlice";
import Swal from 'sweetalert2';


export default function PricingPlans() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [plan, setPlan] = useState("");
    const [planss, setPlans] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.price.success);
    const error = useSelector(state => state.price.error);

    if (success && success.status === 200) {
        dispatch(clearPriceState());
        setTimeout(() => {
            navigate('/UserDashboard');
        }, 1000);

    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPriceState());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPriceState());
                break;
        }
    }


    useEffect(() => {
        getData();
        getPLans();
    }, []);

    async function getPLans() {

        let plans = await dispatch(GetPricingPlans());
        if (plans && plans.payload && plans.payload.data.data) {
            console.log('plaaaaa', plans.payload.data.data);
            setPlans(plans.payload.data.data);
        }

    }

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(UserDetail(dat));
        if (res && res.payload && res.payload.data.data) {
            console.log('res.payload.data.data', res.payload.data.data);
            setPlan(res.payload.data.data.selected_plan ? res.payload.data.data.selected_plan : "");
            // setCode(res.payload.data.data.promoCode ? res.payload.data.data.promoCode : "");
        }
    }


    const selectPlan = (planData) => {
        setOrderData(planData);
        setPlan(planData.planName);

    }


    const Pay = () => {
        if (orderData != null) {
            navigate("/OrderSummary/" + orderData.planName, { state: orderData });
        } else {
            alert("Please select plan!");
        }

    }

    return (

        <>

            <section id="new_price_plane" style={{ backgroundImage: `url(${"images/price_plane_bg.png"})` }}>
                <div class="container-fluid">
                    <div class="scroll">
                        <div class="sh_bg">
                            <div class="row">

                                <div class="col-xl-4 col-3">
                                    <div class="box">
                                        <h2>Select a Pricing Plan</h2>
                                        <h3>No. of Connections <span>per month</span></h3>
                                        <h3>Personal Messages<span>per month</span></h3>
                                        <h3>View Contact Details<span>per month</span></h3>
                                        <h3>Matchmaker’s Recommendations<span>per month</span></h3>
                                        <h3>Validity<span>Months</span></h3>
                                        <h4>Price</h4>
                                    </div>
                                </div>

                                <div class="col-xl-8 col-9">
                                    <div class="box_two">
                                        <div class="row">
                                            {planss.length > 0 && planss.map((item, i) => (

                                                <div class="col-3" onClick={() => selectPlan(item)}>
                                                    <div className={plan && plan === item.planName ? "set three active" : "set three"}>
                                                        <h2>{item.planName}</h2>
                                                        <h3>{item.connectionsCount}</h3>
                                                        <h3>{item.messagesCount}</h3>
                                                        <h3>{item.contactCount}</h3>

                                                        <h3>{item.planName === "Vedic Recommender" ? <i class="bi bi-check2"></i> : <i class="bi bi-x"></i>}</h3>
                                                        <h3>{item.Validity}</h3>
                                                        <h4>Rs. {item.price}</h4>
                                                    </div>
                                                </div>
                                            ))

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-between mt-5">
                                    <a ></a>
                                    <a class="btn" onClick={() => Pay()}>Proceed to Payment <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section >

            {/* {
                open === true &&
                <OrderSummary setOpen={setOpen} setCodeParent={setCode} orderData={orderData} />
            } */}

        </>
    )

}