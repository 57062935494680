import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  AddUserGallery, GetUploaded, DeleteGallery} from '../api';


export const uploadGallery = createAsyncThunk('uploadGallery', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddUserGallery(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetUserUploaded = createAsyncThunk('GetUserUploaded', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUploaded(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const DelGallery = createAsyncThunk('DelGallery', async (formData, { rejectWithValue }) => {
  try {
    const response = await DeleteGallery(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});




const gallerySlice = createSlice({
  name: 'gllry',
  initialState: {
    data: null,
    success: false,
    error: null,
    delImage : false

  },
  reducers: {

    clearGlry: state => {
      state.success = false;
      state.error = null;
      state.delImage = false;
    },
  },
  extraReducers: builder => {
    builder
  
      .addCase(uploadGallery.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(uploadGallery.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(uploadGallery.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetUserUploaded.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetUserUploaded.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetUserUploaded.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(DelGallery.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(DelGallery.fulfilled, (state, action) => {
        // state.success = action.payload;
        state.delImage = action.payload;
        state.error = null;
      })
      .addCase(DelGallery.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })

      
  },
});
export const { clearGlry } = gallerySlice.actions;

export default gallerySlice.reducer;

