import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddSelfy, GetSelfiData } from '../api';

export const uploadSelfi = createAsyncThunk('uploadSelfi', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddSelfy(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetSelfi = createAsyncThunk('GetSelfiData', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetSelfiData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const selfiSlice = createSlice({
  name: 'selfy',
  initialState: {
    data: null,
    success: false,
    error: null,


  },
  reducers: {

    clearSel: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(uploadSelfi.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(uploadSelfi.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(uploadSelfi.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetSelfi.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetSelfi.fulfilled, (state, action) => {
        state.basic = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetSelfi.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearSel } = selfiSlice.actions;

export default selfiSlice.reducer;

