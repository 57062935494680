import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { languages } from "../../Data/content";

export default function
    Language({data, handleChange}) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Prefered Language</label>
                            <select class="form-select"
                                aria-label="Default select example" name="language" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {languages.length > 0 && languages.map((item, i) => (
                                    <option selected={data && data.language === item} value={item} >{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}