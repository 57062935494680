import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function CarrerMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {


    }, []);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Career and Education</h2>
                            <div class="row">
                                <div class="col-12">
                                    <h4>Career Details</h4>
                                </div>
                                <div class="col-6">
                                    <h3>Employer’s Name</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.company_name}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Profession</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.occupation}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Annual Income</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.income}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Working with</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.company_type}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Job Location</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.job_location}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Education Details</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Highest Qualification</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.Ed_qalification}</p>
                                </div>

                                <div class="col-6">
                                    <h3>College</h3>
                                    <p>{user1 && user1.carrers && user1.carrers.Ed_institution}</p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Career and Education</h2>
                            <div class="row">
                                <div class="col-12">
                                    <h4>Career Details</h4>
                                </div>
                                <div class="col-6">
                                    <h3>Employer’s Name</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.company_name}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Profession</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.occupation}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Annual Income</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.income}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Working with</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.company_type}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Job Location</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.job_location}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Education Details</h4>
                                </div>

                                <div class="col-6">
                                    <h3>Highest Qualification</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.Ed_qalification}</p>

                                </div>

                                <div class="col-6">
                                    <h3>College</h3>
                                    <p>{user2 && user2.carrers && user2.carrers.Ed_institution}</p>
                                </div>

                            </div>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}