import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Marital, MotherTounge, ISKCONHKMCentre, initiationNames } from "../../Data/content";
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";
import Sidebar from "../Sidebar";
import { MakerDetail } from "../../../../Reducer/MakerDashboardSlices/makerSlice";
import { UpdateMakerBasic, clearEditMaker } from "../../../../Reducer/MakerDashboardSlices/editMakerSlice";

export default function About() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [errors, setFormErrors] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.makerEdit.success);
    const error = useSelector(state => state.makerEdit.error);

    if (success && success.status === 200) {

        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearEditMaker());
        setTimeout(() => {
            navigate("/MatchMakerEditProfile");
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());
                break;
        }
    }

    useEffect(() => {

        getData();

    }, []);

    async function getData() {
        let dtaa = { uid: auth._id };
        let res = await dispatch(MakerDetail(dtaa));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            setData(responseData.basic.about);

        }
    }


    const handleChangeInput = (e) => {
        const value = e.target.value;
        setData(
            value
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data === "") {
            setFormErrors("This is required field");

        } else {
            let dat = { about: data, uid: auth._id };
            dispatch(UpdateMakerBasic(dat));
            // onNext(dat);
        }
    }


    return (

        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <section id="matchmaker_dashboard">
                        <div class="yourself_set">
                            <h2><span>😎</span>Personality Questions <p>Write About Yourself</p>
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div class="row">
                                    <div class="col-lg-6 mt-3">
                                        <div class="box">
                                            <h3>What you can write</h3>
                                            <h4>Be Authentic</h4>
                                            <h5>Be yourself, and speak from the heart.</h5>

                                            <h4>Highlight Values</h4>
                                            <h5>Mention your important values, like spirituality or family.</h5>

                                            <h4>Interests and Hobbies</h4>
                                            <h5>Share your passions and hobbies.</h5>

                                            <h4>Lifestyle</h4>
                                            <h5>Capture a solo moment to keep the focus on you.</h5>

                                            <h4>Career and Goals</h4>
                                            <h5>Mention your profession and aspirations.</h5>

                                            <h4>Family and Relationship</h4>
                                            <h5>Share what you seek in a relationship.</h5>
                                        </div>
                                    </div>



                                    <div class="col-lg-6 mt-3">
                                        <div class="form-group">
                                            <label class="form-label">Write about yourself</label>
                                            <textarea class="form-control" onChange={handleChangeInput} placeholder="Write your answer here" name="about" value={data && data}></textarea>
                                        </div>
                                        {errors && <p className={"error__feedback"}>{errors}</p>}

                                    </div>

                                    <div class="col-12 text-end mt-3">
                                        <button type="submit">Update</button>

                                    </div>
                                </div>
                            </form>

                        </div>

                    </section>
                </div>
            </section>
        </>



    )

}