import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import { GetListOfUsers } from "../../../../Reducer/MakerDashboardSlices/listUser";
import { GetHome } from "../../../../Reducer/MakerDashboardSlices/homeSlice";

export default function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.makerHome.data);


    useEffect(() => {
        let dat = { uid: auth._id };
        dispatch(GetHome(dat));
    }, []);


    return (
        <section id="full_page_dashboard">
            <div class="container-fluid">
                <Sidebar />
                <section id="matchmaker_dashboard">
                    <h2>Statistics</h2>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 mt-3">
                            <Link to="/PendingRecomendation">
                                <div class="box">
                                    <img src="images/m-1.png" alt="" />
                                    <h3>{data && data.Recomended}</h3>
                                    <p>Recommendations</p>
                                </div>
                            </Link>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt-3">
                            <Link to="/AllUsers">
                                <div class="box">
                                    <img src="images/m-2.png" alt="" />
                                    <h3>{data && data.Users}</h3>
                                    <p>FTM Profiles</p>
                                </div>
                            </Link>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt-3">
                            <a href="ftm.html">
                                <div class="box">
                                    <img src="images/m-3.png" alt="" />
                                    <h3>16</h3>
                                    <p>Successful Matches</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt-3">
                            <Link to="/AllProfiles">
                                <div class="box">
                                    <img src="images/m-4.png" alt="" />
                                    <h3>{data && data.newProfiles}</h3>
                                    <p>Available Profiles</p>
                                </div>
                            </Link>
                        </div>

                        <div class="col-lg-6 mt-4">
                            <div class="box_two">
                                <h4>Action Items</h4>
                                <ul>
                                    <Link to="/PendingProfiles"><li><img src="/images/m-2.png" /><span>{data && data.approvals}</span>Pending FTM Profile Approval <input type="radio" /></li></Link>
                                    <Link to="/PendingRecomendation"><li><img src="/images/m-1.png" /><span>{data && data.PendingRecommendation}</span>Pending Recommendation<input type="radio" /></li> </Link>
                                    <Link to="/PendingSelfyVerification"><li><img src="/images/m-6.png" /><span>{data && data.pendingSelfie}</span>Pending Selfie Verification <input type="radio" /></li></Link>
                                    <Link to="/PendingGovtVerification"><li><img src="/images/m-7.png" /><span>{data && data.pendingDocs}</span>Pending Govt ID Verification <input type="radio" /></li></Link>
                                </ul>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </section>


    )

}