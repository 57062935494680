import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { stresOptions } from "../../Data/content";


export default function Step1({ data, handleChnage, errors }) {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {

    });



    const selectOption = (value) => {

        // Clone the existin array to avoid mutating the state directly
        let updatedSelection = [...data.stresful_situation];

        // Check if the value is already selected
        const selectedIndex = updatedSelection.indexOf(value);
        if (selectedIndex !== -1) {
            // If selected, remove it from the array
            updatedSelection.splice(selectedIndex, 1);
        } else {
            // If not selected, add it to the array
            updatedSelection.push(value);
        }
        // Update the state with the new selection
        handleChnage({ target: { name: "stresful_situation", value: updatedSelection } });
    }



    return (
        <>
            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3>Stress Management</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>How do you handle stressful situations or conflicts?*</h4>
                                <ul>
                                    {stresOptions.length > 0 && stresOptions.map((item, i) => (
                                        <li key={i} className={data.stresful_situation && data.stresful_situation.length > 0 && data.stresful_situation.includes(item) ? 'active' : ''} onClick={() => selectOption(item)} >{item}</li>
                                    ))}
                                    {errors.stresful_situation && <p className={"error__feedback"}>{errors.stresful_situation}</p>}
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="box">
                                <div class="form-group">
                                    <label class="form-label">Can you share an example of a challenging situation you faced and how you dealt with it?</label>
                                    <textarea class="form-control" name="challenging_situation" onChange={handleChnage} value={data.challenging_situation}></textarea>
                                    {errors.challenging_situation && <p className={"error__feedback"}>{errors.challenging_situation}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}