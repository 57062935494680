import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Venues(props) {

    return (
        <section id="our_approach">
            <div class="container">
                <h1>Discover Divine Venues</h1>
                <h2 class="Divine_para"> A Sacred Setting for Your Union 🌺</h2>
                {props.data && props.data.length > 0 &&
                    <div class="row">

                        {props.data.map((item, i) => (
                            <div class="col-lg-4 col-md-6 mb-lg-0 mb-3">
                                <div class="box">
                                    <div class="box_img">
                                        <img src={item.image} alt="img" />
                                    </div>
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                }
            </div>
        </section>

    )

}