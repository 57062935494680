import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { clearUser } from "../../../../Reducer/authSlice";


export default function DefaultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const Logout = () => {
        dispatch(clearUser());
        setTimeout(() => {
            navigate("/Login");
        }, 2000);

    }

    return (


        <section id="login_sec" class="sign_up match_signup" style={{ backgroundImage: `url(${'images/matchmaker_signup.png'})` }}>
            <div class="container-fluid p-0 h-100">
                <div class="row h-100">

                    <div class="col-lg-4 col-md-5 order">
                        <Link to="/"><i class="bi bi-arrow-left"></i> Back to Home</Link>
                        <div class="box_one">
                            <div class="login-img">
                                <img src="images/login-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-7">
                        <div class="upload_finished h-100">
                            <div class="row">
                                <div class="col-xxl-7">
                                    <div class="box">
                                        {/* <h2>Congratulations! 🎉 On to the Next Step!</h2> */}
                                        <h3>Your Profile Verification is Underway</h3>

                                        <p><b>Congratulationson </b> completing your onboarding! 🎉 Your profile is currently
                                            undergoing
                                            our
                                            verification process, a crucial step in ensuring the authenticity and security of our
                                            community. This process will be completed within the <span>next 24 hours.</span>
                                        </p>

                                        <h4>Why it matters? </h4>
                                        <p>Verification boosts <span>trust</span> and <span>credibility,</span> creating a secure
                                            space
                                            for genuine connections.</p>

                                        <h4>Ready for the Next Step?</h4>
                                        <p>Once verified, enjoy full access to all features and start connecting!</p>
                                        <p><b>Thanks for your patience! Excited to have you on board. 🌈🔒</b></p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 text-end mt-5">
                                <a class="btn upload" onClick={Logout}>Log out</a>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </section>


    )

}