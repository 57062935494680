import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { SubmitContactForm, clearContactForm } from "../../../../Reducer/FrontendForms/contactFormSlice";
const schema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    number: Yup.string().min(10, "Phone number must be minimum 10 digits").max(12, "Phone must be less than 12 digits").required("Phone number is required"),
    name: Yup.string().required("Name is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
});


export default function ContactForm() {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const success = useSelector(state => state.contactForm.success);
    const error = useSelector(state => state.contactForm.error);

    if (success && success.status === 200) {
        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearContactForm());
        reset();
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearContactForm());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearContactForm());
                break;
        }
    }



    const onError = (errors) => {
        console.log(errors);
    };


    const onSubmit = async (datas) => {

        let formData = new FormData();
        formData.append('name', datas.name);
        formData.append('email', datas.email);
        formData.append('number', datas.number);
        formData.append('subject', datas.subject);
        formData.append('message', datas.message);
        dispatch(SubmitContactForm(formData));

    };

    return (
        <>
            <section id="contact_us">
                <div class="container">
                    <h1>Contact Us</h1>
                    <h2>Connect with us to start your journey. Whether it's a question, a curiosity, or a desire to
                        explore our
                        offerings, your sacred union awaits. 🕊️</h2>
                    <div class="row">
                        <div class="col-lg-6 col-md-7">

                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Full Name</label>
                                            <input type="text" class="form-control" {...register('name')} />
                                            {errors.name && <p className={"error__feedback"}>{errors.name.message}</p>}
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Phone Number</label>
                                            <input type="tel" class="form-control" {...register('number')} />
                                            {errors.number && <p className={"error__feedback"}>{errors.number.message}</p>}
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Email Address</label>
                                            <input type="email" class="form-control"  {...register('email')} />
                                            {errors.subject && <p className={"error__feedback"}>{errors.email.message}</p>}
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Subject</label>
                                            <input type="text" class="form-control" {...register('subject')} />
                                            {errors.subject && <p className={"error__feedback"}>{errors.subject.message}</p>}
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">How can we help? </label>
                                            <textarea class="form-control" {...register('message')}></textarea>
                                            {errors.message && <p className={"error__feedback"}>{errors.message.message}</p>}
                                        </div>

                                    </div>

                                    <div class="col-12">
                                        <button type="submit">Send a Message</button>
                                    </div>

                                </div>
                            </form>

                        </div>

                        <div class="col-lg-6 col-md-5 d-none d-md-block">
                            <div class="img">
                                <img src="images/contact-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}