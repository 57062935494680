import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { StructuredRoutine } from "../../Data/content";


export default function Step8({ data, handleChnage, errors }) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    const selectOption = (item, name) => {
        handleChnage({ target: { name: name, value: item } });
    };


    return (
        <>
            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3><span>Social</span> Interaction 5</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>Do you prefer a structured routine?*</h4>
                                <ul>
                                    {StructuredRoutine.length > 0 && StructuredRoutine.map((item, i) => (
                                        <li key={i} className={data.structured_routine === item ? 'active' : ''} onClick={() => selectOption(item, "structured_routine")}>{item}</li>
                                    ))}
                                    {errors.structured_routine && <p className={"error__feedback"}>{errors.structured_routine}</p>}
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <div class="form-group">
                                    <label class="form-label">Discuss your approach to balancing structure and
                                        flexibility in your daily life, highlighting the benefits and challenges you
                                        face with each approach. 🔄⚖️</label>
                                    <textarea class="form-control" name="benefits_and_challenges" value={data.benefits_and_challenges} onChange={handleChnage}></textarea>
                                    {errors.benefits_and_challenges && <p className={"error__feedback"}>{errors.benefits_and_challenges}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )

}