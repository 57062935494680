import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Marital, MotherTounge, ISKCONHKMCentre, initiationNames } from "../../Data/content";
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";

export default function Step2({ onNext }) {
    const [countryId, setCountryId] = useState(null);
    const [CountryName, setCountry] = useState(null);
    const [stateId, setstateId] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [basic, setbasic] = useState({});
    const [errors, setFormErrors] = useState({});


    useEffect(() => {

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        basic.fullCountry = CountryName;
        basic.fullState = state;
        basic.fullCity = city;
        let values = validate(basic);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;
        }
        if (chk) {
            onNext(basic);
        } else {
            setFormErrors(validate(basic));
        }

    }

    const validate = (data) => {
        let err = {};
        if (!data.name) {
            err.name = "This field is required!";
        } if (!data.gender) {
            err.gender = "This field is required!";
        }
        if (!data.marital_status) {
            err.marital_status = "This field is required!";
        }
        if (!data.mother_tounge) {
            err.mother_tounge = "This field is required!";
        }
        if (!data.associated_temple) {
            err.associated_temple = "This field is required!";
        }
        if (!data.Iskon_center) {
            err.Iskon_center = "This field is required!";
        }
        if (!data.initiatedName) {
            err.initiatedName = "This field is required!";
        }
        if (CountryName == null) {
            err.fullCountry = "This field is required!";
        }
        if (state == null) {
            err.fullState = "This field is required!";
        }
        if (city == null) {
            err.fullCity = "This field is required!";
        }
        return err;
    }

    const handleChangeInput = (e) => {
        const value = e.target.value;
        setbasic({
            ...basic,
            [e.target.name]: value
        });
    }


    return (

        <div class="box-two h-100">
            <form onSubmit={handleSubmit}>
                <div class="text-center">
                    <a href="index.html">  <img class="logo_login" src="images/Logo.png" alt="" /></a>
                </div>
                <h2>Basic Details</h2>

                <div class="row mt-5">

                    <div class="col-lg-6">
                        <div class="form-group mess">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" name="name" onChange={handleChangeInput} />
                            {errors.name && <p className={"error__feedback"}>{errors.name}</p>}

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Gender</label>
                            <select class="form-select" aria-label="Default select example" name="gender" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.gender && <p className={"error__feedback"}>{errors.gender}</p>}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Marital Status</label>
                            <select class="form-select" aria-label="Default select example" name="marital_status" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {Marital.length > 0 && Marital.map((item, i) => (
                                    <option value={item}>{item}</option>
                                ))}

                            </select>
                            {errors.Marital_status && <p className={"error__feedback"}>{errors.Marital_status}</p>}

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Mother Tongue</label>
                            <select class="form-select" aria-label="Default select example" name="mother_tounge" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {MotherTounge.length > 0 && MotherTounge.map((item, i) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.mother_tounge && <p className={"error__feedback"}>{errors.mother_tounge}</p>}

                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group mb-2">
                            <label class="form-label">Are you associated with ISKCON Bangalore and HKM Group
                                of Temples </label>
                        </div>
                        <div class="row mb-3">

                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" onChange={handleChangeInput}
                                        id="flexRadioDefault1" value="Yes" name="associated_temple" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Yes
                                    </label>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="associated_temple"
                                        id="flexRadioDefault2" value="No" onChange={handleChangeInput} />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        No
                                    </label>
                                </div>
                            </div>
                            {errors.associated_temple && <p className={"error__feedback"}>{errors.associated_temple}</p>}


                        </div>


                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Center</label>
                            <select class="form-select" aria-label="Default select example" name="Iskon_center" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {ISKCONHKMCentre.length > 0 && ISKCONHKMCentre.map((item, i) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.Iskon_center && <p className={"error__feedback"}>{errors.Iskon_center}</p>}

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Initiated Name</label>
                            {/* <select class="form-select" aria-label="Default select example" name="initiatedName" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {initiationNames.length > 0 && initiationNames.map((item, i) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select> */}

                            <input type="text" class="form-control" name="initiatedName" onChange={handleChangeInput} />

                            {errors.initiatedName && <p className={"error__feedback"}>{errors.initiatedName}</p>}

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group flag_sing">
                                    <label class="form-label">Country</label>
                                    <CountrySelect
                                        showFlag={false}
                                        inputClassName="form-control"
                                        class="form-select ps-5"
                                        onChange={(e) => {
                                            setCountryId(e.id);
                                            setCountry(e);
                                            setstateId(null);
                                            setCity(null);
                                            setState(null);
                                        }}

                                        // defaultValue={CountryName}
                                        placeHolder="Select Country"
                                        name="fullCountry"

                                    />
                                </div>
                                {errors.fullCountry && <p className={"error__feedback"}>{errors.fullCountry}</p>}

                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label">State</label>
                                    <StateSelect
                                        showFlag={false}
                                        inputClassName="form-control"

                                        countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                        onChange={(e) => {
                                            console.log('eeeee', e);
                                            if (e && e.id && e.name) {
                                                setstateId(e.id);
                                                setState(e);
                                                setCity("");
                                            } else {
                                                console.log('else ');
                                                setState(null)
                                            }
                                        }}
                                        // defaultValue={state}
                                        placeHolder="Select State"

                                        name="fullState"
                                    />
                                    {errors.fullState && <p className={"error__feedback"}>{errors.fullState}</p>}

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">City</label>
                            <CitySelect
                                showFlag={false}
                                inputClassName="form-control"

                                countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                stateid={state && state.id ? state.id : stateId}

                                onChange={(e) => {

                                    if (e && e.id && e.name) {
                                        console.log(e);
                                        setCity(e)
                                    } else {
                                        setCity(null)
                                    }
                                }}
                                // defaultValue={city}
                                placeHolder="Select City"
                                name="fullCity"

                            />
                            {errors.fullCity && <p className={"error__feedback"}>{errors.fullCity}</p>}

                        </div>
                    </div>


                    <div class="col-12">

                        <div class="sign_up_width">

                            <div class="text-center">
                                <button type="submit"> Next</button>
                                {/* <a class="btn" type="submit">Next</a> */}
                            </div>

                        </div>
                    </div>

                </div>



            </form>

        </div>


    )

}