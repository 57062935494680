import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ChantingRounds, templevisit, spritualRole, spritualhours, parentPrcatices, Books_Names, EkadashiOp, ISKCONHKMCentre, AssociatedType, DevotionalServices, AttendedSeminar, DevotionalCommunity, Krishna_Consciousness_marriage, SrilaPrabhupadaTeach, SpiritualGrowth, initiatedStatus, initiationNames } from "../../../Data/content";
import Select from 'react-select';


export default function Form2({ data, handleChange, errors, seecteBooks }) {
    const dispatch = useDispatch();
    const [Initiation, setInitiation] = useState("");
    const [addiVal, setaddiVal] = useState("");
    const [openAdditional, setOpenAdditional] = useState(false);
    // const [books, selectedBooks] = useState([]);
    const options = Books_Names.map(name => ({ value: name, label: name }));

    console.log('datadata', data);

    useEffect(() => {
        setInitiation(data.initiated);
        if (data.temple_associated_type !== "") {
            setOpenAdditional(true);
            setaddiVal(data.temple_associated_type);
        }
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "initiated") {
            setInitiation(value);

        }
        if (name === "temple_associated_type") {
            console.log('typeeee', value);
            setOpenAdditional(true);
            setaddiVal(value);
        }

        handleChange(e);

    }

    const handleLanguageChange = (selectedOptions) => {
        seecteBooks(selectedOptions);
    };


    return (

        <>
            <div class="row">
             
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Since when have you been practicing Krishna Consciousness?*</label>
                        <input type="number" class="form-control" min="0" max="99" name="practicing_time_period" value={data.practicing_time_period} onChange={handleChange} />
                        <span>Yr.</span>
                        {errors.practicing_time_period && <p className={"error__feedback"}>{errors.practicing_time_period}</p>}
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">On a daily basis, how many rounds of the Hare Krishna Mahamantra do you chant?*</label>
                        <select class="form-select" aria-label="Default select example" name="chanting_rounds" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {ChantingRounds.length > 0 && ChantingRounds.map((item, i) => (
                                <option selected={data.chanting_rounds === item} value={item}>{item}</option>
                            ))}
                        </select>
                        {errors.chanting_rounds && <p className={"error__feedback"}>{errors.chanting_rounds}</p>}
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">How often do you visit temple?*</label>
                        <select class="form-select" aria-label="Default select example" name="temple_visit" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {templevisit.length > 0 && templevisit.map((item, i) => (
                                <option selected={data.temple_visit === item} value={item}>{item}</option>
                            ))}
                        </select>
                        {errors.temple_visit && <p className={"error__feedback"}>{errors.temple_visit}</p>}

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Spirituality's role in your life?*</label>
                        <select class="form-select" aria-label="Default select example" name="spirituality_role" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {spritualRole.length > 0 && spritualRole.map((item, i) => (
                                <option selected={data.spirituality_role === item} value={item}>{item}</option>
                            ))}
                        </select>
                        {errors.spirituality_role && <p className={"error__feedback"}>{errors.spirituality_role}</p>}
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Who is your spiritual master?*</label>
                                <input type="text" class="form-control" name="spiritual_master" value={data.spiritual_master} onChange={handleChange} />
                                {errors.spiritual_master && <p className={"error__feedback"}>{errors.spiritual_master}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Are you initiated?*</label>
                        <select class="form-select" aria-label="Default select example" name="initiated" onChange={handleInputChange}>
                            <option selected disabled>Select</option>
                            {initiatedStatus.length > 0 && initiatedStatus.map((item, i) => (
                                <option selected={data.initiated === item} value={item}>{item}</option>
                            ))}
                        </select>

                        {errors.initiated && <p className={"error__feedback"}>{errors.initiated}</p>}
                    </div>
                </div>

                {Initiation === "Yes" &&
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Initiation Name*</label>
                            <input type="text" class="form-control" name="Initiation_name" value={data.Initiation_name} onChange={handleChange} />
                            {errors.Initiation_name && <p className={"error__feedback"}>{errors.Initiation_name}</p>}
                            {/* <select class="form-select" aria-label="Default select example" name="Initiation_name"onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {initiationNames.length > 0 && initiationNames.map((item, i) => (
                                <option selected={data.Initiation_name === item} value={item}>{item}</option>
                            ))}
                        </select> */}
                        </div>
                    </div>
                }

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Do you follow Four Regulative Principles*</label>
                        <h6>
                            <div class="form-check">
                                <input class="form-check-input" type="radio"
                                    id="flexRadioDefault1" name="four_Regulative_Principles" value="Yes" onChange={handleChange} checked={data.four_Regulative_Principles === "Yes"} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="four_Regulative_Principles" onChange={handleChange} value="No"
                                    id="flexRadioDefault1" checked={data.four_Regulative_Principles === "No"} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    No
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="four_Regulative_Principles" onChange={handleChange} value="Don’t Know"
                                    id="flexRadioDefault1" checked={data.four_Regulative_Principles === "Don’t Know"} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Don’t Know
                                </label>
                            </div>

                        </h6>
                        {errors.four_Regulative_Principles && <p className={"error__feedback"}>{errors.four_Regulative_Principles}</p>}
                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="form-label">Do your parents practice Krishna Consciousness?*
                        </label>
                        <select class="form-select" aria-label="Default select example" name="parents_practice_Krishna" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {parentPrcatices.length > 0 && parentPrcatices.map((item, i) => (
                                <option selected={data.parents_practice_Krishna === item} value={item}>{item}</option>
                            ))}
                        </select>
                        {errors.parents_practice_Krishna && <p className={"error__feedback"}>{errors.parents_practice_Krishna}</p>}

                    </div>
                </div>


                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Select the list of Srila Prabhupada's books read by you*</label>

                        <Select
                            class="form-select"
                            options={options}
                            isMulti
                            name="selectedBooks"
                            value={data.selectedBooks}
                            onChange={handleLanguageChange}

                        />
                        {errors.selectedBooks && <p className={"error__feedback"}>{errors.selectedBooks}</p>}

                    </div>
                </div>


                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">What Srila Prabhupada book are you currently reading?*</label>
                        <select class="form-select" aria-label="Default select example" name="currently_reading" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {Books_Names.length > 0 && Books_Names.map((item, i) => (
                                <option selected={data.currently_reading === item} value={item}>{item}</option>
                            ))}

                        </select>
                        {errors.currently_reading && <p className={"error__feedback"}>{errors.currently_reading}</p>}

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Do you observe fasting on Ekadashi days?*</label>
                        <select class="form-select" aria-label="Default select example" name="ekadashi_fasting" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {EkadashiOp.length > 0 && EkadashiOp.map((item, i) => (
                                <option selected={data.ekadashi_fasting === item} value={item}>{item}</option>

                            ))}

                        </select>
                        {errors.ekadashi_fasting && <p className={"error__feedback"}>{errors.ekadashi_fasting}</p>}

                    </div>
                </div>



                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Which ISKCON/HKM Centre are you associated with?*</label>
                        <select class="form-select" aria-label="Default select example" name="iskcon_associted_with" onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {ISKCONHKMCentre.length > 0 && ISKCONHKMCentre.map((item, i) => (
                                <option selected={data.iskcon_associted_with === item} value={item}>{item}</option>

                            ))}

                        </select>
                        {errors.iskcon_associted_with && <p className={"error__feedback"}>{errors.iskcon_associted_with}</p>}
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">How are you associated with the temple?*</label>
                        <select class="form-select" aria-label="Default select example" name="temple_associated_type" onChange={handleInputChange} >

                            <option selected disabled>Select</option>
                            {AssociatedType.length > 0 && AssociatedType.map((item, i) => (
                                <option selected={data.temple_associated_type === item} value={item}>{item}</option>

                            ))}
                        </select>
                        {errors.temple_associated_type && <p className={"error__feedback"}>{errors.temple_associated_type}</p>}
                    </div>
                </div>
            </div>

            {openAdditional === true &&
                <>
                    <h5 class="part_sec_count">Additional Spiritual Questions</h5>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?*</label>
                                <h6>

                                    {AttendedSeminar.length > 0 && AttendedSeminar.map((item, i) => (
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="attended_seminar" onChange={handleChange}
                                                id="flexRadioDefault1" value={item} checked={data.attended_seminar === item} />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {item}
                                            </label>
                                        </div>
                                    ))}

                                </h6>
                                {errors.attended_seminar && <p className={"error__feedback"}>{errors.attended_seminar}</p>}
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Are you open to engaging in devotional services or
                                    seva together as a couple?*</label>
                                <h6>
                                    {DevotionalServices.length > 0 && DevotionalServices.map((item, i) => (
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="engaged_devotional_services" onChange={handleChange}
                                                id="flexRadioDefault1" value={item} checked={data.engaged_devotional_services === item} />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {item}
                                            </label>
                                        </div>
                                    ))}


                                </h6>
                                {errors.engaged_devotional_services && <p className={"error__feedback"}>{errors.engaged_devotional_services}</p>}
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">How important is living in close proximity to a
                                    temple
                                    or devotional community for you?*</label>
                                <select class="form-select" aria-label="Default select example" name="living_in_temple" onChange={handleChange}>
                                    <option selected disabled>Select</option>
                                    {DevotionalCommunity.length > 0 && DevotionalCommunity.map((item, i) => (
                                        <option selected={data.living_in_temple === item} value={item}>{item}</option>

                                    ))}

                                </select>
                                {errors.living_in_temple && <p className={"error__feedback"}>{errors.living_in_temple}</p>}
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Are you open to further deepening your
                                    understanding
                                    and practice of Krishna Consciousness after marriage?*</label>
                                <h6>
                                    {Krishna_Consciousness_marriage.length > 0 && Krishna_Consciousness_marriage.map((item, i) => (
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="Krishna_Consciousness_after_marriage" onChange={handleChange} checked={data.Krishna_Consciousness_after_marriage === item}
                                                id="flexRadioDefault1" value={item} />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {item}
                                            </label>
                                        </div>
                                    ))}

                                </h6>
                                {errors.Krishna_Consciousness_after_marriage && <p className={"error__feedback"}>{errors.Krishna_Consciousness_after_marriage}</p>}
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">What is your understanding and acceptance of the
                                    principles and teachings of Srila Prabhupada?*</label>
                                {SrilaPrabhupadaTeach.length > 0 && SrilaPrabhupadaTeach.map((item, i) => (
                                    <div class="form-check mt-lg-3 mt-2">
                                        <input class="form-check-input" type="radio" name="tech_of_Srila_Prabhupada" checked={data.tech_of_Srila_Prabhupada === item} onChange={handleChange}
                                            id="flexRadioDefault2" value={item} />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                {errors.tech_of_Srila_Prabhupada && <p className={"error__feedback"}>{errors.tech_of_Srila_Prabhupada}</p>}
                            </div>

                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">How do you envision supporting and encouraging each other's
                                    spiritual growth within the marriage?*</label>

                                {SpiritualGrowth.length > 0 && SpiritualGrowth.map((item, i) => (
                                    <div class="form-check mt-lg-3 mt-2">
                                        <input class="form-check-input" type="radio" name="spritulGrowthMarriage" checked={data.spritulGrowthMarriage === item} onChange={handleChange}
                                            id="flexRadioDefault2" value={item} />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                {errors.spritulGrowthMarriage && <p className={"error__feedback"}>{errors.spritulGrowthMarriage}</p>}



                            </div>

                        </div>

                        <h4>Descriptive Questions</h4>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">How do you envision incorporating Krishna
                                    Consciousness into your married life?</label>
                                <textarea class="form-control" name="incorporating_Krishna_Consciousness" onChange={handleChange}>{data.incorporating_Krishna_Consciousness}</textarea>
                                {errors.incorporating_Krishna_Consciousness && <p className={"error__feedback"}>{errors.incorporating_Krishna_Consciousness}</p>}
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">How do you handle challenges or conflicts in your
                                    spiritual journey?</label>
                                <textarea class="form-control" name="conflicts_spiritualJourny" onChange={handleChange}>{data.conflicts_spiritualJourny}</textarea>
                                {errors.conflicts_spiritualJourny && <p className={"error__feedback"}>{errors.conflicts_spiritualJourny}</p>}
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Do you have any specific spiritual goals or
                                    aspirations that you would like to share?</label>
                                <textarea class="form-control" name="specific_spiritual_goals" onChange={handleChange}>{data.specific_spiritual_goals}</textarea>
                                {errors.specific_spiritual_goals && <p className={"error__feedback"}>{errors.specific_spiritual_goals}</p>}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Do you have a spiritual mentor or guide? If yes, please provide
                                    details.?</label>
                                <textarea class="form-control" name="spiritual_mentor" onChange={handleChange}>{data.spiritual_mentor}</textarea>
                                {errors.spiritual_mentor && <p className={"error__feedback"}>{errors.spiritual_mentor}</p>}
                            </div>
                        </div>



                        {addiVal === "Full-time Temple Devotee" && <>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">What temple service are you engaged in?</label>
                                    <textarea class="form-control" name="temple_services" onChange={handleChange}>{data.temple_services}</textarea>
                                    {errors.temple_services && <p className={"error__feedback"}>{errors.temple_services}</p>}
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">Department Name?</label>
                                    <input type="text" class="form-control" name="department_name" onChange={handleChange} defaultValue={data.department_name} />
                                    {errors.department_name && <p className={"error__feedback"}>{errors.department_name}</p>}                              </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">Designation?</label>
                                    <input type="text" class="form-control" name="designation_name" onChange={handleChange} defaultValue={data.designation_name} />
                                    {errors.designation_name && <p className={"error__feedback"}>{errors.designation_name}</p>}

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">What are the various roles and experiences you have had in your
                                        service at the temple?</label>
                                    <textarea class="form-control" name="roles_and_experiences" onChange={handleChange} >{data.roles_and_experiences}</textarea>
                                    {errors.roles_and_experiences && <p className={"error__feedback"}>{errors.roles_and_experiences}</p>}
                                </div>
                            </div>
                        </>}

                    </div>
                </>
            }

        </>
    )

}
