import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { API_BASE_URL } from "../../Data/content";



export default function About(props) {

    return (
        <>
            <section id="about_us">
                <div class="container">
                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-7 order_sec">
                            <h1>About Us - Vedic Matrimony</h1>
                            <p>{props.data && props.data.content}</p>
                            <a href="#" class="btn">Begin Your Journey Today</a>
                        </div>

                        <div class="col-lg-6 col-md-5">
                            <div class="about_img">
                                <img src={props.data && props.data.image && API_BASE_URL + props.data.image} alt="" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )

}