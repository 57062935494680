import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";



export default function Tab4() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);

    useEffect(() => {
        let dat = { uid: auth._id, type: "carrer" };
        dispatch(GetTabsData(dat));
    }, []);

    console.log('tabbb', data);



    return (
        <div class="set">
            <div class="row">

                <div class="col-12">
                    <h5>Career Details</h5>
                </div>


                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Employer’s Name</h4>
                    <p>{data && data.company_name ? data.company_name : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Profession</h4>
                    <p>{data && data.occupation ? data.occupation : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Annual Income</h4>
                    <p>{data && data.income ? data.income : "-"}</p>
                </div>

                {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <h4>Working with</h4>
                <p>Non-profit</p>
            </div> */}

                <div class="col-12">
                    <h4>Job Location</h4>
                    <p>{data && data.job_location ? data.job_location : "-"}</p>
                </div>

                <div class="col-12">
                    <h5>Education Details</h5>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Highest Qualification</h4>
                    <p>{data && data.Ed_qalification ? data.Ed_qalification : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>College</h4>
                    <p>{data && data.Ed_institution ? data.Ed_institution : "-"}</p>
                </div>

            </div>
        </div>

    )
}