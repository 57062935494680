import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetFamily, AddFamilyInfo } from '../api';

export const GetFamilyInfo = createAsyncThunk('GetFamilyInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetFamily(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const SaveFamilyInfo = createAsyncThunk('Save famillyyyyyy', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddFamilyInfo(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const familySlice = createSlice({
  name: 'family',
  initialState: {
    data: null,
    success: false,
    error: null,
 
  },
  reducers: {

    clearStateFamily: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SaveFamilyInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveFamilyInfo.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveFamilyInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetFamilyInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetFamilyInfo.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetFamilyInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })  
  },
});
export const { clearStateFamily } = familySlice.actions;

export default familySlice.reducer;

