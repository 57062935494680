import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Marital } from "../../Data/content";

export default function
    AstroFilter({ data, handleChange }) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Manglik Status</label>
                            <select class="form-select" aria-label="Default select example" name="manglik" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                <option selected={data && data.manglik === "Yes"} value="Yes">Yes</option>
                                <option selected={data && data.manglik === "No"} value="No">No</option>
                                <option selected={data && data.manglik === "I dont know"} value="I dont know">I dont know</option>
                            </select>
                        </div>
                    </div>
               
                </div>
            </div>
        </>
    )
}