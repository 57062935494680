import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";



export default function Tab2() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);


    useEffect(() => {
        let dat = { uid: auth._id, type: "astro" };
        dispatch(GetTabsData(dat));
    }, []);

    console.log('tabbb', data);


    return (
        <>

            <div class="set">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Date of Birth</h4>
                        <p>{data && data.dob ? data.dob : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Time of Birth</h4>
                        <p>{data && data.time ? data.time + ' ' + data.ampm : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Mangalik / Chevval Dosham</h4>
                        <p>{data && data.manglik ? data.manglik : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Nakshatra</h4>
                        <p>{data && data.nakshtra ? data.nakshtra : "-"}</p>
                    </div>

                    <div class="col-12">
                        <h4>Place of Birth</h4>
                        <p>{data && data.birth_place ? data.birth_place : "-"}</p>
                    </div>
                    <div class="col-12">
                        <h5>Religious Background</h5>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <h4>Religion</h4>
                                <p>{data && data.religion ? data.religion : "-"}</p>
                            </div>

                            {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <h4>Community</h4>
                                <p>{data && data.community ? data.community : "-"}</p>
                            </div> */}

                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <h4>Gothram</h4>
                                <p>{data && data.gotra ? data.gotra : "-"}</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>

    )
}