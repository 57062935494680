import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllCoupons, ApplyPromoCheck } from '../api';

export const GetCoupons = createAsyncThunk('GetCoupons', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetAllCoupons(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const ApplyPromo = createAsyncThunk('Apply code', async (formData, { rejectWithValue }) => {
    try {
        const response = await ApplyPromoCheck(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
})


const orderSummarySlice = createSlice({
    name: 'ordrSmry',
    initialState: {
        data: [],
        success: false,
        error: null,

    },
    reducers: {
        clearOrderState: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetCoupons.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetCoupons.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetCoupons.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })

    },
});
export const { clearOrderState } = orderSummarySlice.actions;

export default orderSummarySlice.reducer;

