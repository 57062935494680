import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import SideProfile from "./SideProfile";
import TabDetails from "./TabDetails";
import Sidebar from "../Sidebar";

export default function MyProfile(props) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    const res = () => {
        props.editRes();
    }


    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar  />
                    <div class="view_profile">
                        <div class="row">
                            <SideProfile />
                            <TabDetails reForParent={res} />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )

}