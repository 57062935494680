
import Tab1 from "../UserDashboard/MyProfile/TabDetails/Tab1";
import Tab2 from "../UserDashboard/MyProfile/TabDetails/Tab2";
import Tab3 from "../UserDashboard/MyProfile/TabDetails/Tab3";
import Tab4 from "../UserDashboard/MyProfile/TabDetails/Tab4";
import Tab5 from "../UserDashboard/MyProfile/TabDetails/Tab5";
import Tab6 from "../UserDashboard/MyProfile/TabDetails/Tab6";
import Tab7 from "../UserDashboard/MyProfile/TabDetails/Tab7";
import AllMatches from "../UserDashboard/Dashboard/AllMatches";
import NearMe from "../UserDashboard/Dashboard/NearMe";
import NewMatches from "../UserDashboard/Dashboard/NewMatches";
import RecentlyViewed from "../UserDashboard/Dashboard/RecentlyViewed";
import Shortlisted from "../UserDashboard/Dashboard/Shortlisted";
// import ViewProfileTab1 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab1";
// import ViewProfileTab2 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab2";
// import ViewProfileTab3 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab3";
// import ViewProfileTab4 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab4";
// import ViewProfileTab5 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab5";
// import ViewProfileTab6 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab6";
// import ViewProfileTab7 from "../UserDashboard/ViewProfile/Tabbing/ViewProfileTab7";

// export const API_BASE_URL = 'https://vedicapi.digittrix.com/';
// export const API_BASE_URL = 'https://api.vedicmarriage.ai/';
// export const API_BASE_URL = 'http://localhost:5800/';
let API_BASE_URL;
if (process.env.NODE_ENV === 'development') {
    API_BASE_URL = 'http://localhost:5800/';
} else if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = 'https://api.vedicmarriage.ai/';
}
export { API_BASE_URL };
export const HeaderData = [
    {
        "id": "1",
        "name": "Home",
        "link": "/"
    },
    {
        "id": "2",
        "name": "Kundali Matching",
        "link": "/KundaliMatching"

    },
    {
        "id": "3",
        "name": "Wedding Venue Planner",
        "link": "/WeddingVenuePlanner"

    },
    {
        "id": "4",
        "name": "Marriage Counselling",
        "link": "/MarriageCouncelling"

    },
    {
        "id": "5",
        "name": "About Us",
        "link": "/AboutUs"
    }

];

export const userSidebar = [
    {
        "name": "MyMatches",
        "icon": "/images/HEART.png",
        "link": "/MyMatches",
    },
    {
        "name": "Recommended",
        "icon": "/images/Recommendations.png",
        "link": "/Recommended",
    },
    {
        "name": "Messages",
        "icon": "/images/Message.png",
        "link": "/Messages",
    },
    {
        "name": "Notification Center",
        "icon": "/images/Notification.png",
        "link": "",
    },
    {
        "name": "Requests",
        "icon": "/images/Recommendations.png",
        "link": "/Requests",
    },
    {
        "name": "Accepted Matches",
        "icon": "/images/HEART.png",
        "link": "/AcceptedMatches",
    },
    {
        "name": "Plans",
        "icon": "/images/Pciing.png",
        "link": "/Plans",
    },
    {
        "name": "My Profile",
        "icon": "/images/team.png",
        "link": "/MyProfile",
    },
    {
        "name": "Settings",
        "icon": "/images/Settings.png",
        "link": "/AccountSettings",
    },
    {
        "name": "Support",
        "icon": "/images/Support.png",
        "link": "/Support",
    },
];

export const MyProfileTabs = [
    {
        name: "Basic info",
        component: <Tab1 />
    },
    {
        name: "Astro",
        component: <Tab2 />
    },
    {
        name: "Family",
        component: <Tab3 />
    },
    {
        name: "Carrer & Education",
        component: <Tab4 />
    },
    {
        name: "Spirituality",
        component: <Tab5 />
    },
    {
        name: "Personality",
        component: <Tab6 />
    },
    {
        name: "Preferences",
        component: <Tab7 />
    },
];

export const userDashTab = [
    {
        name: "All Matches",
        count: 0
    },
    {
        name: "Matches Near Me",
        count: 0
    },
    {
        name: "New Matches",
        count: 0
    },
    {
        name: "Recently Viewed",
        count: 0
    },
    {
        name: "Shortlisted profiles",
        count: 0
    }

];

export const ViewProfileTabs = [
    {
        name: "Basic info",
        // component: <ViewProfileTab1 />
    },
    {
        name: "Astro",
        // component: <ViewProfileTab2 />
    },
    {
        name: "Family",
        // component: <ViewProfileTab3 />
    },
    {
        name: "Carrer & Education",
        // component: <ViewProfileTab4 />
    },
    {
        name: "Spirituality",
        // component: <ViewProfileTab5 />
    },
    {
        name: "Personality",
        // component: <ViewProfileTab6 />
    },
    {
        name: "Preferences",
        // component: <ViewProfileTab7 />
    },
];

export const Filtertabs = [
    {
        name: "Age",
    },
    {
        name: "Height",
    },
    {
        name: "Language",
    },
    {
        name: "Location",
    },
    {
        name: "Education",
    },
    {
        name: "Income",
    },
    {
        name: "Community",
    },
    {
        name: "Manglik Status",
    },
    {
        name: "Marital Status",
    },
    {
        name: "Initiation",
    },
    {
        name: "Partner's affiliation",
    },
    {
        name: "Occupation",

    },
    {
        name: "Food",
    },
    {
        name: "Personality"
    }
];

export const PersonalityFilter = [
    "Extraversion:😄", "Introversion:😌", "Intuition:🌟", "Sensing:🌿", "Judging:❤️", "Perceiving:🔍", "Spiritual and Philosophical: 🙏📚", "Very Comfortable: 🥳 Enjoys socializing", "Uncomfortable: 😳 Introverted, but open to socializing", "Neutral: 😐 Flexible, Versatile,depends on the situation", "Slightly Uncomfortable: 😬 Prefers smaller gatherings", "Extremely Uncomfortable: 🙅‍♀️ Very Reserved, avoids large social gatherings whenever possible",
];

export const MakerDashSingleView = [
    {
        name: "Basic info",

    },
    {
        name: "Astro",

    },
    {
        name: "Family",
    },
    {
        name: "Carrer & Education",
    },
    {
        name: "Spirituality",
    },
    {
        name: "Personality",
    },
    {
        name: "Preferences",
    },
    {
        name: "Gallery",
    },
    {
        name: "Documents"
    }
];




export const makerSidebar = [
    {
        "name": "Home",
        "icon": "/images/Home.png",
        "link": "/MatchMakerDashboard"
    }, {

        "name": "Users",
        "icon": "/images/profile-icon.png",
        "link": "/AllUsers",
    },
    {

        "name": "Messages",
        "icon": "/images/Message.png",
        "link": "/MatchMakerMessages"
    },
    {

        "name": "Notification Center",
        "icon": "/images/Notification.png",
        "link": ""
    },
    {

        "name": "My Profile",
        "icon": "/images/profile-icon.png",
        "link": "/MatchMakerProfile"
    }

];

export const UsersTabsMaker = [
    "Personal", "Contact", "Family", "Spritual", "Personality", "Partner", "Carrer"
];

export const MatchingMaker = [
    "Matches", "Pending Recommendations", "Accepted Recommendations"
];

export const heightData = [
    {
        item: "Below 4ft 5in",
        value: "132cm",
    },
    {
        item: "4ft 5in - 134cm",
        value: "134cm",
    },
    {
        item: "4ft 6in - 137cm",
        value: "137cm",
    },
    {
        item: "4ft 7in - 139cm",
        value: "139cm",
    },
    {
        item: "4ft 8in - 142cm",
        value: "142cm",
    },
    {
        item: "4ft 9in - 144cm",
        value: "144cm",
    },
    {
        item: "4ft 10in - 147cm",
        value: "147cm",
    },
    {
        item: "4ft 11in - 149cm",
        value: "149cm",
    },
    {
        item: "5ft - 152cm",
        value: "152cm",
    },
    {
        item: "5ft 1in - 154cm",
        value: "154cm",
    },
    {
        item: "5ft 2in - 157cm",
        value: "157cm",
    },
    {
        item: "5ft 3in - 159cm",
        value: "159cm",
    },
    {
        item: "5ft 4in - 162cm",
        value: "162cm",
    },
    {
        item: "5ft 5in - 164cm",
        value: "164cm",
    },
    {
        item: "5ft 6in - 167cm",
        value: "167cm",
    },
    {
        item: "5ft 7in - 170cm",
        value: "170cm",
    },
    {
        item: "5ft 8in - 172cm",
        value: "172cm",
    },
    {
        item: "5ft 9in - 175cm",
        value: "175cm",
    },
    {
        item: "5ft 10in - 177cm",
        value: "177cm",
    },
    {
        item: "5ft 11in - 180cm",
        value: "180cm",
    },
    {
        item: "6ft - 182cm",
        value: "182cm",
    },
    {
        item: "6ft 1in - 185cm",
        value: "185cm",
    },
    {
        item: "6ft 2in - 187cm",
        value: "187cm",
    },
    {
        item: "6ft 3in - 190cm",
        value: "190cm",
    },
    {
        item: "6ft 4in - 193cm",
        value: "193cm",
    },
    {
        item: "6ft 5in - 195cm",
        value: "195cm",
    },
    {
        item: "6ft 6in - 198cm",
        value: "198cm",
    },
    {
        item: "6ft 7in - 200cm",
        value: "200cm",
    },
    {
        item: "6ft 8in - 203cm",
        value: "203cm",
    },
    {
        item: "6ft 9in - 205cm",
        value: "205cm",
    },
    {
        item: "6ft 10in - 208cm",
        value: "208cm",
    },
    {
        item: "6ft 11in - 210cm",
        value: "210cm",
    },
    {
        item: "7ft - 213cm",
        value: "213cm",
    },
    {
        item: "Above 7ft",
        value: "215cm",
    },

];

export const MotherTounge = [
    "Aka",
    "Arabic",
    "Arunachali",
    "Assamese",
    "Awadhi",
    "Baluchi",
    "Bengali",
    "Bhojpuri",
    "Bhutia",
    "Brahui",
    "Brij",
    "Burmese",
    "Chattisgarhi",
    "Chinese",
    "Coorgi",
    "Dogri",
    "English",
    "French",
    "Garo",
    "Garhwali",
    "Gujarati",
    "Haryanavi",
    "Himachali / Pahari",
    "Hindi",
    "Hindko",
    "Kakbarak",
    "Kanauji",
    "Kannada",
    "Kashmiri",
    "Khandesi",
    "Khasi",
    "Konkani",
    "Koshali",
    "Kumaoni",
    "Kutchi",
    "Ladakhi",
    "Lepcha",
    "Magahi",
    "Maithili",
    "Malay",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Marwari",
    "Miji",
    "Mizo",
    "Monpa",
    "Nepali",
    "Odia",
    "Pashto",
    "Persian",
    "Punjabi",
    "Rajasthani",
    "Russian",
    "Sanskrit",
    "Santhali",
    "Seraiki",
    "Sindhi",
    "Sinhala",
    "Sourashtra",
    "Spanish",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Telugu",
    "Tulu",
    "Urdu",
    "Other"
];

export const healthData = [
    "No Health Problems",
    "HIV positive",
    "Diabetes",
    "Low BP",
    "High BP",
    "Heart Ailments",
    "Other diseases"
];

export const profileFor = [
    "Myself",
    "Daughter",
    "Son",
    "Sister",
    "Brother",
    "Relative",
    "Friend",
    "Other"
];

export const Marital = [
    "Unmarried",
    "Divorced",
    "Widowed",
    "Applied/Awaited Divorce",
    "Other"
];

export const Nakshtra = [
    "Don't know",
    "Ashwini",
    "Bharani",
    "Kṛttikā",
    "Rohiṇī",
    "Mṛgaśīrṣa",
    "Ārdrā",
    "Punarvasu",
    "Puṣya",
    "Aśleṣā",
    "Maghā",
    "Pūrvaphalgunī",
    "Uttaraphalgunī",
    "Hastā",
    "Chitrā",
    "Svāti",
    "Viśākhā",
    "Anurādhā",
    "Jyeṣṭhā",
    "Mūla",
    "Pūrva Āṣāḍhā",
    "Uttarāṣāḍhā",
    "Śravaṇa",
    "Dhaniṣṭhā",
    "Śatabhiṣā",
    "Pūrva Bhādrapadā",
    "Uttara Bhādrapadā",
    "Revatī",
    "Other",

];

export const Prefered_Relgion = [
    "Doesn't matter",
    "Hindu",
    "Islam",
    "Christianity",
    "Sikh",
    "Buddhism",
    "Jain",
    "Parsi",
    "Jewish",
    "Spiritual",
    "No Religion",
    "Other"
];

export const Relgion = [
    "Hindu",
    "Islam",
    "Christianity",
    "Sikh",
    "Buddhism",
    "Jain",
    "Parsi",
    "Jewish",
    "Spiritual",
    "No Religion",
    "Other"
];

export const CommunityName = [
    "Don't Know",
    "Manai",
    "Telugu",
    "Chettiar",
    "Kuli",
    "Maratha",
    "KokanasthaAdi",
    "AndhraAdi",
    "DharmiAdi",
    "DravidaAdi",
    "Karnataka",
    "Agamudayar",
    "Agarwal",
    "Agnikula",
    "Kshatriya",
    "Agri",
    "Ahir",
    "Ahom",
    "Ambalavasi",
    "Arcot",
    "Arekatica",
    "Arora",
    "Arunthathiyar",
    "Arya Vysya",
    "Aryasamaj",
    "Ayyaraka",
    "Badaga",
    "Baghel",
    "Pal",
    "Gaderiya",
    "Bahi",
    "Baidya",
    "Baishnab",
    "Baishya",
    "Bajantri",
    "Balija",
    "Balija - NaiduBanayat",
    "Oriya",
    "Banik",
    "Baniya",
    "Barai",
    "Bari",
    "Barnwal",
    "Barujibi",
    "Bengali",
    "Besta",
    "Bhandari",
    "Bhatia",
    "Bhatraju",
    "Bhavsar",
    "Bhovi",
    "Billava",
    "Boya",
    "Nayak",
    "Naik",
    "Boyer",
    "Brahmbatt",
    "Brahmin",
    "Brahmin",
    "AnavilBrahmin",
    "AudichyaBrahmin",
    "BarendraBrahmin",
    "BengaliBrahmin",
    "BhattBrahmin",
    "BhumiharBrahmin",
    "BrahmbhattBrahmin",
    "Dadhich / DadheechBrahmin",
    "DaivadnyaBrahmin",
    "DanuaBrahmin",
    "DeshasthaBrahmin",
    "DhimanBrahmin",
    "DravidaBrahmin",
    "EmbrandiriBrahmin",
    "GarhwaliBrahmin",
    "GoswamiBrahmin",
    "GourBrahmin",
    "Gowd SaraswatBrahmin",
    "Gujar GourBrahmin",
    "GurukkalBrahmin",
    "HaluaBrahmin",
    "HavyakaBrahmin",
    "HimachaliBrahmin",
    "HoysalaBrahmin",
    "IyengarBrahmin",
    "IyerBrahmin",
    "JangidBrahmin",
    "JhaduaBrahmin",
    "JhijhotiyaBrahmin",
    "Kannada MadhvaBrahmin",
    "KanyakubjaBrahmin",
    "KarhadeBrahmin",
    "Kashmiri PanditBrahmin",
    "KokanasthaBrahmin",
    "KotaBrahmin",
    "KulinBrahmin",
    "KumaoniBrahmin",
    "MadhwaBrahmin",
    "MaharashtrianBrahmin",
    "MaithiliBrahmin",
    "ModhBrahmin",
    "MohyalBrahmin",
    "NagarBrahmin",
    "NamboodiriBrahmin",
    "NiyogiBrahmin",
    "Niyogi NandavarikiBrahmin",
    "OtherBrahmin",
    "PaliwalBrahmin",
    "PandaBrahmin",
    "PareekBrahmin",
    "PunjabiBrahmin",
    "PushkarnaBrahmin",
    "RarhiBrahmin",
    "RudrajBrahmin",
    "SakaldwipiBrahmin",
    "SanadyaBrahmin",
    "SanketiBrahmin",
    "SaraswatBrahmin",
    "SaruaBrahmin",
    "SaryuparinBrahmin",
    "ShivhalliBrahmin",
    "ShrimaliBrahmin",
    "SmarthaBrahmin",
    "Sri VaishnavaBrahmin",
    "StanikaBrahmin",
    "TyagiBrahmin",
    "VaidikiBrahmin",
    "VaikhanasaBrahmin",
    "VelanaduBrahmin",
    "ViswabrahminBrahmin",
    "VyasBrahmoBuddarBunt",
    "Sri Vaishnava",
    "Chaudary",
    "Chaurasia",
    "Chekkala – Nair",
    "Chennadasar",
    "Cheramar",
    "Chettiar",
    "Chhetri",
    "Chippolu",
    "Mera",
    "Coorgi",
    "Devadiga",
    "DevangaDevar",
    "Thevar",
    "Mukkulathor",
    "Devendra Kula Vellalar",
    "Dhangar",
    "Dheevara",
    "Dhiman",
    "Dhoba",
    "Dhobi",
    "Digambar",
    "Dommala",
    "Dusadh",
    "Ediga",
    "Ezhava",
    "Ezhuth",
    "Achan",
    "Gabit",
    "Ganakar",
    "Gandla",
    "Ganiga",
    "Gatti",
    "Gavali",
    "Gavara",
    "Ghumar",
    "Goala",
    "Goan",
    "Goswami",
    "Goud",
    "Gounder",
    "Gowda",
    "Gramani",
    "Gudia",
    "Gujarati",
    "Gujjar",
    "Gupta",
    "Guptan",
    "Gurjar",
    "Halwai",
    "Hegde",
    "Helava",
    "Hugar(Jeer)",
    "Intercaste",
    "Jaalari",
    "Jaiswal",
    "Jandra",
    "Jangam",
    "JatJatav",
    "Jetty",
    "Malla",
    "Kachara",
    "Kaibarta",
    "Kakkalan",
    "KalarKalinga",
    "Kalinga",
    "Vysya",
    "Kalita",
    "Kalwar",
    "Kamboj",
    "Kamma",
    "Naidu",
    "Kammala",
    "Kaniyan",
    "Kannada",
    "Mogaveera",
    "Kansari",
    "Kanu",
    "Naidu",
    "Karana",
    "Karmakar",
    "Kartha",
    "Karuneegar",
    "Kasar",
    "Kashyap",
    "Kavuthiyya",
    "Ezhavathy",
    "Kayastha",
    "Khandayat",
    "Khandelwal",
    "Kharwar",
    "Khatik",
    "Khatri",
    "Kirar",
    "KoliKoli",
    "Patel",
    "Kongu",
    "Vellala",
    "Gounder",
    "Konkani",
    "Korama",
    "Kori",
    "Koshti",
    "Krishna",
    "Vaka",
    "Kshatriya-BhavasarKshatriya",
    "Raju",
    "Varma",
    "Kudumbi",
    "Kulal",
    "Kulalar",
    "Kulita",
    "Kumawat",
    "Kumbara",
    "Kumbhakar",
    "Kumbhar",
    "Kumhar",
    "Kummari",
    "Kunbi",
    "Kurava",
    "Kuravan",
    "Kurmi",
    "Kuruba",
    "Kuruhina",
    "Shetty",
    "Kurumbar",
    "Kurup",
    "Kushwaha",
    "Kutchi",
    "Lambadi",
    "Banjara",
    "Lambani",
    "Leva Patil",
    "Lingayath",
    "Lohana",
    "LoharLoniya",
    "Lonia",
    "Lunia",
    "Lubana",
    "Madhesiya",
    "Madiga",
    "Mahajan",
    "Mahar",
    "Maharashtrian",
    "Mahendra",
    "Maheshwari",
    "Mahindra",
    "Mahishya",
    "Majabi",
    "Mala",
    "Malayalee",
    "Variar",
    "Mali",
    "Mallah",
    "Mangalorean",
    "Maniyani",
    "Mannadiar",
    "Mannan",
    "Mapila",
    "Marar",
    "Maratha",
    "Maratha – Gomantak",
    "Maruthuvar",
    "Marvar",
    "Marwari",
    "Matang",
    "Maurya",
    "Meda",
    "Medara",
    "Meena",
    "Meenavar",
    "Meghwal",
    "Mehra",
    "Menon",
    "Meru",
    "Darji",
    "Modak",
    "Mogaveera",
    "Monchi",
    "Mudaliar",
    "Mudaliar",
    "ArcotMudaliar",
    "SaivaMudaliar",
    "Senguntha",
    "Mudiraj",
    "Munnuru",
    "Muthuraja",
    "Naagavamsam",
    "Nadar",
    "Nagaralu",
    "Nai",
    "Naicken",
    "Naicker",
    "Naidu",
    "Naik",
    "Nair",
    "VaniyaNair",
    "VelethadathuNair-Vilakkithala",
    "Namasudra",
    "Nambiar",
    "Nambisan",
    "Namdev",
    "Namo",
    "Sudra",
    "Napit",
    "Nayak",
    "Nayaka",
    "Neeli",
    "Nepali",
    "Nhavi",
    "OBC",
    "Barber",
    "Naayee",
    "Oswal",
    "Otari",
    "Padmasali",
    "Panchal",
    "Pandaram",
    "Panicker",
    "Paravan",
    "Parit",
    "Parkava",
    "Kulam",
    "Partraj",
    "Pasi",
    "Paswan",
    "Patel",
    "DesaiPatel",
    "DodiaPatel",
    "KadvaPatel – Leva",
    "Patnaick",
    "Patra",
    "Patwa",
    "Perika",
    "Pillai",
    "Pisha",
    "Rody",
    "Poduval",
    "Poosala",
    "Porwal",
    "Prajapati",
    "Pulaya",
    "Punjabi",
    "Raigar",
    "Rajaka",
    "Rajaka",
    "Chakali",
    "Dhobi",
    "Rajbhar",
    "Rajput",
    "Garhwali",
    "Kumaoni",
    "Lodhi",
    "Ramdasia",
    "Ramgharia",
    "Rauniyar",
    "Ravidasia",
    "Rawat",
    "Reddiar",
    "Reddy",
    "Relli",
    "SSK",
    "Sadgop",
    "Sagara – Uppara",
    "Saha",
    "Sahu",
    "Saini",
    "Saiva",
    "Vellala",
    "Saliya",
    "Sambava",
    "Satnami",
    "Savji",
    "Scheduled Caste(SC)",
    "Scheduled Tribe(ST)",
    "Senai",
    "Thalaivar",
    "Sepahia",
    "Setti",
    "Balija",
    "Shah",
    "Shilpkar",
    "Shimpi",
    "Sindhi",
    "Sindhi",
    "BhanusaliSindhi",
    "BhatiaSindhi",
    "ChhapruSindhi",
    "DaduSindhi",
    "HyderabadiSindhi",
    "LaraiSindhi",
    "LohanaSindhi",
    "RohiriSindhi",
    "SehwaniSindhi",
    "ThataiSindhi",
    "AmilSindhi",
    "BaibhandSindhi",
    "LarkanaSindhi",
    "SahitiSindhi",
    "SakkharSindhi",
    "Shikarpuri",
    "Somvanshi",
    "SonarSoni",
    "Sourashtra",
    "Sowrashtra",
    "Sozhiya",
    "VellalarSri",
    "Vaishnava",
    "Srisayana",
    "Subarna",
    "Banik",
    "Sugali(Naika)",
    "SundhiSurya",
    "Balija",
    "Sutar",
    "SutharSwakula",
    "SaliSwarnakar",
    "Tamboli",
    "Tamil",
    "Yadava",
    "Tanti",
    "Tantuway",
    "Telaga",
    "Teli",
    "Telugu",
    "Thachar",
    "Thakkar",
    "Thakur",
    "Thandan",
    "Thigala",
    "Thiyya",
    "Thuluva",
    "Vellala",
    "Tili",
    "Togata",
    "Turupu",
    "Kapu",
    "Udayar",
    "Urali",
    "Gounder",
    "UrsVada",
    "Balija",
    "Vadagalai",
    "Vaddera",
    "Vaduka",
    "VaishVaish",
    "Dhaneshawat",
    "VaishnavVaishnav",
    "BhatiaVaishnav",
    "VaniaVaishya",
    "VallalaValluvan",
    "Valmiki",
    "Vanika",
    "Vyshya",
    "Vaniya",
    "Chettiar",
    "Vanjara",
    "Vankar",
    "Vannan",
    "Vannar",
    "Vanniya",
    "Kulak",
    "Kshatriya",
    "Vanniyar",
    "Variar",
    "Varshney",
    "Veera",
    "Saivam",
    "Veerashaiva",
    "Velaan",
    "Velama",
    "Velar",
    "Vellalar",
    "Veluthedathu",
    "Nair",
    "Vettuva",
    "Gounder",
    "Vishwakarma",
    "Viswabrahmin",
    "Vokaliga",
    "Vokkaliga",
    "Vysya",
    "Waada",
    "Balija",
    "Yadav",
    "Yellapu",
    "Other"

];

export const Gotra = [
    "Don't know",
    "Aatharvas",
    "Agasthi",
    "Ahabhunasa",
    "Airan",
    "Alampayana",
    "Angiras",
    "Arrishinimi",
    "Athreyasya / Athreyasa",
    "Atri",
    "Attarishi",
    "Aukshanas",
    "Aushanas",
    "Babrahvya",
    "Badarayana",
    "Baijvayas",
    "Bansal",
    "Bashan",
    "Bhandal",
    "Bharadwaj",
    "Bhargava / Bhargav",
    "Bhasyan",
    "Bhrigu",
    "Bindal",
    "Birthare",
    "Bodhaaynas",
    "Chandratri",
    "Chikithasa",
    "Chyavanasa",
    "Daksa",
    "Dalabhya",
    "Darbhas",
    "Devrata",
    "Dhananjaya",
    "Dhanvantri",
    "Dhara Gautam",
    "Dharan",
    "Dharanas",
    "Dixit",
    "Duttatreyas",
    "Galiva",
    "Ganganas",
    "Gangyanas",
    "Gardhmukh",
    "Sandilya",
    "Garg",
    "Garga / Gargya",
    "Gargya Sainasa",
    "Gautam / Gouthama",
    "Ghrit Kaushika",
    "Gowri Veetham",
    "Goyal",
    "Goyan",
    "Haritasya / Harithasa / Haritha",
    "Jaiminiyas",
    "Jamadagni",
    "Jatukarna",
    "Jindal",
    "Kaakavas",
    "Kabi",
    "Kalabouddasa",
    "Kalpangeerasa",
    "Kamakayana",
    "Vishwamitra",
    "Kamsa",
    "Kanav",
    "Kansal",
    "Kanva",
    "Kapi",
    "Kapila Baradwaj",
    "Kapinjal",
    "Kapishthalas",
    "Kaplish",
    "Kashish",
    "Kashyapa / Kaashyapa",
    "Katyayan / Katyan",
    "Kaundinya / Koundanya / Kaundilya",
    "Kaunsa",
    "Kaushal",
    "Kaushika / Kaushik / Kausikasa",
    "Keshoryas",
    "Koushika",
    "Visvamitrasa",
    "Krishnatrey",
    "Kucchal",
    "Kusa",
    "Kutsa / Kutsas / Kutsasa",
    "Laakshmanas",
    "Laugakshi",
    "Lavania",
    "Lodwan",
    "Lohit",
    "Lokaakhyas",
    "Lomasha",
    "Madelia",
    "Madhukul",
    "Maitraya",
    "Manava",
    "Mandavya",
    "Mangal",
    "Marica",
    "Markendeya",
    "Maudlas",
    "Maunas",
    "Mihir",
    "Mittal",
    "Moudgalya",
    "Mouna Bhargava",
    "Munish",
    "Mythravaruna",
    "Naagal",
    "Nagasya",
    "Naidrupa Kashyapa",
    "Narayanas",
    "Nithyandala",
    "Paaniyas",
    "Pachori",
    "Paing",
    "Parashar / Parashara",
    "Parthivasa",
    "Paulastya",
    "Poothamanasa",
    "Pourugutsa",
    "Prachinas",
    "Raghuvanshi",
    "Rajoria",
    "Rathitar",
    "Rohinya",
    "Rohita",
    "Sakalya",
    "Sakhyanasa",
    "Salankayanasa",
    "Sankash",
    "Sankha - Pingala - Kausta",
    "Sankrut",
    "Sankyanasa",
    "Savanaka",
    "Savarana / Sabarna / Savarna / Sraborno",
    "Shaalaksha",
    "Shadamarshana / Shatamarshanam",
    "Shakhanas",
    "Shalavatsa",
    "Shandilya / Sandilyasa",
    "Sharkaras",
    "Sharkvas",
    "Shaunak",
    "Shravanesya",
    "Shrimukh Shandilya",
    "Shukla Atreyas",
    "Sigidha",
    "Singhal",
    "Sri Vatsa / Vatsa / Vats / Vacchas",
    "Srungi Bharadwajasa",
    "Suparnasa",
    "Swathantra Kapisa",
    "Tayal",
    "Tharakayanam",
    "Thingal",
    "Titwal",
    "Tushar",
    "Udbahu",
    "Udhalaka",
    "Uditha Gautham",
    "Udithya",
    "Upamanyu Vasishtasa",
    "Upamanyu",
    "Upathya",
    "Vadoola / Vadulasa",
    "Vainya",
    "Vardheyasa",
    "Vashishtha",
    "Veethahavya",
    "Vishnordhageerasa",
    "Vishnu Vridhha",
    "Vishwamitra",
    "Yaska",
    "Others"
];

export const languages = [
    "Arabic",
    "Arunachali",
    "Assamese",
    "Awadhi",
    "Baluchi",
    "Bengali",
    "Bhojpuri",
    "Bhutia",
    "Brahui",
    "Brij",
    "Burmese",
    "Chattisgarhi",
    "Chinese",
    "Coorgi",
    "Dogri",
    "English",
    "French",
    "Garhwali",
    "Garo",
    "Gujarati",
    "Haryanavi",
    "Himachali/Pahari",
    "Hindi",
    "Hindko",
    "Kakbarak",
    "Kanauji",
    "Kannada",
    "Kashmiri",
    "Khandesi",
    "Khasi",
    "Konkani",
    "Koshali",
    "Kumaoni",
    "Kutchi",
    "Ladakhi",
    "Lepcha",
    "Magahi",
    "Maithili",
    "Malay",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Marwari",
    "Miji",
    "Mizo",
    "Monpa",
    "Nepali",
    "Odia",
    "Pashto",
    "Persian",
    "Punjabi",
    "Rajasthani",
    "Russian",
    "Sanskrit",
    "Santhali",
    "Seraiki",
    "Sindhi",
    "Sinhala",
    "Sourashtra",
    "Spanish",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Telugu",
    "Tulu",
    "Urdu",
    "Other"
];


export const rashiNames = [
    "Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces", "Dont't know"
];

export const prefix1 = [
    "Mr", "Dr", "Sir", "Late"
];

export const prefix2 = [
    "Ms", "Mrs", "Dr", "Madam", "Late"
];

export const familyCulture = [
    "Traditional",
    "Modern",
    "Mix of traditional and modern",
    "Liberal",
    "Spiritual",
    "Other"
];

export const familyStatus = [
    "Lower class",
    "Lower-middle class",
    "Middle class",
    "Upper-middle class",
    "Upper class",
    "Affluent",
    "Other"
];

export const ChildWish = [
    "Don't Know",
    "Not sure",
    "0",
    "1",
    "2",
    "3",
    "more"
];

export const jobDuration = [
    "Years",
    "Months",
    "Days"
];


export const occupationssNam = [
    "Information Technology and Computer Science",
    "Healthcare and Wellness",
    "Creative Arts and Design",
    "Education and Academia",
    "Legal and Compliance",
    "Engineering and Architecture",
    "Business and Management",
    "Marketing and Advertising",
    "Finance and Investment",
    "Hospitality and Tourism",
    "Environmental and Agricultural Sciences",
    "Public Service and Government",
    "Social Work and Counseling",
    "Media and Communication",
    "Entertainment",
    "Transportation",
    "Culinary Arts",
    "Science",
    "Medical / Healthcare",
    "Writer",
    "Construction and Carpentry",
    "Skilled Trades",
    "Agriculture",
    "Fashion",
    "Emergency Services",
    "Law Enforcement",
    "Not working",
    "Database Administrator",
    "Network Administrator",
    "System Administrator",
    "Cybersecurity Professional",
    "Data Scientist",
    "IT Support Specialist",
    "Cloud Architect",
    "DevOps Engineer",
    "UI/UX Designer",
    "IT Project Manager",
    "Software Engineer",
    "Frontend Developer",
    "Backend Developer",
    "Mobile App Developer",
    "Machine Learning Engineer",
    "Computer Programmer",
    "Game Developer",
    "Web Developer",
    "Chiropractor",
    "Dietitian / Nutritionist",
    "Acupuncturist",
    "Yoga Instructor",
    "Personal Trainer",
    "Health Coach",
    "Massage Therapist",
    "Holistic Practitioner",
    "Dermatologist",
    "Radiologist",
    "Oncologist",
    "Surgeon Assistant",
    "Physical Therapist Assistant",
    "Emergency Medical Technician (EMT)",
    "Medical Laboratory Technician",
    "Nurse",
    "Nutritionist",
    "Occupational Therapist",
    "Optometrist",
    "Paramedic",
    "Pharmacist",
    "Psychiatrist",
    "Psychologist",
    "Graphic Designer",
    "Illustrator",
    "Multimedia Artist",
    "3D Modeler",
    "Game Designer",
    "Art Director",
    "Art Therapist",
    "Street Artist",
    "Tattoo Artist",
    "Visual Merchandiser",
    "Ceramicist",
    "Set Designer",
    "Fashion Stylist",
    "Makeup Artist",
    "Painter",
    "Photographer",
    "School Principal",
    "Education Consultant",
    "Academic Advisor",
    "Librarian",
    "School Counselor",
    "Education Policy Analyst",
    "Education Researcher",
    "Adjunct Professor",
    "School Administrator",
    "Special Education Teacher",
    "School Psychologist",
    "Teaching Assistant",
    "Professor",
    "Compliance Officer",
    "Paralegal",
    "Legal Secretary",
    "Corporate Counsel",
    "Patent Attorney",
    "Legal Analyst",
    "Immigration Lawyer",
    "Corporate Compliance Analyst",
    "Trial Attorney",
    "Environmental Lawyer",
    "Intellectual Property Lawyer",
    "Aerospace Engineer",
    "Chemical Engineer",
    "Environmental Engineer",
    "Structural Engineer",
    "Construction Manager",
    "Biomedical Engineer",
    "Geotechnical Engineer",
    "Electrical Engineer",
    "Robotics Engineer",
    "Architectural Technologist",
    "Mechanical Engineer",
    "Supply Chain Manager",
    "Operations Manager",
    "Business Analyst",
    "Quality Assurance Manager",
    "Risk Manager",
    "Project Coordinator",
    "Business Development Executive",
    "Human Resources Manager",
    "Financial Controller",
    "Management Consultant",
    "Retail Manager",
    "Franchise Owner",
    "Salesperson",
    "Real Estate Agent",
    "Receptionist",
    "Digital Marketing Specialist",
    "Social Media Manager",
    "Content Creator",
    "Brand Manager",
    "Media Buyer",
    "SEO Specialist",
    "Email Marketing Manager",
    "Influencer Marketer",
    "Media Planner",
    "Creative Director",
    "Reporter",
    "Publicist",
    "Financial Analyst",
    "Wealth Manager",
    "Actuary",
    "Risk Analyst",
    "Quantitative Analyst",
    "Portfolio Manager",
    "Private Equity Analyst",
    "Hedge Fund Manager",
    "Venture Capitalist",
    "Financial Planner",
    "Resort Manager",
    "Cruise Director",
    "Tourism Development Officer",
    "Event Planner",
    "Hotel Concierge",
    "Sommelier",
    "Ecotourism Guide",
    "Waiter/Waitress",
    "Barista",
    "Environmental Scientist",
    "Conservation Biologist",
    "Agronomist",
    "Farm Manager",
    "Forester",
    "Soil Scientist",
    "Agricultural Economist",
    "Hydrologist",
    "Animal Scientist",
    "Botanist",
    "Zoologist",
    "Diplomat",
    "Government Policy Analyst",
    "Civil Servant",
    "Municipal Administrator",
    "City Planner",
    "Public Health Officer",
    "Policy Advisor",
    "Legislative Assistant",
    "Detective",
    "Police Officer",
    "Judge",
    "Politician",
    "Family Therapist",
    "Substance Abuse Counselor",
    "Marriage Counselor",
    "Rehabilitation Counselor",
    "School Social Worker",
    "Child Welfare Worker",
    "Geriatric Counselor",
    "Career Counselor",
    "Counselor",
    "Social Worker",
    "Radio Broadcaster",
    "Podcast Host",
    "Content Strategist",
    "Copywriter",
    "Investigative Journalist",
    "News Anchor",
    "Social Media Influencer",
    "Media Relations Specialist",
    "Film Critic",
    "Accountant",
    "Actor",
    "Air Traffic Controller",
    "Airline Pilot",
    "Animator",
    "Architect",
    "Artist",
    "Astronomer",
    "Audiologist",
    "Author",
    "Baker",
    "Banker",
    "Biologist",
    "Botanist",
    "Carpenter",
    "Chef",
    "Chemist",
    "Civil Engineer",
    "Composer",
    "Construction Worker",
    "Dentist",
    "Dietitian",
    "Economist",
    "Environmental Scientist",
    "Farmer",
    "Fashion Designer",
    "Firefighter",
    "Flight Attendant",
    "Forester",
    "Game Developer",
    "Genetic Counselor",
    "Geologist",
    "Graphic Designer",
    "Historian",
    "Hotel Manager",
    "Industrial Designer",
    "Information Security Analyst",
    "Instructional Designer",
    "Interior Designer",
    "Interpreter / Translator",
    "Investment Banker",
    "IT Analyst",
    "Journalist",
    "Landscape Architect",
    "Lawyer",
    "Legal Assistant",
    "Life Coach",
    "Loan Officer",
    "Logistician",
    "Market Research Analyst",
    "Marketing Manager",
    "Massage Therapist",
    "Math Teacher",
    "Mechanical Engineer",
    "Medical Assistant",
    "Medical Secretary",
    "Medical Technologist",
    "Mental Health Counselor",
    "Merchandise Planner",
    "Meteorologist",
    "Microbiologist",
    "Military Officer",
    "Model",
    "Music Teacher",
    "Musician",
    "Network Administrator",
    "Nurse",
    "Nutritionist",
    "Occupational Therapist",
    "Operations Manager",
    "Optometrist",
    "Painter",
    "Paralegal",
    "Paramedic",
    "Pastor",
    "Pediatrician",
    "Personal Trainer",
    "Pharmacist",
    "Photographer",
    "Physical Therapist",
    "Physician Assistant",
    "Pilot",
    "Plumber",
    "Police Officer",
    "Political Scientist",
    "Preschool Teacher",
    "Principal",
    "Private Investigator",
    "Probation Officer",
    "Producer",
    "Product Manager",
    "Professor",
    "Project Manager",
    "Psychiatrist",
    "Psychologist",
    "Public Relations Specialist",
    "Quality Engineer",
    "Radiation Therapist",
    "Radiologic Technologist",
    "Real Estate Agent",
    "Receptionist",
    "Recruiter",
    "Registered Nurse",
    "Regulatory Affairs Specialist",
    "Research Assistant",
    "Respiratory Therapist",
    "Retail Manager",
    "Sales Engineer",
    "Sales Manager",
    "School Counselor",
    "School Librarian",
    "Scientist",
    "Secretary",
    "Security Guard",
    "Security Officer",
    "Social Media Manager",
    "Social Worker",
    "Software Developer",
    "Software Engineer",
    "Software Consultant",
    "Software Developer",
    "Software Engineer",
    "Sommelier",
    "Speech-Language Pathologist",
    "Statistician",
    "Substance Abuse Counselor",
    "Supply Chain Manager",
    "Surgeon",
    "Surgical Technologist",
    "Surveyor",
    "Systems Analyst",
    "Systems Engineer",
    "Tax Preparer",
    "Technical Writer",
    "Telecommunications Specialist",
    "Tour Guide",
    "Town Planner",
    "Toxicologist",
    "Trainer",
    "Translator",
    "Transportation Engineer",
    "Travel Agent",
    "Travel Nurse",
    "Travel Writer",
    "Truck Driver",
    "Ultrasound Technician",
    "Urban Planner",
    "Urologist",
    "UX Designer",
    "Vascular Technologist",
    "Veterinarian",
    "Veterinary Assistant",
    "Veterinary Technician",
    "Video Game Designer",
    "Video Producer",
    "Videographer",
    "Virtual Assistant",
    "Visual Designer",
    "Web Designer",
    "Wedding Planner",
    "Welder",
    "Wildlife Biologist",
    "Wind Energy Engineer",
    "Data Entry Operator",
    "Typist",
    "Stenographer",
    "Clerk",
    "Assistant",
    "Office Attendant",
    "Accounts Clerk",
    "Record Keeper",
    "Peon",
    "Driver",
    "Sweeper",
    "Gardener",
    "Security Guard",
    "Cleaner",
    "Mason",
    "Electrician",
    "IAS Officer",
    "IPS Officer",
    "IFS Officer",
    "IRS Officer",
    "Judicial Officer",
    "Tehsildar",
    "District Magistrate",
    "Sub-Inspector",
    "Patwari",
    "Tribal Officer",
    "Assistant Commissioner",
    "Engineer",
    "Technician",
    "Lab Assistant",
    "Technical Assistant",
    "Draftsman",
    "Surveyor",
    "Radiographer",
    "Lab Technician",
    "Doctor",
    "Nurse",
    "Pharmacist",
    "Health Inspector",
    "Dental Surgeon",
    "Veterinary Doctor",
    "Ayurvedic Doctor",
    "Homeopathic Doctor",
    "Naturopath",
    "Teacher",
    "Principal",
    "School Librarian",
    "Education Officer",
    "Headmaster",
    "Lecturer",
    "Professor",
    "Research Scholar",
    "School Counselor",
    "Education Inspector",
    "Auditor",
    "Tax Officer",
    "Accounts Officer",
    "Budget Analyst",
    "Finance Manager",
    "Treasury Officer",
    "Financial Analyst",
    "Cost Accountant",
    "Internal Auditor",
    "Revenue Officer",
    "Advocate",
    "Public Prosecutor",
    "Legal Advisor",
    "District Attorney",
    "Notary",
    "Judicial Clerk",
    "Legal Researcher",
    "Law Officer",
    "Court Reporter",
    "Judicial Magistrate",
    "Student",
    "Retired",
    "Non-Working",
    "Others"
];



export const IncomeList = [
    "Upto INR 1 Lakh",
    "INR 1 Lakh to 2 Lakh",
    "INR 2 Lakh to 4 Lakh",
    "INR 4 Lakh to 7 Lakh",
    "INR 7 Lakh to 10 Lakh",
    "INR 10 Lakh to 15 Lakh",
    "INR 15 Lakh to 20 Lakh",
    "INR 20 Lakh to 30 Lakh",
    "INR 30 Lakh to 50 Lakh",
    "INR 50 Lakh to 75 Lakh",
    "INR 75 Lakh to 1 Crore",
    "INR 1 Crore & above",
    "Not applicable or not working",
    "Keep this private"
];

export const CompanyType = [
    "Private Company",
    "Government / Public Sector",
    "Defense / Civil Services",
    "Business / Self Employed",
    "Not Working"
];

export const Qualifications = [
    "Post Doctoral and above",
    "Doctorate",
    "Master",
    "Bachelor",
    "Honours",
    "Associate",
    "Diploma",
    "Higher Secondary Education",
    "Secondary Education",
    "Less than high school",
    "No formal education",
    "B.E / B.Tech",
    "B.Sc",
    "BSc(Hons)",
    "B.Eng (Hons)",
    "AE",
    "AET",
    "B.A",
    "B.Ed",
    "BJMC",
    "BFA",
    "B.Arch",
    "B.Des",
    "BMM",
    "BA (Hons)",
    "B.Arch(Hons)",
    "AA",
    "AFA",
    "B.Com",
    "CA / CPA",
    "CFA",
    "CS",
    "BSc / BFin",
    "BCom (Hons)",
    "BCA",
    "B.IT",
    "BCS",
    "BA Computer Science",
    "AS",
    "AAS",
    "MBBS",
    "BDS",
    "BPT",
    "BAMS",
    "BHMS",
    "B.Pharma",
    "BVSc",
    "BSN / BScN",
    "ADN",
    "BBA",
    "BHM",
    "BBM",
    "ABA",
    "ADbus",
    "BL / LLB",
    "ML / LLM",
    "LLB(Hons)",
    "ALA",
    "M.E / M.Tech",
    "M.Sc",
    "M.S Engineering",
    "M.Eng (Hons)",
    "MFA",
    "M.Ed",
    "M.A",
    "MSW",
    "MJMC",
    "M.Arch",
    "M.Des",
    "M.Com",
    "MSc / MFin / MS",
    "PGD Finance",
    "MCA",
    "PGDCA",
    "MDS",
    "MCh",
    "M.D",
    "M.S Medicine",
    "MPT",
    "M.Pharma",
    "MVSc",
    "MMed",
    "PGD Medicine",
    "MBA",
    "PGDM",
    "M.Phil",
    "Ph.D",
    "DM",
    "high school diploma",
    "DFA",
    "D.Ed",
    "D.Arch",
    "IT Diploma",
    "ADIT",
    "DipSc",
    "Engineering Diploma",
    "High school",
    "School drop",
    "Others"
];

export const affiliation = [
    "No",
    "Art of Living Foundation",
    "Brahma Kumaris",
    "Chinmaya Mission",
    "Isha Foundation",
    "ISKCON",
    "ISKCON Bangalore & HKM Centers",
    "Gaudiya Vaishnava",
    "Madhva",
    "Sri Vaishnava",
    "Sri Sri Group",
    "Others organization"
];

export const spritualhours = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"
];


export const ChantingRounds = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "Not Regular Chanter",
    "Other"
];

export const templevisit = [
    "Daily",
    "Many times in a week",
    "Many times in a month",
    "On festivals",
    "Occasionally",
    "Other"
];

export const spritualRole = [
    "Central and guiding force",
    "Significant influence and source of strength",
    "Meaningful aspect and source of inspiration",
    "Present but not central to daily life",
    "Not a significant factor or influence",
    "Other"
];

export const initiatedStatus = [
    "Yes",
    "No",
    "Aspiring",
    "Others"
];

export const parentPrcatices = [
    "Serious Practitioners",
    "Beginners",
    "They Support KC",
    "No",
    "Indifferent/Against/Other"
];

export const LocalTemples = [
    "Iskon Banglore",
    "Iskcon",
    "sfdsf",
    "dsfdsf",
    "dsfsfsf",
];

export const initiationNames = [
    "ISKCON Temple, Mayapur, West Bengal",
    "ISKCON Temple, Vrindavan, Uttar Pradesh",
    "ISKCON Temple, Mumbai, Maharashtra (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Delhi (Sri Sri Radha Parthasarathi Temple)",
    "ISKCON Temple, Bengaluru, Karnataka (Sri Sri Radha Krishnachandra Temple)",
    "ISKCON Temple, Pune, Maharashtra",
    "ISKCON Temple, Kolkata, West Bengal (Sri Sri Radha Govinda Mandir)",
    "ISKCON Temple, Ahmedabad, Gujarat (Sri Sri Radha Govind Dham)",
    "ISKCON Temple, Hyderabad, Telangana",
    "ISKCON Temple, Coimbatore, Tamil Nadu",
    "ISKCON Temple, Chennai, Tamil Nadu",
    "ISKCON Temple, Jaipur, Rajasthan (Sri Sri Giridhari Dauji Temple)",
    "ISKCON Temple, Juhu, Mumbai, Maharashtra (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Kanpur, Uttar Pradesh",
    "ISKCON Temple, Mangalore, Karnataka (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Rajkot, Gujarat (Sri Sri Radha Neelmadhav Temple)",
    "ISKCON Temple, Ujjain, Madhya Pradesh",
    "ISKCON Temple, Belgaum, Karnataka (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Ludhiana, Punjab",
    "ISKCON Temple, Chandigarh (Sri Sri Radha Madhav Temple)",
    "ISKCON Temple, Surat, Gujarat",
    "ISKCON Temple, Nasik, Maharashtra",
    "ISKCON Temple, Aurangabad, Maharashtra",
    "ISKCON Temple, Dwarka, Gujarat (Sri Sri Radha Damodar Temple)",
    "ISKCON Temple, Bhuvaneshwar, Odisha",
    "ISKCON Temple, Gurgaon, Haryana (Sri Sri Radha-Madhav Temple)",
    "ISKCON Temple, Coimbatore, Tamil Nadu",
    "ISKCON Temple, Jabalpur, Madhya Pradesh",
    "ISKCON Temple, Patna, Bihar (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, Tirupati, Andhra Pradesh",
    "ISKCON Temple, Nagpur, Maharashtra",
    "ISKCON Temple, Bhopal, Madhya Pradesh",
    "ISKCON Temple, Ludhiana, Punjab",
    "ISKCON Temple, Kanpur, Uttar Pradesh",
    "ISKCON Temple, Salem, Tamil Nadu",
    "ISKCON Temple, Vijayawada, Andhra Pradesh",
    "ISKCON Temple, Madurai, Tamil Nadu",
    "ISKCON Temple, Udaipur, Rajasthan",
    "ISKCON Temple, Varanasi, Uttar Pradesh",
    "ISKCON Temple, Jalandhar, Punjab (Sri Sri Radha Gokulananda Temple)",
    "ISKCON Temple, Manipur",
    "ISKCON Temple, Puri, Odisha",
    "ISKCON Temple, Bhubaneswar, Odisha (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Kolhapur, Maharashtra (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, Mathura, Uttar Pradesh",
    "ISKCON Temple, Ranchi, Jharkhand",
    "ISKCON Temple, Mysuru, Karnataka (Sri Sri Radha Shyamasundara Temple)",
    "ISKCON Temple, Tiruchirappalli, Tamil Nadu",
    "ISKCON Temple, Raipur, Chhattisgarh",
    "ISKCON Temple, Kozhikode, Kerala (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, London, United Kingdom",
    "ISKCON Temple, New York City, USA (Sri Sri Radha Govinda Mandir)",
    "ISKCON Temple, Los Angeles, USA (New Dwaraka Temple)",
    "ISKCON Temple, Washington D.C., USA (Sri Sri Radha Madan Mohan Mandir)",
    "ISKCON Temple, Sydney, Australia (Sri Sri Radha Gopinath Mandir)",
    "ISKCON Temple, Auckland, New Zealand (Sri Sri Radha Giridhari Temple)",
    "ISKCON Temple, Moscow, Russia",
    "ISKCON Temple, Sao Paulo, Brazil (Sri Sri Radha Vrindavan Chandra Temple)",
    "ISKCON Temple, Durban, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Tokyo, Japan (Sri Sri Radha Shyamasundara Temple)",
    "ISKCON Temple, Paris, France (Sri Sri Radha Paris-Isvara Temple)",
    "ISKCON Temple, Barcelona, Spain (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Berlin, Germany (Sri Sri Radha Gopinatha Mandir)",
    "ISKCON Temple, Amsterdam, Netherlands",
    "ISKCON Temple, Dublin, Ireland (Sri Sri Radha-Govinda Temple)",
    "ISKCON Temple, Zurich, Switzerland",
    "ISKCON Temple, Rome, Italy (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Budapest, Hungary (Sri Sri Radha Syamasundara Temple)",
    "ISKCON Temple, Prague, Czech Republic (Sri Sri Radha Madhava Temple)",
    'ISKCON Temple, Vienna, Austria',
    "ISKCON Temple, Belgium",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Kuala Lumpur, Malaysia",
    "ISKCON Temple, Bangkok, Thailand (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Melbourne, Australia (Sri Sri Radha Ballabha Temple)",
    "ISKCON Temple, Manila, Philippines (Sri Sri Radha-Madhava Temple)",
    "ISKCON Temple, Dhaka, Bangladesh (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Dubai, UAE (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Toronto, Canada (Sri Sri Radha Ksira-chora Gopinatha Temple)",
    "ISKCON Temple, Boston, USA (Sri Sri Radha Gopivallabha Temple)",
    "ISKCON Temple, Vancouver, Canada (Sri Sri Radha Madan Mohana Temple)",
    "ISKCON Temple, Chicago, USA (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Atlanta, USA (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Seattle, USA (Sri Sri Radha Nila-madhava Temple)",
    "ISKCON Temple, Montreal, Canada (Sri Sri Radha Manohara Temple)",
    "ISKCON Temple, Houston, USA (Sri Sri Radha Nilamadhava Temple)",
    "ISKCON Temple, Dallas, USA (Sri Sri Radha Kalachandji Temple)",
    "ISKCON Temple, Phoenix, USA (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Mexico City, Mexico (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Buenos Aires, Argentina (Sri Sri Gaura-Nitai Temple)",
    "ISKCON Temple, Santiago, Chile (Sri Sri Radha Govinda Temple)",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Lima, Peru (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Cape Town, South Africa (Sri Sri Radha-Radhanath Temple)",
    "ISKCON Temple, Nairobi, Kenya (Sri Sri Radha Banke Bihari Temple)",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Jakarta, Indonesia (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Kathmandu, Nepal (Sri Sri Radha-Madhav Temple)",
    "ISKCON Temple, Colombo, Sri Lanka (Sri Sri Radha-Madhava Temple)",
    "ISKCON Temple, Lagos, Nigeria (Sri Sri Radha Govinda Mandir)",
    "ISKCON Bangalore",
    "Vrindavan Chandrodaya Mandir, Vrindavan",
    "Hare Krishna Movement, Dharmapuri",
    "Hare Krishna Movement, Hyderabad",
    "Hare Krishna Movement, Chennai",
    "Hare Krishna Movement, Ahmedabad",
    "Hare Krishna Movement, Puri / Bhubaneshwar",
    "Hare Krishna Movement, Guwahati",
    "Hare Krishna Movement, Visakhapatnam",
    "Hare Krishna Movement, Bhilai",
    "Hare Krishna Movement, Jaipur",
    "Sri Krishna Balarama Mandir, Sunnyvale",
    "Hare Krishna Ashram, Penang",
    "Hare Krishna Ashram, Johor",
    "Hare Krishna Movement, Tirupati",
    "Hare Krishna Movement, Lucknow",
    "Hare Krishna Movement, Mumbai",
    "Hare Krishna Movement, Vijayawada",
    "Hare Krishna Movement, Coimbatore",
    "India Heritage Foundation, NJ-NY, USA",
    "India Heritage Foundation, Boston",
    "India Heritage Foundation, London"
];



export const Preferred_Marital_Status = [
    "Unmarried",
    "Divorced",
    "Applied Divorce",
    "Widowed",
    "Not particularly",
    "Other"
];

export const Prefered_Community = [
    "Doesn't matter",
    "Manai",
    "Telugu",
    "Chettiar",
    "Kuli",
    "Maratha",
    "KokanasthaAdi",
    "AndhraAdi",
    "DharmiAdi",
    "DravidaAdi",
    "Karnataka",
    "Agamudayar",
    "Agarwal",
    "Agnikula",
    "Kshatriya",
    "Agri",
    "Ahir",
    "Ahom",
    "Ambalavasi",
    "Arcot",
    "Arekatica",
    "Arora",
    "Arunthathiyar",
    "Arya Vysya",
    "Aryasamaj",
    "Ayyaraka",
    "Badaga",
    "Baghel",
    "Pal",
    "Gaderiya",
    "Bahi",
    "Baidya",
    "Baishnab",
    "Baishya",
    "Bajantri",
    "Balija",
    "Balija - NaiduBanayat",
    "Oriya",
    "Banik",
    "Baniya",
    "Barai",
    "Bari",
    "Barnwal",
    "Barujibi",
    "Bengali",
    "Besta",
    "Bhandari",
    "Bhatia",
    "Bhatraju",
    "Bhavsar",
    "Bhovi",
    "Billava",
    "Boya",
    "Nayak",
    "Naik",
    "Boyer",
    "Brahmbatt",
    "Brahmin",
    "Brahmin",
    "AnavilBrahmin",
    "AudichyaBrahmin",
    "BarendraBrahmin",
    "BengaliBrahmin",
    "BhattBrahmin",
    "BhumiharBrahmin",
    "BrahmbhattBrahmin",
    "Dadhich / DadheechBrahmin",
    "DaivadnyaBrahmin",
    "DanuaBrahmin",
    "DeshasthaBrahmin",
    "DhimanBrahmin",
    "DravidaBrahmin",
    "EmbrandiriBrahmin",
    "GarhwaliBrahmin",
    "GoswamiBrahmin",
    "GourBrahmin",
    "Gowd SaraswatBrahmin",
    "Gujar GourBrahmin",
    "GurukkalBrahmin",
    "HaluaBrahmin",
    "HavyakaBrahmin",
    "HimachaliBrahmin",
    "HoysalaBrahmin",
    "IyengarBrahmin",
    "IyerBrahmin",
    "JangidBrahmin",
    "JhaduaBrahmin",
    "JhijhotiyaBrahmin",
    "Kannada MadhvaBrahmin",
    "KanyakubjaBrahmin",
    "KarhadeBrahmin",
    "Kashmiri PanditBrahmin",
    "KokanasthaBrahmin",
    "KotaBrahmin",
    "KulinBrahmin",
    "KumaoniBrahmin",
    "MadhwaBrahmin",
    "MaharashtrianBrahmin",
    "MaithiliBrahmin",
    "ModhBrahmin",
    "MohyalBrahmin",
    "NagarBrahmin",
    "NamboodiriBrahmin",
    "NiyogiBrahmin",
    "Niyogi NandavarikiBrahmin",
    "OtherBrahmin",
    "PaliwalBrahmin",
    "PandaBrahmin",
    "PareekBrahmin",
    "PunjabiBrahmin",
    "PushkarnaBrahmin",
    "RarhiBrahmin",
    "RudrajBrahmin",
    "SakaldwipiBrahmin",
    "SanadyaBrahmin",
    "SanketiBrahmin",
    "SaraswatBrahmin",
    "SaruaBrahmin",
    "SaryuparinBrahmin",
    "ShivhalliBrahmin",
    "ShrimaliBrahmin",
    "SmarthaBrahmin",
    "Sri VaishnavaBrahmin",
    "StanikaBrahmin",
    "TyagiBrahmin",
    "VaidikiBrahmin",
    "VaikhanasaBrahmin",
    "VelanaduBrahmin",
    "ViswabrahminBrahmin",
    "VyasBrahmoBuddarBunt",
    "Sri Vaishnava",
    "Chaudary",
    "Chaurasia",
    "Chekkala – Nair",
    "Chennadasar",
    "Cheramar",
    "Chettiar",
    "Chhetri",
    "Chippolu",
    "Mera",
    "Coorgi",
    "Devadiga",
    "DevangaDevar",
    "Thevar",
    "Mukkulathor",
    "Devendra Kula Vellalar",
    "Dhangar",
    "Dheevara",
    "Dhiman",
    "Dhoba",
    "Dhobi",
    "Digambar",
    "Dommala",
    "Dusadh",
    "Ediga",
    "Ezhava",
    "Ezhuth",
    "Achan",
    "Gabit",
    "Ganakar",
    "Gandla",
    "Ganiga",
    "Gatti",
    "Gavali",
    "Gavara",
    "Ghumar",
    "Goala",
    "Goan",
    "Goswami",
    "Goud",
    "Gounder",
    "Gowda",
    "Gramani",
    "Gudia",
    "Gujarati",
    "Gujjar",
    "Gupta",
    "Guptan",
    "Gurjar",
    "Halwai",
    "Hegde",
    "Helava",
    "Hugar(Jeer)",
    "Intercaste",
    "Jaalari",
    "Jaiswal",
    "Jandra",
    "Jangam",
    "JatJatav",
    "Jetty",
    "Malla",
    "Kachara",
    "Kaibarta",
    "Kakkalan",
    "KalarKalinga",
    "Kalinga",
    "Vysya",
    "Kalita",
    "Kalwar",
    "Kamboj",
    "Kamma",
    "Naidu",
    "Kammala",
    "Kaniyan",
    "Kannada",
    "Mogaveera",
    "Kansari",
    "Kanu",
    "Naidu",
    "Karana",
    "Karmakar",
    "Kartha",
    "Karuneegar",
    "Kasar",
    "Kashyap",
    "Kavuthiyya",
    "Ezhavathy",
    "Kayastha",
    "Khandayat",
    "Khandelwal",
    "Kharwar",
    "Khatik",
    "Khatri",
    "Kirar",
    "KoliKoli",
    "Patel",
    "Kongu",
    "Vellala",
    "Gounder",
    "Konkani",
    "Korama",
    "Kori",
    "Koshti",
    "Krishna",
    "Vaka",
    "Kshatriya – BhavasarKshatriya",
    "Raju",
    "Varma",
    "Kudumbi",
    "Kulal",
    "Kulalar",
    "Kulita",
    "Kumawat",
    "Kumbara",
    "Kumbhakar",
    "Kumbhar",
    "Kumhar",
    "Kummari",
    "Kunbi",
    "Kurava",
    "Kuravan",
    "Kurmi",
    "Kuruba",
    "Kuruhina",
    "Shetty",
    "Kurumbar",
    "Kurup",
    "Kushwaha",
    "Kutchi",
    "Lambadi",
    "Banjara",
    "Lambani",
    "Leva Patil",
    "Lingayath",
    "Lohana",
    "LoharLoniya",
    "Lonia",
    "Lunia",
    "Lubana",
    "Madhesiya",
    "Madiga",
    "Mahajan",
    "Mahar",
    "Maharashtrian",
    "Mahendra",
    "Maheshwari",
    "Mahindra",
    "Mahishya",
    "Majabi",
    "Mala",
    "Malayalee",
    "Variar",
    "Mali",
    "Mallah",
    "Mangalorean",
    "Maniyani",
    "Mannadiar",
    "Mannan",
    "Mapila",
    "Marar",
    "Maratha",
    "Maratha – Gomantak",
    "Maruthuvar",
    "Marvar",
    "Marwari",
    "Matang",
    "Maurya",
    "Meda",
    "Medara",
    "Meena",
    "Meenavar",
    "Meghwal",
    "Mehra",
    "Menon",
    "Meru",
    "Darji",
    "Modak",
    "Mogaveera",
    "Monchi",
    "Mudaliar",
    "Mudaliar",
    "ArcotMudaliar",
    "SaivaMudaliar",
    "Senguntha",
    "Mudiraj",
    "Munnuru",
    "Muthuraja",
    "Naagavamsam",
    "Nadar",
    "Nagaralu",
    "Nai",
    "Naicken",
    "Naicker",
    "Naidu",
    "Naik",
    "Nair",
    "VaniyaNair",
    "VelethadathuNair – Vilakkithala",
    "Namasudra",
    "Nambiar",
    "Nambisan",
    "Namdev",
    "Namo",
    "Sudra",
    "Napit",
    "Nayak",
    "Nayaka",
    "Neeli",
    "Nepali",
    "Nhavi",
    "OBC",
    "Barber",
    "Naayee",
    "Oswal",
    "Otari",
    "Padmasali",
    "Panchal",
    "Pandaram",
    "Panicker",
    "Paravan",
    "Parit",
    "Parkava",
    "Kulam",
    "Partraj",
    "Pasi",
    "Paswan",
    "Patel",
    "DesaiPatel",
    "DodiaPatel",
    "KadvaPatel – Leva",
    "Patnaick",
    "Patra",
    "Patwa",
    "Perika",
    "Pillai",
    "Pisha",
    "Rody",
    "Poduval",
    "Poosala",
    "Porwal",
    "Prajapati",
    "Pulaya",
    "Punjabi",
    "Raigar",
    "Rajaka",
    "Rajaka",
    "Chakali",
    "Dhobi",
    "Rajbhar",
    "Rajput",
    "Garhwali",
    "Kumaoni",
    "Lodhi",
    "Ramdasia",
    "Ramgharia",
    "Rauniyar",
    "Ravidasia",
    "Rawat",
    "Reddiar",
    "Reddy",
    "Relli",
    "SSK",
    "Sadgop",
    "Sagara – Uppara",
    "Saha",
    "Sahu",
    "Saini",
    "Saiva",
    "Vellala",
    "Saliya",
    "Sambava",
    "Satnami",
    "Savji",
    "Scheduled Caste(SC)",
    "Scheduled Tribe(ST)",
    "Senai",
    "Thalaivar",
    "Sepahia",
    "Setti",
    "Balija",
    "Shah",
    "Shilpkar",
    "Shimpi",
    "Sindhi",
    "Sindhi",
    "BhanusaliSindhi",
    "BhatiaSindhi",
    "ChhapruSindhi",
    "DaduSindhi",
    "HyderabadiSindhi",
    "LaraiSindhi",
    "LohanaSindhi",
    "RohiriSindhi",
    "SehwaniSindhi",
    "ThataiSindhi",
    "AmilSindhi",
    "BaibhandSindhi",
    "LarkanaSindhi",
    "SahitiSindhi",
    "SakkharSindhi",
    "Shikarpuri",
    "Somvanshi",
    "SonarSoni",
    "Sourashtra",
    "Sowrashtra",
    "Sozhiya",
    "VellalarSri",
    "Vaishnava",
    "Srisayana",
    "Subarna",
    "Banik",
    "Sugali(Naika)",
    "SundhiSurya",
    "Balija",
    "Sutar",
    "SutharSwakula",
    "SaliSwarnakar",
    "Tamboli",
    "Tamil",
    "Yadava",
    "Tanti",
    "Tantuway",
    "Telaga",
    "Teli",
    "Telugu",
    "Thachar",
    "Thakkar",
    "Thakur",
    "Thandan",
    "Thigala",
    "Thiyya",
    "Thuluva",
    "Vellala",
    "Tili",
    "Togata",
    "Turupu",
    "Kapu",
    "Udayar",
    "Urali",
    "Gounder",
    "UrsVada",
    "Balija",
    "Vadagalai",
    "Vaddera",
    "Vaduka",
    "VaishVaish",
    "Dhaneshawat",
    "VaishnavVaishnav",
    "BhatiaVaishnav",
    "VaniaVaishya",
    "VallalaValluvan",
    "Valmiki",
    "Vanika",
    "Vyshya",
    "Vaniya",
    "Chettiar",
    "Vanjara",
    "Vankar",
    "Vannan",
    "Vannar",
    "Vanniya",
    "Kulak",
    "Kshatriya",
    "Vanniyar",
    "Variar",
    "Varshney",
    "Veera",
    "Saivam",
    "Veerashaiva",
    "Velaan",
    "Velama",
    "Velar",
    "Vellalar",
    "Veluthedathu",
    "Nair",
    "Vettuva",
    "Gounder",
    "Vishwakarma",
    "Viswabrahmin",
    "Vokaliga",
    "Vokkaliga",
    "Vysya",
    "Waada",
    "Balija",
    "Yadav",
    "Yellapu",
    "Other"

];

export const Prefered_languages = [
    "Doesn't matter",
    "Arabic",
    "Arunachali",
    "Assamese",
    "Awadhi",
    "Baluchi",
    "Bengali",
    "Bhojpuri",
    "Bhutia",
    "Brahui",
    "Brij",
    "Burmese",
    "Chattisgarhi",
    "Chinese",
    "Coorgi",
    "Dogri",
    "English",
    "French",
    "Garhwali",
    "Garo",
    "Gujarati",
    "Haryanavi",
    "Himachali/Pahari",
    "Hindi",
    "Hindko",
    "Kakbarak",
    "Kanauji",
    "Kannada",
    "Kashmiri",
    "Khandesi",
    "Khasi",
    "Konkani",
    "Koshali",
    "Kumaoni",
    "Kutchi",
    "Ladakhi",
    "Lepcha",
    "Magahi",
    "Maithili",
    "Malay",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Marwari",
    "Miji",
    "Mizo",
    "Monpa",
    "Nepali",
    "Odia",
    "Pashto",
    "Persian",
    "Punjabi",
    "Rajasthani",
    "Russian",
    "Sanskrit",
    "Santhali",
    "Seraiki",
    "Sindhi",
    "Sinhala",
    "Sourashtra",
    "Spanish",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Telugu",
    "Tulu",
    "Urdu",
    "Other"
];

export const Prefered_Qualifications = [
    "Doesn't matter",
    "B.E / B.Tech",
    "M.E / M.Tech",
    "M.S Engineering",
    "B.Eng (Hons)",
    "M.Eng (Hons)",
    "Engineering Diploma",
    "AE",
    "AET",
    "B.A",
    "B.Ed",
    "BJMC",
    "BFA",
    "B.Arch",
    "B.Des",
    "BMM",
    "MFA",
    "M.Ed",
    "M.A",
    "MSW",
    "MJMC",
    "M.Arch",
    "M.Des",
    "BA (Hons)",
    "B.Arch(Hons)",
    "DFA",
    "D.Ed",
    "D.Arch",
    "AA",
    "AFA",
    "B.Com",
    "CA / CPA",
    "CFA",
    "CS",
    "BSc / BFin",
    "M.Com",
    "MSc / MFin / MS",
    "BCom (Hons)",
    "PGD Finance",
    "BCA",
    "B.IT",
    "BCS",
    "BA Computer Science",
    "MCA",
    "PGDCA",
    "IT Diploma",
    "ADIT",
    "B.Sc",
    "M.Sc",
    "BSc(Hons)",
    "DipSc",
    "AS",
    "AAS",
    "MBBS",
    "BDS",
    "BPT",
    "BAMS",
    "BHMS",
    "B.Pharma",
    "BVSc",
    "BSN / BScN",
    "MDS",
    "MCh",
    "M.D",
    "M.S Medicine",
    "MPT",
    "DM",
    "M.Pharma",
    "MVSc",
    "MMed",
    "PGD Medicine",
    "ADN",
    "BBA",
    "BHM",
    "BBM",
    "MBA",
    "PGDM",
    "ABA",
    "ADBus",
    "BL / LLB ML / LLM",
    "LLB(Hons)",
    "ALA",
    "Ph.D",
    "M.Phil",
    "Bachelor",
    "Master",
    "Diploma",
    "Honours",
    "Doctorate",
    "Associate",
    "High school",
    "Less than high school"
];

export const Prefered_proffessional_bkgrd = [
    "Information Technology and Computer Science",
    "Healthcare and Wellness",
    "Creative Arts and Design",
    "Education and Academia",
    "Legal and Compliance",
    "Engineering and Architecture",
    "Business and Management",
    "Marketing and Advertising",
    "Finance and Investment",
    "Hospitality and Tourism",
    "Environmental and Agricultural Sciences",
    "Public Service and Government",
    "Social Work and Counseling",
    "Media and Communication",
    "Entertainment",
    "Transportation",
    "Culinary Arts",
    "Science",
    "Medical / Healthcare",
    "Writer",
    "Construction and Carpentry",
    "Skilled Trades",
    "Agriculture",
    "Fashion",
    "Emergency Services",
    "Law Enforcement",
    "Not working",
    "Database Administrator",
    "Network Administrator",
    "System Administrator",
    "Cybersecurity Professional",
    "Data Scientist",
    "IT Support Specialist",
    "Cloud Architect",
    "DevOps Engineer",
    "UI/UX Designer",
    "IT Project Manager",
    "Software Engineer",
    "Frontend Developer",
    "Backend Developer",
    "Mobile App Developer",
    "Machine Learning Engineer",
    "Computer Programmer",
    "Game Developer",
    "Web Developer",
    "Chiropractor",
    "Dietitian / Nutritionist",
    "Acupuncturist",
    "Yoga Instructor",
    "Personal Trainer",
    "Health Coach",
    "Massage Therapist",
    "Holistic Practitioner",
    "Dermatologist",
    "Radiologist",
    "Oncologist",
    "Surgeon Assistant",
    "Physical Therapist Assistant",
    "Emergency Medical Technician (EMT)",
    "Medical Laboratory Technician",
    "Nurse",
    "Nutritionist",
    "Occupational Therapist",
    "Optometrist",
    "Paramedic",
    "Pharmacist",
    "Psychiatrist",
    "Psychologist",
    "Graphic Designer",
    "Illustrator",
    "Multimedia Artist",
    "3D Modeler",
    "Game Designer",
    "Art Director",
    "Art Therapist",
    "Street Artist",
    "Tattoo Artist",
    "Visual Merchandiser",
    "Ceramicist",
    "Set Designer",
    "Fashion Stylist",
    "Composer",
    "Makeup Artist",
    "Painter",
    "Photographer",
    "School Principal",
    "Education Consultant",
    "Academic Advisor",
    "Librarian",
    "School Counselor",
    "Education Policy Analyst",
    "Education Researcher",
    "Adjunct Professor",
    "School Administrator",
    "Special Education Teacher",
    "School Psychologist",
    "Teaching Assistant",
    "Professor",
    "Compliance Officer",
    "Paralegal",
    "Legal Secretary",
    "Corporate Counsel",
    "Patent Attorney",
    "Legal Analyst",
    "Immigration Lawyer",
    "Corporate Compliance Analyst",
    "Trial Attorney",
    "Environmental Lawyer",
    "Intellectual Property Lawyer",
    "Aerospace Engineer",
    "Chemical Engineer",
    "Environmental Engineer",
    "Urban Planner",
    "Construction Manager",
    "Biomedical Engineer",
    "Geotechnical Engineer",
    "Electrical Engineer",
    "Robotics Engineer",
    "Architectural Technologist",
    "Civil Engineer",
    "Mechanical Engineer",
    "Supply Chain Manager",
    "Operations Manager",
    "Business Analyst",
    "Quality Assurance Manager",
    "Risk Manager",
    "Project Coordinator",
    "Business Development Executive",
    "Human Resources Manager",
    "Financial Controller",
    "Management Consultant",
    "Retail Manager",
    "Franchise Owner",
    "Salesperson",
    "Real Estate Agent",
    "Receptionist",
    "Digital Marketing Specialist",
    "Social Media Manager",
    "Content Creator",
    "Market Research Analyst",
    "Brand Manager",
    "Media Buyer",
    "SEO Specialist",
    "Email Marketing Manager",
    "Influencer Marketer",
    "Media Planner",
    "Creative Director",
    "Reporter",
    "Publicist",
    "Financial Analyst",
    "Wealth Manager",
    "Actuary",
    "Risk Analyst",
    "Quantitative Analyst",
    "Investment Banker",
    "Portfolio Manager",
    "Private Equity Analyst",
    "Hedge Fund Manager",
    "Venture Capitalist",
    "Financial Planner",
    "Economist",
    "Tour Guide",
    "Travel Agent",
    "Resort Manager",
    "Cruise Director",
    "Tourism Development Officer",
    "Event Planner",
    "Hotel Concierge",
    "Sommelier",
    "Ecotourism Guide",
    "Waiter/Waitress",
    "Barista",
    "Environmental Scientist",
    "Conservation Biologist",
    "Agronomist",
    "Farm Manager",
    "Forester",
    "Soil Scientist",
    "Agricultural Economist",
    "Hydrologist",
    "Animal Scientist",
    "Botanist",
    "Diplomat",
    "Government Policy Analyst",
    "Civil Servant",
    "Municipal Administrator",
    "City Planner",
    "Public Health Officer",
    "Policy Advisor",
    "Legislative Assistant",
    "Detective",
    "Police Officer",
    "Judge",
    "Politician",
    "Family Therapist",
    "Substance Abuse Counselor",
    "Marriage Counselor",
    "Rehabilitation Counselor",
    "School Social Worker",
    "Child Welfare Worker",
    "Geriatric Counselor",
    "Career Counselor",
    "Counselor",
    "Social Worker",
    "Radio Broadcaster",
    "Podcast Host",
    "Content Strategist",
    "Copywriter",
    "Investigative Journalist",
    "News Anchor",
    "Social Media Influencer",
    "Media Relations Specialist",
    "Film Critic",
    "Actor",
    "Air Traffic Controller",
    "Airline Pilot",
    "Animator",
    "Architect",
    "Artist",
    "Astronomer",
    "Audiologist",
    "Author",
    "Baker",
    "Banker",
    "Biologist",
    "Botanist",
    "Carpenter",
    "Chef",
    "Chemist",
    "Construction Worker",
    "Dentist",
    "Dietitian",
    "Doctor",
    "Electrician",
    "Engineer",
    "Environmental Scientist",
    "Farmer",
    "Fashion Designer",
    "Firefighter",
    "Flight Attendant",
    "Forester",
    "Game Developer",
    "Genetic Counselor",
    "Geologist",
    "Graphic Designer",
    "Historian",
    "Hotel Manager",
    "Industrial Designer",
    "Information Security Analyst",
    "Instructional Designer",
    "Interior Designer",
    "Interpreter / Translator",
    "IT Analyst",
    "Journalist",
    "Landscape Architect",
    "Lawyer",
    "Legal Assistant",
    "Life Coach",
    "Loan Officer",
    "Logistician",
    "Marketing Manager",
    "Massage Therapist",
    "Math Teacher",
    "Mechanical Engineer",
    "Medical Assistant",
    "Medical Secretary",
    "Medical Technologist",
    "Mental Health Counselor",
    "Merchandise Planner",
    "Meteorologist",
    "Microbiologist",
    "Military Officer",
    "Model",
    "Music Teacher",
    "Musician",
    "Network Administrator",
    "Nurse",
    "Nutritionist",
    "Occupational Therapist",
    "Operations Manager",
    "Optometrist",
    "Painter",
    "Paralegal",
    "Paramedic",
    "Pastor",
    "Pediatrician",
    "Personal Trainer",
    "Pharmacist",
    "Photographer",
    "Physical Therapist",
    "Physician Assistant",
    "Pilot",
    "Plumber",
    "Police Officer",
    "Political Scientist",
    "Preschool Teacher",
    "Principal",
    "Private Investigator",
    "Probation Officer",
    "Producer",
    "Product Manager",
    "Professor",
    "Project Manager",
    "Psychiatrist",
    "Psychologist",
    "Public Relations Specialist",
    "Quality Engineer",
    "Radiation Therapist",
    "Radiologic Technologist",
    "Real Estate Agent",
    "Receptionist",
    "Recruiter",
    "Registered Nurse",
    "Regulatory Affairs Specialist",
    "Research Assistant",
    "Respiratory Therapist",
    "Retail Manager",
    "Sales Engineer",
    "Sales Manager",
    "School Counselor",
    "School Librarian",
    "Scientist",
    "Secretary",
    "Security Guard",
    "Security Officer",
    "Social Media Manager",
    "Social Worker",
    "Software Developer",
    "Software Engineer",
    "Software Consultant",
    "Software Developer",
    "Software Engineer",
    "Sommelier",
    "Speech-Language Pathologist",
    "Statistician",
    "Structural Engineer",
    "Substance Abuse Counselor",
    "Supply Chain Manager",
    "Surgeon",
    "Surgical Technologist",
    "Surveyor",
    "Systems Analyst",
    "Systems Engineer",
    "Tax Preparer",
    "Teacher",
    "Technical Writer",
    "Telecommunications Specialist",
    "Town Planner",
    "Toxicologist",
    "Trainer",
    "Translator",
    "Transportation Engineer",
    "Travel Nurse",
    "Travel Writer",
    "Truck Driver",
    "Ultrasound Technician",
    "Urologist",
    "UX Designer",
    "Vascular Technologist",
    "Veterinarian",
    "Veterinary Assistant",
    "Veterinary Technician",
    "Video Game Designer",
    "Video Producer",
    "Videographer",
    "Virtual Assistant",
    "Visual Designer",
    "Web Designer",
    "Wedding Planner",
    "Welder",
    "Wildlife Biologist",
    "Wind Energy Engineer",
    "Zoologist",
    "Data Entry Operator",
    "Typist",
    "Stenographer",
    "Clerk",
    "Assistant",
    "Office Attendant",
    "Accounts Clerk",
    "Record Keeper",
    "Peon",
    "Driver",
    "Sweeper",
    "Gardener",
    "Security Guard",
    "Cleaner",
    "Mason",
    "IAS Officer",
    "IPS Officer",
    "IFS Officer",
    "IRS Officer",
    "Judicial Officer",
    "Tehsildar",
    "District Magistrate",
    "Sub-Inspector",
    "Patwari",
    "Tribal Officer",
    "Assistant Commissioner",
    "Technician",
    "Lab Assistant",
    "Technical Assistant",
    "Draftsman",
    "Surveyor",
    "Radiographer",
    "Lab Technician",
    "Nurse",
    "Pharmacist",
    "Health Inspector",
    "Dental Surgeon",
    "Veterinary Doctor",
    "Ayurvedic Doctor",
    "Homeopathic Doctor",
    "Naturopath",
    "Principal",
    "School Librarian",
    "Education Officer",
    "Headmaster",
    "Lecturer",
    "Professor",
    "Research Scholar",
    "School Counselor",
    "Education Inspector",
    "Accountant",
    "Auditor",
    "Tax Officer",
    "Accounts Officer",
    "Budget Analyst",
    "Finance Manager",
    "Treasury Officer",
    "Financial Analyst",
    "Cost Accountant",
    "Internal Auditor",
    "Revenue Officer",
    "Advocate",
    "Public Prosecutor",
    "Legal Advisor",
    "District Attorney",
    "Notary",
    "Judicial Clerk",
    "Legal Researcher",
    "Law Officer",
    "Court Reporter",
    "Judicial Magistrate",
    "Student",
    "Retired",
    "Non-Working",
    "Others"
];


export const Prefered_IncomeList = [
    "Doesn't matter",
    "Upto INR 1 Lakh",
    "INR 1 Lakh to 2 Lakh",
    "INR 2 Lakh to 4 Lakh",
    "INR 4 Lakh to 7 Lakh",
    "INR 7 Lakh to 10 Lakh",
    "INR 10 Lakh to 15 Lakh",
    "INR 15 Lakh to 20 Lakh",
    "INR 20 Lakh to 30 Lakh",
    "INR 30 Lakh to 50 Lakh",
    "INR 50 Lakh to 75 Lakh",
    "INR 75 Lakh to 1 Crore",
    "INR 1 Crore & above",
    "Not applicable",
    "Keep this private"
];

export const Prefered_food = [
    "Doesn't matter",
    "Jain Vegan",
    "Strictly Vegeterain",
    "Strictly Krishna Prasadam",
    "Vegetarian and occasionally Krishna Prasadam",
    "Vegetarian with occasional consumption of onion and garlic",
    "Eggeterian",
    "Non-veg",
    "Other"
];

export const Prefered_manglik = [
    "Doesn't matter",
    "Shouldn't have any manglik matching issue",
    "A little manglik maching  issue is ok",
    "Others"
];

export const Prefered_cultualVal = [
    "Doesn't Matter",
    "Traditional",
    "Modern",
    "Mix of traditional and modern",
    "Liberal",
    "Spiritual",
    "Other"
];

export const Prefered_Initiation = [
    "Doesn't matter",
    "Yes",
    "No",
    "Aspiring",
    "Others"
];

export const Prefered_Affiliation = [
    "Doesn't Matter",
    "Full-time Temple Devotee",
    "FOLK",
    "Congregation",
    "Krishna Life",
    "Krishnashraya",
    "Saadhika",
    "IYF Boy",
    "IYF Girl",
    "Open to discussion or further consideration",  
    "Any affiliation",
    "Other"
];

export const Books_Names = [
    "Bhagavad-gita as it is",
    "Srimad Bhagavatam",
    "Sri Caitanya caritamrta",
    "KRSNA, The Supreme Personality of Godhead",
    "A Second Chance",
    "Beyond Birth and Death",
    "Beyond Illusion & Doubt",
    "Bhakti, the Art of Eternal Love",
    "Civilization and Transcendence",
    "Dharma: The Way of Transcendence",
    "Easy Journey to Other Planets",
    "Elevation to Krsna Consciousness",
    "Journey of Self-Discovery",
    "Krsna Consciousness The Topmost Yoga System",
    "Krsna Consciousness: The Matchless Gift",
    "Laws of Nature: An Infallible Justice",
    "Life Comes from Life",
    "Light of the Bhagavata",
    "Message of Godhead",
    "Mukunda-mala-stotra",
    "Nectar of Devotion",
    "Nectar of Instruction",
    "Narada-bhakti-sutra",
    "On the Way to Krsna",
    "Path of Perfection",
    "Perfect Questions, Perfect Answers",
    "Quest for Enlightenment",
    "Raja-Vidya: The King of Knowledge",
    "Renunciation Through Wisdom",
    "Science of Self Realization",
    "Spiritual Yoga",
    "Sri Isopanisad",
    "Teachings of Lord Caitanya",
    "Teachings of Lord Kapila, the Son of Devahuti",
    "Teachings of Queen Kunti",
    "The Hare Krishna Challenge",
    "The Perfection of Yoga",
    "Other"
];

export const EkadashiOp = [
    "Yes",
    "No",
    "Occasionally"
];

export const ISKCONHKMCentre = [
    "ISKCON Temple, Mayapur, West Bengal",
    "ISKCON Temple, Vrindavan, Uttar Pradesh",
    "ISKCON Temple, Mumbai, Maharashtra (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Delhi (Sri Sri Radha Parthasarathi Temple)",
    "ISKCON Temple, Bengaluru, Karnataka (Sri Sri Radha Krishnachandra Temple)",
    "ISKCON Temple, Pune, Maharashtra",
    "ISKCON Temple, Kolkata, West Bengal (Sri Sri Radha Govinda Mandir)",
    "ISKCON Temple, Ahmedabad, Gujarat (Sri Sri Radha Govind Dham)",
    "ISKCON Temple, Hyderabad, Telangana",
    "ISKCON Temple, Coimbatore, Tamil Nadu",
    "ISKCON Temple, Chennai, Tamil Nadu",
    "ISKCON Temple, Jaipur, Rajasthan (Sri Sri Giridhari Dauji Temple)",
    "ISKCON Temple, Juhu, Mumbai, Maharashtra (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Kanpur, Uttar Pradesh",
    "ISKCON Temple, Mangalore, Karnataka (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Rajkot, Gujarat (Sri Sri Radha Neelmadhav Temple)",
    "ISKCON Temple, Ujjain, Madhya Pradesh",
    "ISKCON Temple, Belgaum, Karnataka (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Ludhiana, Punjab",
    "ISKCON Temple, Chandigarh (Sri Sri Radha Madhav Temple)",
    "ISKCON Temple, Surat, Gujarat",
    "ISKCON Temple, Nasik, Maharashtra",
    "ISKCON Temple, Aurangabad, Maharashtra",
    "ISKCON Temple, Dwarka, Gujarat (Sri Sri Radha Damodar Temple)",
    "ISKCON Temple, Bhuvaneshwar, Odisha",
    "ISKCON Temple, Gurgaon, Haryana (Sri Sri Radha-Madhav Temple)",
    "ISKCON Temple, Coimbatore, Tamil Nadu",
    "ISKCON Temple, Jabalpur, Madhya Pradesh",
    "ISKCON Temple, Patna, Bihar (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, Tirupati, Andhra Pradesh",
    "ISKCON Temple, Nagpur, Maharashtra",
    "ISKCON Temple, Bhopal, Madhya Pradesh",
    "ISKCON Temple, Ludhiana, Punjab",
    "ISKCON Temple, Kanpur, Uttar Pradesh",
    "ISKCON Temple, Salem, Tamil Nadu",
    "ISKCON Temple, Vijayawada, Andhra Pradesh",
    "ISKCON Temple, Madurai, Tamil Nadu",
    "ISKCON Temple, Udaipur, Rajasthan",
    "ISKCON Temple, Varanasi, Uttar Pradesh",
    "ISKCON Temple, Jalandhar, Punjab (Sri Sri Radha Gokulananda Temple)",
    "ISKCON Temple, Manipur",
    "ISKCON Temple, Puri, Odisha",
    "ISKCON Temple, Bhubaneswar, Odisha (Sri Sri Krishna Balarama Temple)",
    "ISKCON Temple, Kolhapur, Maharashtra (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, Mathura, Uttar Pradesh",
    "ISKCON Temple, Ranchi, Jharkhand",
    "ISKCON Temple, Mysuru, Karnataka (Sri Sri Radha Shyamasundara Temple)",
    "ISKCON Temple, Tiruchirappalli, Tamil Nadu",
    "ISKCON Temple, Raipur, Chhattisgarh",
    "ISKCON Temple, Kozhikode, Kerala (Sri Sri Radha Gopinath Temple)",
    "ISKCON Temple, London, United Kingdom",
    "ISKCON Temple, New York City, USA (Sri Sri Radha Govinda Mandir)",
    "ISKCON Temple, Los Angeles, USA (New Dwaraka Temple)",
    "ISKCON Temple, Washington D.C., USA (Sri Sri Radha Madan Mohan Mandir)",
    "ISKCON Temple, Sydney, Australia (Sri Sri Radha Gopinath Mandir)",
    "ISKCON Temple, Auckland, New Zealand (Sri Sri Radha Giridhari Temple)",
    "ISKCON Temple, Moscow, Russia",
    "ISKCON Temple, Sao Paulo, Brazil (Sri Sri Radha Vrindavan Chandra Temple)",
    "ISKCON Temple, Durban, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Tokyo, Japan (Sri Sri Radha Shyamasundara Temple)",
    "ISKCON Temple, Paris, France (Sri Sri Radha Paris-Isvara Temple)",
    "ISKCON Temple, Barcelona, Spain (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Berlin, Germany (Sri Sri Radha Gopinatha Mandir)",
    "ISKCON Temple, Amsterdam, Netherlands",
    "ISKCON Temple, Dublin, Ireland (Sri Sri Radha-Govinda Temple)",
    "ISKCON Temple, Zurich, Switzerland",
    "ISKCON Temple, Rome, Italy (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Budapest, Hungary (Sri Sri Radha Syamasundara Temple)",
    "ISKCON Temple, Prague, Czech Republic (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Vienna, Austria",
    "ISKCON Temple, Belgium",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Kuala Lumpur, Malaysia",
    "ISKCON Temple, Bangkok, Thailand (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Melbourne, Australia (Sri Sri Radha Ballabha Temple)",
    "ISKCON Temple, Manila, Philippines (Sri Sri Radha-Madhava Temple)",
    "ISKCON Temple, Dhaka, Bangladesh (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Dubai, UAE (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Toronto, Canada (Sri Sri Radha Ksira-chora Gopinatha Temple)",
    "ISKCON Temple, Boston, USA (Sri Sri Radha Gopivallabha Temple)",
    "ISKCON Temple, Vancouver, Canada (Sri Sri Radha Madan Mohana Temple)",
    "ISKCON Temple, Chicago, USA (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Atlanta, USA (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Seattle, USA (Sri Sri Radha Nila-madhava Temple)",
    "ISKCON Temple, Montreal, Canada (Sri Sri Radha Manohara Temple)",
    "ISKCON Temple, Houston, USA (Sri Sri Radha Nilamadhava Temple)",
    "ISKCON Temple, Dallas, USA (Sri Sri Radha Kalachandji Temple)",
    "ISKCON Temple, Phoenix, USA (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Mexico City, Mexico (Sri Sri Radha Madhava Temple)",
    "ISKCON Temple, Buenos Aires, Argentina (Sri Sri Gaura-Nitai Temple)",
    "ISKCON Temple, Santiago, Chile (Sri Sri Radha Govinda Temple)",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Lima, Peru (Sri Sri Radha Krishna Temple)",
    "ISKCON Temple, Cape Town, South Africa (Sri Sri Radha-Radhanath Temple)",
    "ISKCON Temple, Nairobi, Kenya (Sri Sri Radha Banke Bihari Temple)",
    "ISKCON Temple, Johannesburg, South Africa (Sri Sri Radha Radhanath Temple)",
    "ISKCON Temple, Jakarta, Indonesia (Sri Sri Radha Rasabihari Temple)",
    "ISKCON Temple, Kathmandu, Nepal (Sri Sri Radha-Madhav Temple)",
    "ISKCON Temple, Colombo, Sri Lanka (Sri Sri Radha-Madhava Temple)",
    "ISKCON Temple, Lagos, Nigeria (Sri Sri Radha Govinda Mandir)",
    "ISKCON Bangalore",
    "Vrindavan Chandrodaya Mandir, Vrindavan",
    "Hare Krishna Movement, Dharmapuri",
    "Hare Krishna Movement, Hyderabad",
    "Hare Krishna Movement, Chennai",
    "Hare Krishna Movement, Ahmedabad",
    "Hare Krishna Movement, Puri / Bhubaneshwar",
    "Hare Krishna Movement, Guwahati",
    "Hare Krishna Movement, Visakhapatnam",
    "Hare Krishna Movement, Bhilai",
    "Hare Krishna Movement, Jaipur",
    "Sri Krishna Balarama Mandir, Sunnyvale",
    "Hare Krishna Ashram, Penang",
    "Hare Krishna Ashram, Johor",
    "Hare Krishna Movement, Tirupati",
    "Hare Krishna Movement, Lucknow",
    "Hare Krishna Movement, Mumbai",
    "Hare Krishna Movement, Vijayawada",
    "Hare Krishna Movement, Coimbatore",
    "India Heritage Foundation, NJ-NY, USA",
    "India Heritage Foundation, Boston",
    "India Heritage Foundation, London"
];

export const AssociatedType = [
    "Full-time Temple Devotee",
    "FOLK",
    "Congregation",
    "Krishna Life",
    "Krishnashraya",
    "Saadhika",
    "IYF Boy",
    "IYF Girl",
    "Open to discussion or further consideration",  
    "Any affiliation",
    "Other"
];

export const DevotionalServices = [
    "Yes",
    "No",
    "Maybe",
    "Other"
];

export const AttendedSeminar = [
    "Yes",
    "No",
    "Maybe",
];

export const DevotionalCommunity = [
    "Extremely important",
    "Somewhat important",
    "Neutral",
    "Somewhat not important",
    "Not important",
    "Other"
];

export const Krishna_Consciousness_marriage = [
    "Yes",
    "No",
    "Maybe",
];

export const SrilaPrabhupadaTeach = [
    "I have a deep understanding of and wholeheartedly accept the principles and teachings of Srila Prabhupada.",
    "I have a good understanding of and accept the principles and teachings of Srila Prabhupada",
    "I am familiar with the principles and teachings of Srila Prabhupada",
    "I am new to the principles and teachings of Srila Prabhupada",
    "Others"
];

export const SpiritualGrowth = [
    "Yes, I envision supporting and encouraging each other's spiritual growth.",
    "Somewhat, I'm open to supporting and encouraging spiritual growth if it aligns with our beliefs.",
    "Neutral/Undecided, I haven't thought about it much.",
    "Not a priority, I don't see the need for it.",
    "No, I prefer individual spiritual journeys.",
    "Others"
];


export const stresOptions = [
    "Spiritual Perspective: 🙏📿",
    "Practice mindfulness and self-reflection: 🧘‍♂️🤔",
    "Seek Personal Guidance: 🌟🔍",
    "Self-help resources: 📚💡",
    "Proactive mindset for practical solutions: 💪🛠️",
    "Chanting and meditation: 🧘‍♀️🎶",
    "Exercise: 🏋️‍♂️🚴‍♀️",
    "Nature walks: 🌿🚶‍♂️",
    "Creative Hobbies: 🎨✂️",
    "Music therapy: 🎵🎶",
    "Pet therapy: 🐾😸",
    "Social support: 🤗👥",
    "Journaling: 📓✍️"
];

export const freeTime = [
    "Creative Arts: 🎨✏️",
    "Physical Activities: 🏋️‍♂️🚴‍♀️",
    "Reading and Writing: 📚✍️",
    "Music and Performing Arts: 🎵🎭",
    "Cooking and Baking: 🍳🍰",
    "Gardening and Nature: 🌱🌿",
    "Collecting: 🔍🎭",
    "Gaming and Video Games: 🎮🕹️",
    "DIY and Home Improvement: 🛠️🏠",
    "Travel and Exploration: ✈️🌍",
    "Volunteering and Community Service: 🤝🌟",
    "Technology and Gadgets: 💻📱",
    "Learning and Education: 📖🎓",
    "Puzzles and Brain Teasers: 🧩🧠",
    "Spirituality and Mindfulness: 🙏☮️"
];

export const movieBook = [
    "Action:💥🔫",
    "Comedy: 😂🎭",
    "Spiritual and Philosophical: 🙏📚",
    "Inspirational: 🌟💡",
    "Drama: 🎭🎬",
    "Thriller/Suspense: 😱🕵️‍♂️",
    "Horror: 🧟‍♂️👻",
    "Science Fiction: 🚀👽",
    "Fantasy: 🧙‍♂️🐉",
    "Romance: 💖💑",
    "Animation: 🎬👾",
    "Documentary: 🎥📖",
    "Others: 🌈🎉"
];

export const motivateOption = [
    "Knowledge and Learning: 📚🧠",
    "Creativity and Innovation: 🎨💡",
    "Helping Others: 🤝❤️",
    "Making a Positive Impact: 🌟🌱",
    "Problem-Solving: 🧩💡",
    "Growth and Improvement: 🌱📈",
    "Nature and Beauty: 🌿🌺",
    "Curiosity and Exploration: 🌍🔍",
    "Collaboration and Teamwork: 👥🤝",
    "Personal Development: 🧘‍♂️🌟",
    "Overcoming Challenges: 💪🏆",
    "Making Connections: 🤝🔗",
    "Balance and Harmony: ⚖️☯️",
    "Gratitude and Positivity: 🙏😊",
    "Fulfilling Potential: 🚀🌟"
];

export const strength = [
    "Leadership 💡‍",
    "Empathy 🤝‍",
    "Creativity 🎨‍",
    "Attention to detail 🧐‍",
    "Emotional sensitivity 😔‍",
    "Work-life balance ⚖️‍",
    "Communication 💬‍"
];

export const weakness = [
    "Impatience 😬",
    "Procrastination⏳️",
    "Punctuality ⏰",
    "Shy 🙈",
    "Inflexible 🚫",
    "Disorganized 📦",
    "Forgetful 🤦‍♂️"
];

export const personalityOp = [
    "Outspoken",
    "Introverted",
    "Extroverted",
    "Jovial",
    "Reserved",
    "Friendly",
    "Thoughtful",
    "Adventurous",
    "Observant",
    "Charismatic",
    "Other"
];

export const socialgathering = [
    "Extremely Comfortable: 🎉 Life of the party",
    "Very Comfortable: 🥳 Enjoys socializing",
    "Comfortable: 😊 Likes social events",
    "Neutral: 😐 Flexible, Versatile,depends on the situation",
    "Slightly Uncomfortable: 😬 Prefers smaller gatherings",
    "Uncomfortable: 😳 Introverted, but open to socializing",
    "Very Uncomfortable: 🙈 Prefers one-on-one interactions",
    "Extremely Uncomfortable: 🙅‍♀️ Very Reserved, avoids large social gatherings whenever possible"
];

export const lifestyle = [
    "Yoga & Meditation: 🧘‍♂️🧘‍♀️",
    "Spiritual Practices: 🙏",
    "Simplicity and Mindfulness: 🌿☮️",
    "Compassion and Service: ❤️‍🩹🤝",
    "Connection with Nature: 🌳🌺",
    "Study of Sacred Texts: 📖🕉️",
    "Others: 💫🌟"
];

export const dietOp = [
    "Jain Vegan",
    "Strictly Vegeterain",
    "Strictly Krishna Prasadam",
    "Vegetarian and occasionally Krishna Prasadam",
    "Vegetarian with occasional consumption of onion and garlic",
    "Eggeterian",
    "Non-veg",
    "Other"
];

export const criticism_feedback = [
    "Open-mindedness and growth: 🌱🌿",
    "Defensiveness and denial: 🛡️🙅‍♂️",
    "Reflection and self-improvement: 🤔📈",
    "Emotional reactions: 😢😡",
    "Ignoring or dismissing: 🙉🙈",
    "Other: 💭🔄"
];

export const StructuredRoutine = [
    "Yes",
    "No",
    "Maybe",
    "Other"
];

export const LongTermGoal = [
    "Career growth 💼📈‍",
    "Community impact 🤝🌍",
    "Financial security 💰🔒",
    "Work-life balance ⚖️😌",
    "Spiritual progress 🙏😊"

];


export const privacyOptions = [
    "Everyone",
    "My connection",
    "Matchmaker",
    "None",
];

export const BlockReasons = [
    "Nudity or Sexual Activity", "Sale of illegal or regulated goods", "Suicide or Self Injury", "Hate Speech or Symbols", "Bullying or Harrassment", "Scam or Fraud", "Pretending to be someone else", "The problem isn’t listed here", "Violence or dangerous organisations", "Intellectual Property violation", "Spam"
]




// Api responses


export const WeadingRes = {
    "status": 200,
    "message": "Weading planner data",
    "data": {
        "intro": {
            "title": "Introducing Wedding Venues",
            "description": "At Vedic Marriage, we take pride in offering a distinguished array of wedding venue services designed to transform your special day into an extraordinary, unforgettable celebration. Our commitment to excellence is reflected in every detail, ensuring that your wedding is a seamless, joyous experience.",
            "image": "images/venu-img.png"
        },
        "divineVenues": [{
            "id": 1,
            "image": "images/Divine1.png",
            "description": "Immerse in a wedding experience beyond the ordinary. Our venues, nestled within Hare Krishna temples, provide a unique sacred realm where spirituality intertwines seamlessly with your celebration.",
            "title": "Sacred Realm of Spirituality",
        },
        {
            "id": 2,
            "image": "images/Divine2.png",
            "description": "Your special day deserves a setting that transcends the mundane. Our venues offer a divine ambiance, creating a unique and spiritually enriched backdrop for your union.",
            "title": "Divine Ambiance",
        },
        {
            "id": 3,
            "image": "images/Divine3.png",
            "description": "Tie the knot amidst the hallowed halls of our partnered temples. Beyond mere venues, they are sacred spaces where every corner resonates with blessings, adding a layer of spiritual significance to your matrimonial journey.",
            "title": "Blessings Beyond Walls",
        },
        ],
        "services": [
            {
                "id": 1,
                "head1": "Venue Selection",
                "head2": "Discover Your Perfect Setting",
                "content": "Embark on a journey to find the ideal backdrop for your love story. Our curated selection of venues encompasses a variety of styles, from romantic gardens to opulent ballrooms. Let us help you find a venue that speaks to the essence of your love."
            },
            {
                "id": 2,
                "head1": "Event Planning",
                "head2": "Crafting Your Dream Celebration",
                "content": "Our experienced event planners are dedicated to turning your vision into reality. From the initial concept to the final execution, we'll handle every detail to ensure a seamless and stress-free celebration. Let us take care of the logistics, so you can focus on creating lasting memories."
            },
            {
                "id": 3,
                "head1": "Catering",
                "head2": "Exquisite Culinary Experiences",
                "content": "Delight your guests with a culinary journey that complements the magic of your day. Our catering services offer a variety of menus, from traditional to modern cuisine. We work with top-notch chefs to ensure that every bite is a celebration of flavors."
            }
        ],
        "stories": [{
            "id": 1,
            "text": "I had been searching for a partner who not only understood my cultural values but also share my spiritual beliefs. Thanks to the Vedic Harmony platform, I found my soulmate.I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform. .I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform",
            "image": "images/stories1.png",
            "name": "Sai Manoj",
            "designation": "Product Manager"
        },
        {
            "id": 2,
            "text": "I had been searching for a partner who not only understood my cultural values but also share my spiritual beliefs. Thanks to the Vedic Harmony platform, I found my soulmate.I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform. .I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform",
            "image": "images/stories1.png",
            "name": "Sai Manoj",
            "designation": "Product Manager"
        },
        {
            "id": 3,
            "text": "I had been searching for a partner who not only understood my cultural values but also share my spiritual beliefs. Thanks to the Vedic Harmony platform, I found my soulmate.I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform. .I had been searching for a partner who not only understood my cultural values but also shared my spiritual beliefs. Thanks to the Vedic Harmony platform",
            "image": "images/stories1.png",
            "name": "Sai Manoj",
            "designation": "Product Manager"
        }
        ],
    }
}

