import MakerDashboard from '../Components/Pages/MakerDashboard';
import Home from '../Components/Pages/MakerDashboard/Home';
import Messages from '../Components/Pages/MakerDashboard/Messages';
import Profile from '../Components/Pages/MakerDashboard/Profile';
import Users from '../Components/Pages/MakerDashboard/Users';
import SingleFTM from '../Components/Pages/MakerDashboard/SingleFTM';
import FtmMatchingView from '../Components/Pages/MakerDashboard/FtmMatchingView';
import EditProfile from '../Components/Pages/MakerDashboard/EditProfile';
import BasicInfoEdit from '../Components/Pages/MakerDashboard/BasicInfoEdit';
import Gallry from '../Components/Pages/MakerDashboard/Gallry';
import About from '../Components/Pages/MakerDashboard/About';
import ProfileApprovals from '../Components/Pages/MakerDashboard/ProfileApprovals';
import SingleUserView from '../Components/Pages/MakerDashboard/SingleUserView';
import DefaultPage from '../Components/Pages/MakerDashboard/DefaultPage';
import AllPendingRecomendation from '../Components/Pages/MakerDashboard/AllPendingRecomendation';
import NewProfiles from '../Components/Pages/MakerDashboard/NewProfiles';
import PendingSelfieVerification from '../Components/Pages/MakerDashboard/PendingSelfieVerification';
import PendingGovtIds from '../Components/Pages/MakerDashboard/PendingGovtIds';
import History from '../Components/Pages/MakerDashboard/History';

export const makerRoutes = [
    {
        path: "/MakerDashboard",
        component: <MakerDashboard />
    },
    {
        path: "/MatchMakerDashboard",
        component: <Home />
    },
    {
        path: "/DefaultDashbard",
        component: <DefaultPage />
    },
    {
        path: "/MatchMakerMessages",
        component: <Messages />
    },
    {
        path: "/MatchMakerProfile",
        component: <Profile />
    },
    {
        path: "/AllUsers",
        component: <Users />
    },
    {
        path: "/UserProfile/:id",
        component: <SingleFTM />
    },
    {
        path: "/MatchingView/:id/:uid",
        component: <FtmMatchingView />
    },
    {
        path: "/MatchMakerEditProfile",
        component: <EditProfile />
    },
    {
        path: "/MatchMakerEditProfile/Edit",
        component: <BasicInfoEdit />
    },
    {
        path: "/MatchMakerEditProfile/UploadGallery",
        component: <Gallry />
    },
    {
        path: "/MatchMakerEditProfile/About",
        component: <About />
    },
    {
        path: "/PendingProfiles",
        component: <ProfileApprovals />
    },
    {
        path: "/PendingProfiles/:id",
        component: <SingleUserView />
    },
    {
        path: "/PendingRecomendation",
        component: <AllPendingRecomendation />
    },
    {
        path: "/AllProfiles",
        component: <NewProfiles />
    },
    {
        path: "/PendingSelfyVerification",
        component: <PendingSelfieVerification />
    },
    {
        path: "/PendingGovtVerification",
        component: <PendingGovtIds />
    },
    {
        path: "/UserHistory/:id",
        component: <History />
    }

];