import React, { Component, useEffect, useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { RegisterMatchMaker, clearStatus } from "../../../Reducer/authSlice";


export default function MakerSignup() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [form, setFormData] = useState({
        email: "",
        mobile: "",
        mobile_otp: "",
        email_otp: "",
        password: "",
        name: "",
        gender: "",
        marital_status: "",
        mother_tounge: "",
        associated_temple: "",
        Iskon_center: "",
        initiatedName: "",
        fullCountry: null,
        fullState: null,
        fullCity: null,
        about: "",
        images: [],
        id : ''
    });
    const success = useSelector(state => state.auth.successM);
    const error = useSelector(state => state.auth.errorM);

    console.log('success', success);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearStatus());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearStatus());
            setTimeout(() => {
                navigate("/Login");
            }, 2000);
        }

    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());

        }
    }


    useEffect(() => {

    }, []);


    const handleStep1Next = (data) => {
        console.log('dataaaa', data);
        setFormData(prevState => ({
            ...prevState,
            ...data  // Merge new data into the existing form data
        }));


        setStep(step + 1); // Render Step2
    };

    const hadleSubmitttt = (e) => {
        e.preventDefault();
        // alert("form submitted");
        console.log('abcc form data', form);
        let formData = new FormData();
        formData.append('name', form.name);
        formData.append('email', form.email);
        formData.append('mobile', form.mobile);
        formData.append('email_otp', form.email_otp);
        formData.append('mobile_otp', form.mobile_otp);
        formData.append('password', form.password);
        formData.append('gender', form.gender);
        formData.append('marital_status', form.marital_status);
        formData.append('mother_tounge', form.mother_tounge);
        formData.append('associated_temple', form.associated_temple);
        formData.append('Iskon_center', form.Iskon_center);
        formData.append('initiatedName', form.initiatedName);
        formData.append('fullCountry', JSON.stringify(form.fullCountry));
        formData.append('fullState', JSON.stringify(form.fullState));
        formData.append('fullCity', JSON.stringify(form.fullCity));
        formData.append('about', form.about);
        formData.append('id', form.id);
        formData.append('userType', "Maker");
        for (let index = 0; index < form.images.length; index++) {
            formData.append('images', form.images[index]);
        }
        dispatch(RegisterMatchMaker(formData));

    }

    return (

        <section id="login_sec" class="sign_up match_signup" style={{ backgroundImage: `url(${'images/matchmaker_signup.png'})` }}>
            <div class="container-fluid p-0 h-100">
                <div class="row h-100">
                    {/* <div class="col-lg-4 col-md-5 order">
                        <Link to="/"><i class="bi bi-arrow-left"></i> Back to Home</Link>
                        <div class="box_one">
                            <div class="login-img">
                                <img src="images/login-img.png" alt="" />
                            </div>
                        </div>
                    </div> */}
                    <div class="col-lg-5 col-md-5 order pe-md-0">
                        <Link to="/"><i class="bi bi-arrow-left"></i> Back to Home</Link>
                        <div class="box_one">
                            <div class="login-img h-100">
                                <img className="w-100 h-100" src="/images/loginnew.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 ps-md-0">
                        {step === 1 && <Step1 onNext={handleStep1Next} />}
                        {step === 2 && <Step2 onNext={handleStep1Next} />}
                        {step === 3 && <Step3 onNext={handleStep1Next} />}
                        {step === 4 && <Step4 onNext={handleStep1Next} />}
                        {step === 5 && <Step5 onFinish={hadleSubmitttt} />}
                    </div>
                </div>
            </div>
        </section>
    )

}