import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPwd, clerForgetDta } from "../../../Reducer/authSlice";
const schema = Yup.object().shape({
    password: Yup
        .string()
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

export default function ResetPwdModal({ setOpen2, emailForget }) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useDispatch();
    const success = useSelector(state => state.auth.Pwd);

    if(success){
        setOpen2(false);
        dispatch(clerForgetDta());
        
    }

 

    const handleCloseModal = () => {
        setOpen2(false); // Update the state in the Header component
    }

    const onSubmit = async (datas) => {
        console.log('dataass', datas);
        console.log('emailForget', emailForget);
        let formData = new FormData();
        formData.append('email', emailForget);
        formData.append('password', datas.password);
        // dispatch(ResetPwd(formData))
        dispatch(ResetPwd(formData));

    }

    const onError = (errors) => {
        console.log(errors);
    };


    return (
        <>
            <div class="modal fade show forgot_popup" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ display: "block" }}>
                <div class="modal-dialog">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal()}></button>
                            </div>
                            <div class="modal-body">
                                <div class="Logo">
                                    <img src="images/Logo.png" alt="" />
                                </div>
                                <h2>Forgot Password</h2>
                                <h3>Please enter your Passwords here</h3>
                                <div class="form-group">
                                    <label class="form-label">New Password</label>
                                    <input type="password" class="form-control" name="password"  {...register('password')} />
                                    {errors.password && <p className={"error__feedback"}>{errors.password.message}</p>}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Confirm password</label>
                                    <input type="password" class="form-control" name="confirm_password"  {...register('confirm_password')} />
                                    {errors.confirm_password && <p className={"error__feedback"}>{errors.confirm_password.message}</p>}
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleCloseModal()}>Back to Login</button>
                                <button type="submit" class="btn btn-primary">Reset Password    </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}