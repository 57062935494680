import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import { GetSinglResource } from "../../../../Reducer/apiSlice";
import { API_BASE_URL } from "../../Data/content";
import ReactHtmlParser from 'react-html-parser';
import "../../../../css/resource.css";
export default function SingleResource() {
    const dispatch = useDispatch();
    const params = useParams();
    const data = useSelector(state => state.api.resource);
    // const success = useSelector(state => state.api.success);
    // const error = useSelector(state => state.api.error);

    useEffect(() => {
        let dat = { id: params.id };
        
        dispatch(GetSinglResource(dat));
    }, []);
    const  formatDate =(timestamp) =>{
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      }
      
    return (
        <>
            <Header />

           

            <section id="blog_details">
        <div class="container">
        {data ?
            <div class="row">

                <div class="col-lg-12">
                    <div class="details-item">
                        <div class="details-img">
                            <img class="w-100" src={data && data.image && API_BASE_URL + data.image} alt="Details" />
                            <ul>
                                <li>
                                    <i class="bi bi-person-circle"></i>
                                    By: <a href="javascript:void(0)">admin</a>
                                </li>
                                <li>
                                    <i class="bi bi-calendar2"></i>
                                    {/* 20 December, 2023 */}
                                    {formatDate(data.updated_at)}
                                </li>
                                {/* <li>
                                    <i class="bi bi-chat-text"></i>
                                    <a href="#">Comments (02)</a>
                                </li> */}
                            </ul>
                            <h2>{data && data.title}</h2>
                            <div>
                            {ReactHtmlParser(data && data.description)}
                            </div>
                           

                        </div>

                        {/* <div class="details-comments">
                            <h3>Comments (02)</h3>
                            <ul>
                                <li>
                                    <img src="images/slider-img-2.png" alt="Details">
                                    <h4>Tom Henry</h4>
                                    <span>Saturday, May 20, 2020</span>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen
                                        book. It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged.</p>
                                    <a href="#">Reply</a>
                                </li>
                                <li>
                                    <img src="images/slider-img-3.png" alt="Details">
                                    <h4>Jac Jacson</h4>
                                    <span>Monday, August 22, 2020</span>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen
                                        book. It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged.</p>
                                    <a href="#">Reply</a>
                                </li>
                            </ul>
                        </div>
                        <div class="details-form">
                            <h3>Leave A Reply</h3>
                            <span>Your email address will not be published. Required fields are marked *</span>
                            <form>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Name*</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Email*</label>
                                            <input type="email" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Comment</label>
                                            <textarea id="your-comment" rows="6" class="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="btn cmn-btn">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                    </div>


                </div>


            </div>
              : <div class="row">Resource not found.</div>}
        </div>
    </section>
            <Footer />

        </>
    );
}
