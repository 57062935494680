import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Sidebar from "../UserDashboard/Sidebar";
import { GetSelfi, clearSel, uploadSelfi } from "../../../Reducer/StepForms/selfiSlice";
import { API_BASE_URL } from "../Data/content";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";

export default function SelfiVerify() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [element, setElemeent] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [opened, setOpned] = useState(false);
    const [img, setImage] = useState(null);
    const [imgView, setImageView] = useState(null);
    const videoRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const [err, setFormError] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.selfy.success);
    const error = useSelector(state => state.selfy.error);
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const data = useSelector(state => state.user.data);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearSel());
        } else {
            setMsg(success.data.message);
            setBtn(true);
            setDisabled(false);
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            dispatch(clearSel());
            // setTimeout(() => {
            //     navigate("/IdVerification");
            // }, 2000);
        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearSel());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearSel());

        }
    }

    const Next = () => {
        if (data.selected_plan == null) {
            navigate("/IdVerification");
        } else {


            if (data && data.basic === false) {
                navigate("/UserStepForms", { state: "Basic" });
            }
            else if (data && data.astro === false) {
                navigate("/UserStepForms", { state: "Astro" });
            }
            else if (data && data.families === false) {
                navigate("/UserStepForms", { state: "Family" });
            }
            else if (data && data.carrers === false) {
                navigate("/UserStepForms", { state: "Career" });
            }
            else if (data && data.spirituals === false) {
                navigate("/UserStepForms", { state: "Spiritual" });
            }
            else if (data && data.preferences === false) {
                navigate("/UserStepForms", { state: "Preferences" });
            }
            else if (data && data.personalityquestions === false) {
                navigate("/PersonalityQuestions");
            }
            else if (data && data.about === false) {
                navigate("/AboutPersonality");
            }
            else if (data && data.gallery === false) {
                navigate("/UploadGallery");
            }
            else if (data && data.govtVerification === false) {
                navigate("/IdVerification");
            }
            else if (data && data.privacy === false) {
                navigate("/PrivacySetting");
            } else {
                navigate("/Dashboard");
            }
        }
    }


    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetSelfi(dat));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            console.log('response selfie', responseData);
            if (responseData.img) {
                setImageView(API_BASE_URL + responseData.img);
                setImage(responseData.img);
            }
        }
    }


    const openCamera = async () => {
        try {
            setOpned(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            mediaStreamRef.current = stream; // Store the stream reference
        } catch (err) {
            console.error('Error accessing camera:', err);
        }
    };

    const captureImage = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(blob => {
            const capturedImageFile = new File([blob], 'captured_image.png', { type: 'image/png', lastModified: Date.now() });
            // Here you can send the captured image file to a server, display it, etc.
            console.log('Captured image file:', capturedImageFile);
            setImage(capturedImageFile);
            setImageView(URL.createObjectURL(capturedImageFile));

        }, 'image/png');
        closeCamera();

    };


    const closeCamera = () => {
        setOpned(false);
        if (mediaStreamRef.current) {
            const tracks = mediaStreamRef.current.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    const handleSubmit = (e) => {
        setMsg("");
        if (img != null) {
            console.log('img', img);
            setDisabled(true);
            let formData = new FormData();
            formData.append('img', img);
            formData.append('uid', auth._id);

            dispatch(uploadSelfi(formData));
        } else {
            setFormError("Please complete selfi verfiication");
        }
    }

    const delSelfi = (e) => {
        setImage(null);
        setImageView(null);
    }


    return (
        <>
            <section id="full_page_dashboard_withoutSidebar">
                <div class="container-fluid">
                    {/* <Sidebar data={element} sendToParent={childRes} disabled={sidebarDisabled} /> */}

                    <div class="upload_photos">
                        <div class="row align-items-center">
                            <div class="col-lg-6 pe-xl-5">
                                <div class="box">
                                    <h2><img src="images/Photos-upload-2.png" alt="" />
                                        <span>Verification</span>
                                        Selfie Verification
                                    </h2>
                                    <h3>Selfie Verification Guidelines</h3>
                                    <h4>Clear and Natural</h4>
                                    <p>Kindly ensure good lighting for a clear and natural selfie.</p>

                                    <h4>No Frills, Just You</h4>
                                    <p>Your genuine self shines best without accessories or filters</p>

                                    <h4>Just Like Your Profile</h4>
                                    <p>Let your selfie be a simple reflection, matching your profile</p>

                                    <h4>Solo, No Distractions</h4>
                                    <p>Capture a solo moment to keep the focus on you.</p>

                                    <p>Your thoughtful cooperation is truly appreciated! 📸🔒</p>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="box-two">

                                    {imgView ?

                                        <div class="imagefile verify_gov" style={{ backgroundImage: `url(${imgView})` }}>  </div> :
                                        <div class="imagefile verify_gov" style={{ backgroundImage: `url(${"images/img-upload-2.png"})` }}>  </div>
                                    }

                                    <div class="text-center mt-3">
                                        <a href="#" class="btn upload_selfi_camera" onClick={openCamera}>Open Camera 📷 </a>

                                    </div>

                                    <div className="video_capcha">
                                        <div className="row">
                                            <div className="col-12 mt-4">
                                                <video ref={videoRef} autoPlay playsInline></video>
                                            </div>
                                            {opened === true &&

                                                <>
                                                    <div className="col-6">
                                                        <a href="#" class="btn upload_selfi_camera" onClick={captureImage}>Capture 📷 </a>
                                                    </div>

                                                    <div className="col-6">
                                                        <a href="#" class="btn upload_selfi_camera" onClick={closeCamera}>Close Camera 📷 </a>
                                                    </div>
                                                </>
                                            }

                                        </div>



                                        {/* {opened === true &&

                                            <>
                                                <a href="#" class="btn upload_selfi_camera" onClick={captureImage}>Capture 📷 </a>
                                                <a href="#" class="btn upload_selfi_camera" onClick={closeCamera}>Close Camera 📷 </a>
                                            </>


                                        } */}

                                    </div>

                                    {err && <p className={"error__feedback"}>{err}</p>}

                                </div>
                            </div>

                            <div class="col-12 mt-5">
                                <Link to="/Dashboard" class="btn">Back to Dashboard</Link>
                                <Link to="/IdVerification" class="btn skip">Skip</Link>
                                <h5 class="heading_btn">

                                    {btnst === false ? <a className={`btn upload ${disabled ? "disabled" : ""}`} onClick={() => !disabled && handleSubmit()}
                                    >Upload</a>:
                                     <a class="btn upload" onClick={() => Next()}>Next</a>
                                        // <Link to="/IdVerification" class="btn upload">Next</Link>
                                    }


                                </h5>
                            </div>

                            {msg !== "" && <p class="SucessColor">{msg}</p>}

                        </div>
                    </div>

                </div>
            </section>
        </>
    )

}