import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllMakerNotifications, ReadAllMatchMakerNotiifcation } from '../makerApi';

export const GetNotiMaker = createAsyncThunk('GetNoti', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetAllMakerNotifications(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const ReadAllMaker = createAsyncThunk('ReadAllmaker noti', async (formData, { rejectWithValue }) => {
    try {
        const response = await ReadAllMatchMakerNotiifcation(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const notiSlice = createSlice({
    name: 'makerNoti',
    initialState: {
        data: {},
        success: null,
        error: null,
        count: 0,


    },
    reducers: {
        clearMakerNoti: state => {
            state.success = false;
            state.error = null;

        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetNotiMaker.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetNotiMaker.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.count = action.payload.data.count;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetNotiMaker.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(ReadAllMaker.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(ReadAllMaker.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(ReadAllMaker.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});
export const { clearMakerNoti } = notiSlice.actions;

export default notiSlice.reducer;

