import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';


export default function Loader() {

    useEffect(() => {

    }, []);

    return (
        <>

            {/* <div class="loader"> */}
            <img src="/images/loader2.gif" alt="" style={{
                position: 'fixed',
                height: '160px',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto'
            }} />
            {/* </div> */}

        </>
    )

}