import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetDetailsTab, UpdateApproval, clearftmUsers } from "../../../../Reducer/MakerDashboardSlices/ftmUsersSlice";
import { API_BASE_URL } from "../../Data/content";
import Swal from 'sweetalert2';


export default function Gallery() {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);
    const [data, setTabData] = useState(null);
    const success = useSelector(state => state.ftmUsers.success);
    const error = useSelector(state => state.ftmUsers.error);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearftmUsers());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearftmUsers());
            getData();

        }
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
        }
    }


    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: params.id, type: "Gallery" };
        let res = await dispatch(GetDetailsTab(dat));
        if (res.payload && res.payload.data.data) {
            setTabData(res.payload.data.data);
        }
    }
    const verifySelfie = (type) => {

        let res = { uid: params.id, type: type, Approved_by: "MatchMaker", Maker_id: auth._id };
        dispatch(UpdateApproval(res));

    }

    return (
        <div class="set">

            <div class="row">
                <h1>
                    Uploaded Selfie
                </h1>
                <div class="col-lg-4 col-sm-6">
                    {data && data.data1 && data.data1.img ? <img src={API_BASE_URL + data.data1.img} alt="" height="200px" /> : <p>Selfie not uploaded</p>}
                </div>
                {data && data.data1 && data.data1.verified === true && <button class="btn">Verified</button>}
                {data && data.data1 && data.data1.verified === false && <button class="btn" onClick={() => verifySelfie('selfie')}>Click to verify</button>}

            </div>
            <div class="row">
                <h3>Uploaded Photos</h3>
                {data && data.data2 && data.data2.photos && data.data2.photos.length > 0 ?
                    <>
                        {data.data2.photos.map((item, i) => (
                            <div class="col-lg-4 col-sm-6">
                                <img src={API_BASE_URL + item} alt="img" width="100%" height="150x" />
                            </div>
                        ))}
                    </>

                    : <p>No uploads found</p>}
                {data && data.data2 && data.data2.verified === true && <button class="btn">Verified</button>}
                {data && data.data2 && data.data2.verified === false && <button class="btn" onClick={() => verifySelfie("photos")}>Click to verify</button>}


            </div>

        </div>
    )
}