import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

export default function Test() {



    const handleSuccess = (credentialResponse) => {

        console.log('response form google ', credentialResponse);
        const credential = credentialResponse.credential;

        const decoded = jwtDecode(credential);

        console.log('Success decoded:', decoded);
        let formData = new FormData();
        formData.append('email', decoded.email);
        formData.append('userType', 'User');
        formData.append('name', decoded.name);
        formData.append('socialId', decoded.sub);
        // dispatch(RegisterUser(formData));
    };

    const handleError = (error) => {
        console.log('Error:', error);
    };



    return (
        <>
            <GoogleOAuthProvider clientId="405738133784-u8kl23jo5qgumpl7fef6gj8ms3l2k4u2.apps.googleusercontent.com">

                <div class="box-two h-100">
                    <div class="sign_up_width">


                        <div class="text-center">
                            <button type="submit">Signup</button>
                        </div>

                        <h5><span>or</span></h5>

                        <ul>
                            {/* <li><a href="#"><img src="images/google-icon.png" alt="" /></a></li> */}
                            <li><a >


                                <GoogleLogin
                                    onSuccess={handleSuccess}
                                    onError={handleError}
                                />
                            </a></li>
                            <li><a href="#"><img src="images/micro-icon.png" alt="" /></a></li>
                            <li><a href="#"><img src="images/facebook-icon.png" alt="" /></a></li>
                        </ul>

                        <h6>Already have an account? <Link to="/Login">Login</Link></h6>

                    </div>
                </div>
            </GoogleOAuthProvider>
        </>


    )

}

