import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetFtmMatches } from "../../../../../Reducer/MakerDashboardSlices/singleFTMSlice";
import { API_BASE_URL } from "../../../Data/content";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function AcceptedRecommendation() {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.singleFtm.Matches);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };




    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id, type: "Accepted", page, pageSize };
        dispatch(GetFtmMatches(data));

    }, [page, pageSize]);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-matches" role="tabpanel"
                aria-labelledby="pills-matches-tab">
                {data && data.length > 0 ?
                    <>

                        {
                            data.map((item, i) => (
                                <div class="box_two">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="sec_one">
                                                <div class="sec_img">
                                                    <img src={API_BASE_URL + item.profileImage} alt="" />
                                                    <h3>{item.name}</h3>
                                                    <p>{item.age} Yrs</p>

                                                </div>
                                                <h4>Profile Match %

                                                    <div style={{ width: 40, height: 20 }} >
                                                        <CircularProgressbar value={item.OverAll} text={`${item.OverAll}%`}
                                                            styles={{
                                                                path: {
                                                                    stroke: '#28A745', // You can replace 
                                                                },
                                                                text: {
                                                                    fill: '#000000', // You can replace 
                                                                },
                                                            }} />
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>

                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="sec_two">
                                                        <h5><span><img src="/images/k7.png" />Spiritual</span>
                                                            <span>
                                                                <div style={{ width: 40, height: 20 }} >
                                                                    <CircularProgressbar value={item.SpritualPercentage} text={`${item.SpritualPercentage}%`}
                                                                        styles={{
                                                                            path: {
                                                                                stroke: '#28A745', // You can replace 
                                                                            },
                                                                            text: {
                                                                                fill: '#000000', // You can replace 
                                                                            },
                                                                        }} />
                                                                </div>
                                                            </span>
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="sec_two">
                                                        <h5><span><img src="/images/k6.png" />Astrology</span>
                                                            <span>
                                                                <div style={{ width: 40, height: 20 }}>
                                                                    <CircularProgressbar value={item.HorscopePercentage} text={`${item.HorscopePercentage}%`}
                                                                        styles={{
                                                                            path: {
                                                                                stroke: '#FF6347', // You can 
                                                                            },
                                                                            text: {
                                                                                fill: '#000000', // You can 
                                                                            },
                                                                        }} />
                                                                </div>
                                                            </span>
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 mt-md-3">
                                                    <div class="sec_two">
                                                        <h5><span><img src="/images/k-10.png" />Socio-Culture</span>
                                                            <span>
                                                                <div style={{ width: 40, height: 20 }}>
                                                                    <CircularProgressbar value={item.SocialPercenatge} text={`${item.SocialPercenatge}%`}
                                                                        styles={{
                                                                            path: {
                                                                                stroke: '#F8BA41', // You can 
                                                                            },
                                                                            text: {
                                                                                fill: '#000000', // You can 
                                                                            },
                                                                        }} />
                                                                </div>
                                                            </span>
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 mt-md-3">
                                                    <div class="sec_two">
                                                        <h5><span><img src="/images/k9.png" />Economical</span>
                                                            <span>
                                                                <div style={{ width: 40, height: 20 }}>
                                                                    <CircularProgressbar value={item.EconomicPercentage} text={`${item.EconomicPercentage}%`}
                                                                        styles={{
                                                                            path: {
                                                                                stroke: '#FF6347', // You can 
                                                                            },
                                                                            text: {
                                                                                fill: '#000000', // You can 
                                                                            },
                                                                        }} />
                                                                </div>
                                                            </span>
                                                        </h5>
                                                    </div>
                                                </div>

                                                {/* 
                                                <div class="col-12 mt-3">
                                                    <div class="text-end">
                                                        <a href="#" class="btn remove_btn">Remove</a>
                                                        <a href="#" class="btn">Recommended</a>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }
                    </>


                    : "No Matches Found."}
                <div className="common_paging">
                    <div className="d-flex">
                        <button onClick={prevPage} disabled={page === 1}>Previous</button>
                        <span>{page}</span>
                        <button onClick={nextPage}>Next</button>
                    </div>
                    <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            </div>

        </>
    )

}