import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import SideBasic from "./SideBasic";
import Matches from "./Matches";
import { clearIds } from "../../../../Reducer/MakerDashboardSlices/matchingFtmSlice";

export default function SingleFTM() {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        // let data = { uid: params.id, login_id: auth._id };
        dispatch(clearIds());
        

    }, []);


    return (
        <>

            <section id="full_page_dashboard" class="match_maker_width">
                <div class="container-fluid">
                    <div className="updated_sidebar">
                        <Sidebar />
                    </div>

                    <section id="single_ftm">
                        <div class="row">
                            <SideBasic />
                            <Matches />
                        </div>
                    </section>

                </div>
            </section>
        </>
    )

}