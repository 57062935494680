import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { DelAc, clacAcRes } from "../../../../../Reducer/UserDashboardSlices/userAccountSlice";
import { clearUser } from "../../../../../Reducer/authSlice";


export default function DeleteAccount({ setOpn2 }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, seEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.userAccount.AcDelSuccess);
    const error = useSelector(state => state.userAccount.AcDelError);
    if (success && success.status === 200) {
    
        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clacAcRes());
        dispatch(clearUser());
        navigate("/Login");
        setTimeout(() => {
            navigate("/Login");
        }, 2000);


    }



    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());

        }
    }

    useEffect(() => {


    }, []);

    const handleCloseModal = () => {
        setOpn2(false); // Update the state in the Header component
    }



    const enterEmail = (e) => {
        const newEmail = e.target.value;
        seEmail(newEmail);
        // Perform email format validation here
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(newEmail));
    }

    const DelAccount = () => {
        if (email === "") {
            alert('Please enter your registered email!');
        } else {
            if (isValidEmail) {
                if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
                    let dat = { uid: auth._id, email: email };
                    dispatch(DelAc(dat));
                }
            } else {
                alert('Invalid email format!');
            }
        }

    }

    return (
        <>
            {/* <!-- Change Password Popup --> */}

            <div class="modal fade show account_setting_popup" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ display: 'block' }}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"><i class="bi bi-trash3"></i> Delete My Account </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                        </div>
                        <div class="modal-body">
                            <div class="box">
                                <p>This action is irreversible and will permanently delete all of your account information,
                                    including your profile, preferences, and saved data. If you proceed, you will lose access to
                                    your account and all associated content.</p>

                                <p>Please confirm your decision to delete your account by typing your email address below. This
                                    is to ensure that you understand the consequences of this action.</p>

                                <div class="form-group">
                                    <label class="form-label">Type your Email ID here</label>
                                    <input type="email" class="form-control" placeholder="@ Type your “Email ID” here" onChange={enterEmail} name="emailll" />
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <a onClick={handleCloseModal} class="btn">Cancel</a>
                                    <a onClick={() => DelAccount()} class="btn color">Delete Account</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}