import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserDetail } from '../api';


export const UserDetail = createAsyncThunk('UserDetail', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUserDetail(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    loading: false,
    error: null,

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(UserDetail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserDetail.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(UserDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default userSlice.reducer;

