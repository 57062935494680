import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserDetail } from "../../../../Reducer/UserDashboardSlices/userSlice";
import { API_BASE_URL } from "../../Data/content";
import { clearUser } from "../../../../Reducer/authSlice";
import Filters from "../Filters";
import { SearchProfiles, clearFilter, clearSearchRecord, saveSearchFilters, saveSearchResults, saveSearched, saveSearchedRecord } from "../../../../Reducer/UserDashboardSlices/filterSlice";

export default function
    Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.user.data);
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [search, setSearch] = useState("");
    // const apidata = useSelector(state => state.filter.data);
    // const success = useSelector(state => state.filter.success);
    // const error = useSelector(state => state.filter.error);

    // if (success && success.status === 200) {
    //     dispatch(clearFilter());
    //     dispatch(saveSearchFilters(search));
    //     dispatch(saveSearchResults(apidata));
    //     setTimeout(() => {
    //         setOpenModal(false);
    //         navigate("/SearchResults");
    //     }, 1000);

    // }


    useEffect(() => {
        getData();
    }, []);


    function getData() {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
    }

    const Logout = () => {
        dispatch(clearUser());
        setTimeout(() => {
            navigate("/Login");
        }, 2000);

    }

    const openFilter = () => {
        setOpenModal(true); // Open the modal
    }

    const addInput = (e) => {
        setSearch(e.target.value);
    }

    const SubmitSearch = async (e) => {
        e.preventDefault();
        dispatch(clearSearchRecord());
        if (search !== "") {
            let data = { uid: auth._id, searchtext: search };
            let res = await dispatch(SearchProfiles(data));
            if (res.payload && res.payload.data.data) {
                dispatch(saveSearched(search));
                dispatch(saveSearchedRecord(res.payload.data.data));
                dispatch(clearFilter());
                setTimeout(() => {
                    setOpenModal(false);
                    navigate("/SearchResults");
                }, 1000);
            }


        }
    }

    return (
        <>
            <div class="header_dashboard">
                <h3>Welcome, {data && data.name}</h3>
                <ul class="right">
                    <li class="item">
                        <form onSubmit={SubmitSearch}>
                            <div class="form-group">
                                <input type="text" class="form-control" value={search} name="searching" placeholder="Search" onChange={addInput} />
                                <button type="submit"><i class="bi bi-search"></i></button>
                            </div>
                        </form>
                    </li>

                    <li class="item" onClick={() => openFilter()}>
                        <a class="btn filters" ><label>Filters</label><img src="/images/Fitlers.png" /> </a>

                    </li>

                    <li class="item">
                        <div class="dropdown">
                            <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <img src={data && API_BASE_URL + data.profileImage} alt="" />
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li onClick={() => Logout()}><a class="dropdown-item" >Logout</a></li>
                            </ul>
                        </div>
                    </li>

                </ul>

            </div>

            {openModal &&
                <Filters openModal={openModal} setOpenModal={setOpenModal} />
            }



        </>
    )

}