import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function FamilyMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {


    }, []);


    return (
        <>

            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Family Background</h2>
                            <div class="row">
                                <div class="col-sm-4 col-6">
                                    <h3>Father’s Employment</h3>
                                    <p>{user1 && user1.families && user1.families.father_occcupation}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mother’s Employment</h3>
                                    <p>{user1 && user1.families && user1.families.mother_occcupation}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Location</h3>
                                    <p>{user1 && user1.families && user1.families.location}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Values</h3>
                                    <p>{user1 && user1.families && user1.families.family_status}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Culture</h3>
                                    <p>{user1 && user1.families && user1.families.familyCulture}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Sibling Details</h4>
                                </div>


                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>No of Brothers</h3>
                                    <p>{user1 && user1.families && user1.families.brother_count}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>Married Brother</h3>
                                    <p>{user1 && user1.families && user1.families.brother_married}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>Sisters</h3>
                                    <p>{user1 && user1.families && user1.families.brother_married}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>Married Sister</h3>
                                    <p>{user1 && user1.families && user1.families.sister_married}</p>
                                </div>

                            </div>


                        </div>

                    </div>

                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Family Background</h2>
                            <div class="row">
                                <div class="col-sm-4 col-6">
                                    <h3>Father’s Employment</h3>
                                    <p>{user2 && user2.families && user2.families.father_occcupation}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mother’s Employment</h3>
                                    <p>{user2 && user2.families && user2.families.mother_occcupation}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Location</h3>
                                    <p>{user2 && user2.families && user2.families.location}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Values</h3>
                                    <p>{user2 && user2.families && user2.families.family_status}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Family Culture</h3>
                                    <p>{user2 && user2.families && user2.families.familyCulture}</p>

                                </div>

                                <div class="col-12">
                                    <h4>Sibling Details</h4>
                                </div>


                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>No of Brothers</h3>
                                    <p>{user2 && user2.families && user2.families.brother_count}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>Married Brother</h3>
                                    <p>{user2 && user2.families && user2.families.brother_married}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>No fo Sisters</h3>
                                    <p>{user2 && user2.families && user2.families.sister_count}</p>
                                </div>

                                <div class="col-sm-4 col-md-3 col-6">
                                    <h3>Married Sister</h3>
                                    <p>{user2 && user2.families && user2.families.sister_married}</p>
                                </div>

                            </div>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}