import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddSpritual, GetSpritualDetails } from '../api';

export const GetSpritualInfo = createAsyncThunk('GetSpritualInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetSpritualDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const SaveSpritualInfo = createAsyncThunk('SaveSpritualInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddSpritual(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const spritualSlice = createSlice({
  name: 'spritual',
  initialState: {
    data: null,
    success: false,
    error: null,
 
  },
  reducers: {

    clearspritualState: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SaveSpritualInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveSpritualInfo.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveSpritualInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetSpritualInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetSpritualInfo.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetSpritualInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })  
  },
});
export const { clearspritualState } = spritualSlice.actions;

export default spritualSlice.reducer;

