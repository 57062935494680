import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab6({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "personality" };
        // dispatch(ViewProfileTabs(data));
    }, []);
    return (
        <>
            <div class="set">
                {data && data.privacysetups && data.privacysetups.personality_privacy === "Everyone" &&
                    <div class="questions_sec">
                        <h2>How do you usually handle stress and pressure? 😓</h2>
                        {data && data.personalityquestions && data.personalityquestions.stresful_situation && data.personalityquestions.stresful_situation.length > 0 ?
                            <ul>
                                {data.personalityquestions.stresful_situation.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>Can you share an example of a challenging situation you faced and how you dealt with it?🌟</h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.challenging_situation ? data.personalityquestions.challenging_situation : "-"}</p>

                        <h2>What are your favorite ways to spend your free time?</h2>
                        {data && data.personalityquestions && data.personalityquestions.free_time && data.personalityquestions.free_time.length > 0 ?
                            <ul>
                                {data.personalityquestions.free_time.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>What kind of books or movies do you enjoy?</h2>
                        {data && data.personalityquestions && data.personalityquestions.movies_books && data.personalityquestions.movies_books.length > 0 ?
                            <ul>
                                {data.personalityquestions.movies_books.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>Your Strength?</h2>
                        {data && data.personalityquestions && data.personalityquestions.strength && data.personalityquestions.strength.length > 0 ?
                            <ul>
                                {data.personalityquestions.strength.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}
                        <h2>Your Weakness?</h2>
                        {data && data.personalityquestions && data.personalityquestions.weakness && data.personalityquestions.weakness.length > 0 ?
                            <ul>
                                {data.personalityquestions.weakness.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>What motivates and inspires you in life?</h2>
                        {data && data.personalityquestions && data.personalityquestions.motivate_insipre && data.personalityquestions.motivate_insipre.length > 0 ?
                            <ul>
                                {data.personalityquestions.motivate_insipre.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        {/* <h2>How would you characterize your personality?</h2> */}
                        <h2>How you focus your attention: on the outer world or your inner world?
                        </h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.focus_your_attention ? data.personalityquestions.focus_your_attention : "-"}</p>

                        <h2>How you take in information: using your physical senses or your instincts and impressions?</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.take_in_information ? data.personalityquestions.take_in_information : "-"}</p>

                        <h2>How you make decisions: solely using logic or by weighing how it will affect the people involved ?</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.decisions ? data.personalityquestions.decisions : "-"}</p>

                        <h2>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?</h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.deal_with_world_around ? data.personalityquestions.deal_with_world_around : "-"}</p>


                        <h2>Are you more comfortable in social gatherings?*</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.social_Gatherings ? data.personalityquestions.social_Gatherings : "-"}</p>

                        <h2>Your lifestyle</h2>
                        {data && data.personalityquestions && data.personalityquestions.lifestyle && data.personalityquestions.lifestyle.length > 0 ?
                            <ul>
                                {data.personalityquestions.lifestyle.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}
                        <h2>Your Diet
                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.diet ? data.personalityquestions.diet : "-"}</p>
                        <h2>
                            What are your long-term goals and aspirations?
                        </h2>
                        {data && data.personalityquestions && data.personalityquestions.goals_and_aspirations && data.personalityquestions.goals_and_aspirations.length > 0 ?
                            <ul>
                                {data.personalityquestions.goals_and_aspirations.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        {/* <p>{data && data.goals_and_aspirations ? data.goals_and_aspirations : "-"}</p> */}
                        <h2>
                            Elaborate on your vision for the future and the steps you plan to take to achieve your long-term goals and aspirations.
                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.elaborate_goals ? data.personalityquestions.elaborate_goals : "-"}</p>
                        <h2>
                            How do you handle criticism or feedback from others?

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.criticism_or_feedback ? data.personalityquestions.criticism_or_feedback : "-"}</p>
                        <h2>
                            Describe a specific instance where you received feedback and explain how you processed and utilized it for your personal growth and development

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.personal_growth_development ? data.personalityquestions.personal_growth_development : "-"}</p>
                        <h2>
                            Do you prefer structured routine?

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.structured_routine ? data.personalityquestions.structured_routine : "-"}</p>
                        <h2>
                            Discuss your approach to balancing structure and
                            flexibility in your daily life, highlighting the benefits and challenges you
                            face with each approach.

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.benefits_and_challenges ? data.personalityquestions.benefits_and_challenges : "-"}</p>



                    </div>
                }
                {data && data.privacysetups && data.privacysetups.personality_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="questions_sec">
                        <h2>How do you usually handle stress and pressure? 😓</h2>
                        {data && data.personalityquestions && data.personalityquestions.stresful_situation && data.personalityquestions.stresful_situation.length > 0 ?
                            <ul>
                                {data.personalityquestions.stresful_situation.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>Can you share an example of a challenging situation you faced and how you dealt with it?🌟</h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.challenging_situation ? data.personalityquestions.challenging_situation : "-"}</p>

                        <h2>What are your favorite ways to spend your free time?</h2>
                        {data && data.personalityquestions && data.personalityquestions.free_time && data.personalityquestions.free_time.length > 0 ?
                            <ul>
                                {data.personalityquestions.free_time.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>What kind of books or movies do you enjoy?</h2>
                        {data && data.personalityquestions && data.personalityquestions.movies_books && data.personalityquestions.movies_books.length > 0 ?
                            <ul>
                                {data.personalityquestions.movies_books.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>Your Strength?</h2>
                        {data && data.personalityquestions && data.personalityquestions.strength && data.personalityquestions.strength.length > 0 ?
                            <ul>
                                {data.personalityquestions.strength.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}
                        <h2>Your Weakness?</h2>
                        {data && data.personalityquestions && data.personalityquestions.weakness && data.personalityquestions.weakness.length > 0 ?
                            <ul>
                                {data.personalityquestions.weakness.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        <h2>What motivates and inspires you in life?</h2>
                        {data && data.personalityquestions && data.personalityquestions.motivate_insipre && data.personalityquestions.motivate_insipre.length > 0 ?
                            <ul>
                                {data.personalityquestions.motivate_insipre.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        {/* <h2>How would you characterize your personality?</h2> */}
                        <h2>How you focus your attention: on the outer world or your inner world?
                        </h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.focus_your_attention ? data.personalityquestions.focus_your_attention : "-"}</p>

                        <h2>How you take in information: using your physical senses or your instincts and impressions?</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.take_in_information ? data.personalityquestions.take_in_information : "-"}</p>

                        <h2>How you make decisions: solely using logic or by weighing how it will affect the people involved ?</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.decisions ? data.personalityquestions.decisions : "-"}</p>

                        <h2>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?</h2>

                        <p>{data && data.personalityquestions && data.personalityquestions.deal_with_world_around ? data.personalityquestions.deal_with_world_around : "-"}</p>


                        <h2>Are you more comfortable in social gatherings?*</h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.social_Gatherings ? data.personalityquestions.social_Gatherings : "-"}</p>

                        <h2>Your lifestyle</h2>
                        {data && data.personalityquestions && data.personalityquestions.lifestyle && data.personalityquestions.lifestyle.length > 0 ?
                            <ul>
                                {data.personalityquestions.lifestyle.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}
                        <h2>Your Diet
                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.diet ? data.personalityquestions.diet : "-"}</p>
                        <h2>
                            What are your long-term goals and aspirations?
                        </h2>
                        {data && data.personalityquestions && data.personalityquestions.goals_and_aspirations && data.personalityquestions.goals_and_aspirations.length > 0 ?
                            <ul>
                                {data.personalityquestions.goals_and_aspirations.map((item, i) => (
                                    <li>{item}</li>
                                ))}

                            </ul>
                            : "-"}

                        {/* <p>{data && data.goals_and_aspirations ? data.goals_and_aspirations : "-"}</p> */}
                        <h2>
                            Elaborate on your vision for the future and the steps you plan to take to achieve your long-term goals and aspirations.
                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.elaborate_goals ? data.personalityquestions.elaborate_goals : "-"}</p>
                        <h2>
                            How do you handle criticism or feedback from others?

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.criticism_or_feedback ? data.personalityquestions.criticism_or_feedback : "-"}</p>
                        <h2>
                            Describe a specific instance where you received feedback and explain how you processed and utilized it for your personal growth and development

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.personal_growth_development ? data.personalityquestions.personal_growth_development : "-"}</p>
                        <h2>
                            Do you prefer structured routine?

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.structured_routine ? data.personalityquestions.structured_routine : "-"}</p>
                        <h2>
                            Discuss your approach to balancing structure and
                            flexibility in your daily life, highlighting the benefits and challenges you
                            face with each approach.

                        </h2>
                        <p>{data && data.personalityquestions && data.personalityquestions.benefits_and_challenges ? data.personalityquestions.benefits_and_challenges : "-"}</p>



                    </div>
                }
            </div>

        </>
    )

}