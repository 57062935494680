import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function BasicMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {

        console.log('user 2', user2);


    }, []);


    return (
        <>

            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">

                <div class="row">
                    <div class="col-lg-6 mt-3">
                        <div class="set">
                            <h2>Basic Info</h2>
                            <div class="row">

                                <div class="col-sm-4 col-6">
                                    <h3>Name</h3>
                                    <p>{user1 && user1.name}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Gender</h3>
                                    <p>{user1 && user1.basic && user1.basic.gender}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Marital Status</h3>
                                    <p>{user1 && user1.basic && user1.basic.Marital_status}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mother Tongue</h3>
                                    <p>{user1 && user1.basic && user1.basic.Mother_tongue}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Height</h3>
                                    <p>{user1 && user1.basic && user1.basic.height}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Weight</h3>
                                    <p>{user1 && user1.basic && user1.basic.weight} Kg</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Health Status</h3>
                                    <p>{user1 && user1.basic && user1.basic.health}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Any Disability</h3>
                                    <p>{user1 && user1.basic && user1.basic.Disability}</p>
                                </div>

                                <div class="col-sm-4 col-12">

                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Country</h3>
                                    <p>{user1 && user1.basic && user1.basic.country}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>State</h3>
                                    <p>{user1 && user1.basic && user1.basic.state}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>City</h3>
                                    <p>{user1 && user1.basic && user1.basic.city}</p>
                                </div>


                                <div class="col-sm-4 col-6">
                                    <h3>Mobile Number</h3>
                                    <p>{user1 && user1.mobile}</p>
                                </div>



                                <div class="col-sm-4 col-6">
                                    <h3>Email Address</h3>
                                    <p>{user1 && user1.email}</p>
                                </div>


                            </div>


                        </div>
                    </div>

                    <div class="col-lg-6 mt-3">
                        <div class="set">
                            <h2>Basic Info</h2>
                            <div class="row">

                                <div class="col-sm-4 col-6">
                                    <h3>Name</h3>
                                    <p>{user2 && user2.name}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Gender</h3>
                                    <p>{user2 && user2.basic && user2.basic.gender}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Marital Status</h3>
                                    <p>{user2 && user2.basic && user2.basic.Marital_status}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mother Tongue</h3>
                                    <p>{user2 && user2.basic && user2.basic.Mother_tongue}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Height</h3>
                                    <p>{user2 && user2.basic && user2.basic.height}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Weight</h3>
                                    <p>{user2 && user2.basic && user2.basic.weight} Kg</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Health Status</h3>
                                    <p>{user2 && user2.basic && user2.basic.health}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Any Disability</h3>
                                    <p>{user2 && user2.basic && user2.basic.Disability}</p>
                                </div>

                                <div class="col-sm-4 col-12">

                                </div>


                                <div class="col-sm-4 col-6">
                                    <h3>Country</h3>
                                    <p>{user2 && user2.basic && user2.basic.country}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>State</h3>
                                    <p>{user2 && user2.basic && user2.basic.state}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>City</h3>
                                    <p>{user2 && user2.basic && user2.basic.city}</p>
                                </div>


                                <div class="col-sm-4 col-6">
                                    <h3>Mobile Number</h3>
                                    <p>{user2 && user2.mobile}</p>
                                </div>
                                <div class="col-sm-4 col-6">
                                    <h3>Email Address</h3>
                                    <p>{user2 && user2.email}</p>
                                </div>


                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}