import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Carrer(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id };

    }, []);


    return (
        <>


            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">

                <div class="box">
                    <h2>Carrer Information</h2>


                    <div class="row">


                        <div class="col-sm-6">
                            <h3>Company Name</h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.company_name
                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Company Type</h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.company_type
                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Annual Income</h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.income
                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Job location</h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.job_location
                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Occupation
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.occupation

                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Time period in current company
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.timePeriod

                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Total Wokring Experience
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.working_period

                            }</p>
                        </div>
                        <div class="col-12">
                            <h3>Latest Qualification
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.Ed_qalification

                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Institute
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.Ed_institution

                            }</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Any other qualification
                            </h3>
                            <p>{props.data && props.data.carrers && props.data.carrers.Ed_other_qualification

                            }</p>
                        </div>

                    </div>

                </div>
            </div >





        </>
    )

}