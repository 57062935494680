import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab7({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "preference" };
        // dispatch(ViewProfileTabs(data));
    }, []);

    return (
        <>
            <div class="set">
                <div class="row">

                    <div class="col-12">
                        <h5>Basic Preference</h5>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Marital Status</h4>
                        {data && data.preferences && data.preferences.prefered_marital_status && data.preferences.prefered_marital_status.length > 0 ?
                            <ul>
                                {data.preferences.prefered_marital_status.map((item, i) => (
                                    <li>{item.value}</li>
                                ))}
                            </ul>

                            : "-"}
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Preferred Age</h4>
                        <p>{data && data.preferences && data.preferences.prefered_age_start ? data.preferences.prefered_age_start + "-" + data.preferences.prefered_age_end + " Yrs." : "-"} </p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Preferred Height</h4>
                        <p>{data && data.preferences && data.preferences.prefered_height_start ? data.preferences.prefered_height_start + "-" + data.preferences.prefered_height_end : "-"} </p>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Preferred Food</h4>
                        {data && data.preferences && data.preferences.prefered_food && data.preferences.prefered_food.length > 0 ?
                            <ul>
                                {data.preferences.prefered_food.map((item, i) => (
                                    <li>{item.value}</li>
                                ))}
                            </ul>

                            : "-"}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Preferred Language</h4>
                        {data && data.preferences && data.preferences.prefered_language && data.preferences.prefered_language.length > 0 ?
                            <ul>
                                {data.preferences.prefered_language.map((item, i) => (
                                    <li>{item.value}</li>
                                ))}
                            </ul>

                            : "-"}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Preferred Affiliation</h4>
                        {data && data.preferences && data.preferences.prefered_affiliation && data.preferences.prefered_affiliation.length > 0 ?
                            <ul>
                                {data.preferences.prefered_affiliation.map((item, i) => (
                                    <li>{item.value}</li>
                                ))}
                            </ul>

                            : "-"}
                    </div>

                    <div class="col-12">
                        <h5>Community Preferences</h5>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Religion</h4>
                        <p>{data && data.preferences && data.preferences.prefered_religion ? data.preferences.prefered_religion : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Caste</h4>
                        <p>{data && data.preferences && data.preferences.prefered_caste ? data.preferences.prefered_caste : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Community</h4>
                        <p>{data && data.preferences && data.preferences.prefered_community ? data.preferences.prefered_community : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Cultural Values</h4>
                        <p>{data && data.preferences && data.preferences.prefered_cultr_val ? data.preferences.prefered_cultr_val : "-"}</p>
                    </div>

                    <div class="col-12">
                        <h5>Location Preferences</h5>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>Country</h4>
                        <p>{data && data.preferences && data.preferences.prefered_country ? data.preferences.prefered_country : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>State</h4>
                        <p>{data && data.preferences && data.preferences.prefered_state ? data.preferences.prefered_state : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>City / Location</h4>
                        <p>{data && data.preferences && data.preferences.prefered_city ? data.preferences.prefered_city : "-"}</p>
                    </div>

                    <div class="col-12">
                        <h5>Career Preferences</h5>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>Qualification</h4>
                        <p>{data && data.preferences && data.prefered_qualification ? data.preferences.prefered_qualification : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>Professional Background</h4>
                        <p>{data && data.preferences && data.preferences.prefered_Proffessional_bkgrd ? data.preferences.prefered_Proffessional_bkgrd : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <h4>Annual Income</h4>
                        <p>{data && data.preferences && data.preferences.prefered_income ? data.preferences.prefered_income : "-"}</p>
                    </div>
                </div>
            </div>

        </>
    )

}