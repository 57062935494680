import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ViewUserProfileSide, ViewUserTabsData, AcceptProfileInterest, MatchRemove, RecomendationApproval, SendProfileInterest} from '../api';

export const GetViewProfileSide = createAsyncThunk('GetViewProfileSide', async (formData, { rejectWithValue }) => {
  try {
    const response = await ViewUserProfileSide(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const ViewProfileTabs = createAsyncThunk('ViewProfileTabs', async (formData, { rejectWithValue }) => {
  try {
    const response = await ViewUserTabsData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const RemoveConnection = createAsyncThunk('RemoveFromMatches', async (formData, { rejectWithValue }) => {
  try {
    const response = await MatchRemove(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const AcceptRejectInterest = createAsyncThunk('AcceptRejectInterest', async (formData, { rejectWithValue }) => {
  try {
    const response = await AcceptProfileInterest(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const AcceptRejcteRcd = createAsyncThunk('AcceptRejcteRcd', async (formData, { rejectWithValue }) => {
  try {
    const response = await RecomendationApproval(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const SendProfileInterrest = createAsyncThunk('SendProfileInterrest', async (formData, { rejectWithValue }) => {
  try {
    const response = await SendProfileInterest(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});




const viewProfileSlice = createSlice({
  name: 'viewProfile',
  initialState: {
    data: {},
    tabData: {},
    success: null,
    error: null,
    successPro: null,
    errorPro: null

  },
  reducers: {
    clearViewPro: state => {
      state.success = false;
      state.error = null;
      state.successPro = false;
      state.errorPro = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(GetViewProfileSide.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetViewProfileSide.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetViewProfileSide.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(ViewProfileTabs.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(ViewProfileTabs.fulfilled, (state, action) => {
        state.tabData = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(ViewProfileTabs.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(RemoveConnection.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(RemoveConnection.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(RemoveConnection.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(AcceptRejectInterest.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(AcceptRejectInterest.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AcceptRejectInterest.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(AcceptRejcteRcd.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(AcceptRejcteRcd.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AcceptRejcteRcd.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(SendProfileInterrest.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SendProfileInterrest.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SendProfileInterrest.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearViewPro } = viewProfileSlice.actions;

export default viewProfileSlice.reducer;

