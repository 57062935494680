import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";


export default function TermsAndConditions() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.api.data)

    useEffect(() => {
        // dispatch(AboutPage());
    }, []);

    return (
        <>
            <Header />

            <section id="terms_cond">
                <div class="container">
                    <h1>Agreement Terms and Conditions</h1>
                    <h2>Introduction</h2>
                    <p>Welcome to vedicMarriage.ai !</p>
                    <p>
                        We are delighted to have you as a valued member of our platform, where we strive to connect individuals
                        for meaningful and lasting relationships. As part of our commitment to your privacy and security, we
                        adhere to the latest laws of the nation.
                    </p>

                    <h3>"Applicable Laws"</h3>
                    <p>These terms and conditions are published in accordance with Chapter II of the Digital Personal Data
                        Protection Act, 2023, hence forward will be referred to as “DPDPA” or "Applicable Laws". </p>

                    <h3>“Site”</h3>
                    <p> VedicMarriage.ai is an “Data Fiduciary” as defined under CHAPTER I, section 2(i) of “DPDPA”.
                        In order to use the VedicMarriage.ai website and its subsidiary platforms, one must register as a member
                        on the Website/ community sites/ mobile applications, hence forward referred as “Site”.
                    </p>

                    <h3>"Member"</h3>
                    <p>For purposes of this Agreement, the term "Member" means any person who voluntarily submits information to
                        the Site whether such person uses the Service as a free member, or a paid member and “Member” shall mean
                        any person who actively uses the Site to promote his/her profile for matchmaking purpose. “Member” also
                        includes persons such as father, mother, brother, sister, relative or a friend of the prospective bride/
                        bridegroom, who uses the service of this “Site” on behalf of prospective bride/ bridegroom. In case of
                        any other person other than prospective bride/bridegroom,their prior consent is must. "Member " is an
                        “Data Principal” as defined under CHAPTER I, section 2(j) of “DPDPA”. </p>

                    <h3>“Personal Data”</h3>
                    <p>“Personal Data” which means any data about an individual who is identifiable by or in relation to such
                        data, and “digital personal data” means personal data in digital form as per section 2(t) and 2(n) of
                        “DPDPA”. For the purpose of this agreement “digital personal data” shall be interchangeably used as
                        “Personal Data”</p>

                    <h3>“Scope”</h3>
                    <p>The “Site” acts as an intermediate platform to facilitate its “Member” , to register and then advertise
                        and promote their profiles for the purpose of seeking prospective lawful matrimonial alliances for
                        themselves. Please note that this platform is intended for personal use only and cannot be used in
                        connection with any commercial endeavours.
                        Access to the website is free, offering a basic set of features to all users. However, to enhance your
                        experience and provide additional premium services, we offer features such as search profile, chat
                        functionality, sending matching interests, and personalized matchmaking services though our subscription
                        plan, ensuring a more tailored and efficient matchmaking experience
                    </p>

                    <h3>“Services” and "Agreement"</h3>
                    <p>Please read the following terms and conditions very carefully and follow the instructions in the
                        registration process. By using the “Site” which include “Services”, the “Member” agrees to be bound by
                        these Terms and conditions of “Site” hereinafter referred as “Agreement”.</p>

                    <p>“Service” will include “Matchmaking Service”, “Astrology Service, “ Wedding Venue Planner Service”,
                        “Marriage Counselling and Courses service”.</p>

                    <p>“Service” will include “Matchmaking Service”, “Astrology Service, “ Wedding Venue Planner Service”,
                        “Marriage Counselling and Courses service”.</p>

                    <p>If “Member” wants to withdraw this consent or if “Member” has any objection in continuous collection or
                        storage of their “Personal Data”, “Member” must discontinue using “Service” of “Site” as “Site” can’t
                        provide “Member” any Service without having their “Personal Data”. “Member” can withdraw their consent
                        by deleting their account. For any further query reach out to us at <a
                            href="mailto:support@vedicmarriage.ai.">support@vedicmarriage.ai.</a> </p>

                    <h3>1. Acceptance Of The Terms</h3>
                    <p>By using the services provided by VedicMarriage.ai, “Member” voluntarily consent to submit Members’s
                        “Personal Data” ,which may include sensitive personal data such as government Identity proofs, financial
                        data etc. to the “Site”. The same data will be collected, processed, displayed and use of said
                        information will be used by other members to contact any other member. </p>

                    <h3>2. Eligibility Requirement.</h3>
                    <p>“Member” must be legally competent and capable of forming a legally binding agreement to use our
                        matrimonial services. By accessing the site, “Member” confirm that they meet these following eligibility
                        requirements.</p>
                    <ul>
                        <li>To register as a “Member” on this “Site” , one must be legally competent and of marriageable age, as
                            per the law of India its Currently 18 years or over for females and 21 years or over for males.
                        </li>
                        <li>We only facilitate our “Members” matchmaking “Service” for marriage alliance between “Members” who
                            are legally competent to enter into marriage under different laws to which they are subjected, and
                            using this “Site”, “Member” warrants that they have the right, consent, authority, and legal
                            capacity to enter into this “Agreement” and they are not prohibited or prevented by any applicable
                            law for the time being in force or any order or decree or injunction from any Court, Tribunal or any
                            such competent authority restraining them from entering into matrimony. “Members” eligibility to use
                            our “Service” is void when they are legally prohibited.</li>
                        <li>In case “Member” is a registrant of the prospective bride/ bridegroom and has created profile in our
                            “Site” on their behalf or are accessing the “Site” on behalf of them,then in such cases, it implies
                            that “Member” have taken their consent for their profile creation in “Site” or for accessing the
                            “Site” . </li>
                        <li>By becoming a “Member”, they confirm that they are intending to seek a life partner and that they
                            shall not use this “Site” for casual dating or for any commercial use. “Member” agrees to abide by
                            all of the terms and conditions of this “Agreement” and use the “service” in conformity with all
                            "Applicable Laws" in this regard.</li>
                        <li>“Member” agrees to provide their consent to the “Site”, to collect, process and consume your
                            “Personal Data” ,to continue being a member. Without “Members” “Personal Data”, “Site” can’t provide
                            any service. </li>
                        <li>If a “Member” is a resident of any other Country other than India,They confirm that they are legally
                            competent to marry as per the local laws applicable to their Country and they shall comply with the
                            Indian laws as well for marrying a person of Indian Origin.</li>
                    </ul>
                    <p><b>Note:</b> If at any point of time the “Site“ is of the opinion (in its sole discretion) or has any
                        reason to believe that “Member” is not eligible or that they have
                        made any misrepresentation, or violated any provisions of the Terms and Condition, in violation of an
                        applicable laws, the “Site” reserves the right to terminate
                        their membership forthwith and / or their right to use the “Service” without any refund
                        of their unutilized subscription fee or any fee which is with the “Site”.
                    </p>

                    <h3>3. User Registration And Account Security</h3>
                    <p>To access desired features of the “Site”, with the genuine purpose of marriage and not otherwise , one
                        must register for an account. </p>
                    <ul>
                        <li>Always provide true, accurate, current and complete information during the registration process with
                            the recent photo and update it to keep it current.</li>
                        <li>At any point of time, if the “Site” comes to know or has reasons to believe that any information
                            provided by the “Member” during registration process is untrue, inaccurate, or incomplete, the
                            “Site” shall have full right to suspend or terminate your membership without any notice and forfeit
                            any amount paid by you towards the membership fee and refuse to provide “Service” to you thereafter.
                        </li>
                        <li>Registration of duplicate profiles of the same “Member” is not allowed
                            The “Site” shall have full right to suspend or terminate such duplicate profile, without any notice
                        </li>
                        <li>It’s the duty of the “Member”, to ensure not to impersonate another person while providing your
                            personal data for the registration process. If found so, will lead to termination of service and may
                            lead to legal action.</li>
                        <li>Membership is strictly restricted to individuals only. Organizations, companies, businesses and/or
                            individuals carrying on similar or competitive business cannot become Members of the “Site” and nor
                            use the “Service” or “Member” data for any commercial purpose, and the “Site” reserves its right to
                            initiate appropriate legal action for breach of these obligations.</li>
                    </ul>

                    <p>“Member” is responsible for maintaining the confidentiality of your account information, including their
                        login user id and password and for restricting access to their computer/mobile to prevent unauthorized
                        access to their account. The “Site” or its employees will not ask for password and “Members” are
                        cautioned not to share your password to any persons. “Member” is solely responsible for all activities
                        that occur under their account.</p>

                    <h3>4. Privacy And Personal Data Protection Law</h3>
                    <p>VedicMarriage.ai ,the “Site” acts as a “data fiduciary” as defined by the Digital Data Protection Act of
                        India 2023 , Chapter I, section 2(i). We acknowledge our obligations as a Data Fiduciary under the Act
                        as per Chapter II of the Act.
                        “Member” as the User of the “Site” as a Data Principal, have certain rights and responsibility under
                        Chapter III of the Act.
                    </p>
                    <p><b>A.</b> Obligations of The “Site” As “DATA FIDUCIARY”:</p>
                    <p>We are committed to fulfilling the obligations outlined in the Act. </p>
                    <p><b>i.</b> Grounds for Processing personal data:</p>
                    <p>In order to provide matchmaking “Service” we need to collect, process and reproduce “Members” “Personal
                        Data” in the form of member profile once they register on our “Site” and also share their profile with
                        other registered ”Member”</p>

                    <p><b>B.</b> Notice</p>
                    <p>We shall “Notify” “Member” for their consent, every time when we collect their “Personal Data”.</p>
                    <p>In this light, below are three important notices.</p>
                    <p><b>1. </b>Personal data and purpose of its processing:
                        We are hereby notifying “Member” that we are collecting the below personal data that they provide for a
                        tailored matchmaking “Service”.
                    </p>

                    <ul>
                        <li>Basic info</li>
                        <li>Family details</li>
                        <li>Astro detail</li>
                        <li>Education and career</li>
                        <li>Personality info</li>
                        <li>Spiritual practice info</li>
                        <li>“Members” marriage preference</li>
                    </ul>

                    <p><b>a. </b>“Member” may exercise their rights in following manner</p>
                    <p><b>b. </b>“Member” shall have the right to withdraw their consent at any time, with the ease of doing so
                        being comparable to the ease with which such consent was taken.</p>
                    <p><b>c. </b>“Member” shall have the right to have readily available means of grievance redressal provided
                        by the “Site”. You may make a complaint to the Board in the following manner. “Member” may make a
                        complaint to the Board, “Board” means the Data Protection Board of India, established by the Central
                        Government , for any violation in use of your “Personal Data” by our “Site”. “Member” will have an
                        option to access the contents of the notice in any of the languages as described in the schedule 8th of
                        constitution. </p>

                    <p><b>d. </b>Consent</p>
                    <p>“Site” shall seek a free, specific, informed, unconditional and unambiguous consent from the “Member”
                        with a clear affirmative action, and shall signify an agreement to the processing of “Member’s” personal
                        data for the specified purpose i.e matchmaking and the said Consent shall be limited to such personal
                        data as is necessary for such specified purpose.</p>

                    <p><b>e. </b>Defining the legitimate use.</p>
                    <p>“Site” may process personal data of the “Member” for the specified purpose of match making or related
                        services, for which the “Member” has voluntarily provided their personal data.</p>

                    <p><b>f. </b>General obligations of the “Site” as Data Fiduciary.</p>
                    <p>“Site” ensures processing of personal data in a lawful and fair manner Ensuring purpose limitation, data
                        minimization, and data accuracy while Implementing security safeguards to protect personal data.</p>

                    <ul>
                        <li>The “Site” shall be responsible for complying with the provisions of the DPDP Act.</li>
                        <li>While processing the personal data, “Site” shall ensure its completeness, accuracy and consistency.
                        </li>
                        <li>“Site” shall protect personal data in its possession or under its control including in respect of
                            any processing undertaken by it or on its behalf by any third party, by taking reasonable security
                            safeguards to prevent personal data breach.</li>
                        <li>﻿In the event of a personal data breach, the “Site” shall give the Board and each affected “Member”,
                            the intimation of such breach.</li>
                        <li>upon the “Member” withdrawing their consent or as soon as it is reasonable to assume that the
                            specified purpose is no longer being served if intimated to the “Site”, whichever is earlier, the
                            “Site” shall erase personal data of the “Member”.</li>
                        <li>“Member” can contact our “Data Protection Officer” to dpo@vedicmarriage.ai to answer any query about
                            the processing of your “Personal Data”.</li>
                        <li>For any grievances, please write a mail to “Sites” grievance redressal officer at <a
                            href="mailto:gro@vedicmarriage.ai">gro@vedicmarriage.ai . </a></li>
                    </ul>

                    <p><b>g. </b>Rights and Duties Of The “Members” As “Data Principal”</p>
                    <ul>
                        <li><b>Right to access information about personal data:</b> “Member” has the right to obtain a summary
                            of personal data which is being processed by the “Site” and the processing activities undertaken by
                            it. </li>
                        <li><b>Right to correction and erasure of personal data: </b>“Member” shall have the right to
                            correction, completion, updating and erasure of their personal data for the processing of which they
                            have previously given consent.</li>
                        <li><b>Right of grievance redressal: </b>The “Member” shall have the right to have readily available
                            means of grievance redressal provided by the “Site”.</li>
                        <li><b>Right to nominate: </b>“Member” shall have the right to nominate, in such manner as may be
                            prescribed, any other individual, who shall, in the event of death or incapacity the “Member”,
                            exercise the rights of the “Member”.</li>

                    </ul>

                    <h3>5. Role and Responsibility of the “Site” As “Service” Provider.</h3>
                    <ul>
                        <li>“Site’s” obligation is only to provide an interface to its registered “Members” to search their
                            prospect themselves, and in case of matchmaking service, it acts as an assistant who may recommend
                            matches to the “Members” based on the “Members” preferences.</li>
                        <li>The “Site” does not prohibit any “Members” from sending interest to other “Members” profiles or
                            communicating to others based on their preference. But “Members” are at liberty to deny their
                            interest or proceed further if the “Member” is interested.</li>
                        <li>The “Site” cannot guarantee or assume responsibility for any specific results from the use of the
                            data available from the “Site”</li>
                        <li>The “Site” shall safeguard “Personal Data” using security standards, authentication mechanisms,
                            access controls and encryption techniques. </li>
                        <li>“Member” will take full control of their privacy setting, where they can choose whom to show their
                            basic info, Family details, Astro detail, Education and career info, Personality info, Spiritual
                            practice info, their marriage preference etc..</li>
                        <li>“Site” will not authenticate/endorse any information of any “Members” profile and hence “Member” as
                            a user need to verify the credentials and information provided by other “Member”</li>
                    </ul>

                    <h3>6. Additional Terms Of Use /Role and Responsibility of “Member”.</h3>
                    <ul>
                        <li>“Member”, shall safeguard your profile information by creating a strong password And “Member” agree
                            to use secure devices, software and networks in a private place for accessing.</li>
                        <li>“Member” is requested to scrutinizingly verify the credentials of the prospect, regarding their
                            profile information which includes marital status, educational and career info, health, personality
                            , character etc, and consciously make a choice of their match. “Site” shall not be liable for short
                            coming due to any misrepresentations made by any of its “Member” and “Site” shall not be liable for
                            any misbehavior/ misrepresentations made by the Prospect to “Member” during site chat or your
                            personal call.</li>
                        <li>For better results, “Member” is expected to provide the latest photograph which should not be more
                            than 3 (three) months old. Providing old photographs/ photographs of others, inaccurate / false
                            information shall be treated as violation of terms and conditions </li>
                        <li>Be cautious of prospects who ask for favors, money etc or call from multiple phone numbers, only
                            interact over phone, don't come forward for face to face meetings (physically or through video
                            calls) and don’t involve family and friends in match making. Beware of suspended profiles status
                            before you finalize an alliance with the prospect</li>
                        <li>In case of finalization of “Members” marriage, they should delete their profile by themselves
                            .”Member” is prohibited from using any kind of Bots like web robots, Chatbot , CHATGPT, or any other
                            automated processes/programs/scripts to use, communicate or respond.</li>
                        <li>“Members” shall not probe, scan or test the vulnerabilities of the VEDICMARRIAGE.AI Website/App or
                            any network connected to the Website/App nor breach the security measures instituted.</li>
                        <li>“Members” are restrained from making any payment / transfer any amount to any “Site” employees. All
                            payments shall be made only to the “Site” account.</li>
                        <li>“Members” are expected to approach “Site” to resolve their complaints and grievances and “Members”
                            shall not air their grievance/complaints in social media.</li>
                        <li>The “Site” reserves the right to review the activity and status of each account and block, restrict,
                            suspend, terminates access based on the review, if the “Site” see it fit, with due intimation to
                            “Member”</li>
                        <li>“Member" hereby confirm to give their consent to receive emails, SMS/WhatsApp messages and receive
                            calls from “Site”,including SMS permission for authenticating mobile verification and transactions
                            via OTP, sent by the Payment Gateway. This consent shall supersede any preferences set by you with
                            or registration done with the Do Not Disturb (DND Register)/ National Customer Preference Register
                            (NCPR). This consent extends to emails, messages or calls relating but not limited to phone number
                            verification, the provision of matchmaking advertising service, matchmaking enquiries and any
                            promotions.</li>
                        <li>“Member” hereby agreeing to give consent to receive promotional message, email, call regarding
                            similar products offered by”Site”, which may include “Personalized matchmaker service”, “Wedding
                            venue planning” and “pre and post marital counselling and courses”.</li>
                        <li>Multiple profiles of the same “Member” are not allowed and the “Site” reserved the right to remove
                            all such multiple profiles when found, and this will lead to forfeiture of subscription amount in
                            view of violation of the terms and condition. </li>
                        <li>“Member” hereby confirms that the Content, information including the personal information provided
                            by them is correct and accurate. If a profile is created on behalf of their friend/sibling/children,
                            they hereby agree that they have already taken the permission/consent from the particular person
                            whose profile is created on “Site” by them. It’s made clear that impersonation is a crime and
                            punishable under various laws and “Site” is not responsible for such an act by the “Member”. </li>
                        <li>“Site” strongly advises to submit a copy of “Member” Aadhar, Driving License, Passport or any other
                            Government issued identity and add a trust badge to their profile.</li>
                        <li>Except for the purpose of promoting/advertising their profile for matchmaking purposes, “Member”
                            cannot engage in advertising to, or solicitation of, other “Member” to buy or sell any products or
                            services through “Site” Service.</li>
                        <li>“Member” hereby undertake not to transmit any chain letters or junk email to other “Member”.
                            Although “Site” cannot monitor the conduct of it’s “Member” outside the “Site”, any information
                            obtained from the “Site” if used to harass, abuse, or harm another “Member”, or used to contact,
                            advertise, solicit, or sell the “Personal Data'' without prior consent of such “Member”, then the
                            “Site” reserves its right to take appropriate steps to protect “Site” and/or its “Member” from any
                            abuse/misuse if it deems appropriate in its sole discretion. </li>
                        <li>The “Site” reserves the right to screen communications/advertisements that “Member” may send to
                            other “Member” and also regulate the same by deleting unwarranted/obscene
                            communications/advertisements at any time at its sole discretion without prior notice to any
                            “Member”.</li>
                        <li>“Member” who has subscribed to the “Site”, matchmaker service/marriage counselling service/ wedding
                            venue planning service etc. hereby, unconditionally and irrevocably confirm that they have read
                            terms and conditions and have agreed to abide by it.</li>
                    </ul>

                    <h3>7. Terms on The Content Posted on The Site, Including “Personal Data”</h3>
                    <p>“Site” hereby confirms that it is fully complying with the "Applicable Laws" including “DPDPA 2023” and
                        other relevant law on the matter of the content submitted by the “Member” in the form of “Personal
                        Data”, and its “processing’. </p>

                    <p><b>a. </b>The “Site” may delete any listing, content, communication, photos or profiles (collectively,
                        "Content") that in the sole judgment of “Site” if it violate this Agreement or which might be offensive,
                        illegal, or that might violate the rights, harm, or threaten the safety of either “Site” and/or its
                        “Member”</p>
                    <p><b>b. </b>“Member” hereby unconditionally and irrevocably grant permission to the
                        “Site” to post, to use, store, distribute, reproduce,prepare derivative works of, modify, adapt,
                        publicly perform and publicly display such Content on the “Site” and to the “Member” of the “Site”.</p>
                    <p><b>c. </b>“Member” hereby expressly authorize “Site” to use , publish and display their photographs and
                        testimony on the “Site”</p>
                    <p><b>d. </b>“Member” hereby agree that, they (and not “Site”) are solely
                        responsible for all Content that they upload, post, email, transmit or otherwise make available in the
                        “Site”. The Content including all information, data, text, photographs, graphics, communications, tags
                        etc. or any other Content whether publicly posted or privately transmitted or otherwise, which are not
                        made available to “Site” are the sole responsibility of “Member”. The “Site” does not guarantee the
                        accuracy, integrity or quality of such Content of its “Member”. “Site” advise all “Member” to
                        independently verify the content /information posted by the other “Member” before acting upon the same
                        and under no circumstances “Site” be liable in any way for any Content, including, but not limited to,
                        any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the
                        use of any Content posted, emailed, transmitted or otherwise made available by the “Site”.
                    </p>
                    <p><b>e. </b>The “Site” reserves the right to check the authenticity of Content posted on the “Site” in
                        exceptional cases or if it thinks so. In exercising this right, “Site” may ask “Member” to provide any
                        documentary or other form of evidence supporting the Content they have posted on the “Site”. If the
                        “Member” fails to produce such evidence to the satisfaction of “Site”, then “Site” may, in its sole
                        discretion, terminate such Membership without any refund.</p>
                    <p><b>f. </b>Below is the list of the Content that is illegal or prohibited on the “Site” , we will
                        investigate and take appropriate legal action against anyone who violates this Agreement, including
                        without limitation, removing the offending communication/Content from the “Site” and terminating such
                        Membership of such violators without any refund.</p>
                    <p>Some of the list of illegal or Prohibited Content and Activities</p>

                    <ul>
                        <li>Offensive Content: Content that is blatantly offensive, promotes racism, bigotry, hatred, or
                            advocates physical harm against any group or individual.</li>
                        <li>Harassment: Any form of harassment or advocacy for harassment directed towards another “Member”.
                        </li>
                        <li>Unsolicited Communication: Involvement in the transmission of "junk mail," "chain letters," or
                            unsolicited mass mailing, commonly known as "spamming."</li>
                        <li>False Information: Promotion of information known to be false, misleading, or encouragement of
                            illegal activities, including abusive conduct, threats, obscenity, defamation, or libel.</li>
                        <li>Copyright Infringement: Promotion of illegal or unauthorized copies of copyrighted works, including
                            pirated computer programs or links to them, and information on circumventing copy-protect devices.
                        </li>
                        <li>Restricted Access: Content containing restricted or password-only access pages, hidden pages or
                            images not linked to or from another accessible page.</li>
                        <li>Adult Content: Display of pornographic or sexually explicit material of any kind.</li>
                        <li>Exploitation: Material that exploits individuals under the age of 18 in a sexual or violent manner,
                            or solicits personal information from anyone under 18.</li>
                        <li>Illegal Activities: Instructional information on illegal activities, such as making or buying
                            illegal weapons, violating privacy, or creating computer viruses.</li>
                        <li>Unauthorized Solicitation: Solicitation of passwords or personal identifying information for
                            commercial or unlawful purposes from other “Member”.</li>
                        <li>Commercial Activities: Engagement in commercial activities and/or sales without prior written
                            consent, including contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
                        <li>National Security: Content that threatens the unity, integrity, defense, security, or sovereignty of
                            India, friendly relations with foreign states, public order, or causes incitement to the commission
                            of any cognizable offense or prevents investigation of any offense, or is insulting to any other
                            nation.</li>
                        <li>Impersonation: Impersonation of another person.</li>
                        <li>Deceptive Information: Sending messages that deceive or mislead the addressee about the origin of
                            such messages, or communicate any information that is grossly offensive or menacing in nature.</li>
                        <li>Financial transaction: Entering into any financial transactions with any other “Member”.</li>
                        <li>Abusive language: when a “Member” communicates with another “Member” using discriminative or using
                            racial comments of any nature is restricted. </li>
                        <li>Physical relationship: Entering into physical relationship with any other “Member” of “Site” is
                            illegal.</li>
                        <li>Violating "Applicable Laws":Violating "Applicable Laws" for the time being in force , including
                            sharing the personal data of the “Member” with others, without consent of such “Member” is illegal.
                        </li>
                    </ul>

                    <p>If “Member” found violating any of these guidelines may face consequences, including account termination
                        and also legal action.</p>

                    <h3>8. Terms And Termination Of Services</h3>

                    <ul>
                        <li>This Agreement will be effective, valid and subsisting as long as “Member” uses “Site”.</li>
                        <li>“Site” reserves the right to suspend or terminate “Member” accounts if they violate any terms or
                            engage in activities that compromise the security or integrity of the “Site”.</li>
                        <li>Upon termination due to violation, “Site” will retain “Member” personal data only as long as
                            necessary for the purposes for which it was collected or as required by law.</li>
                        <li>In the event of termination of membership, “Member” will not be entitled to a refund of any
                            unutilized subscription fees, if any, paid by them.</li>
                        <li>“Site” may terminate “Member” access to the “Site” and/or their membership for any reason including
                            but not limited to the breach of the terms of use, using the service for commercial purposes,
                            engaging in prohibited or inappropriate communication or activity, may by sending notice to “Member”
                            at the email address as provided by them in their application.</li>
                        <li>Clauses of this “Agreement” shall remain in effect even on termination for the purpose of law. </li>
                        <li>Termination of the Paid Membership would be in accordance with the type of membership undertaken.
                        </li>
                        <li> In the event of business discontinuity, the “Site” will not refund the unused membership fees if
                            any paid by “Member”.</li>
                    </ul>

                    <h3>9. Non-Commercial Use By “Member”</h3>
                    <ul>
                        <li>The services provided by the “Site” are intended for personal, non-commercial use by our “Member” ,
                            to advertise and promote their profiles for the purpose of getting relevant matches and cannot be
                            used in connection with any commercial endeavors. </li>
                        <li>“Member” is prohibited from using the “Site” or its content for any commercial purposes, including
                            but not limited to advertising, solicitation, or the promotion of any products or services. ,
                            selling products , providing links to/from other web sites.</li>
                        <li>Organizations, companies, and/or businesses entities cannot become “Member” of “Site”. Any illegal
                            and/or unauthorized uses of the “Site”, including unauthorized framing of or linking to the “Site”
                            will be investigated, and appropriate legal action will be taken, including without limitation,
                            civil, criminal, and injunctive redress.</li>
                    </ul>

                    <h3>10. Proprietary Rights In The Content</h3>
                    <ul>
                        <li>All content provided on “Site”, including but not limited to profiles, text, graphics, images,
                            photographs, and other files, is the proprietary property of “Site”.</li>
                        <li>The “Site” owns and retains all proprietary rights in the “Site” and all other“Services” listed
                            under the “Site”. </li>
                        <li>“Member” shall retain ownership of the content which they have submitted or uploaded to the “Site”,
                            but by doing so, they grant “Site” a exclusive, worldwide, royalty-free, sublicensable, and
                            transferable license to use, reproduce, modify, distribute, display such content.</li>
                        <li>“Member” can’t copy, modify, reproduce , publish, transmit, distribute, perform, display, or sell
                            any such proprietary or any content on the “Site” without the express written permission of “Site”.
                        </li>
                    </ul>

                    <h3>11. Copyright Policy</h3>

                    <ul>
                        <li>All content on “Site”, including text, graphics, logos, images, and software, is the property of
                            “Site” and is protected by Indian and international copyright laws.</li>
                        <li>“Member” is prohibited from reproducing, distributing, modifying, or otherwise using any content
                            from the site without the explicit written permission of “Site”.</li>
                        <li>If someone believes that their copyright has been infringed upon by “Site”, please contact us at <a
                            href="mailto:support@vedicmarrigae.ai">support@vedicmarrigae.ai</a> with a detailed description
                            of the alleged infringement. </li>
                    </ul>

                    <h3>12. Disclaimers</h3>
                    <ul>
                        <li>The “Site” is a platform for matchmaking and connecting individuals. We do not guarantee the
                            accuracy, completeness, or suitability of information provided by users on their profiles.</li>
                        <li>“Site” does not endorse or verify the authenticity of any “Member” claims, background, or
                            representations. “Member” is encouraged to exercise caution and use their discretion when
                            interacting with other “Member”.</li>
                        <li>The “Site” is not responsible for any communication or interaction between “Member”, whether online
                            or offline. “Member” is solely responsible for their interactions and any outcomes that may result
                            from those interactions.</li>
                        <li>“Site” does not conduct background checks on “Member”. “Member” is advised to independently verify
                            the information provided by other “Member” before making any decisions.</li>
                        <li>Under no circumstances , “Site” will be responsible for any loss or damage resulting from anyone's
                            use of the “Site” or any other “Service” and/or any Content posted on the “Site” or transmitted to
                            “Site” “Member”.</li>
                        <li>“Site” shall not be held responsible for any loss or damage to any individual arising out of, or
                            subsequent to, any relations (including but not limited to matrimonial relations) established
                            pursuant to the use of “Site” and “service”.</li>
                        <li>“Site” expressly disclaims any liability or responsibility whatsoever and howsoever arising as a
                            result of any Content/listing posted on the “Site” made available in “Site”by any “Member” of the
                            “Site” or any third party.</li>
                        <li>All liability, whether civil or criminal arising out of any Content that is Posted on the “Site”
                            will be of that “Member” or third party who has Posted such Content and “Site” reserves its right to
                            claim damages from such “Member” or third party. “Site” does not claim ownership of Content “Member”
                            has submitted.</li>
                        <li>The “Site” reserves the right to modify, suspend, or discontinue the services at any time without
                            notice. “Site” is not liable for any loss or damage resulting from such modifications, suspension,
                            or discontinuation.</li>
                        <li>The “Site” does not guarantee the security or confidentiality of information transmitted through the
                            “Site”. “Member” is advised to take appropriate measures to secure their data.</li>
                        <li>The “Site” doesn’t guarantee a match for the “Member” during their membership period. </li>
                    </ul>

                    <h3>13. Limitation Of Liability</h3>
                    <ul>
                        <li>Under No circumstance ,the”Site” will be liable, to “Member” or any third person, for any direct,
                            indirect, incidental, consequential, special or punitive damages arising from the use of its
                            “Service”. </li>
                        <li>Our liability is limited to the extent permitted under law. </li>
                    </ul>

                    <h3>14. Governing Law And Jurisdiction</h3>
                    <p>These terms are governed by the laws of India, specifically the Digital Personal Data Protection Act of
                        2023.
                        Any disputes will be resolved in the courts of Bangalore, India only.</p>

                    <h3>15. Grievance Redressal </h3>

                    <p>Please be free to provide your concerns in email at <a
                        href="mailto:gro@vedicmarriage.ai">gro@vedicmarriage.ai</a></p>

                    <h3>16. Indemnity</h3>

                    <p>“Member” agree to indemnify and hold ”Site”, its affiliates, officers, agents, and employees harmless
                        from any claims, demands, losses, liabilities, and expenses (including legal fees) arising out of or in
                        connection with: </p>

                    <ul>
                        <li>“Member”s use of the site or/and service in violation of this Agreement</li>
                        <li>“Member”s violation of these terms or any “Applicable Laws”. </li>
                        <li>“Member”s violation of the rights of any third party.</li>
                        <li>Any breach of “Member”s representations and warranties set forth above </li>
                        <li>Any fraudulent act on “Member”s part.</li>
                    </ul>

                    <h3>17. Exemptions From Data Processing Disclosure</h3>

                    <ul>
                        <li>“Site” employs a novel recommendation method for calculating matching scores which we seek to be
                            exempted from disclosing as per for “DPDPA” </li>
                        <li>“Site”s data processing is necessary for research, archiving, or statistical purposes, and it is not
                            used to make decisions specific to any individual “Member”. Revealing the intricate details of our
                            AI algorithm could lead to a leak of our proprietary technology, putting “Site” at a competitive
                            disadvantage.</li>
                        <li>“Site” assures “Member” that their personal data is processed securely and with the utmost
                            confidentiality. The exemption from detailed disclosure is aimed at protecting our innovative
                            approach while ensuring compliance with applicable data protection laws.</li>
                        <li>By Accepting these terms and conditions, “Member” hereby agrees to “Site”s exemption from data
                            process disclosure. </li>
                    </ul>

                    <h3>18. Advertising And Email Communication Consent</h3>
                    <ul>
                        <li>By providing contact information of “Member”, “Member” consent to give “Site” permission for sending
                            their advertising materials related to “Site" and other services.</li>
                        <li>“Member” agrees to receive emails from “Site” and/or its group sites. These emails may include
                            newsletters, promotional offers, and updates related to the “Services” we offered.</li>
                        <li>“Member” can unsubscribe at any time for any reason quickly and easily by clicking on the
                            unsubscribe link enclosed in the mail.</li>
                        <li>“Site” looks forward to serving “Member” on your journey to find meaningful connections .</li>
                    </ul>

                    <h3>19. Changes To Terms</h3>

                    <p>“Site” reserves the right to modify these terms at any time. Changes will be effective upon posting on
                        the “Site”, and the same shall be notified from time to time. </p>

                    <h3>20. User Consent</h3>

                    <p>By becoming a member of “Site” and/or using the “Services” of the “Site”, “Member” unconditionally and
                        irrevocably confirms that they have read and understood the above terms and agrees to abide by them.
                    </p>

                    <p>If “Member” is not agreeing to any of the above terms and conditions ,they should refrain themself from
                        registering on the “Site”.</p>
                    <p>“Site” is property of a proprietorship establishment in the name “Vedic Marriage”, registered in
                        Bangalore. </p>
                    <p>This Agreement is an electronic document in terms of the DPDPA of 2023 and Rules there under pertaining
                        to electronic records as applicable and amended. This electronic record is generated by a computer
                        system and does not require any physical or digital signatures.</p>




                </div>
            </section>

            <Footer />
        </>
    )

}