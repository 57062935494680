import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';



export default function Form3({data, handleChange}) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    return (

        <>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Enter your organization name*</label>
                        <input type="text" class="form-control" name="other_organization_name" value={data.other_organization_name} onChange={handleChange}/>
                    </div>
                </div>

            </div>

        </>
    )

}