import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

export default function
    Age({ data, handleChange }) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Preferred Age Range</label>

                            <div class="row align-items-center">
                                <div class="col-5">
                                    <input type="number" name="age_start" class="form-control" min="0" max="999" value={data.age_start} onChange={handleChange} />
                                </div>

                                <div class="col-2">
                                    <h6>To</h6>
                                </div>

                                <div class="col-5">
                                    <input type="number" name="age_end" class="form-control" min={data.age_start} max="999" value={data.age_end} onChange={handleChange} />

                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}