import React, { useEffect } from 'react';
import $ from 'jquery';

const BodyColor = ({ }) => {
    useEffect(() => {
        // Get the body element
        const body = $('body');

        body.css('background-color', "#fff");

    }, []);

    return null; // This component doesn't render anything
};

export default BodyColor;
