import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { API_BASE_URL, MyProfileTabs } from "../../Data/content";
import BasicMatch from "./BasicMatch";
import AstroMatch from "./AstroMatch";
import FamilyMatch from "./FamilyMatch";
import CarrerMatch from "./CarrerMatch";
import SpritualityMatch from "./SpritualityMatch";
import PersonalityMatch from "./PersonalityMatch";
import { GetMatchingDetails, RecommendToUser, addViewdIds, clearMatchFtm } from "../../../../Reducer/MakerDashboardSlices/matchingFtmSlice";
import Sidebar from "../Sidebar";
import PrefMatch from "./Prefmatch";
import Swal from 'sweetalert2';


export default function FtmMatchingView() {
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const params = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.matchingFtm.data);
    // const [data, setData] = useState(null);
    const success = useSelector(state => state.matchingFtm.loading);
    const error = useSelector(state => state.matchingFtm.error);
    const Ids = useSelector(state => state.matchingFtm.ViewdIds);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearMatchFtm());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearMatchFtm());
            getData();

        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearMatchFtm());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearMatchFtm());
                break;
        }
    }

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        getData();
    }, [params.id]);

    async function getData() {
        dispatch(addViewdIds(params.id));
        let data = { id: auth._id, uid: params.uid, viewuser: params.id };
        dispatch(GetMatchingDetails(data));

    }

    const Recommend = (id, uid) => {
        let data1 = { maker_id: auth._id, user_id: params.uid, otheruser_id: params.id };
        if (params.uid === id) {
            data1.type = "recomendedToUser";
        } else {
            data1.type = "recomendedToOther";
        }
        if (data && data.basic.gender === "Female") {
            data1.girl_id = data._id;
            data1.boy_id = data.ViewUser._id;
            if (id === data._id) {
                data1.recomend_to_girl = true
            } else {
                data1.recomend_to_boy = true;

            }

        } else {
            data1.girl_id = data.ViewUser._id;
            data1.boy_id = data._id;
            if (id === data._id) {
                data1.recomend_to_boy = true
            } else {
                data1.recomend_to_girl = true;

            }
        }
        // console.log('data1', data1);
        dispatch(RecommendToUser(data1));
    }

    async function NextProfile() {
        let data = { id: auth._id, uid: params.uid, viewdArry: JSON.stringify(Ids) };
        let res = await dispatch(GetMatchingDetails(data));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            naviagte("/MatchingView/" + responseData.ViewUser._id + "/" + responseData._id);
        }
    }

    console.log('dataaa', data);
    return (
        <>
            <section id="full_page_dashboard" class="match_maker_width">
                <div class="container-fluid">
                    <div className="updated_sidebar">
                        <Sidebar />
                    </div>

                    <section id="profile_view_matchmaker">
                        <div class="main_sec">
                            <div class="main_box">
                                <img src={data && data.profileImage && API_BASE_URL + data.profileImage} alt="" />
                                <h3>{data && data.name}</h3>
                                <p>{data && data.userAge1} Yrs </p>
                            </div>

                            <div class="main_box_two">
                                <h4><span>

                                    <div className="persent_div_two" style={{ width: 40, height: 20 }} >
                                        <CircularProgressbar value={data && data.OverAll} text={`${data && data.OverAll}%`}
                                            styles={{
                                                path: {
                                                    stroke: '#FF6347', // You can replace 
                                                },
                                                text: {
                                                    fill: '#000000', // You can replace 
                                                },
                                            }} />
                                    </div>
                                </span>
                                    Overall Match
                                </h4>
                            </div>

                            <div class="main_box_three">
                                <img src={data && data.ViewUser && API_BASE_URL + data.ViewUser.profileImage} alt="" />
                                <h3>{data && data.ViewUser && data.ViewUser.name}</h3>
                                <p>{data && data.ViewUser && data.ViewUser.useAge2} Yrs </p>
                            </div>

                        </div>

                        <div class="astro_box">
                            <div class="row">

                                <div class="col-lg-3 col-sm-6 mt-3">
                                    <h4><span><img src="/images/A-1.png" /> Astro Match</span> <label>
                                        <div className="persent_div" style={{ width: 40, height: 20 }} >
                                            <CircularProgressbar value={data && data.HorscopePercentage} text={`${data && data.HorscopePercentage}%`}
                                                styles={{
                                                    path: {
                                                        stroke: '#FF6347', // You can replace 
                                                    },
                                                    text: {
                                                        fill: '#000000', // You can replace 
                                                    },
                                                }} />
                                        </div>
                                    </label></h4>
                                </div>

                                <div class="col-lg-3 col-sm-6 mt-3">
                                    <h4><span><img src="/images/A-2.png" />Spiritual</span><label>
                                        <div className="persent_div" style={{ width: 40, height: 20 }} >
                                            <CircularProgressbar value={data && data.SpritualPercentage} text={`${data && data.SpritualPercentage}%`}
                                                styles={{
                                                    path: {
                                                        stroke: '#28A745', // You can replace 
                                                    },
                                                    text: {
                                                        fill: '#000000', // You can replace 
                                                    },
                                                }} />
                                        </div>
                                    </label></h4>
                                </div>

                                <div class="col-lg-3 col-sm-6 mt-3">
                                    <h4><span><img src="/images/A-3.png" />Socio-Cultural</span><label>
                                        <div className="persent_div" style={{ width: 40, height: 20 }}>
                                            <CircularProgressbar value={data && data.SocialPercenatge} text={`${data && data.SocialPercenatge}%`}
                                                styles={{
                                                    path: {
                                                        stroke: '#F8BA41', // You can 
                                                    },
                                                    text: {
                                                        fill: '#000000', // You can 
                                                    },
                                                }} />
                                        </div>
                                    </label></h4>
                                </div>

                                <div class="col-lg-3 col-sm-6 mt-3">
                                    <h4><span><img src="/images/A-4.png" />Economical</span><label>
                                        <div className="persent_div" style={{ width: 40, height: 20 }}>
                                            <CircularProgressbar value={data && data.EconomicPercentage} text={`${data && data.EconomicPercentage}%`}
                                                styles={{
                                                    path: {
                                                        stroke: '#FF6347', // You can 
                                                    },
                                                    text: {
                                                        fill: '#000000', // You can 
                                                    },
                                                }} />
                                        </div>
                                    </label></h4>
                                </div>

                            </div>


                            <Tabs>
                                <TabList className={"nav nav-pills"} id="pills-tab" role="tablist">
                                    {MyProfileTabs.length > 0 && MyProfileTabs.map((item, i) => (
                                        <Tab key={i}
                                            className={"nav-link" + (activeTab === i ? " active" : "")}
                                            onClick={() => handleTabClick(i)}
                                        >
                                            {item.name}
                                        </Tab>
                                    ))}

                                </TabList>

                                {MyProfileTabs.map((tem, index) => (
                                    <>
                                        <TabPanel>
                                            <BasicMatch user1={data && data} user2={data && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <AstroMatch user1={data && data} user2={data && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <FamilyMatch user1={data && data} user2={data && data.ViewUser && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <CarrerMatch user1={data && data} user2={data && data.ViewUser && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <SpritualityMatch user1={data && data} user2={data && data.ViewUser && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <PersonalityMatch user1={data && data} user2={data && data.ViewUser && data.ViewUser} />
                                        </TabPanel>
                                        <TabPanel >
                                            <PrefMatch user1={data && data} user2={data && data.ViewUser && data.ViewUser} />
                                        </TabPanel>
                                    </>

                                ))}

                            </Tabs>

                            <section id="profile_view_tab_sh">

                                <div className="row">
                                    <div className="col-md-6">
                                        {data && data.basic && data.basic.gender === "Male" ? (
                                            data.checkRecomndUser === null ? (
                                                <div className="button_sh text-end">
                                                    <a className="btn disabled">Recommend to {data && data.name}</a>
                                                </div>
                                            ) : (

                                                data && data.checkRecomndUser && data.checkRecomndUser.girl_Status === "Accepted" ? (

                                                    data && data.checkRecomndUser && data.checkRecomndUser.recomend_to_boy === true ? (
                                                        <div className="button_sh text-end">
                                                            <a className={data && data.checkRecomndUser.boy_Status === "Accepted" ? "btn rec_accpt" : "btn rec_pending"}>{data.checkRecomndUser.boy_Status}</a>
                                                        </div>
                                                    ) : (
                                                        <div className="button_sh text-end">
                                                            <a className="btn" onClick={() => Recommend(params.uid, params.id)}>Recommend to {data && data.name}</a>
                                                        </div>
                                                    )

                                                ) : (
                                                    <div className="button_sh text-end">
                                                        <a className="btn disabled">Recommend to {data && data.name}</a>
                                                    </div>)
                                            )
                                        ) : (
                                            data && data.checkRecomndUser === null ? (
                                                <div className="button_sh text-end" onClick={() => Recommend(params.uid, params.id)}>
                                                    <a className="btn">Recommend to {data && data.name}</a>
                                                </div>
                                            ) : (
                                                <div className="button_sh text-end">
                                                    <a className={data && data.checkRecomndUser.girl_Status === "Accepted" ? "btn disabled rec_accpt" : "btn disabled rec_pending"}>{data && data.checkRecomndUser && data.checkRecomndUser.girl_Status}</a>
                                                </div>
                                            )
                                        )}

                                    </div>


                                    <div className="col-md-6">
                                        <div class="button_sh text-end" >
                                            <a class="btn nexy_btn" onClick={() => NextProfile()}>Next Profile</a>
                                            {data && data.ViewUser && data.ViewUser.basic && data.ViewUser.basic.gender === "Male" ?
                                                (
                                                    data && data.checkRecomndUser === null ? (
                                                        <a class="btn disabled" >Recommend to {data && data.ViewUser && data.ViewUser.name}</a>
                                                    ) : (
                                                        data && data.checkRecomndUser && data.checkRecomndUser.girl_Status === "Accepted" ? (


                                                            data && data.checkRecomndUser && data.checkRecomndUser.recomend_to_boy === true ? (
                                                                <a className={data && data.checkRecomndUser.boy_Status === "Accepted" ? "btn rec_accpt" : "btn rec_pending"}>{data.checkRecomndUser.boy_Status}</a>

                                                            ) : (
                                                                <a className="btn" onClick={() => Recommend(params.id, params.uid)}>Recommend to {data && data.ViewUser && data.ViewUser.name}</a>

                                                            )

                                                        ) : (
                                                            <a class="btn disabled">Recommend to {data && data.ViewUser && data.ViewUser.name}</a>
                                                        )
                                                    )
                                                ) : (
                                                    data && data.checkRecomndUser === null ? (
                                                        <a class="btn" onClick={() => Recommend(params.id, params.uid)}>Recommend to {data && data.ViewUser && data.ViewUser.name}</a>
                                                    ) : (

                                                        <a className={data && data.checkRecomndUser.girl_Status === "Accepted" ? "btn disabled rec_accpt" : "btn disabled rec_pending"}>{data && data.checkRecomndUser && data.checkRecomndUser.girl_Status}</a>

                                                    )
                                                )}

                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>

                    </section>

                </div >
            </section >
        </>
    )

}