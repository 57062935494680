import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../Data/content";
import Sidebar from "../Sidebar";
import { MakerDetail } from "../../../../Reducer/MakerDashboardSlices/makerSlice";
import { UpdateMakerGallery, clearEditMaker } from "../../../../Reducer/MakerDashboardSlices/editMakerSlice";

export default function Gallry() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [files, setFiles] = useState([]);
    const [err, setFormError] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.makerEdit.success);
    const error = useSelector(state => state.makerEdit.error);

    console.log('photos', photos);
    if (success && success.status === 200) {
        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearEditMaker());
        getData();
    }


    if (success && success.status === 200) {

        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearEditMaker());
        setTimeout(() => {
            navigate("/MatchMakerEditProfile");
        }, 2000);

    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());

        }
    }

    useEffect(() => {
        getData();

    }, []);



    async function getData() {
        let dtaa = { uid: auth._id };
        let res = await dispatch(MakerDetail(dtaa));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            setPhotos(responseData.basic.images);

        }
    }



    const selectFile = async (event) => {
        // console.log('eveneteeee uploaded', event.target.files);
        for (let index = 0; index < event.target.files.length; index++) {
            const element = event.target.files[index];
            console.log('element', element);
            setFiles(prevFiles => [...prevFiles, element]);

            setPhotos2(prevPhotos => [...prevPhotos, { url: URL.createObjectURL(element), file: element }]);

        }
    }

    const DelImage = (file, url, type) => {
        console.log('file', file, url);
        if (type === "back") {

            let data = { name: file, uid: auth._id };
            // dispatch(DelGallery(data));
        } else {
            setPhotos2(photos2.filter(photo => photo.url !== url));

            setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.name !== file.name));

        }
    }


    const uploadPhotos = (e) => {

        console.log('files', files);
        if (photos2.length > 0 || photos.length > 0) {
            let formData = new FormData();
            formData.append('uid', auth._id);

            for (let index = 0; index < files.length; index++) {
                formData.append('images', files[index]);

            }
            dispatch(UpdateMakerGallery(formData));
        }
        else {
            setFormError("Please select atleast one image");
        }

    }

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />

                    <div class="upload_photos">
                        <div class="row">
                            <div class="col-lg-6 pe-xl-5">
                                <div class="box">
                                    <h2><img src="images/Photos-upload.png" alt="" />
                                        <span>Photos</span>
                                        Upload Photos
                                    </h2>
                                    <h3>Photo Upload Guidelines</h3>
                                    <h4>Capture Diversity</h4>
                                    <p>📸 Showcase a mix of single photos, family moments, and travel memories to highlight
                                        various aspects of your life.</p>

                                    <h4>Authenticity Matters</h4>
                                    <p>🌟 Ensure your photos reflect your genuine self without excessive edits or filters.</p>

                                    <h4>Balanced Lifestyle Moments</h4>
                                    <ul>
                                        <li>🌈 Include snapshots from your spiritual or devotee moments, adding depth.</li>
                                        <li>📸 Showcase your social life outside the temple, giving a well-rounded view of your
                                            lifestyle.</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="box-two">

                                    {photos[0] ? (
                                        <>
                                            <div class="imagefile" style={{ backgroundImage: `url(${API_BASE_URL + photos[0]})` }} >
                                                <i class="bi bi-x" onClick={() => DelImage(photos[0], 'url', 'back')}></i></div>
                                        </>
                                    ) : (
                                        <div class="imagefile" style={{ backgroundImage: `url(${"images/img-upload.png"})` }} ></div>
                                    )}
                                    <ul>
                                        {photos.length > 1 && photos.slice(1).map((photo, index) => (
                                            <>
                                                <li key={index} style={{
                                                    backgroundImage: `url(${API_BASE_URL + photo || "images/img-upload.png"})`
                                                }}> <i class="bi bi-x" onClick={() => DelImage(photo, 'url', 'back')}></i>
                                                </li>
                                            </>
                                        ))}
                                        {photos2.length > 0 && photos2.map((ph, index) => (
                                            <>
                                                <li key={index} style={{
                                                    backgroundImage: `url(${ph.url || "images/img-upload.png"})`
                                                }}><i class="bi bi-x" onClick={() => DelImage(ph.file, ph.url, 'front')}></i>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                    <h6><input class="change_placeholder_two" type="file" onChange={selectFile} accept="image/*" multiple /></h6>
                                    {err && <p className={"error__feedback"}>{err}</p>}
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between mt-5">
                                {/* <Link to="/UserDashboard" class="btn">Cancel</Link>
                                <Link to="/SelfiVerification" class="btn">Skip</Link> */}
                                <a class="btn upload" onClick={uploadPhotos}>Upload</a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}