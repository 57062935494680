import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MyProfileTabs } from "../../../Data/content";
import { GetSideProfile } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";


export default function TabDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.data);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    useEffect(() => {
        let dat = { uid: auth._id };
        dispatch(GetSideProfile(dat));

    }, []);

    const sendRes = () => {
        props.reForParent();

    }

    return (
        <>

            <div class="col-xl-8 mb-3">
                <div class="box_two">
                    <h2>{data && data.basic && data.basic.name}<Link to="/Dashboard"><img src="images/Edit.png" alt="" /></Link></h2>
                    <h3>{data && data.about && data.about.about}</h3>

                    {MyProfileTabs.length > 0 &&

                        <Tabs>
                            <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                {MyProfileTabs.map((tem, index) => (
                                    <Tab key={index}
                                        className={"nav-link" + (activeTab === index ? " active" : "")}
                                        onClick={() => handleTabClick(index)}
                                    >
                                        {tem.name}
                                    </Tab>
                                ))}
                            </TabList>


                            {MyProfileTabs.map((tem, index) => (
                                <TabPanel key={index} className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}>
                                    {tem.component}
                                </TabPanel>
                            ))}

                        </Tabs>
                    }

                </div>
            </div>
        </>

    )
}