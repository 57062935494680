import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Banner from './Banner';
import Form from './Form';
import HomeTabs from "./HomeTabs";
import SuccessStories from "../Tools/SuccessStrories";
import Resources from "../Tools/Resources";
import Plans from "./Plans";
import { useDispatch, useSelector } from 'react-redux';
import { homePage } from "../../../Reducer/apiSlice";
import { clearFilter } from "../../../Reducer/UserDashboardSlices/filterSlice";


export default function Home() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.api.data)
    useEffect(() => {
        dispatch(homePage());
        dispatch(clearFilter())
    }, []);

    return (
        <>
            <Header />
            <Banner data={data && data.BannerSection} slider={data && data.Slider} />
            {auth == null &&
                <Form />
            }
            <HomeTabs data={data && data.Features} />
            <SuccessStories data={data && data.stories} />
            <Resources data={data && data.Resources} />
            <Plans />
            <Footer />

        </>
    );
}
