import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "./Sidebar";
import Home from "./Home";
import Users from "./Users";
import Messages from "./Messages";
import Profile from "./Profile";

export default function MakerDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        if (auth && auth.profile_status === "Approved") {
            navigate('/MatchMakerDashboard');
        } else {
            navigate('/DefaultDashbard');
        }
    }, []);



    return (
        <>

        </>
    )

}