import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { occupationssNam, IncomeList, CompanyType, Qualifications, jobDuration } from "../../Data/content";
import { GetCarrerInfo, SaveCarrerDetail, cleasrData } from "../../../../Reducer/StepForms/carrerSlice";
import Swal from 'sweetalert2';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';

export default function Career() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [data, setData] = useState({});
    const [location, setLocation] = useState(null);
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [location2, setLocation2] = useState(null);
    const [lat2, setLat2] = useState("");
    const [long2, setLong2] = useState("");
    const [errors, setFormErrors] = useState({});
    const error = useSelector(state => state.carrer.error);
    const success = useSelector(state => state.carrer.success);
    const occupationss = occupationssNam.sort();
    const [msg, setMsg] = useState("");
    const [submitType, setSubmitType] = useState("");



    useEffect(() => {

        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetCarrerInfo(dat));
        if (res.payload && res.payload.data.data) {
            setData(res.payload.data.data);
            setLocation({
                value: "",
                label: res.payload.data.data.job_location
            });
            setLocation2({
                value: "",
                label: res.payload.data.data.Ed_institution
            })
        }
    }

    const navigationn = () => {
        setMsg("");
        let name = "Spiritual";
        navigate("/UserStepForms", { state: { name } });
    }


    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(cleasrData());

        } else {

            if (submitType === "draft") {
                setMsg(success.data.message);
                dispatch(cleasrData());
            } else {
                navigationn();
                dispatch(cleasrData());
            }



        }
    }



    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(cleasrData()); break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(cleasrData()); break;
        }
    }

    async function handleSubmit(e) {
        setMsg("");
        e.preventDefault();

        let formData = new FormData();
        formData.append('uid', auth._id);
        formData.append('company_name', data && data.company_name ? data.company_name : "");
        formData.append('occupation', data && data.occupation ? data.occupation : "");
        formData.append('income', data && data.income ? data.income : "Upto INR 1 Lakh");
        formData.append('company_type', data && data.company_type ? data.company_type : "Private Company");
        formData.append('job_location', location && location.label ? location.label : "");
        formData.append('working_period', data && data.working_period ? data.working_period : 0);
        formData.append('timePeriod', data && data.timePeriod ? data.timePeriod : 0);
        formData.append('Ed_qalification', data && data.Ed_qalification ? data.Ed_qalification : "");
        formData.append('Ed_institution', location2 && location2.label ? location2.label : "");
        formData.append('Ed_other_qualification', data && data.Ed_other_qualification ? data.Ed_other_qualification : "");
        if (e.nativeEvent.submitter.name === 'draft') {
            setSubmitType('draft');
            formData.append('draft', 'true');
            dispatch(SaveCarrerDetail(formData));
        } else {
            let values = validate(data);
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            }
            if (chk) {
                setSubmitType('next');
                formData.append('draft', 'false');
                formData.append('saved', true);
                dispatch(SaveCarrerDetail(formData));

            } else {
                setFormErrors(validate(data));
            }
        }
    }

    const handleChangeInput = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation({
                value: value.value,
                label: value.label
            });
            setLat(latLng.lat);
            setLong(latLng.lng);

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    const handleSelect2 = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation2({
                value: value.value,
                label: value.label
            });
            setLat2(latLng.lat);
            setLong2(latLng.lng);

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    const validate = (values) => {
        console.log('vall', values);
        let error = {};
        if (!values.company_name) {
            error.company_name = "This field is required!";
        }
        if (!values.occupation) {
            error.occupation = "This field is required!";
        }
        if (!values.income || (values.income.trim() === "" && values.income !== "Upto INR 1 Lakh")) {
            errors.income = "This field is required!";
        }

        if (!values.company_type || (values.company_type.trim() === "" && values.company_type !== "Private Company")) {
            errors.company_type = "This field is required!";
        }
        if (!location) {
            error.job_location = "This field is required!";
        }
        if (location && location.value && location.value == "") {
            error.job_location = "This field is required!";
        }
        if (!location2) {
            error.Ed_institution = "This field is required!";
        }
        if (location2 && location2.value && location2.value == "") {
            error.Ed_institution = "This field is required!";
        }
        if (!values.working_period) {
            error.working_period = "This field is required!";
        }
        if (values.working_period && values.timePeriod && values.working_period > values.timePeriod) {
            error.working_period = "This should not be greater than total experience";
        }
        if (!values.timePeriod) {
            error.timePeriod = "This field is required!";
        }
        if (values.timePeriod && values.timePeriod < values.working_period) {
            error.timePeriod = "Total experience should be greater than working experience";
        }
        if (!values.Ed_qalification) {
            error.Ed_qalification = "This field is required!";
        }

        return error;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div class="tab">
                <h3>Career & Education</h3>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Employer/Organization*</label>
                            <input type="text" class="form-control" name="company_name" onChange={handleChangeInput} defaultValue={data && data.company_name} spellCheck={true} />
                            {errors.company_name && <p className={"error__feedback"}>{errors.company_name}</p>}

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Occupation*</label>
                            <select class="form-select" aria-label="Default select example" name="occupation" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {occupationss.length > 0 && occupationss.map((item, i) => (
                                    <option selected={data && data.occupation === item} value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.occupation && <p className={"error__feedback"}>{errors.occupation}</p>}

                        </div>
                    </div>


                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Annual Income*</label>
                            <select class="form-select" aria-label="Default select example" name="income" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                {IncomeList.length > 0 && IncomeList.map((item, i) => (
                                    <option selected={data && data.income === item} value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.income && <p className={"error__feedback"}>{errors.income}</p>}

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Company Type*</label>
                            <select class="form-select" aria-label="Default select example" name="company_type" onChange={handleChangeInput}>
                                {/* <option selected disabled>Select</option> */}
                                {CompanyType.length > 0 && CompanyType.map((item, i) => (
                                    <option selected={data && data.company_type === item} value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.company_type && <p className={"error__feedback"}>{errors.company_type}</p>}

                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Job Location*</label>
                            <PlacesAutocomplete
                                selectProps={{
                                    value: location,
                                    onChange: handleSelect,
                                }}
                                query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                fetchDetails={true}
                                onFail={error => console.log(error)}
                            />
                            <input name="job_location" className="service-input" type="hidden" placeholder="Enter address" value={location} />
                            {errors.job_location && <p className={"error__feedback"}>{errors.job_location}</p>}

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">How long you are working with this company (years)?*</label>
                            <input type="number" class="form-control" name="working_period" onChange={handleChangeInput} defaultValue={data && data.working_period} min="0" max="999" />
                            {errors.working_period && <p className={"error__feedback"}>{errors.working_period}</p>}
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Total working experience (years)?*</label>
                            {/* <select class="form-select" aria-label="Default select example" name="timePeriod" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {jobDuration.length > 0 && jobDuration.map((item, i) => (
                                    <option selected={data && data.timePeriod === item} value={item}>{item}</option>

                                ))}
                            </select> */}

                            <input type="number" class="form-control" name="timePeriod" onChange={handleChangeInput} defaultValue={data && data.timePeriod} min="0" max="999" />
                            {errors.timePeriod && <p className={"error__feedback"}>{errors.timePeriod}</p>}
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <h3>Educational Details </h3>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Highest Education Qualification*</label>
                            <select class="form-select" aria-label="Default select example" name="Ed_qalification" onChange={handleChangeInput}>
                                <option selected disabled>Select</option>
                                {Qualifications.length > 0 && Qualifications.map((item, i) => (
                                    <option selected={data && data.Ed_qalification === item} value={item}>{item}</option>

                                ))}
                            </select>
                            {errors.Ed_qalification && <p className={"error__feedback"}>{errors.Ed_qalification}</p>}
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Educational Institution*</label>
                            <PlacesAutocomplete
                                selectProps={{
                                    value: location2,
                                    onChange: handleSelect2,
                                }}
                                query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                fetchDetails={true}
                                onFail={error => console.log(error)}
                            />
                            <input name="Ed_institution" className="service-input" type="hidden" placeholder="Enter address" value={location2} />
                            {errors.Ed_institution && <p className={"error__feedback"}>{errors.Ed_institution}</p>}
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Aditional Educational Qulaification</label>
                            <input type="text" class="form-control" name="Ed_other_qualification" spellCheck={true} onChange={handleChangeInput} defaultValue={data && data.Ed_other_qualification} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="step_common_btn">
                <div class="row">
                    <div class="col-sm-6" >
                        <button class="btn two" type="submit" name="draft" disabled={data && data.saved && data.saved === true}>Save Draft</button>
                    </div>
                    <div class="col-sm-6 text-end">
                        <a class="btn" onClick={() => navigationn()}>Skip</a>
                        <button class="btn" type="submit"
                        >Next</button>
                    </div>
                    {msg !== "" && <p class="SucessColor">{msg}</p>}
                </div>

            </div>

        </form>
    )

}