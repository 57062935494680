import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ element,user }) => {
  
  const CC = useSelector(state => state.auth.auth);
  const userData = JSON.parse(localStorage.getItem("user"))

  console.log("userData", userData);

  console.log('cc', CC, user);
  let checkTermOrNot=false;
  if(CC?.importedbyexcel !==undefined && CC?.importedbyexcel===true){
    if(CC?.acceptedTerm===false){
      checkTermOrNot=true;
    }
  }

  return CC && CC.userType && CC.userType === user ? (
    checkTermOrNot ? (
      <Navigate to="/accept-terms" replace /> 
      // element
    ) : (
      element
    )
  ) : (
    <Navigate to="/login" replace />
  );

};

export default AuthRoute;
