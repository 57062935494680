import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

export default function PrivacyPolicy({ setOpenModal }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setOpenModal(false); // Update the state in the Header component
    }


    return (
        <>
            <div class="modal fade terms_conditions show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ display: 'block' }}>
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Terms and Conditions</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                        </div>
                        <div class="modal-body">

                            <div class="terms_cond_box">
                                <h3>1. Introduction Welcome</h3>
                                <p>Welcome to [Your Vedic Marriage Service] ("we," "us," "our"). We respect your privacy and are
                                    committed to protecting your personal information. This Privacy Policy is designed to help
                                    you understand how we collect, use, disclose, and safeguard your personal information. By
                                    using our services, you consent to the practices described in this Privacy Policy.</p>

                                <h3>2. Information We Collect</h3>
                                <ul>
                                    <li>Personal Information: We may collect personal information such as your name, contact
                                        information, and other identifying details when you use our services or communicate with
                                        us.</li>
                                    <li>Marriage Details: We may collect information related to your marriage or marital
                                        counseling, including preferences, schedules, and notes from counseling sessions.</li>
                                    <li>Communication: We may collect information from your communications with us, including
                                        emails, messages, and feedback.</li>
                                </ul>

                                <h3>3. How We Use Your Information</h3>
                                <p>We may use your information for the following purposes:</p>
                                <ul>
                                    <li>To provide and maintain our marriage counseling services.</li>
                                    <li>To communicate with you and respond to your inquiries.</li>
                                    <li>To improve and personalize our services.</li>
                                    <li>To send you relevant updates and information about our services.</li>
                                </ul>

                                <h3>4. Sharing Your Information</h3>
                            </div>

                        </div>
                        {/* <div class="modal-footer">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    I agree to Terms and condition
                                </label>
                            </div>
                            <button type="submit" class="btn">Submit</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )

}