import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../Data/content";



export default function Step4({ onNext }) {
    const [photos, setPhotos] = useState([]);
    const [files, setFiles] = useState([]);
    const [errors, setFormErrors] = useState("");


    useEffect(() => {

    }, []);


    const selectFile = async (event) => {
        for (let index = 0; index < event.target.files.length; index++) {
            const element = event.target.files[index];
            setFiles(prevFiles => [...prevFiles, element]);

            setPhotos(prevPhotos => [...prevPhotos, { url: URL.createObjectURL(element), file: element }]);

        }
    }
    const DelImage = (file, url) => {

        setPhotos(photos.filter(photo => photo.url !== url));

        setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.name !== file.name));

    }

    const uploadPhotos = (e) => {
        e.preventDefault();
        if (files.length > 0) {
            let data = { images: files };
            onNext(data);
        } else {
            setFormErrors("Upload atleast one image");
        }
    }

    return (

        <div class="upload_photos h-100">
            <div class="row">
                <div class="col-lg-6 pe-xl-5">
                    <div class="box">
                        <h2><img src="images/Photos-upload.png" alt="" />
                            <span>Photos</span>
                            Upload Photos
                        </h2>
                        <h3>Photo Upload Guidelines</h3>
                        <h4>Capture Diversity</h4>
                        <p>📸 Showcase a mix of single photos, family moments, and travel memories to
                            highlight
                            various aspects of your life.</p>

                        <h4>Authenticity Matters</h4>
                        <p>🌟 Ensure your photos reflect your genuine self without excessive edits or
                            filters.</p>

                        <h4>Balanced Lifestyle Moments</h4>
                        <ul>
                            <li>🌈 Include snapshots from your spiritual or devotee moments, adding depth.
                            </li>
                            <li>📸 Showcase your social life outside the temple, giving a well-rounded view
                                of your
                                lifestyle.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="box-two image_upload_match">
                        {photos[0] ? (
                            <>
                                <div class="imagefile" style={{ backgroundImage: `url(${photos[0].url})` }} >
                                    <i class="bi bi-x"  onClick={() => DelImage(photos[0].file, photos[0].url)}></i> </div>
                            </>
                        ) : (
                            <div class="imagefile" style={{ backgroundImage: `url(${"images/img-upload.png"})` }} > </div>
                        )}

                        <ul>
                            {photos.length > 1 && photos.slice(1).map((photo, index) => (
                                <>
                                    <li key={index} style={{
                                        backgroundImage: `url(${photo.url || "images/img-upload.png"})`
                                    }}> <i class="bi bi-x" onClick={() => DelImage(photo.file, photo.url,)}></i>
                                    </li>
                                </>
                            ))}
                        </ul>
                        <h6><input type="file" onChange={selectFile} accept="image/*" multiple /></h6>
                    </div>
                    {errors && <p className={"error__feedback"}>{errors}</p>}

                </div>

                <div class="col-12 text-end mt-3">
                    <a class="btn upload" onClick={uploadPhotos}>Next</a>
                </div>

            </div>
        </div>

    )

}