import { NavLink, useNavigate, Link, useLocation, Navigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from "../Data/content";
import Swal from 'sweetalert2';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import BodyColor from "../../Layouts/BodyColor";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { InterestSend, ShortList, clacDash } from "../../../Reducer/UserDashboardSlices/dashboardSlice";

export default function SearchResults() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.filter.record);
    const filttt = useSelector(state => state.filter.Searched);
    const UserType = useSelector(state => state.filter.UserType);

    const success = useSelector(state => state.dshbrd.success);
    const error = useSelector(state => state.dshbrd.error);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clacDash());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clacDash());


        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
        }
    }

    const ShortlistUser = (id) => {
        let data = { shortlist_By: auth._id, shortlist_id: id };
        dispatch(ShortList(data));
    }


    const SendInterest = (id) => {
        let data = { sent_by: auth._id, sent_to: id };
        dispatch(InterestSend(data));

    }

    const goto = (id) => {
        navigate("/MyMatches/ViewProfile/" + id);
    }


    return (
        <>
            <BodyColor />
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <Header />
                    <section id="match_dashboard">
                        <div class="filter_select">

                        </div>
                        <div class="row">
                            {data && data.length > 0 ? data.map((item, i) => (
                                <div class="col-xl-4 col-sm-6 mb-3" onClick={() => goto(item._id)}>

                                    <div class="box">
                                        <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                            <div className="carousel-indicators">
                                                {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        data-bs-target="#carouselExampleIndicators2"
                                                        data-bs-slide-to={index}
                                                        className={index === 0 ? 'active' : ''}
                                                        aria-current={index === 0 ? 'true' : 'false'}
                                                        aria-label={`Slide ${index + 1}`}
                                                    />
                                                ))}
                                            </div>
                                            <div className="carousel-inner">
                                                {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                                         <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl} />
                                                    </div>
                                                ))}
                                            </div>
                                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </div>

                                        <h2 >{item.name}<img src="images/verify.png" alt="" /></h2>
                                        <h3>Profile Match %

                                            <div style={{ width: 40, height: 20 }}>
                                                <CircularProgressbar value={item.OverAll} text={`${item.OverAll}%`}
                                                />
                                            </div>

                                        </h3>
                                        <h4><img src="images/k1.png" alt="" />Profile managed by Self</h4>
                                        <h4><img src="images/k2.png" alt="" />Mother Tongue is {item.Mother_tongue}</h4>
                                        <h4><img src="images/k3.png" alt="" />{item.city}, {item.country}</h4>
                                        <h4><img src="images/k4.png" alt="" />{item.income}</h4>
                                        <h4><img src="images/k5.png" alt="" />{item.Marital_status}</h4>

                                        {UserType && UserType == "Normal" &&
                                            <div class="d-flex justify-content-between">
                                                {item.InterestSent === true ? <a class="btn" >Interest Sent</a> : <a class="btn" onClick={() => SendInterest(item._id)}>Send Interest</a>}
                                                {item.shortlisted === true ? <a class="btn shortlist" >Shortlisted</a> : <a class="btn shortlist" onClick={() => ShortlistUser(item._id)}>Shortlist</a>}
                                            </div>}
                                    </div>
                                </div>
                            )) : <p>No Matches found.</p>}
                        </div>
                    </section>
                </div>
            </section>
        </>

    )

}
