import { NavLink, useNavigate, Link, useLocation, Navigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { InterestSend, ShortList, clacDash, } from "../../../Reducer/UserDashboardSlices/dashboardSlice";
import { API_BASE_URL } from "../Data/content";
import Swal from 'sweetalert2';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import BodyColor from "../../Layouts/BodyColor";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { FilterSearch, clearFilter, saveSearchFilters, saveSearchResults } from "../../../Reducer/UserDashboardSlices/filterSlice";



export default function FilterResults() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [summry, setSummary] = useState("");
    const [filArry, setFilArry] = useState([]);
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.filter.Results);
    const filttt = useSelector(state => state.filter.filterData);

    const success = useSelector(state => state.dshbrd.success);
    const error = useSelector(state => state.dshbrd.error);



    useEffect(() => {
        let appliedFilters = [];

        if (Object.keys(filttt).length > 0) {
            // setUPdatedFilter(filttt);

            let ageFilterSet = false;
            let heightFilterSet = false;

            for (const key in filttt) {
                if (filttt.hasOwnProperty(key)) {
                    if (key === 'age_start' && filttt[key] !== "" && filttt.hasOwnProperty('age_end') && filttt.age_end !== "") {
                        ageFilterSet = true;
                        appliedFilters.push(`Age: ${filttt.age_start} - ${filttt.age_end} Yrs.`);
                    } else if (key === 'height_start' && filttt[key] !== "" && filttt.hasOwnProperty('height_end') && filttt.height_end !== "") {
                        heightFilterSet = true;
                        appliedFilters.push(`Height: ${filttt.height_start} - ${filttt.height_end} cm`);
                    } else if (filttt[key] !== "" && key !== 'age_end' && key !== 'height_end' && key !== 'age_start' && key !== 'height_start') { // Exclude 'age_end' and 'height_end' from being treated as separate filters
                        // appliedFilters.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${filttt[key]}`);
                        appliedFilters.push(`${key}: ${filttt[key]}`);
                    }
                }
            }



            // Count age as one filter if only one of age_start or age_end is set
            if (ageFilterSet && !(filttt.age_start !== "" && filttt.age_end !== "")) {
                appliedFilters.push("Age");
            }
            // Count height as one filter if only one of height_start or height_end is set
            if (heightFilterSet && !(filttt.height_start !== "" && filttt.height_end !== "")) {
                appliedFilters.push("Height");
            }
            let summary = `${appliedFilters.length} Filters Applied`;
            setSummary(summary);
            setFilArry(appliedFilters);
        }

    }, [filttt, data]);// Only re-run the effect if filttt changes

    const removeFilter = async (indexToRemove) => {
        const updatedFilters = [...filArry];

        const removedFilter = updatedFilters[indexToRemove].split(':')[0].trim();
        // const removedFilter = updatedFilters.filter((el,i)=>i!==indexToRemove)

        console.log('removedFilter', removedFilter);


        updatedFilters.splice(indexToRemove, 1);
        setFilArry(updatedFilters);

        // Recalculate summary
        const remainingFiltersCount = updatedFilters.length;
        const newSummary = `${remainingFiltersCount} Filters Applied`;
        setSummary(newSummary);

        const updatedFilttt = { ...filttt };

        switch (removedFilter) {
            case 'Age':
                updatedFilttt.age_start = "";
                updatedFilttt.age_end = "";
                break;
            case 'Height':
                updatedFilttt.height_start = "";
                updatedFilttt.height_end = "";
                break;
            case 'country':

                updatedFilttt.country = "";
                break;
            case 'state':
                updatedFilttt.state = "";
                break;
            case 'city':
                updatedFilttt.city = "";
                break;
            case 'income':
                updatedFilttt.income = "";
                break;
            case 'language':

                updatedFilttt.language = "";
                break;
            case 'education':

                updatedFilttt.education = "";
                break;
            case 'religion':

                updatedFilttt.religion = "";
                break;
            case 'community':

                updatedFilttt.community = "";
                break;
            case 'manglik':
                updatedFilttt.manglik = "";
                break;
            case 'initiation':
                updatedFilttt.initiation = "";
                break;
            case 'affiliation':
                updatedFilttt.affiliation = "";
                break;
            case 'marital_status':
                updatedFilttt.marital_status = "";
                break;
            case 'food':
                updatedFilttt.food = "";
                break;
            case 'occupation':
                updatedFilttt.occupation = "";
                break;
            case 'personality':
                updatedFilttt.personality = "";
                break;
            // Add cases for other filters if needed
            default:
                // No specific action needed for other filters
                break;
        }


        await dispatch(saveSearchFilters(updatedFilttt));
        await saveUodatedFilter(updatedFilttt, async (res, err) => {
            if (res != null) {
                await dispatch(saveSearchResults(res));
            } else { alert(err) }
        });
    };

    const saveUodatedFilter = async (updatedFilttt, callBack) => {
        let formData = new FormData();
        formData.append('age_start', updatedFilttt.age_start);
        formData.append('age_end', updatedFilttt.age_end);
        formData.append('height_start', updatedFilttt.height_start);
        formData.append('height_end', updatedFilttt.height_end);
        formData.append('language', updatedFilttt.language);
        formData.append('education', updatedFilttt.education);
        formData.append('income', updatedFilttt.income);
        formData.append('religion', updatedFilttt.religion);
        formData.append('community', updatedFilttt.community);
        formData.append('manglik', updatedFilttt.manglik);
        formData.append('initiation', updatedFilttt.initiation);
        formData.append('affiliation', updatedFilttt.affiliation);
        formData.append('marital_status', updatedFilttt.marital_status);
        formData.append('food', updatedFilttt.food);
        formData.append('occupation', updatedFilttt.occupation);
        formData.append('country', updatedFilttt.country);
        formData.append('state', updatedFilttt.state);
        formData.append('city', updatedFilttt.city);
        formData.append('personality', updatedFilttt.personality);
        formData.append('uid', auth._id);
        let res = await dispatch(FilterSearch(formData));
        if (res) {
            console.log('res', res.payload.data.data);
            callBack(res.payload.data.data, null);
            // saveSearchResults(res.payload.data.data);
        } else {
            callBack(null, "Something went wrong!")
        }

    }


    const goto = (id) => {
        navigate("/MyMatches/ViewProfile/" + id);
    }

    const ShortlistUser = (id) => {
        let data = { shortlist_By: auth._id, shortlist_id: id };
        dispatch(ShortList(data));
    }


    const SendInterest = (id) => {
        let data = { sent_by: auth._id, sent_to: id };
        dispatch(InterestSend(data));

    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clacDash());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clacDash());


        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
        }
    }

    return (
        <>
            <BodyColor />
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <Header />
                    <section id="match_dashboard">
                        {summry !== "" && (
                            <div class="filter_select">
                                <ul>
                                    <li>{summry}</li>
                                    {filArry.length > 0 && (
                                        <>
                                            {filArry.map((item, index) => (
                                                <li key={index}>
                                                    {item}<i onClick={() => removeFilter(index)} class="bi bi-x-lg"></i>

                                                </li>
                                            ))}

                                        </>
                                    )}
                                </ul>
                            </div>
                        )}

                        {/* <div class="filter_select">
                            <ul>
                              
                                    <li>3 Filters Applied</li>
                                    <li>Age: 24 - 29 Yrs.<i class="bi bi-x-lg"></i></li>

                                    <li>Hindi + 2 More<i class="bi bi-x-lg"></i></li>
                                
                            </ul>
                        </div> */}
                        <div class="row">
                            {data && data.length > 0 ? data.map((item, i) => (
                                <div class="col-xl-4 col-sm-6 mb-3" >
                                    <div class="box">
                                        <div onClick={() => goto(item._id)}>
                                            <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            data-bs-target="#carouselExampleIndicators2"
                                                            data-bs-slide-to={index}
                                                            className={index === 0 ? 'active' : ''}
                                                            aria-current={index === 0 ? 'true' : 'false'}
                                                            aria-label={`Slide ${index + 1}`}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="carousel-inner">
                                                    {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                                             <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl} />
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                            <h2 >{item.name}<img src="images/verify.png" alt="" /></h2>
                                            <h3>Profile Match %
                                                <div style={{ width: 40, height: 20 }}>
                                                    <CircularProgressbar value={item.OverAll} text={`${item.OverAll}%`}
                                                    />
                                                </div>
                                            </h3>
                                            <h4><img src="images/k1.png" alt="" />Profile managed by Self</h4>
                                            <h4><img src="images/k2.png" alt="" />Mother Tongue is {item.Mother_tongue}</h4>
                                            <h4><img src="images/k3.png" alt="" />{item.city}, {item.country}</h4>
                                            <h4><img src="images/k4.png" alt="" />{item.income}</h4>
                                            <h4><img src="images/k5.png" alt="" />{item.Marital_status}</h4>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            {item.InterestSent === true ? <a class="btn" >Interest Sent</a> : <a class="btn" onClick={() => SendInterest(item._id)}>Send Interest</a>}
                                            {item.shortlisted === true ? <a class="btn shortlist" >Shortlisted</a> : <a class="btn shortlist" onClick={() => ShortlistUser(item._id)}>Shortlist</a>}
                                        </div>
                                    </div>
                                </div>
                            )) : <p>No Matches found.</p>}
                            {/* <div className="common_paging">
                                <div className="d-flex">
                                    <button onClick={prevPage} disabled={page === 1}>Previous</button>
                                    <span>{page}</span>
                                    <button onClick={nextPage}>Next</button>
                                </div>
                                <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div> */}
                        </div>
                    </section>
                </div>
            </section>
        </>

    )

}