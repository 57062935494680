import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function SpritualityMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {


    }, []);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Spirituality</h2>
                            <div class="row">

                                <div class="col-6">
                                    <h3>Spiritual Organisation Affiliation</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.affiliated_with_spiritual_organization}</p>
                                </div>

                                <div class="col-6">
                                    <h3>How Long? </h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.affiliated_duration ? user1.spirituals.affiliated_duration : "-"}</p>
                                </div>

                                <div class="col-12">
                                    <h3>Daily time for spiritual practice</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.sadhanaTime ? user1.spirituals.sadhanaTime : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>How long in KC Practice? </h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.practicing_time_period ? user1.spirituals.practicing_time_period : "-"}</p>

                                </div>

                                <div class="col-6">
                                    <h3>Affiliated to which local temple</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.temple_associated_type ? user1.spirituals.temple_associated_type : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Spirituality’s role in your life? </h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.spirituality_role ? user1.spirituals.spirituality_role : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Chanting Rounds</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.chanting_rounds ? user1.spirituals.chanting_rounds : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Are you initaited? </h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.initiated ? user1.spirituals.initiated : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Spiritual Master</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.spiritual_master ? user1.spirituals.spiritual_master : "-"}</p>
                                </div>

                                <div class="col-12">
                                    <h3>Do you follow Four Regulative Principles</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.four_Regulative_Principles ? user1.spirituals.four_Regulative_Principles : "-"}</p>
                                </div>

                                <div class="col-12">
                                    <h3>Do your parents practice Krishna Consciousness?</h3>
                                    <p>{user1 && user1.spirituals && user1.spirituals.parents_practice_Krishna ? user1.spirituals.parents_practice_Krishna : "-"}</p>
                                </div>

                            </div>


                        </div>

                    </div>

                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Spirituality</h2>
                            <div class="row">

                                <div class="col-6">
                                    <h3>Spiritual Organisation Affiliation</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.affiliated_with_spiritual_organization}</p>

                                </div>

                                <div class="col-6">
                                    <h3>How Long? </h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.affiliated_duration ? user2.spirituals.affiliated_duration : "-"}</p>
                                </div>

                                <div class="col-12">
                                    <h3>Daily time for spiritual practice</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.sadhanaTime ? user2.spirituals.sadhanaTime : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>How long in KC Practice? </h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.practicing_time_period ? user2.spirituals.practicing_time_period : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Affiliated to which local temple</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.temple_associated_type ? user2.spirituals.temple_associated_type : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Spirituality’s role in your life? </h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.spirituality_role ? user2.spirituals.spirituality_role : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Chanting Rounds</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.chanting_rounds ? user2.spirituals.chanting_rounds : "-"}</p>
                                </div>

                                <div class="col-6">
                                    <h3>Are you initaited? </h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.initiated ? user2.spirituals.initiated : "-"}</p>

                                </div>

                                <div class="col-6">
                                    <h3>Spiritual Master</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.spiritual_master ? user2.spirituals.spiritual_master : "-"}</p>

                                </div>

                                <div class="col-12">
                                    <h3>Do you follow Four Regulative Principles</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.four_Regulative_Principles ? user2.spirituals.four_Regulative_Principles : "-"}</p>
                                </div>

                                <div class="col-12">
                                    <h3>Do your parents practice Krishna Consciousness?</h3>
                                    <p>{user2 && user2.spirituals && user2.spirituals.parents_practice_Krishna ? user2.spirituals.parents_practice_Krishna : "-"}</p>
                                </div>

                            </div>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}