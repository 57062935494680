import UserDashboard from "../Components/Pages/UserDashboard";
import MyMatches from "../Components/Pages/UserDashboard/Dashboard";
import UserStepForms from "../Components/Pages/UserStepForms";
import PersonalityQuestions from "../Components/Pages/PersonalityQuestions";
import AddAbout from "../Components/Pages/AddAbout";
import UploadGallery from "../Components/Pages/UploadGallery";
import SelfiVerify from "../Components/Pages/SelfiVerify";
import GovtVerify from "../Components/Pages/GovtVerify";
import PrivacyStep from "../Components/Pages/PrivacyStep";
import FinalStep from "../Components/Pages/FinalStep";
import ChoicesSelection from "../Components/Pages/ChoicesSelection";
import PricingPlans from "../Components/Pages/PricingPlans";
import MyProfile from "../Components/Pages/UserDashboard/MyProfile";
import Main from "../Components/Pages/UserDashboard/Main";
import Recommended from "../Components/Pages/UserDashboard/Recommended";
import Messages from "../Components/Pages/UserDashboard/Messages";
import Requests from "../Components/Pages/UserDashboard/Requests";
import ViewProfile from "../Components/Pages/UserDashboard/ViewProfile";
import AcceptedMatches from "../Components/Pages/UserDashboard/AcceptedMatches";
import Plans from "../Components/Pages/UserDashboard/Plans";
import FilterResults from "../Components/Pages/UserDashboard/FilterResults";
import AccountSettings from "../Components/Pages/UserDashboard/AccountSettings";
import Support from "../Components/Pages/UserDashboard/Support";
import SearchResults from "../Components/Pages/UserDashboard/SearchResults";
import OrderSummary from "../Components/Pages/OrderSummary";

export const userPrivateRoutes = [

    {
        path: "/UserDashboard",
        component: <UserDashboard />,
    },
    {
        path: "/Dashboard",
        component: <Main />,
    },
    {
        path: "/MyMatches",
        component: <MyMatches />,
    },
    {
        path: "/MyProfile",
        component: <MyProfile />,
    },
    {
        path: "/Recommended",
        component: <Recommended />,
    },
    {
        path: "/Messages",
        component: <Messages />,
    },
    {
        path: "/Requests",
        component: <Requests />,
    },
    {
        path: "/AcceptedMatches",
        component: <AcceptedMatches />,
    },
    {
        path: "/Plans",
        component: <Plans />,
    },
    {
        path: "/UserStepForms",
        component: <UserStepForms />,
    },
    {
        path: "/MyMatches/ViewProfile/:id",
        component: <ViewProfile />,
    },
    {
        path: "/PersonalityQuestions",
        component: <PersonalityQuestions />,
    },
    {
        path: "/AboutPersonality",
        component: <AddAbout />,
    },
    {
        path: "/UploadGallery",
        component: <UploadGallery />,
    },
    {
        path: "/SelfiVerification",
        component: <SelfiVerify />,
    },
    {
        path: "/IdVerification",
        component: <GovtVerify />,
    },
    {
        path: "/PrivacySetting",
        component: <PrivacyStep />,
    },
    {
        path: "/FinalStep",
        component: <FinalStep />,
    },
    {
        path: "/MatchmakingSelection",
        component: <ChoicesSelection />,
    },
    {
        path: "/SelectPlans",
        component: <PricingPlans />,
    },
    {
        path: "/FilterResults",
        component: <FilterResults />,
    },
    {
        path: "/SearchResults",
        component: <SearchResults />,
    },
    {
        path: "/AccountSettings",
        component: <AccountSettings />,
    },
    {
        path: "/Support",
        component: <Support />,
    },
    {
        path: "/OrderSummary/:plan",
        component: <OrderSummary />,
    },
];

export default userPrivateRoutes;