import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab5({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "Spirituality" };
        // dispatch(ViewProfileTabs(data));

    }, []);

    return (
        <>

            <div class="set">
                {data && data.privacysetups && data.privacysetups.spritual_privacy === "Everyone" &&
                    <div class="row">

                        <div class="col-lg-4 col-sm-6">
                            <h4>Spiritual Organisation Affiliation</h4>
                            <p>{data && data.spirituals && data.spirituals.affiliated_with_spiritual_organization ? data.spirituals.affiliated_with_spiritual_organization : "-"}</p>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <h4>How Long? </h4>
                            <p>{data && data.spirituals && data.spirituals.affiliated_duration ? data.spirituals.affiliated_duration : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How much time you spend daily on your spiritual sadhana?</h4>
                            <p>{data && data.spirituals && data.spirituals.sadhanaTime ? data.spirituals.sadhanaTime : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Since when have you been practicing Krishna Consciousness?</h4>
                            <p>{data && data.spirituals && data.spirituals.practicing_time_period ? data.spirituals.practicing_time_period : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Chanting rounds?</h4>
                            <p>{data && data.spirituals && data.spirituals.chanting_rounds ? data.spirituals.chanting_rounds : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How often do you visit temple?</h4>
                            <p>{data && data.spirituals && data.spirituals.temple_visit ? data.spirituals.temple_visit : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Spirituality's role in your life?</h4>
                            <p>{data && data.spirituals && data.spirituals.spirituality_role ? data.spirituals.spirituality_role : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Who is your spiritual master?</h4>
                            <p>{data && data.spirituals && data.spirituals.spiritual_master ? data.spirituals.spiritual_master : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you initiated?</h4>
                            <p>{data && data.spirituals && data.spirituals.initiated ? data.spirituals.initiated : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you initiated?</h4>
                            <p>{data && data.spirituals && data.spirituals.Initiation_name ? data.spirituals.Initiation_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you follow Four Regulative Principles
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.four_Regulative_Principles ? data.spirituals.four_Regulative_Principles : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do your parents practice Krishna Consciousness?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.parents_practice_Krishna ? data.spirituals.parents_practice_Krishna : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>List of Prabhupad's book read by you.</h4>
                            {data && data.spirituals && data.spirituals.selectedBooks && data.spirituals.selectedBooks.length > 0 ?
                                <ul>
                                    {data.spirituals.selectedBooks.map((item, i) => (
                                        <li>{item.value}</li>
                                    ))}

                                </ul>
                                : "-"}
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What Srila Prabhupada book are you currently reading?

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.currently_reading ? data.spirituals.currently_reading : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you observe fasting on Ekadashi days?*

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.ekadashi_fasting ? data.spirituals.ekadashi_fasting : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Which ISKCON/HKM Centre are you associated with?*

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.iskcon_associted_with ? data.spirituals.iskcon_associted_with : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How are you associated with the temple?

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.temple_associated_type ? data.spirituals.temple_associated_type : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.attended_seminar ? data.spirituals.attended_seminar : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you open to engaging in devotional services or seva together as a couple?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.engaged_devotional_services ? data.spirituals.engaged_devotional_services : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How important is living in close proximity to a temple or devotional community for you?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.living_in_temple ? data.spirituals.living_in_temple : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you open to further deepening your understanding and practice of Krishna Consciousness after marriage?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.Krishna_Consciousness_after_marriage ? data.spirituals.Krishna_Consciousness_after_marriage : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What is your understanding and acceptance of the principles and teachings of Srila Prabhupada?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.tech_of_Srila_Prabhupada ? data.spirituals.tech_of_Srila_Prabhupada : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you envision supporting and encouraging each other's spiritual growth within the marriage?*
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.spritulGrowthMarriage ? data.spirituals.spritulGrowthMarriage : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you envision incorporating Krishna Consciousness into your married life?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.incorporating_Krishna_Consciousness ? data.spirituals.incorporating_Krishna_Consciousness : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you handle challenges or conflicts in your spiritual journey?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.conflicts_spiritualJourny ? data.spirituals.conflicts_spiritualJourny : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you have any specific spiritual goals or
                                aspirations that you would like to share?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.specific_spiritual_goals ? data.spirituals.specific_spiritual_goals : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you have a spiritual mentor or guide? If yes, please provide
                                details?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.spiritual_mentor ? data.spirituals.spiritual_mentor : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What temple service are you engaged in?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.temple_services ? data.spirituals.temple_services : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Department Name?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.department_name ? data.spirituals.department_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Designation?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.designation_name ? data.spirituals.designation_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What are the various roles and experiences you have had in your
                                service at the temple?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.roles_and_experiences ? data.spirituals.roles_and_experiences : "-"}</p>
                        </div>
                    </div>
                }
                {data && data.privacysetups && data.privacysetups.spritual_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="row">

                        <div class="col-lg-4 col-sm-6">
                            <h4>Spiritual Organisation Affiliation</h4>
                            <p>{data && data.spirituals && data.spirituals.affiliated_with_spiritual_organization ? data.spirituals.affiliated_with_spiritual_organization : "-"}</p>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <h4>How Long? </h4>
                            <p>{data && data.spirituals && data.spirituals.affiliated_duration ? data.spirituals.affiliated_duration : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How much time you spend daily on your spiritual sadhana?</h4>
                            <p>{data && data.spirituals && data.spirituals.sadhanaTime ? data.spirituals.sadhanaTime : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Since when have you been practicing Krishna Consciousness?</h4>
                            <p>{data && data.spirituals && data.spirituals.practicing_time_period ? data.spirituals.practicing_time_period : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Chanting rounds?</h4>
                            <p>{data && data.spirituals && data.spirituals.chanting_rounds ? data.spirituals.chanting_rounds : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How often do you visit temple?</h4>
                            <p>{data && data.spirituals && data.spirituals.temple_visit ? data.spirituals.temple_visit : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Spirituality's role in your life?</h4>
                            <p>{data && data.spirituals && data.spirituals.spirituality_role ? data.spirituals.spirituality_role : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Who is your spiritual master?</h4>
                            <p>{data && data.spirituals && data.spirituals.spiritual_master ? data.spirituals.spiritual_master : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you initiated?</h4>
                            <p>{data && data.spirituals && data.spirituals.initiated ? data.spirituals.initiated : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you initiated?</h4>
                            <p>{data && data.spirituals && data.spirituals.Initiation_name ? data.spirituals.Initiation_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you follow Four Regulative Principles
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.four_Regulative_Principles ? data.spirituals.four_Regulative_Principles : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do your parents practice Krishna Consciousness?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.parents_practice_Krishna ? data.spirituals.parents_practice_Krishna : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>List of Prabhupad's book read by you.</h4>
                            {data && data.spirituals && data.spirituals.selectedBooks && data.spirituals.selectedBooks.length > 0 ?
                                <ul>
                                    {data.spirituals.selectedBooks.map((item, i) => (
                                        <li>{item.value}</li>
                                    ))}

                                </ul>
                                : "-"}
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What Srila Prabhupada book are you currently reading?

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.currently_reading ? data.spirituals.currently_reading : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you observe fasting on Ekadashi days?*

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.ekadashi_fasting ? data.spirituals.ekadashi_fasting : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Which ISKCON/HKM Centre are you associated with?*

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.iskcon_associted_with ? data.spirituals.iskcon_associted_with : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How are you associated with the temple?

                            </h4>
                            <p>{data && data.spirituals && data.spirituals.temple_associated_type ? data.spirituals.temple_associated_type : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.attended_seminar ? data.spirituals.attended_seminar : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you open to engaging in devotional services or seva together as a couple?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.engaged_devotional_services ? data.spirituals.engaged_devotional_services : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How important is living in close proximity to a temple or devotional community for you?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.living_in_temple ? data.spirituals.living_in_temple : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Are you open to further deepening your understanding and practice of Krishna Consciousness after marriage?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.Krishna_Consciousness_after_marriage ? data.spirituals.Krishna_Consciousness_after_marriage : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What is your understanding and acceptance of the principles and teachings of Srila Prabhupada?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.tech_of_Srila_Prabhupada ? data.spirituals.tech_of_Srila_Prabhupada : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you envision supporting and encouraging each other's spiritual growth within the marriage?*
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.spritulGrowthMarriage ? data.spirituals.spritulGrowthMarriage : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you envision incorporating Krishna Consciousness into your married life?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.incorporating_Krishna_Consciousness ? data.spirituals.incorporating_Krishna_Consciousness : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>How do you handle challenges or conflicts in your spiritual journey?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.conflicts_spiritualJourny ? data.spirituals.conflicts_spiritualJourny : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you have any specific spiritual goals or
                                aspirations that you would like to share?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.specific_spiritual_goals ? data.spirituals.specific_spiritual_goals : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Do you have a spiritual mentor or guide? If yes, please provide
                                details?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.spiritual_mentor ? data.spirituals.spiritual_mentor : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What temple service are you engaged in?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.temple_services ? data.spirituals.temple_services : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Department Name?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.department_name ? data.spirituals.department_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>Designation?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.designation_name ? data.spirituals.designation_name : "-"}</p>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <h4>What are the various roles and experiences you have had in your
                                service at the temple?
                            </h4>
                            <p>{data && data.spirituals && data.spirituals.roles_and_experiences ? data.spirituals.roles_and_experiences : "-"}</p>
                        </div>
                    </div>
                }
            </div>


        </>
    )

}