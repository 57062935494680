import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";

export default function Privacy() {

    return (
        <>
            <section id="privacy_matters">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-7 col-sm-8">
                            <h1>Your <span>Privacy</span> <label>Matters.</label> </h1>
                            <h2>Rest assured, your counseling sessions with Vedic Matrimony are strictly confidential. We
                                prioritize the security of your personal journey towards a harmonious marriage. Trust us to
                                provide a discreet and supportive environment.</h2>
                        </div>

                        <div class="col-md-5 col-sm-4">
                            <div class="privacy_matters_img">
                                <img src="images/tab4.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}