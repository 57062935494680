import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddGovtIds, GetGovtIds } from '../api';


export const SaveGovtIds = createAsyncThunk('SaveGovtIds', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddGovtIds(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetUploadedGovtIds = createAsyncThunk('GetGovtIds', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetGovtIds(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const govtIdSlice = createSlice({
  name: 'govtId',
  initialState: {
    data: null,
    success: false,
    error: null,

  },
  reducers: {

    clearGvt: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(SaveGovtIds.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveGovtIds.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveGovtIds.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetUploadedGovtIds.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetUploadedGovtIds.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetUploadedGovtIds.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearGvt } = govtIdSlice.actions;

export default govtIdSlice.reducer;

