import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { occupationssNam } from "../../Data/content";

export default function
    OccupationFilter({ data, handleChange }) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Preferred Occupation</label>
                            <select class="form-select" aria-label="Default select example" name="occupation" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {occupationssNam.length > 0 && occupationssNam.map((item, i) => (
                                    <option selected={data && data.occupation === item} value={item} >{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}