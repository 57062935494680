import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, makerSidebar } from "../../Data/content";
import { clearUser } from "../../../../Reducer/authSlice";
import { MakerDetail } from "../../../../Reducer/MakerDashboardSlices/makerSlice";
import { GetNotiMaker, ReadAllMaker } from "../../../../Reducer/MakerDashboardSlices/notiSlice";

export default function Sidebar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.maker.data);
    const dataNoti = useSelector(state => state.makerNoti.data);
    const count = useSelector(state => state.makerNoti.count);

    // console.log('dataNoti', dataNoti);

    useEffect(() => {
        getData();
        getNotifications();

        const intervalId = setInterval(getNotifications, 300000); // Call every 5 minutes (300000 ms)

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const getData = () => {
        let dat = { uid: auth._id };
        dispatch(MakerDetail(dat));
    }

    function getNotifications() {
        let dataaa = { uid: auth._id };
        dispatch(GetNotiMaker(dataaa));
    }


    const Logout = () => {
        dispatch(clearUser());
        setTimeout(() => {
            navigate("/Login");
        }, 2000);

    }


    const readNoti = () => {
        let dataaa = { uid: auth._id };
        dispatch(ReadAllMaker(dataaa));
    }

    function openComponent(name, link) {
        navigate(link);
    }
    return (
        <>

            <div class="side_bar match_dash">
                <div class="logo closed_bar">
                    {/* <img class="show" src="/images/Logo.png" alt="" /> */}
                    <img src="/images/favicon.png" alt="" />
                    {/* <!-- <span class="closed_bar"><i class="bi bi-list"></i></span> --> */}
                </div>
                <div class="profile_img">
                    <img src="/images/beads.png" alt="" />
                    <h3>{data && data.basic && data.basic.name}</h3>
                    <p>{data && data.basic && data.basic.Iskon_center}</p>
                </div>


                <ul class="mt-5">


                    {makerSidebar.map((item, i) => (
                        <>
                            {item.name == "Notification Center" ? <li><a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                aria-controls="offcanvasExample"><img src="/images/Notification.png" />
                                <label>Notification Center </label>{count > 0 && <span>{count}</span>}</a>
                                {/*  */}
                            </li> : <li key={i} onClick={() => openComponent(item.name, item.link)}><a className={location.pathname === item.link ? 'active' : ''}><img src={item.icon} />
                                <label>{item.name}</label></a>
                            </li>}

                        </>
                    ))}
                </ul>
                <div class="sign_out_btn" onClick={() => Logout()}>
                    <a><i class="bi bi-box-arrow-right"></i><label>Sign Out</label></a>
                </div>
            </div>

            <div class="offcanvas offcanvas-start notification_sec" tabindex="-1" id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img src="images/Notification-2.png"
                        alt="" />Notifications</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <a class="mark_read" onClick={() => readNoti()}>Mark all as Read</a>
                    {dataNoti && Object.keys(dataNoti).length > 0 && Object.keys(dataNoti).map(category => (
                        <div key={category}>
                            <h2>{category}</h2>
                            <ul>
                                {dataNoti[category].map((item, i) => (
                                    <li className={item.read === false && "active"} key={item._id}>
                                        <span class="img"><img src={API_BASE_URL + item.profileImage} /></span>
                                        <span class="title">{item.title}</span>
                                        {item.name} {item.description}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}


                </div>
            </div>
        </>
    )

}