import React, { Component, useEffect, useState } from "react";


export default function Family(props) {

    useEffect(() => {

    }, []);


    return (
        <>


            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="box">
                    <h2><img src="/images/pro-1.png" alt="" />Family and Culture</h2>
                    <div class="row">

                        <div class="col-sm-6">
                            <h3>Father’s Name</h3>
                            <p>{props.data && props.data.families && props.data.families.father_name}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Mother’s Name</h3>
                            <p>{props.data && props.data.families && props.data.families.mother_name}</p>
                        </div>

                        <div class="col-12">
                            <h3>Father’s Occupation</h3>
                            <p>{props.data && props.data.families && props.data.families.father_occcupation}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Mother’s Occupation</h3>
                            <p>{props.data && props.data.families && props.data.families.mother_occcupation}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>No. of Siblings</h3>
                            <p>2</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>No. of Brothers</h3>
                            <p>{props.data && props.data.families && props.data.families.brother_count}</p>
                        </div>

                        {/* <div class="col-sm-6">
                            <h3>Brother’s Marital Status</h3>
                            <p>1</p>
                        </div> */}

                        <div class="col-sm-6">
                            <h3>No. of Sisters</h3>
                            <p>{props.data && props.data.families && props.data.families.sister_count}</p>
                        </div>

                        <div class="col-sm-12">
                            <h3>Family Status</h3>
                            <p>{props.data && props.data.families && props.data.families.family_status}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Religion</h3>
                            <p>{props.data && props.data.families && props.data.astro.religion}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Caste</h3>

                            <p>{props.data && props.data.families && props.data.astro.caste}</p>

                        </div>

                    </div>
                </div>
            </div>


        </>
    )

}