import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";

export default function UserDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {

        getData();

    }, []);

    async function getData() {
        let datasss = { uid: auth._id };
        let res = await dispatch(UserDetail(datasss));
        console.log('daaaaa', res);
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            // if (responseData.completion_percentage === 100 && responseData.gallryVerified === true && responseData.govtVerified === true && responseData.selfiverified === true) {
            if (responseData.profile_status === "Approved") {
                navigate("/MyMatches");
            } else {
                navigate("/Dashboard");
            }
        }
    }



    return (
        <>


        </>
    )

}

