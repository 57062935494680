import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import About from "./About";
import Mission from "./Mission";
import Approach from "./Approach";
import Instructors from "../Tools/Instructors";
import ContactForm from "./ContactForm";
import { GetAboutPage } from "../../../Reducer/api";
import { AboutPage } from "../../../Reducer/apiSlice";

export default function AboutUs() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.api.data)

    useEffect(() => {
        dispatch(AboutPage());
    }, []);

    return (
        <>
            <Header />
            <About data={data && data.about}/>
            <Mission data={data && data.mission}/>
            <Approach data={data && data.approach}/>
            <Instructors />
            <ContactForm />
            <Footer />
        </>
    )

}