import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_BASE_URL } from "../../Data/content";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
export default function Resources(props) {

    useEffect(() => {


    }, []);


    const homeOptions = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 20,
        responsiveClass: true,
        responsive: {
            0: { items: 1, nav: false },
            576: { items: 1, nav: true },
            768: { items: 3.5, nav: true },
            992: { items: 3.5, nav: true },
            1200: { items: 3.5, nav: true }
        }
    };

    return (
        <>
            <section id="resources_sec">
                <div class="container pe-0">
                    <h1>Resources</h1>
                    {props.data &&

                        <>
                            <OwlCarousel className='owl-theme' {...homeOptions}>
                                {props.data.map((item, i) => (
                                    <div className='item'>
                                        <div class="resources_box">
                                            <div class="resources-img" style={{ backgroundImage: `url(${API_BASE_URL + item.image})` }} >
                                            </div>
                                            <h3>{item.title}</h3>
                                            <div>
                                            {ReactHtmlParser(item?.description?.length > 300 ? item?.description?.substring(0, 300) + '...' : item?.description)}
                                            </div>
                                            {/* <p>{item.description.length > 300 ? item.description.substring(0, 300) + '...' : item.description}</p> */}

                                            <h5><Link to={"/resource/" + item.url} target="_blank"class="btn">Read More <img src="images/Arrow.png" alt="" /></Link> <span>
                                                <a href={API_BASE_URL + item.pdf} target="_blank" Download><img src="images/pdf.png" alt="" /></a></span></h5>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                            <h2><a href="#">View All Resources</a></h2>
                        </>
                    }



                </div>
            </section >
        </>
    )

}