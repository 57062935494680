import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sidebar from "../Sidebar";
import Header from "../Header";
import AllAccepted from "./AllAccepted";
import MyAccepted from "./MyAccepted";
import RecommendedAccepted from "./RecommendedAccepted";
import { GetCounts } from "../../../../Reducer/UserDashboardSlices/dashboardSlice";


export default function AcceptedMatches() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const coundata = useSelector(state => state.dshbrd.countsData);
    const updated = useSelector(state => state.dshbrd.updated);
    const [Tabbs, setTabData] = useState([
        {
            name: "All Accepted Matches",
            Component: <AllAccepted />,
            count: 0
        },
        {
            name: "My Accepted Matches",
            Component: <MyAccepted />,
            count: 0
        },
        {
            name: "Recommended Accepted Matches",
            Component: <RecommendedAccepted />,
            count: 0
        }
    ]);


    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        getData();
    }, [updated]);

    const getData = () => {
        let dat = { uid: auth._id };
        dispatch(GetCounts(dat));
    }

    useEffect(() => {
        if (coundata) {
            const updatedTabs = Tabbs.map((tab) => {
                if (tab.name === "All Accepted Matches") {
                    return { ...tab, count: coundata.all_accepted };
                }
                if (tab.name === "My Accepted Matches") {
                    return { ...tab, count: coundata.myAccepted };
                }
                if (tab.name === "Recommended Accepted Matches") {
                    return { ...tab, count: coundata.recomAccepted };
                }
                return tab;
            });

            // Update the local state for tabData
            setTabData(updatedTabs);
        }
    }, [coundata]);



    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />

                    <section id="match_dashboard">
                        <Header />

                        <Tabs>
                            <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                {Tabbs.length > 0 && Tabbs.map((item, i) => (
                                    <Tab key={i}
                                        className={"nav-link" + (activeTab === i ? " active" : "")}
                                        onClick={() => handleTabClick(i)}
                                    >
                                        {item.name} ({item.count})
                                    </Tab>
                                ))}

                            </TabList>
                            {Tabbs.map((tem, index) => (
                                <TabPanel key={index} className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}>
                                    {tem.Component}
                                </TabPanel>
                            ))}
                        </Tabs>


                    </section>


                </div>
            </section>

        </>
    )

}