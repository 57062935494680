import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";


export default function Tab7() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);

    useEffect(() => {
        let dat = { uid: auth._id, type: "preference" };
        dispatch(GetTabsData(dat));
    }, []);

    console.log('atb pref', data);


    return (
        <div class="set">
            <div class="row">

                <div class="col-12">
                    <h5>Basic Preference</h5>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Marital Status</h4>
                    {data && data.prefered_marital_status && data.prefered_marital_status.length > 0 ? 
                    <ul>
                        {data.prefered_marital_status.map((item, i)=>(
                            <li>{item.value}</li>
                        ))}
                    </ul>

                    : "-"}
                </div>



                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Preferred Age</h4>
                    <p>{data && data.prefered_age_start ? data.prefered_age_start+"-"+data.prefered_age_end+" Yrs.": "-"} </p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Preferred Height</h4>
                    <p>{data && data.prefered_height_start ? data.prefered_height_start+"-"+data.prefered_height_end : "-"} </p>  
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Preferred Food</h4>
                    {data && data.prefered_food && data.prefered_food.length > 0 ? 
                    <ul>
                        {data.prefered_food.map((item, i)=>(
                            <li>{item.value}</li>
                        ))}
                    </ul>

                    : "-"}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Preferred Language</h4>
                    {data && data.prefered_language && data.prefered_language.length > 0 ? 
                    <ul>
                        {data.prefered_language.map((item, i)=>(
                            <li>{item.value}</li>
                        ))}
                    </ul>

                    : "-"}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Preferred Affiliation</h4>
                    {data && data.prefered_affiliation && data.prefered_affiliation.length > 0 ? 
                    <ul>
                        {data.prefered_affiliation.map((item, i)=>(
                            <li>{item.value}</li>
                        ))}
                    </ul>

                    : "-"}
                </div>

                <div class="col-12">
                    <h5>Community Preferences</h5>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Religion</h4>
                    <p>{data && data.prefered_religion ? data.prefered_religion : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Caste</h4>
                    <p>{data && data.prefered_caste ? data.prefered_caste : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Community</h4>
                    <p>{data && data.prefered_community ? data.prefered_community : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Cultural Values</h4>
                    <p>{data && data.prefered_cultr_val ? data.prefered_cultr_val :"-"}</p>
                </div>

                <div class="col-12">
                    <h5>Location Preferences</h5>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>Country</h4>
                    <p>{data && data.prefered_country ? data.prefered_country : "-"}</p>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>State</h4>
                    <p>{data && data.prefered_state ? data.prefered_state : "-"}</p>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>City / Location</h4>
                    <p>{data && data.prefered_city ? data.prefered_city : "-"}</p>
                </div>

                <div class="col-12">
                    <h5>Career Preferences</h5>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>Qualification</h4>
                    <p>{data && data.prefered_qualification ? data.prefered_qualification : "-"}</p>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>Professional Background</h4>
                    <p>{data && data.prefered_Proffessional_bkgrd ? data.prefered_Proffessional_bkgrd :"-"}</p>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <h4>Annual Income</h4>
                    <p>{data && data.prefered_income ? data.prefered_income :"-"}</p>
                </div>



            </div>
        </div>
    )
}