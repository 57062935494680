import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "./Sidebar";
import Basic from "./Basic";
import Astro from "./Astro";
import Family from "./Family";
import Career from "./Career";
import Spiritual from "./Spiritual";
import Preferences from "./Preferences";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";


export default function UserStepForms() {
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector(state => state.auth.auth);
    const [ele, setElement] = useState("");
    const [completed, setCompleted] = useState(0);
    useEffect(() => {
        const data = location.state;
        console.log('data elemnet', data);
        if (data && data.name && data.name !== ele) {
            setElement(data.name);
        } else {
            setElement("Basic");
        }

        getData();

    }, [location.state]);


    async function getData() {
        let datasss = { uid: auth._id };
        let res = await dispatch(UserDetail(datasss));
        console.log('daaaaa', res);
        if (res.payload && res.payload.data.data) {
            // setData(res.payload.data.data);
            if (res.payload.data.data.completion_percentage) {
                setCompleted(res.payload.data.data.completion_percentage);
            }
        }
    }




    return (
        <>

            <section id="steps_form">
                <div class="container-fluid">
                    <div class="row">
                        <Sidebar data={ele} completed={completed} />

                        <div class="col-lg-9 ps-lg-0">
                            <div class="box-two">
                                {ele && ele === "Basic" &&
                                    <Basic />
                                }
                                {ele && ele === "Astro" &&
                                    <Astro />
                                }
                                {ele && ele === "Family" &&
                                    <Family />
                                }
                                {ele === "Career" &&
                                    <Career />
                                }
                                {ele === "Spiritual" &&
                                    <Spiritual />
                                }
                                {ele === "Preferences" &&
                                    <Preferences />
                                }


                            </div>
                        </div>
                    </div>


                </div>
            </section>

        </>
    )

}