import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { UsersTabsMaker } from "../../../Data/content";
import Personal from "./Personal";
import Contact from "./Contact";
import Family from "./Family";
import Personality from "./Personality";
import Partner from "./Partner";
import Spritual from "./Spritual";
import Carrer from "./Carrer";
import { GetUsrSideData } from "../../../../../Reducer/MakerDashboardSlices/singleFTMSlice";

export default function SideBasic() {
    const dispatch = useDispatch();
    const params = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.singleFtm.data);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

console.log('dataaaaa', data);

    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id };
        dispatch(GetUsrSideData(data));

    }, []);


    return (
        <>

            <div class="col-xl-5 mt-3">
                <div class="set">
                    <div class="monk_sec">
                        <img src="/images/monk.png" alt="" />
                        <h3>{data && data.name}</h3>
                        <p>Year of Joining: <b>{(new Date(data && data.created_at)).getFullYear()}</b></p>
                        <p>DOB: <b> {data && data.astro && data.astro.dob}</b></p>
                    </div>

                    <Tabs>
                        <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                            {UsersTabsMaker.length > 0 && UsersTabsMaker.map((item, i) => (
                                <Tab key={i}
                                    className={"nav-link" + (activeTab === i ? " active" : "")}
                                    onClick={() => handleTabClick(i)}
                                >
                                    {item}
                                </Tab>
                            ))}

                        </TabList>
                        {UsersTabsMaker.map((tem, index) => (
                            <>
                                <TabPanel>
                                    <Personal data={data && data}/>
                                </TabPanel>
                                <TabPanel >
                                    <Contact data={data && data}/>
                                </TabPanel>
                                <TabPanel>
                                    <Family data={data && data}/>
                                </TabPanel>
                                <TabPanel>
                                    <Spritual data={data && data}/>
                                </TabPanel>
                                <TabPanel>
                                    <Personality data={data && data}/>
                                </TabPanel>
                                <TabPanel>
                                    <Partner data={data && data}/>
                                </TabPanel>
                                <TabPanel>
                                    <Carrer data={data && data}/>
                                </TabPanel>
                            </>

                        ))}
                    </Tabs>

                </div>
            </div>

        </>
    )

}