import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Marital } from "../../Data/content";

export default function MaritalStatus({ data, handleChange }) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Marital Status</label>
                            <select class="form-select" aria-label="Default select example" name="marital_status" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {Marital.length > 0 && Marital.map((rel, i) => (
                                    <option selected={data && data.religion === rel} value={rel}>{rel}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}