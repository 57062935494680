import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Locations() {

    return (

        <section id="our_location">
            <div class="container">
                <h1>Our Venue Locations</h1>
                <h2>Select a Location to view Venues</h2>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true"><img class="show" src="images/temple.png" /><img class="hide"
                                src="images/temple-white.png" />Bangalore</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false"><img class="show" src="images/temple.png" /><img class="hide"
                                src="images/temple-white.png" />Vrindavana</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                            aria-selected="false"><img class="show" src="images/temple.png" /><img class="hide"
                                src="images/temple-white.png" />Jaipur</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-mysore-tab" data-bs-toggle="pill" data-bs-target="#pills-mysore"
                            type="button" role="tab" aria-controls="pills-mysore" aria-selected="false"><img class="show"
                                src="images/temple.png" /><img class="hide" src="images/temple-white.png" />Mysore</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-hyderabad-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-hyderabad" type="button" role="tab" aria-controls="pills-hyderabad"
                            aria-selected="false"><img class="show" src="images/temple.png" /><img class="hide"
                                src="images/temple-white.png" />Hyderabad</button>
                    </li>

                    <li class="show_venue"><a data-bs-toggle="modal" data-bs-target="#exampleModal"
                        class="btn show_venue">Show Venue Locations <img src="images/Arrow.png" alt="" /></a></li>

                </ul>

                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                        <div class="location_box">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Dwarkapuri Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>

                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Mathura Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Thirupati Thirumala Devasthanam</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="location_box">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Vrindavan Chandrodaya Mandir</h3>
                                        <h4>VCM, Chatikera, Vrindavana</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div class="location_box">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Dwarkapuri Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Mathura Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Thirupati Thirumala Devasthanam</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-mysore" role="tabpanel" aria-labelledby="pills-mysore-tab">
                        <div class="location_box">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Dwarkapuri Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-hyderabad" role="tabpanel" aria-labelledby="pills-hyderabad-tab">
                        <div class="location_box">
                            <div class="row">

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Dwarkapuri Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Mathura Hall</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6">
                                    <div class="set">
                                        <div class="set_img" style={{ backgroundImage: `url(${"images/location-img.png"})` }}></div>
                                        <h3>Thirupati Thirumala Devasthanam</h3>
                                        <h4>ISKCON Bangalore</h4>
                                        <h5><span><img src="images/Seats.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Calendar.png" />720 Banquet Chairs</span></h5>
                                        <h5><span><img src="images/Arrow-gray.png" />720 Banquet Chairs</span> <span><img
                                            src="images/Time.png" />720 Banquet Chairs</span></h5>
                                        <a href="wedding_venue.html" class="btn">View Venue <img src="images/Arrow.png"
                                            alt="" /></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>

    )
}
