import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AddAboutYourself, GetAboutYourself, clearAbt } from "../../../Reducer/StepForms/aboutSlice";
import Swal from 'sweetalert2';


export default function AddAbout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [data, setData] = useState({});
    const [errors, setFormErrors] = useState({});
    const success = useSelector(state => state.abt.success);
    const error = useSelector(state => state.abt.error);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearAbt());
        } else {
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            dispatch(clearAbt());
            navigate("/UploadGallery");
            // setTimeout(() => {
            //     navigate("/UploadGallery");
            // }, 2000);
        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearAbt());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearAbt());

        }
    }

    useEffect(() => {

        getData();
    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetAboutYourself(dat));
        if (res.payload && res.payload.data.data) {
            setData(res.payload.data.data);

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let values = validate(data);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;
        }
        if (chk) {

            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('about', data.about);
            dispatch(AddAboutYourself(formData));

        } else {
            setFormErrors(validate(data));
        }
    }


    const validate = (data) => {
        let error = {};
        if (!data.about) {
            error.about = "This is required";
        }
        if (data.about && data.about.length > 1500) {
            error.about = "Character should not be greater than 300.";
        }
        return error;
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    }


    return (
        <>

            <section id="personaloity" class="not_befor_personaloity">
                <div class="container">
                    <form onSubmit={handleSubmit}>
                        <div class="personaloity_yourself">
                            <h2><span>😎</span>Personality Questions <p>Write About Yourself</p>
                            </h2>
                            <div class="row">

                                <div class="col-md-6 mt-3">
                                    <div class="box">
                                        <h3>What you can write</h3>
                                        <h4>Be Authentic</h4>
                                        <h5>Be yourself, and speak from the heart.</h5>

                                        <h4>Highlight Values</h4>
                                        <h5>Mention your important values, like spirituality or family.</h5>

                                        <h4>Interests and Hobbies</h4>
                                        <h5>Share your passions and hobbies.</h5>

                                        <h4>Lifestyle</h4>
                                        <h5>Capture a solo moment to keep the focus on you.</h5>

                                        <h4>Career and Goals</h4>
                                        <h5>Mention your profession and aspirations.</h5>

                                        <h4>Family and Relationship</h4>
                                        <h5>Share what you seek in a relationship.</h5>
                                    </div>
                                </div>

                                <div class="col-md-6 mt-3">
                                    <div class="form-group">
                                        <label class="form-label">Write about yourself</label>
                                        <textarea class="form-control" placeholder="Write your answer here" name="about" value={data && data.about && data.about} onChange={handleChange} spellCheck={true}></textarea>
                                        {errors.about && <p className={"error__feedback"}>{errors.about}</p>}

                                    </div>
                                </div>

                                <div class="col-12 text-end mt-3">
                                {/* d-flex justify-content-between mt-5 */}
                                    {/* <Link to="/Dashboard" class="btn ">Back</Link> */}
                                    <button type="submit" class="btn">Submit</button>
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
            </section>


        </>
    )

}