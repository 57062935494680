import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sidebar from "../Sidebar";
import Header from "../Header";
import { userDashTab } from "../../Data/content";
import BodyColor from "../../../Layouts/BodyColor";
import AllMatches from "./AllMatches";
import NearMe from "./NearMe";
import NewMatches from "./NewMatches";
import RecentlyViewed from "./RecentlyViewed";
import Shortlisted from "./Shortlisted";
import { GetCounts } from "../../../../Reducer/UserDashboardSlices/dashboardSlice";


export default function Dashboard() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [tabData, setTabData] = useState(userDashTab); // Local state for tab data
    const auth = useSelector(state => state.auth.auth);
    const coundata = useSelector(state => state.dshbrd.countsData);
    const updated = useSelector(state => state.dshbrd.updated);
    const success = useSelector(state => state.dshbrd.success);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    console.log('ciuntsss', coundata);
    useEffect(() => {
        getData();
    }, [updated]);
    useEffect(() => {
        if (coundata) {
            const updatedTabs = userDashTab.map((tab) => {
                if (tab.name === "All Matches") {
                    return { ...tab, count: coundata.all_match };
                }
                if (tab.name === "Matches Near Me") {
                    return { ...tab, count: coundata.near_me };
                }
                if (tab.name === "New Matches") {
                    return { ...tab, count: coundata.new_match };
                }
                if (tab.name === "Recently Viewed") {
                    return { ...tab, count: coundata.viewd_match };
                }
                if (tab.name === "Shortlisted profiles") {
                    return { ...tab, count: coundata.shortlisted_match };
                }
                return tab;
            });

            // Update the local state for tabData
            setTabData(updatedTabs);
        }
    }, [coundata]);


    const getData = () => {
        let dat = { uid: auth._id };
        dispatch(GetCounts(dat));
    }



    return (
        <>

            <BodyColor />

            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <Header />
                    <section id="match_dashboard">

                        <div class="filter_select">

                        </div>
                        {tabData.length > 0 &&
                            <Tabs>
                                <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                    {tabData.length > 0 && tabData.map((item, i) => (
                                        <Tab key={i}
                                            className={"nav-link" + (activeTab === i ? " active" : "")}
                                            onClick={() => handleTabClick(i)}
                                        >
                                            {item.name} ({item.count})
                                        </Tab>
                                    ))}

                                </TabList>
                                <TabPanel>
                                    <AllMatches />
                                </TabPanel>
                                <TabPanel>
                                    <NearMe />
                                </TabPanel>
                                <TabPanel>
                                    <NewMatches />
                                </TabPanel>
                                <TabPanel>
                                    <RecentlyViewed />
                                </TabPanel>
                                <TabPanel>
                                    <Shortlisted />
                                </TabPanel>
                            </Tabs>}


                    </section>

                </div>
            </section>



        </>
    )

}