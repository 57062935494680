import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddPrivacySettings, GetPrivacySettings } from '../api';

export const SaveSetting = createAsyncThunk('SaveSetting', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddPrivacySettings(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetSavedPrivacy = createAsyncThunk('GetSavedPrivacy', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetPrivacySettings(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const privacySlice = createSlice({
  name: 'prvcy',
  initialState: {
    data: null,
    success: false,
    error: null,


  },
  reducers: {

    clearPvcy: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SaveSetting.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveSetting.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveSetting.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetSavedPrivacy.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetSavedPrivacy.fulfilled, (state, action) => {
        state.basic = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetSavedPrivacy.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearPvcy } = privacySlice.actions;

export default privacySlice.reducer;

