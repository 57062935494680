import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab2({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "astro" };
        // dispatch(ViewProfileTabs(data));
    }, []);

    return (
        <>
            <div class="set">
                {data && data.privacysetups && data.privacysetups.astro_privacy === "Everyone" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Date of Birth</h4>
                            <p>{data && data.astro && data.astro.dob ? data.astro.dob : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Time of Birth</h4>
                            <p>{data && data.astro && data.astro.time ? data.astro.time + ' ' + data.astro.ampm : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mangalik / Chevval Dosham</h4>
                            <p>{data && data.astro && data.astro.manglik ? data.astro.manglik : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Nakshatra</h4>
                            <p>{data && data.astro && data.astro.nakshtra ? data.astro.nakshtra : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h4>Place of Birth</h4>
                            <p>{data && data.astro && data.astro.birth_place ? data.astro.birth_place : "-"}</p>
                        </div>
                        <div class="col-12">
                            <h5>Religious Background</h5>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Religion</h4>
                                    <p>{data && data.astro && data.astro.religion ? data.astro.religion : "-"}</p>
                                </div>
                                {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Community</h4>
                                    <p>{data && data.astro && data.astro.community ? data.astro.community : "-"}</p>
                                </div> */}
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Gothram</h4>
                                    <p>{data && data.astro && data.astro.gotra ? data.astro.gotra : "-"}</p>
                                </div>
                            </div>
                        </div>


                    </div>
                }
                {data && data.privacysetups && data.privacysetups.astro_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Date of Birth</h4>
                            <p>{data && data.astro && data.astro.dob ? data.astro.dob : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Time of Birth</h4>
                            <p>{data && data.astro && data.astro.time ? data.astro.time + ' ' + data.astro.ampm : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mangalik / Chevval Dosham</h4>
                            <p>{data && data.astro && data.astro.manglik ? data.astro.manglik : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Nakshatra</h4>
                            <p>{data && data.astro && data.astro.nakshtra ? data.astro.nakshtra : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h4>Place of Birth</h4>
                            <p>{data && data.astro && data.astro.birth_place ? data.astro.birth_place : "-"}</p>
                        </div>
                        <div class="col-12">
                            <h5>Religious Background</h5>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Religion</h4>
                                    <p>{data && data.astro && data.astro.religion ? data.astro.religion : "-"}</p>
                                </div>
                                {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Community</h4>
                                    <p>{data && data.astro && data.astro.community ? data.astro.community : "-"}</p>
                                </div> */}
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Gothram</h4>
                                    <p>{data && data.astro && data.astro.gotra ? data.astro.gotra : "-"}</p>
                                </div>
                            </div>
                        </div>


                    </div>
                }
            </div>

        </>
    )

}