import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MakerDashSingleView, MyProfileTabs } from "../../Data/content";
import Basic from "./Basic";
import Astro from "./Astro";
import Family from "./Family";
import Carrer from "./Carrer";
import Spritual from "./Spritual";
import Personality from "./Personality";
import Preference from "./Preference";
import Gallery from "./Gallery";
import Documents from "./Documents";

export default function SingleUserView() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <div className="view_profile">

                


                        <Tabs>
                            <TabList className={"box_two nav nav-pills mt-0 mb-3"} id="pills-tab" role="tablist">
                                {MakerDashSingleView.length > 0 && MakerDashSingleView.map((item, i) => (
                                    <Tab key={i}
                                        className={"nav-link" + (activeTab === i ? " active" : "")}
                                        onClick={() => handleTabClick(i)}
                                    >
                                        {item.name}
                                    </Tab>
                                ))}
                            </TabList>
                            {MakerDashSingleView.map((tem, index) => (
                                <>
                                    <TabPanel>
                                        <Basic />
                                    </TabPanel>
                                    <TabPanel>
                                        <Astro />
                                    </TabPanel>
                                    <TabPanel>
                                        <Family />
                                    </TabPanel>
                                    <TabPanel>
                                        <Carrer />
                                    </TabPanel>
                                    <TabPanel>
                                        <Spritual />
                                    </TabPanel>
                                    <TabPanel>
                                        <Personality />
                                    </TabPanel>
                                    <TabPanel>
                                        <Preference />
                                    </TabPanel>
                                    <TabPanel>
                                        <Gallery />
                                    </TabPanel>
                                    <TabPanel>
                                        <Documents />
                                    </TabPanel>
                                </>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </section>
        </>
    )
}
