import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ViewProfileTabs } from "../../../Data/content";
import ViewProfileTab1 from "./ViewProfileTab1";
import ViewProfileTab2 from "./ViewProfileTab2";
import ViewProfileTab3 from "./ViewProfileTab3";
import ViewProfileTab4 from "./ViewProfileTab4";
import ViewProfileTab5 from "./ViewProfileTab5";
import ViewProfileTab6 from "./ViewProfileTab6";
import ViewProfileTab7 from "./ViewProfileTab7";

export default function Tabbing(props) {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);


    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {

    });

    console.log('sdsfdfdsf', props.data);


    return (
        <>
            <div class="col-xl-8 mb-3">
                <div class="box_two">
                    <h2>{props.data && props.data.name}</h2>
                    <h3>{props.data && props.data.aboutdetails && props.data.aboutdetails.about}</h3>
                    {ViewProfileTabs.length > 0 &&
                        <Tabs>
                            <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                {ViewProfileTabs.map((tem, index) => (
                                    <Tab key={index}
                                        className={"nav-link" + (activeTab === index ? " active" : "")}
                                        onClick={() => handleTabClick(index)}
                                    >
                                        {tem.name}
                                    </Tab>
                                ))}
                            </TabList>
                            <TabPanel>
                                <ViewProfileTab1 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab2 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab3 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab4 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab5 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab6 data={props.data} />
                            </TabPanel>
                            <TabPanel>
                                <ViewProfileTab7 data={props.data} />
                            </TabPanel>
                        </Tabs>
                    }
                </div>
            </div>
        </>
    )

}