import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LongTermGoal } from "../../Data/content";

export default function Step6({ data, handleChnage, errors }) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    const selectOption = (item, name) => {
        let updatedSelection = [...data.goals_and_aspirations];
        const selectedIndex = updatedSelection.indexOf(item);
        if (selectedIndex !== -1) {
            // If selected, remove it from the array
            updatedSelection.splice(selectedIndex, 1);
        } else {
            // If not selected, add it to the array
            updatedSelection.push(item);
        }
        handleChnage({ target: { name: name, value: updatedSelection } });
    };


    return (
        <>

            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3><span>Social</span> Interaction 3</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>What are your long-term goals and aspirations?</h4>
                                <ul>
                                    {LongTermGoal.length > 0 && LongTermGoal.map((item, i) => (
                                        <li key={i} className={data.goals_and_aspirations && data.goals_and_aspirations.length > 0 && data.goals_and_aspirations.includes(item) ? 'active' : ''} onClick={() => selectOption(item, "goals_and_aspirations")}>{item}</li>
                                    ))}
                                    {errors.goals_and_aspirations && <p className={"error__feedback"}>{errors.goals_and_aspirations}</p>}
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <div class="form-group">
                                    <label class="form-label">Elaborate on your vision for the future and the steps
                                        you
                                        plan to take to achieve your long-term goals and aspirations.🚀*</label>
                                    <textarea class="form-control" name="elaborate_goals" value={data.elaborate_goals} onChange={handleChnage}></textarea>
                                    {errors.elaborate_goals && <p className={"error__feedback"}>{errors.elaborate_goals}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )

}