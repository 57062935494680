import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddMatchmakingChoices, AddPricingPlan, AllPricingPlans } from '../api';

export const GetPricingPlans = createAsyncThunk('GetPricingPlans', async (_, { rejectWithValue }) => {
  try {
    const response = await AllPricingPlans();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const UpdateMatchmakingChoices = createAsyncThunk('UpdateMatchmakingChoices', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddMatchmakingChoices(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const UpdatePricing = createAsyncThunk('AddPricingPlan', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddPricingPlan(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});



const pricingSlice = createSlice({
  name: 'price',
  initialState: {
    data: null,
    success: false,
    error: null,

  },
  reducers: {
    clearPriceState: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(UpdateMatchmakingChoices.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(UpdateMatchmakingChoices.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(UpdateMatchmakingChoices.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(UpdatePricing.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(UpdatePricing.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(UpdatePricing.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearPriceState } = pricingSlice.actions;

export default pricingSlice.reducer;

