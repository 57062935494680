import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Introduction from "./Introduction";
import Venues from "./Venues";
import Locations from "./Locations";
import Services from "./Services";
import SuccessStroies from "../Tools/SuccessStrories";
import { useDispatch, useSelector } from 'react-redux';
import { weadingPlan } from "../../../Reducer/apiSlice";


export default function WeddingVenuePlanner() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.api.data)
    useEffect(() => {
        dispatch(weadingPlan());
    });

    return (
        <>
            <Header />
            <Introduction data={data && data.intro} />
            <Venues data={data && data.divineVenues}/>
            <Locations />
            <Services data={data && data.services}/>
            <SuccessStroies data={data && data.stories}/>
            <Footer />
        </>
    )

}