import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function Offerings() {

    return (
        <>
            <section id="home_tabs">
                <div class="container">
                    <div class="box_sh">

                        <h1>Our <span>Offerings</span> </h1>

                        <Tabs>
                            <div class="nav nav-pills" id="pills-tab">
                                <TabList class="nav-item" >
                                    <Tab class="nav-link">01</Tab>
                                    <Tab class="nav-link">02</Tab>
                                
                                </TabList>
                            </div>

                            <TabPanel>
                                <div class="first_box_tab">
                                    <div class="row">
                                        <div class="col-xl-6 col-md-7">
                                            <div class="first_box_width">
                                                <h3>Premarital Counselling</h3>
                                                <p>Embark on a spiritually enriched marriage with Vedic Matrimony's counseling.
                                                    Rooted in timeless wisdom, our program offers personalized sessions on
                                                    communication, conflict resolution, and integrating Vedic principles. Ensure
                                                    a strong foundation for your union—sign up to invest in the strength of your
                                                    marriage. May your path together be blessed with love and understanding.</p>

                                                <a href="marriage_counselling_more.html" class="btn">Explore <img src="images/Arrow-white.png" alt="" /></a>
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-md-5">
                                            <div class="img_tab">
                                                <img src="images/Offerings-tab.png" alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div class="first_box_tab">
                                    <div class="row">
                                        <div class="col-xl-6 col-md-7">
                                            <div class="first_box_width">
                                                <h3>Post Marriage Counselling</h3>
                                                <p>Embark on a post-marriage counseling journey with Vedic Matrimony. Our
                                                    program, rooted in Vedic wisdom, offers personalized sessions to enhance
                                                    communication, intimacy, and spiritual growth. Strengthen your bond and
                                                    navigate married life with resilience</p>

                                                <a href="marriage_counselling_more_two.html" class="btn">Explore <img src="images/Arrow-white.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-5">
                                            <div class="img_tab">
                                                <img src="images/Offerings-tab2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                        </Tabs>

                        {/* <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">01</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false">02</button>
                    </li>

                </ul> */}

                        {/* <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">

                        <div class="first_box_tab">
                            <div class="row">
                                <div class="col-xl-6 col-md-7">
                                    <div class="first_box_width">
                                        <h3>Premarital Counselling</h3>
                                        <p>Embark on a spiritually enriched marriage with Vedic Matrimony's counseling.
                                            Rooted in timeless wisdom, our program offers personalized sessions on
                                            communication, conflict resolution, and integrating Vedic principles. Ensure
                                            a strong foundation for your union—sign up to invest in the strength of your
                                            marriage. May your path together be blessed with love and understanding.</p>

                                        <a href="marriage_counselling_more.html" class="btn">Explore <img src="images/Arrow-white.png" alt=""/></a>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-md-5">
                                    <div class="img_tab">
                                        <img src="images/Offerings-tab.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

          
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="first_box_tab">
                            <div class="row">
                                <div class="col-xl-6 col-md-7">
                                    <div class="first_box_width">
                                        <h3>Post Marriage Counselling</h3>
                                        <p>Embark on a post-marriage counseling journey with Vedic Matrimony. Our
                                            program, rooted in Vedic wisdom, offers personalized sessions to enhance
                                            communication, intimacy, and spiritual growth. Strengthen your bond and
                                            navigate married life with resilience</p>

                                        <a href="marriage_counselling_more_two.html" class="btn">Explore <img src="images/Arrow-white.png" alt=""/></a>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-md-5">
                                    <div class="img_tab">
                                        <img src="images/Offerings-tab2.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div> */}

                    </div>
                </div>
            </section>
        </>
    )

}