import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function Understanding(props) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [activeTab, setActiveTab] = useState(0);



    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (
        <section id="understanding">
            <div class="container">
                <h1>Understanding the Kundali Matching</h1>
                {props.data && props.data.content &&
                    <h2>{props.data.content}</h2>
                }
                {

                    <>
                        {props.data && props.data.rashi.length > 0 &&

                            <Tabs>
                                <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                    {props.data.rashi.map((tem, index) => (
                                        <Tab key={index}
                                            className={"nav-link" + (activeTab === index ? " active" : "")}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {tem.name}
                                        </Tab>
                                    ))}
                                </TabList>


                                {props.data.rashi.map((tem, index) => (
                                    <TabPanel key={index} className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}>
                                       <div class="bx_under">
                                                <b>Varna/Varan/Jaati: </b>{tem.description}
                                            </div>
                                    </TabPanel>
                                ))}

                            </Tabs>
                        }

                  
                    </>
                }
            </div>
        </section>

    )

}