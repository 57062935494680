import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
// import Matching from "./Matching";
import Introduction from "./Introduction";
import { useDispatch, useSelector } from 'react-redux';
import Resources from "../Tools/Resources";
import Importance from "./Importance";
import Understanding from "./Understanding";
import Matching from "./Matching";
import AfterMatching from "./AfterMatching";
import { kundaliPage } from "../../../Reducer/apiSlice";

export default function KundaliMatching() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.api.data)
    const [open, setOpen] = useState(false);
    const [uerData, setuseData] = useState(null);

    useEffect(() => {
        dispatch(kundaliPage());
        // setOpen(false);
    }, []);

    const fromMatching = (response) => {
        setOpen(response);
    };

    const SaveData = (data) => {
        setuseData(data);
    }

    return (
        <>
            <Header />
            <Matching sendResponseToParent={fromMatching} data={SaveData} />
            {open == false &&
                <>
                    <Introduction data={data && data.intro} />
                    <Understanding data={data && data.understanding} />
                    <Resources data={data && data.Resources} />
                    <Importance data={data && data.importance} />
                </>
            }
            {open == true &&
                <AfterMatching uerData={uerData} />
            }
            <Footer />
        </>
    )

}