import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import { UserDetail } from "../../../../Reducer/UserDashboardSlices/userSlice";

export default function Main(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [barColor, setBarColor] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.user.data);


    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        setColor();

    }, []);


    console.log('backend dataaaaa', data);

    const setColor = () => {
        if (data && data.completion_percentage >= 10 && data.completion_percentage <= 30) {
            setBarColor('#FF0000'); // Example color for completion_percentage less than 25%
        } else if (data && data.completion_percentage >= 30 && data.completion_percentage <= 60) {
            setBarColor('#FFA500');// Example color for completion_percentage between 25% and 50%
        } else if (data && data.completion_percentage >= 60 && data.completion_percentage <= 100) {
            setBarColor('#00FF00'); // Example color for completion_percentage between 50% and 75%
        } else {
            setBarColor('#ed6129'); // Example color for completion_percentage greater than or equal 
        }
    }


    function Goto(name) {

        if (name === "Personality") {
            navigate("/PersonalityQuestions");

        }
        else if (name === "About") {
            navigate("/AboutPersonality");
        }
        else if (name === "photos") {
            navigate("/UploadGallery");
        }
        else if (name === "selfi") {
            navigate("/SelfiVerification");

        } else if (name === "GovtId") {
            navigate("/IdVerification");
        }
        else if (name === "PrivacySetup") {
            navigate("/PrivacySetting");
        }
        else {
            navigate("/UserStepForms", { state: { name } });
        }
    }


    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">

                    <Sidebar />
                    <div class="header_dashboard">
                        <h2>Welcome to your Dashboard!</h2>
                    </div>

                    {/* <!-- **** Inner Section Dashboard **** --> */}

                    <div class="complete_profile">
                        <h2>🌟 Unlock the Full Experience: Complete Your Profile</h2>
                        <div class="row">

                            <div class="col-xl-8 mb-xl-0 mb-3">
                                <div class="box">
                                    <h3>Profile Completion Status</h3>
                                    <div class="progress" style={{ height: '5px' }} >
                                        <div className="progress-bar" role="progressbar" style={{ width: `${data && data.completion_percentage}%`, backgroundColor: barColor }} aria-valuenow="25"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <span>{data && (data.completion_percentage > 100 ? 100 : data.completion_percentage)}% Completed</span>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="set">
                                                <h4 onClick={() => Goto("Basic")}><span><img src="images/p1.png" />Basic Info</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-18" checked={data && data.basic == true} />
                                                                <label for="checkbox-18"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("Astro")}><span><img src="images/p2.png" />Astro Details</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-19" checked={data && data.astro == true} />
                                                                <label for="checkbox-19"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("Family")}><span><img src="images/p3.png" />Family Details</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-20" checked={data && data.families == true} />
                                                                <label for="checkbox-20"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("Career")}><span><img src="images/p4.png" />Career Details</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-21" checked={data && data.carrers == true} />
                                                                <label for="checkbox-21"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 ps-lg-5">
                                            <div class="set">
                                                <h4 onClick={() => Goto("Spiritual")}><span><img src="images/p5.png" />Spiritual Details</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-22" checked={data && data.spirituals == true} />
                                                                <label for="checkbox-22"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("Preferences")}><span><img src="images/p6.png" />Partner Preferences</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-23" checked={data && data.preferences == true} />
                                                                <label for="checkbox-23"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("Personality")} ><span><img src="images/p7.png" />Personality Questions</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-24" checked={data && data.personalityquestions == true} />
                                                                <label for="checkbox-24"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => Goto("About")}><span><img src="images/p8.png" />About Me</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-25" checked={data && data.about == true} />
                                                                <label for="checkbox-25"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-12 text-end">
                                            <Link to="/UserStepForms" class="btn">Complete your profile now</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="col-xl-4">
                                <div class="box_two">
                                    <h3>Photos</h3>
                                    <div class="set">
                                        <h4 onClick={() => Goto("photos")}><span><img src="images/p9.png" />Upload Photos {data && data.gallery === true && data.gallryVerified === true && <i class="bi bi-patch-check-fill"></i>} {data && data.gallery === true && data.gallryVerified === false && <i class="bi bi-exclamation-octagon-fill"></i>}</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-26" checked={data && data.gallery == true} />
                                                        <label for="checkbox-26"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>

                                        <h4 onClick={() => Goto("selfi")}><span><img src="images/p10.png" />Selfie Verification{data && data.selfy === true && data.selfiverified === true && <i class="bi bi-patch-check-fill"></i>} {data && data.selfy && data.selfy === true && data.selfiverified === false && <i class="bi bi-exclamation-octagon-fill"></i>}</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-27" checked={data && data.selfy == true} />
                                                        <label for="checkbox-27"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>

                                    </div>
                                </div>
                                <div class="box_two mt-3">
                                    <h3>Verification & Privacy</h3>
                                    <div class="set">
                                        <h4><span><img src="images/p11.png" />Email Verification</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-28" checked={data && data.emailverification == true} />
                                                        <label for="checkbox-28"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>
                                        <h4><span><img src="images/p12.png" />Mobile Number Verification</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-29" checked={data && data.mobileverification === true} />
                                                        <label for="checkbox-29"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>
                                        <h4 onClick={() => Goto("GovtId")}><span><img src="images/p13.png" />Govt. ID Verification {data && data.govtVerification === true && data.govtVerified === true && <i class="bi bi-patch-check-fill"></i>} {data && data.govtVerification === true && data.govtVerified === false && <i class="bi bi-exclamation-octagon-fill"></i>}</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-30" checked={data && data.govtVerification === true} />
                                                        <label for="checkbox-30"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>

                                        <h4 onClick={() => Goto("PrivacySetup")}><span><img src="images/p14.png" />Setup Your Privacy Settings</span>
                                            <span>
                                                <div class="checkbox-wrapper-18">
                                                    <div class="round">
                                                        <input type="checkbox" id="checkbox-31" checked={data && data.privacy === true} />
                                                        <label for="checkbox-31"></label>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>

                                    </div>
                                </div>

                            </div>

                            <div class="col-12">
                                <div class="better_matches">
                                    <h3>✨ Why Complete Your Profile?</h3>
                                    <ul>
                                        <li>Better Matches: The more you share, the more accurate your matches become.</li>
                                        <li>Show Your Personality: Let your unique qualities shine for others to see.</li>
                                        <li>Build Trust: A complete profile builds trust and credibility.</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}