import { NavLink, useNavigate, Link, useLocation, json } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import { GetPersonalityData, SavePerosnalityQuetsions, clearPersonalityState } from "../../../Reducer/StepForms/personalitySlice";
import Swal from 'sweetalert2';


export default function PersonalityQuestions() {
    const dispatch = useDispatch();
    const [def, setDef] = useState(1);
    const [currentTab, setCurrentTab] = useState(1);
    const auth = useSelector(state => state.auth.auth);
    const [step1Data, setStep1Data] = useState({
        uid: auth._id,
        stresful_situation: [],
        challenging_situation: "",
        free_time: [],
        movies_books: [],
        strength: [],
        weakness: [],
        motivate_insipre: [],
        characterize_your_personality: [],
        social_Gatherings: "",
        lifestyle: [],
        diet: "",
        goals_and_aspirations: [],
        elaborate_goals: "",
        criticism_or_feedback: "",
        personal_growth_development: "",
        structured_routine: "",
        benefits_and_challenges: "",

        // new add
        focus_your_attention: "",
        take_in_information: "",
        decisions: "",
        deal_with_world_around: "",

    });
    const [errors, setFormErrors] = useState({});
    const success = useSelector(state => state.personality.success);
    const error = useSelector(state => state.personality.error);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearPersonalityState());

        } else {
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            dispatch(clearPersonalityState());
            setDef(3);
            // setTimeout(() => {
            //     setDef(3);
            // }, 2000);
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPersonalityState());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPersonalityState());
                break;
        }
    }

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetPersonalityData(dat));
        if (res.payload && res.payload.data.data) {
            const responeData = res.payload.data.data;
            console.log('responseee', responeData);
            setStep1Data(prevState => ({
                ...prevState,
                stresful_situation: responeData.stresful_situation ? responeData.stresful_situation : [],
                challenging_situation: responeData.challenging_situation ? responeData.challenging_situation : "",
                free_time: responeData.free_time ? responeData.free_time : [],
                movies_books: responeData.movies_books ? responeData.movies_books : [],
                strength: responeData.strength ? responeData.strength : [],
                weakness: responeData.weakness ? responeData.weakness : [],
                motivate_insipre: responeData.motivate_insipre ? responeData.motivate_insipre : [],
                characterize_your_personality: responeData.characterize_your_personality ? responeData.characterize_your_personality : [],
                social_Gatherings: responeData.social_Gatherings ? responeData.social_Gatherings : "",
                lifestyle: responeData.lifestyle ? responeData.lifestyle : [],
                diet: responeData.diet ? responeData.diet : "",
                goals_and_aspirations: responeData.goals_and_aspirations ? responeData.goals_and_aspirations : [],
                elaborate_goals: responeData.elaborate_goals ? responeData.elaborate_goals : "",
                criticism_or_feedback: responeData.criticism_or_feedback ? responeData.criticism_or_feedback : "",
                personal_growth_development: responeData.personal_growth_development ? responeData.personal_growth_development : "",
                structured_routine: responeData.structured_routine ? responeData.structured_routine : "",
                benefits_and_challenges: responeData.benefits_and_challenges ? responeData.benefits_and_challenges : "",

                // newww
                focus_your_attention: responeData.focus_your_attention ? responeData.focus_your_attention : "",
                take_in_information: responeData.take_in_information ? responeData.take_in_information : "",
                decisions: responeData.decisions ? responeData.decisions : "",
                deal_with_world_around: responeData.deal_with_world_around ? responeData.deal_with_world_around : "",



            })
            )
        }

    }

    const handleStartQuestions = () => {
        setDef(2);
    };

    const nextPrev = (step) => {
        if (step === "Skip") {
            setCurrentTab(currentTab + 1);
        } else {
            if (step === 1) {
                const values = validateCurrentStep(currentTab, step1Data);
                let chk = true;
                if (Object.keys(values).length > 0) {
                    chk = false;

                } if (chk) {
                    setCurrentTab(currentTab + step);
                } else {
                    setFormErrors(validateCurrentStep(currentTab, step1Data));

                }
            }

            else {
                setCurrentTab(currentTab + step);
            }
        }

    };

    const validateCurrentStep = (step, data) => {
        console.log('test data', data);
        console.log('step number', step);
        let error = {};

        if (step === 1) {
            if (data.stresful_situation.length === 0) {
                error.stresful_situation = "This is required.";
            };
            // if (!data.challenging_situation) {
            //     error.challenging_situation = "This is required.";
            // };
        }
        if (step === 2) {
            if (data.free_time.length === 0) {
                error.free_time = "This is required.";
            };
            if (data.movies_books.length === 0) {
                error.movies_books = "This is required.";
            };
        }
        if (step === 3) {
            if (data.strength.length === 0) {
                error.strength = "This is required.";
            };
            if (data.weakness.length === 0) {
                error.weakness = "This is required.";
            };
            if (data.motivate_insipre.length === 0) {
                error.motivate_insipre = "This is required.";
            };
        }
        if (step === 4) {
            // if (data.characterize_your_personality.length === 0) {
            //     error.characterize_your_personality = "This is required.";
            // };

            if (!data.focus_your_attention) {
                error.focus_your_attention = "This is required.";
            }
            if (!data.take_in_information) {
                error.take_in_information = "This is required.";
            }
            if (!data.decisions) {
                error.decisions = "This is required.";
            }
            if (!data.deal_with_world_around) {
                error.deal_with_world_around = "This is required.";
            }
            // if (!data.social_Gatherings) {
            //     error.social_Gatherings = "This is required.";
            // };

        }
        if (step === 5) {
            if (data.lifestyle.length === 0) {
                error.lifestyle = "This is required.";
            };
            if (!data.diet) {
                error.diet = "This is required.";
            };
        }
        if (step === 6) {
            if (data.goals_and_aspirations === 0) {
                error.goals_and_aspirations = "This is required.";
            };
            // if (!data.elaborate_goals) {
            //     error.elaborate_goals = "This is required.";
            // };
        }
        if (step === 7) {
            if (!data.criticism_or_feedback) {
                error.criticism_or_feedback = "This is required.";
            };

        }
        if (step === 8) {
            // if (!data.structured_routine) {
            //     error.structured_routine = "This is required.";
            // };

        }

        return error;
    };



    const handleFormChange = (e) => {
        console.log('eeeee', e);
        const { name, value } = e.target;
        setStep1Data(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const renderTabContent = () => {
        switch (currentTab) {
            case 1:
                return <Step1 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 2:
                return <Step2 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 3:
                return <Step3 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 4:
                return <Step4 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 5:
                return <Step5 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 6:
                return <Step6 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 7:
                return <Step7 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            case 8:
                return <Step8 data={step1Data} handleChnage={handleFormChange} errors={errors} />;
            default:
                return null;
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        // console.log('formdata ', step1Data);
        const values = validateCurrentStep(currentTab, step1Data);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;

        } if (chk) {
            console.log('step1Data', step1Data);
            // step1Data.characterize_your_personality = JSON.stringify(step1Data.characterize_your_personality);
            step1Data.stresful_situation = JSON.stringify(step1Data.stresful_situation);
            step1Data.free_time = JSON.stringify(step1Data.free_time);
            step1Data.movies_books = JSON.stringify(step1Data.movies_books);
            step1Data.strength = JSON.stringify(step1Data.strength);
            step1Data.weakness = JSON.stringify(step1Data.weakness);
            step1Data.motivate_insipre = JSON.stringify(step1Data.motivate_insipre);
            step1Data.lifestyle = JSON.stringify(step1Data.lifestyle);
            step1Data.goals_and_aspirations = JSON.stringify(step1Data.goals_and_aspirations);
            dispatch(SavePerosnalityQuetsions(step1Data));

        } else {
            setFormErrors(validateCurrentStep(currentTab, step1Data));
        }
    }


    return (
        <>

            {def === 1 || def === 2 ?

                <section id="personaloity">
                    <div class="container">
                        {def === 1 &&

                            <>
                                <div class="personaloity_cont">
                                    <h2>Discover what makes you special! 🌺</h2>
                                    <h3>The Final Touch to Your Profile</h3>
                                    <h4>Congratulations on 8/9 sections! Now, add the final touch – your personality! Answer quick, fun
                                        questions to showcase the real you. Click "Start Personality Questions" and let's find your perfect
                                        match! 💖</h4>
                                    <a class="btn" onClick={handleStartQuestions}>Start Personality Questions<i class="bi bi-arrow-right"></i></a>
                                </div>
                            </>
                        }

                        {def === 2 &&

                            <form id="regForm_two" onSubmit={handleSubmit}>

                                {renderTabContent()}

                                <div class="personaloity_btn">
                                    <div class="row">
                                        {currentTab > 1 ?
                                            <div class="col-4">
                                                <button type="button" id="prevBtn1" onClick={() => nextPrev(-1)}>Back</button>
                                            </div> : <div class="col-4">

                                            </div>
                                        }
                                        {currentTab < 8 ?
                                            <div class="col-8 text-end">
                                                <a class="btn skip" onClick={() => nextPrev("Skip")}>Skip</a>
                                                <button type="button" id="nextBtn1" onClick={() => nextPrev(1)}>Next</button>
                                            </div>
                                            : <div class="col-8 text-end">

                                                <button type="submit" id="nextBtn1">Submit</button>
                                            </div>}
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </section>

                : <section id="personaloity" class="not_befor_personaloity">
                    <div class="container">
                        <div class="personaloity_para">
                            <h2>🌟 Congratulations on completing the personality questionnaire! </h2>
                            <h2> Now, it's time to tell potential partners more about yourself.</h2>
                            <h2> 📝 Please provide a brief self-description to express your personality and interests. 🌱🎯🌄</h2>

                            <Link to="/AboutPersonality" class="btn">Next</Link>

                        </div>
                    </div>
                </section>

            }


        </>
    )

}