import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Sidebar from '../Sidebar';
import { GetHistory } from "../../../../Reducer/MakerDashboardSlices/historySlice";

export default function History() {
    const params = useParams();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.histry.data);

    console.log('data', data);

    useEffect(() => {
        getData();

    }, []);

    async function getData() {
        let dat = { maker_id: auth._id, uid: params.id };
        dispatch(GetHistory(dat));
    }

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />

                    <section id="empty_noti_sec">
                        <h1>User History</h1>
                        {data.length > 0 ? data.map((item, i) => (
                            <div className="set">
                                <img src="/images/favicon.png" alt="" />
                                <h3>{item.title}<span> {('0' + (new Date(item.created_at)).getDate()).slice(-2)}-
                                    {('0' + ((new Date(item.created_at)).getMonth() + 1)).slice(-2)}-
                                    {(new Date(item.created_at)).getFullYear()}
                                </span></h3>
                                <p>{item.description}</p>
                            </div>
                        )) : <p>No history found.</p>}


                    </section>
                </div>
            </section>

        </>
    )
}