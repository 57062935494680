import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import AccSettings from "./AccSettings";
import PrivacySettings from "./PrivacySettings";

export default function AccountSettings() {

    useEffect(() => {

    }, []);

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <section id="account_setting">
                        <AccSettings />
                        <PrivacySettings />

                    </section>

                </div>
            </section>


        </>
    )

}