import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetFTMApprovalListing, GetTabData, ApproveProfile, ProfileApproveByMaker } from '../makerApi';


export const GetFtmUsers = createAsyncThunk('GetFtmUsers', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetFTMApprovalListing(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const GetDetailsTab = createAsyncThunk('GetDetailsTab', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetTabData(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdateApproval = createAsyncThunk('UpdateApproval', async (formData, { rejectWithValue }) => {
    try {
        const response = await ApproveProfile(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const ApproveProfileStatus = createAsyncThunk('ApproveProfileStatus', async (formData, { rejectWithValue }) => {
    try {
        const response = await ProfileApproveByMaker(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



const ftmUsersSlice = createSlice({
    name: 'ftmUsers',
    initialState: {
        data: [],
        success: false,
        error: null,
    },
    reducers: {
        clearftmUsers: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetFtmUsers.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetFtmUsers.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetFtmUsers.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(UpdateApproval.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(UpdateApproval.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdateApproval.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(ApproveProfileStatus.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(ApproveProfileStatus.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(ApproveProfileStatus.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});
export const { clearftmUsers } = ftmUsersSlice.actions;

export default ftmUsersSlice.reducer;

