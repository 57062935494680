import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAccDetailsSettings, UpdateAccountDetails, DelUserAccount, UpdateUserPassword } from '../api';

export const GetAccountDetails = createAsyncThunk('GetAccountDetails', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetAccDetailsSettings(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdateAccount = createAsyncThunk('UpdateAccount', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateAccountDetails(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelAc = createAsyncThunk('DelAc', async (formData, { rejectWithValue }) => {
    try {
        const response = await DelUserAccount(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdatePwd = createAsyncThunk('UpdatePwd', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateUserPassword(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});





const userAccountSlice = createSlice({
    name: 'userAccount',
    initialState: {
        data: null,
        success: false,
        error: null,
        AcDelSuccess: false,
        AcDelError: null,
        upPwd: false,
        erPwd: null
    },
    reducers: {
        clacAcRes: state => {
            state.success = false;
            state.error = null;
            state.AcDelError = null;
            state.AcDelSuccess = false;
            state.upPwd = false;
            state.erPwd = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetAccountDetails.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetAccountDetails.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetAccountDetails.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(UpdateAccount.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(UpdateAccount.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdateAccount.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(DelAc.pending, state => {
                state.AcDelSuccess = false;
                state.AcDelError = null;
            })
            .addCase(DelAc.fulfilled, (state, action) => {
                state.AcDelSuccess = action.payload;
                state.AcDelError = null;
            })
            .addCase(DelAc.rejected, (state, action) => {
                state.AcDelSuccess = false;
                state.AcDelError = action.payload;
            })
            .addCase(UpdatePwd.pending, state => {
                state.upPwd = false;
                state.erPwd = null;
                
            })
            .addCase(UpdatePwd.fulfilled, (state, action) => {
                state.upPwd = action.payload;
                state.erPwd = null;
            })
            .addCase(UpdatePwd.rejected, (state, action) => {
                state.upPwd = false;
                state.erPwd = action.payload;
            
            })

    },
});
export const { clacAcRes } = userAccountSlice.actions;

export default userAccountSlice.reducer;

