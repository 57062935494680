import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MakerBasicUpdate, MakerGalleryUpload } from '../makerApi';


export const UpdateMakerBasic = createAsyncThunk('UpdateMakerBasic', async (formData, { rejectWithValue }) => {
    try {
        const response = await MakerBasicUpdate(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdateMakerGallery = createAsyncThunk('UpdateMakerGallery', async (formData, { rejectWithValue }) => {
    try {
        const response = await MakerGalleryUpload(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const editMakerSlice = createSlice({
    name: 'makerEdit',
    initialState: {
        data: null,
        success: false,
        error: null,
    },
    reducers: {
        clearEditMaker: state => {

            state.success = false;
            state.error = null;
        }

    },
    extraReducers: builder => {
        builder
            .addCase(UpdateMakerBasic.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(UpdateMakerBasic.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdateMakerBasic.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(UpdateMakerGallery.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(UpdateMakerGallery.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdateMakerGallery.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});
export const { clearEditMaker } = editMakerSlice.actions;


export default editMakerSlice.reducer;

