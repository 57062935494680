import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { API_BASE_URL } from "../../Data/content";
export default function HomeTabs(props) {

    useEffect(() => {

    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };



    return (
        <>

            <section id="home_tabs">
                <div class="container">

                    {props.data && props.data.length > 0 && props.data.map((tab, index) => (

                        <div data-aos="fade-right" class="box_sh">

                            <h1>Features that <span>Transcends</span> </h1>

                            {/* <!-- **** One **** --> */}
                            <div class="step_tab">
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <button className={index === 0 ? "nav-link active" : "nav-link"}>01</button>
                                    </li>
                                    <li class="nav-item">
                                        <button className={index === 1 ? "nav-link active" : "nav-link"}>02</button>
                                    </li>
                                    <li class="nav-item">
                                        <button className={index === 2 ? "nav-link active" : "nav-link"}>03</button>
                                    </li>
                                    <li class="nav-item">
                                        <button className={index === 3 ? "nav-link active" : "nav-link"}>04</button>
                                    </li>
                                </ul>


                                <div class="first_box_tab">
                                    <div class="row">
                                        <div class="col-xl-6 col-md-7">
                                            <div class="first_box_width">
                                                <h3><img src="images/AI.png" alt="" />{tab.title}</h3>
                                                <p>{tab.content}</p>
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-md-5">
                                            <div class="img_tab">
                                                <img src={API_BASE_URL + tab.image} alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>




                        </div>

                    ))}
                </div>
            </section >
            {/* <section id="home_tabs">
                <div class="container">
                    <div class="box_sh">
                        <h1>Features that <span>Transcends</span> </h1>
                        {props.data &&props.data.length > 0 && 
                        <>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            {props.data.map((tab, index) => (
                                <li className="nav-item" key={index} role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                        onClick={() => handleTabClick(index)}
                                        id={`pills-tab-${index}`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-content-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-content-${index}`}
                                        aria-selected={activeTab === index}
                                    >
                                        {"0" + (index + 1)}
                                    </button>
                                </li>
                            ))}
                        </ul>


                        <div className="tab-content" id="pills-tabContent">
                            {props.data.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                    id={`pills-content-${index}`}
                                    role="tabpanel"
                                    aria-labelledby={`pills-tab-${index}`}
                                >
                                        <div class="first_box_tab">
                                            <div class="row">
                                                <div class="col-xl-6 col-md-7">
                                                    <div class="first_box_width">
                                                        <h3><img src="images/AI.png" alt="" />{tab.title}</h3>
                                                        <p>{tab.content}</p>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-md-5">
                                                    <div class="img_tab">
                                                        <img src={API_BASE_URL + tab.image} alt="img" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                        </>
                            }
                    </div>
                </div>
            </section> */}

        </>
    )

}