import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetCarrerDetails, AddCarrerData} from '../api';

export const GetCarrerInfo = createAsyncThunk('GetCarrerInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetCarrerDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const SaveCarrerDetail = createAsyncThunk('AddCarrerData', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddCarrerData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const carrerSlice = createSlice({
  name: 'carrer',
  initialState: {
    data: null,
    success: false,
    error: null,
 
  },
  reducers: {

    cleasrData: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SaveCarrerDetail.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveCarrerDetail.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveCarrerDetail.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetCarrerInfo.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetCarrerInfo.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetCarrerInfo.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })  
  },
});
export const { cleasrData } = carrerSlice.actions;

export default carrerSlice.reducer;

