import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';


export default function Step3({ onNext }) {
    const [abt, setAbt] = useState("");
    const [errors, setFormErrors] = useState("");


    useEffect(() => {

    }, []);

    const handleSubmit=(e)=> {
        e.preventDefault();
        if(abt === ""){
            setFormErrors("This is required field");

        }else{
            let dat = { about : abt};
            onNext(dat);
        }
    }

    
    const handleChangeInput = (e) => {
        const value = e.target.value;
        setAbt(e.target.value);
     
    }

    return (

        <div class="yourself_set">
            <h2><span>😎</span>Personality Questions <p>Write About Yourself</p>
            </h2>
            <form onSubmit={handleSubmit}>
                <div class="row">
                    <div class="col-lg-6 mt-3">
                        <div class="box">
                            <h3>What you can write</h3>
                            <h4>Be Authentic</h4>
                            <h5>Be yourself, and speak from the heart.</h5>

                            <h4>Highlight Values</h4>
                            <h5>Mention your important values, like spirituality or family.</h5>

                            <h4>Interests and Hobbies</h4>
                            <h5>Share your passions and hobbies.</h5>

                            <h4>Lifestyle</h4>
                            <h5>Capture a solo moment to keep the focus on you.</h5>

                            <h4>Career and Goals</h4>
                            <h5>Mention your profession and aspirations.</h5>

                            <h4>Family and Relationship</h4>
                            <h5>Share what you seek in a relationship.</h5>
                        </div>
                    </div>



                    <div class="col-lg-6 mt-3">
                        <div class="form-group">
                            <label class="form-label">Write about yourself</label>
                            <textarea class="form-control" onChange={handleChangeInput} placeholder="Write your answer here" name="about" ></textarea>
                        </div>
                        {errors && <p className={"error__feedback"}>{errors}</p>}

                    </div>

                    <div class="col-12 text-end mt-3">
                        {/* <button class="btn" type="submit">Next</button> */}
                        <button type="submit"> Next</button>

                    </div>
                </div>
            </form>

        </div>


    )

}