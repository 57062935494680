import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../UserDashboard/Sidebar";


export default function FinalStep() {
    const dispatch = useDispatch();
    const [element, setElemeent] = useState("");
    const [sidebarDisabled, setSidebarDisabled] = useState(true);

    useEffect(() => {

    });

    function childRes() {
        // props.sendres("selfi");
    }

    return (
        <>
            <section id="full_page_dashboard_withoutSidebar">
                <div class="container-fluid">
                    {/* <Sidebar data={element} sendToParent={childRes} disabled={sidebarDisabled} /> */}
                    <div class="upload_finished">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="box">
                                    <h2>Congratulations! 🎉 On to the Next Step!</h2>
                                    <h3>Your Profile Verification is Underway</h3>

                                    <p><b>Congratulationson </b> completing your onboarding! 🎉 Your profile is currently
                                        undergoing
                                        our
                                        verification process, a crucial step in ensuring the authenticity and security of our
                                        community. This process will be completed within the <span>next 24 hours.</span>
                                    </p>

                                    <h4>Why it matters? </h4>
                                    <p>Verification boosts <span>trust</span> and <span>credibility,</span> creating a secure
                                        space
                                        for genuine connections.</p>

                                    <h4>Ready for the Next Step?</h4>
                                    <p>Once verified, enjoy full access to all features and start connecting!</p>
                                    <p><b>Thanks for your patience! Excited to have you on board. 🌈🔒</b></p>
                                </div>
                            </div>

                            <div class="col-lg-5">
                                <div class="upload_finished_img">
                                    <img src="images/upload-finished.png" alt="" />
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-5">
                                <a href="#"></a>
                                <Link to="/MatchmakingSelection" class="btn upload">Next</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}