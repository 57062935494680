import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default function PersonalityMatch({ user1, user2 }) {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {


    }, []);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-lg-6 mt-3">

                        <div class="set_two">
                            <h2>Personality Questions</h2>
                            <h5>How do you usually handle stress and pressure? 😓</h5>
                            {user1 && user1.personalityquestions && user1.personalityquestions.stresful_situation && user1.personalityquestions.stresful_situation.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.stresful_situation.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Can you share an example of a challenging situation you faced and how you dealt with it?</h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.challenging_situation}</h6>

                            <h5>Personality Questions
                                Hobbies
                                What are your favorite ways to spend your free time? 🌴</h5>
                            {user1 && user1.personalityquestions && user1.personalityquestions.free_time && user1.personalityquestions.free_time.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.free_time.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>What kind of books or movies do you enjoy?</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.movies_books && user1.personalityquestions.movies_books.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.movies_books.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Your strengths?</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.strength && user1.personalityquestions.strength.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.strength.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Your weakness?</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.weakness && user1.personalityquestions.weakness.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.weakness.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>What motivates and inspires you in life?</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.motivate_insipre && user1.personalityquestions.motivate_insipre.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.motivate_insipre.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Personality Questions
                                Social Interaction
                                How would you characterize your personality?</h6>
                            <h6>
                                How you focus your attention: on the outer world or your inner world?
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.focus_your_attention}</h6>
                            <h6>
                                How you take in information: using your physical senses or your instincts and impressions?
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.take_in_information}</h6>
                            <h6>How you make decisions: solely using logic or by weighing how it will affect the people involved?
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.decisions}</h6>
                            <h6>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.deal_with_world_around}</h6>
                            <h6>Are you more comfortable in social gatherings?
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.social_Gatherings}</h6>

                            <h6>Lifestyle</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.lifestyle && user1.personalityquestions.lifestyle.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.lifestyle.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Diet
                            </h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.diet}</h6>

                            <h6>What are your long-term goals and aspirations?</h6>
                            {user1 && user1.personalityquestions && user1.personalityquestions.goals_and_aspirations && user1.personalityquestions.goals_and_aspirations.length > 0 ?
                                <ul>
                                    {user1.personalityquestions.goals_and_aspirations.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Personality Questions
                                Social Interaction 4
                                How do you handle criticism or feedback from others?</h6>
                            <h6>{user1 && user1.personalityquestions && user1.personalityquestions.criticism_or_feedback}</h6>
                        </div>



                    </div>
                    <div class="col-lg-6 mt-3">

                        <div class="set_two">
                            <h2>Personality Questions</h2>
                            <h5>How do you usually handle stress and pressure? 😓</h5>
                            {user2 && user2.personalityquestions && user2.personalityquestions.stresful_situation && user2.personalityquestions.stresful_situation.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.stresful_situation.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Can you share an example of a challenging situation you faced and how you dealt with it?</h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.challenging_situation}</h6>

                            <h5>Personality Questions
                                Hobbies
                                What are your favorite ways to spend your free time? 🌴</h5>
                            {user2 && user2.personalityquestions && user2.personalityquestions.free_time && user2.personalityquestions.free_time.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.free_time.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>What kind of books or movies do you enjoy?</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.movies_books && user2.personalityquestions.movies_books.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.movies_books.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Your strengths?</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.strength && user2.personalityquestions.strength.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.strength.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Your weakness?</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.weakness && user2.personalityquestions.weakness.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.weakness.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>What motivates and inspires you in life?</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.motivate_insipre && user2.personalityquestions.motivate_insipre.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.motivate_insipre.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Personality Questions
                                Social Interaction
                                How would you characterize your personality?</h6>
                            <h6>
                                How you focus your attention: on the outer world or your inner world?
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.focus_your_attention}</h6>
                            <h6>
                                How you take in information: using your physical senses or your instincts and impressions?
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.take_in_information}</h6>
                            <h6>How you make decisions: solely using logic or by weighing how it will affect the people involved?
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.decisions}</h6>
                            <h6>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.deal_with_world_around}</h6>
                            <h6>Are you more comfortable in social gatherings?
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.social_Gatherings}</h6>

                            <h6>Lifestyle</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.lifestyle && user2.personalityquestions.lifestyle.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.lifestyle.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}
                            <h6>Diet
                            </h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.diet}</h6>

                            <h6>What are your long-term goals and aspirations?</h6>
                            {user2 && user2.personalityquestions && user2.personalityquestions.goals_and_aspirations && user2.personalityquestions.goals_and_aspirations.length > 0 ?
                                <ul>
                                    {user2.personalityquestions.goals_and_aspirations.map((item, i) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                : "-"}

                            <h6>Personality Questions
                                Social Interaction 4
                                How do you handle criticism or feedback from others?</h6>
                            <h6>{user2 && user2.personalityquestions && user2.personalityquestions.criticism_or_feedback}</h6>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}