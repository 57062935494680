import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Personality(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id };

    }, []);


    return (
        <>


            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">



                <div class="box">
                    <h2><img src="/images/pro-3.png" alt="" />Personality and Interests</h2>
                    <div class="row">
                        <div class="col-12">
                            <h3>How would you describe your personality?</h3>
                            <p>
                                {props.data && props.data.personalityquestions && props.data.personalityquestions.focus_your_attention},
                                {props.data && props.data.personalityquestions && props.data.personalityquestions.take_in_information},
                                {props.data && props.data.personalityquestions && props.data.personalityquestions.decisions},
                                {props.data && props.data.personalityquestions && props.data.personalityquestions.deal_with_world_around} </p>
                        </div>
                        <div class="col-12">
                            <h3>Hobbies and Interests</h3>
                            {props.data && props.data.personalityquestions && props.data.personalityquestions.free_time &&
                                <ul>
                                    {props.data.personalityquestions.free_time.map((item, i) => (
                                        <li>{item}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>


                        <div class="col-12">
                            <h3>Favourite Books or Authors</h3>
                            {props.data && props.data.personalityquestions && props.data.personalityquestions.movies_books &&
                                <ul>
                                    {props.data.personalityquestions.movies_books.map((item, i) => (
                                        <li>{item}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>

                        <div class="col-12">
                            <h3>Strengths</h3>
                            {props.data && props.data.personalityquestions && props.data.personalityquestions.strength &&
                                <ul>
                                    {props.data.personalityquestions.strength.map((item, i) => (
                                        <li>{item}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>
                        <div class="col-12">
                            <h3>Weakness</h3>
                            {props.data && props.data.personalityquestions && props.data.personalityquestions.weakness &&
                                <ul>
                                    {props.data.personalityquestions.weakness.map((item, i) => (
                                        <li>{item}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>


                    </div>
                </div>


            </div>





        </>
    )

}