import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { criticism_feedback } from "../../Data/content";


export default function Step7({ data, handleChnage, errors }) {
    const dispatch = useDispatch();

    useEffect(() => {

    });

    const selectOption = (item, name) => {
        handleChnage({ target: { name: name, value: item } });
    };

    return (
        <>

            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3><span>Social</span> Interaction 4</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>How do you handle criticism or feedback from others?*</h4>
                                <ul>
                                    {criticism_feedback.length > 0 && criticism_feedback.map((item, i) => (
                                        <li key={i} className={data.criticism_or_feedback === item ? 'active' : ''} onClick={() => selectOption(item, "criticism_or_feedback")}>{item}</li>
                                    ))}
                                    {errors.criticism_or_feedback && <p className={"error__feedback"}>{errors.criticism_or_feedback}</p>}

                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <div class="form-group">
                                    <label class="form-label">Describe a specific instance where you received
                                        feedback
                                        and explain how you processed and utilized it for your personal growth and
                                        development. 🔄</label>
                                    <textarea class="form-control" name="personal_growth_development" value={data.personal_growth_development} onChange={handleChnage} ></textarea>
                                    {errors.personal_growth_development && <p className={"error__feedback"}>{errors.personal_growth_development}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}