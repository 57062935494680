import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetDetailsTab } from "../../../../Reducer/MakerDashboardSlices/ftmUsersSlice";



export default function Personality() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const params = useParams();
    const [data, setTabData] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: params.id, type: "Personality" };
        let res = await dispatch(GetDetailsTab(dat));
        if (res.payload && res.payload.data.data) {
            setTabData(res.payload.data.data);
        }
    }


    return (
        <div class="set">
            <div class="questions_sec">
                <h5>How do you usually handle stress and pressure? 😓</h5>
                {data && data.stresful_situation && data.stresful_situation.length > 0 ?
                    <ul>
                        {data.stresful_situation.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>Can you share an example of a challenging situation you faced and how you dealt with it?🌟</h5>
                <p>{data && data.challenging_situation ? data.challenging_situation : "-"}</p>
                <h5>What are your favorite ways to spend your free time?</h5>
                {data && data.free_time && data.free_time.length > 0 ?
                    <ul>
                        {data.free_time.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>What kind of books or movies do you enjoy?</h5>
                {data && data.movies_books && data.movies_books.length > 0 ?
                    <ul>
                        {data.movies_books.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>Your Strength?</h5>
                {data && data.strength && data.strength.length > 0 ?
                    <ul>
                        {data.strength.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>Your Weakness?</h5>
                {data && data.weakness && data.weakness.length > 0 ?
                    <ul>
                        {data.weakness.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>What motivates and inspires you in life?</h5>
                {data && data.motivate_insipre && data.motivate_insipre.length > 0 ?
                    <ul>
                        {data.motivate_insipre.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>How you focus your attention: on the outer world or your inner world?
                </h5>
                <p>{data && data.focus_your_attention ? data.focus_your_attention : "-"}</p>
                <h5>How you take in information: using your physical senses or your instincts and impressions?</h5>
                <p>{data && data.take_in_information ? data.take_in_information : "-"}</p>
                <h5>How you make decisions: solely using logic or by weighing how it will affect the people involved ?</h5>
                <p>{data && data.decisions ? data.decisions : "-"}</p>
                <h5>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?</h5>
                <p>{data && data.deal_with_world_around ? data.deal_with_world_around : "-"}</p>
                <h5>Are you more comfortable in social gatherings?*</h5>
                <p>{data && data.social_Gatherings ? data.social_Gatherings : "-"}</p>
                <h5>Your lifestyle</h5>
                {data && data.lifestyle && data.lifestyle.length > 0 ?
                    <ul>
                        {data.lifestyle.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>Your Diet
                </h5>
                <p>{data && data.diet ? data.diet : "-"}</p>
                <h5>
                    What are your long-term goals and aspirations?
                </h5>
                {data && data.goals_and_aspirations && data.goals_and_aspirations.length > 0 ?
                    <ul>
                        {data.goals_and_aspirations.map((item, i) => (
                            <li>{item}</li>
                        ))}

                    </ul>
                    : "-"}
                <h5>
                    Elaborate on your vision for the future and the steps you plan to take to achieve your long-term goals and aspirations.
                </h5>
                <p>{data && data.elaborate_goals ? data.elaborate_goals : "-"}</p>
                <h5>
                    How do you handle criticism or feedback from others?
                </h5>
                <p>{data && data.criticism_or_feedback ? data.criticism_or_feedback : "-"}</p>
                <h5>
                    Describe a specific instance where you received feedback and explain how you processed and utilized it for your personal growth and development
                </h5>
                <p>{data && data.personal_growth_development ? data.personal_growth_development : "-"}</p>
                <h5>
                    Do you prefer structured routine?
                </h5>
                <p>{data && data.structured_routine ? data.structured_routine : "-"}</p>
                <h5>
                    Discuss your approach to balancing structure and
                    flexibility in your daily life, highlighting the benefits and challenges you
                    face with each approach.
                </h5>
                <p>{data && data.benefits_and_challenges ? data.benefits_and_challenges : "-"}</p>

            </div>
        </div>

    )
}