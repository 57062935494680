import axios from 'axios';
import { API_BASE_URL } from '../Components/Pages/Data/content';

export const MatchMakerRegistration = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/MatchMakerRegistration`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetMakerDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetMakerDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAllListOfUsers = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetAllListOfUsers`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const SingleFtmSideDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/SingleFtmSideDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetSingleFtmMatches = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetSingleFtmMatches`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetMatchingViewDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetMatchingViewDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const SendRecommendation = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/SendRecommendation`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetMakerHome = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetMakerHome`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const MakerBasicUpdate = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/MakerBasicUpdate`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const MakerGalleryUpload = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/MakerGalleryUpload`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAllMakerNotifications = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetAllMakerNotifications`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const ReadAllMatchMakerNotiifcation = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/ReadAllMatchMakerNotiifcation`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetFTMApprovalListing = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetFTMApprovalListing`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetTabData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetTabData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ApproveProfile = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/ApproveProfile`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const ProfileApproveByMaker = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/ProfileApproveByMaker`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetPendingRecomendations = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetPendingRecomendations`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetNewUsers = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetNewUsers`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetPendingVerificationList = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetPendingVerificationList`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetuserHistory = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}maker/GetuserHistory`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}