import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Sidebar(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [barColor, setBarColor] = useState("");



    useEffect(() => {
        setColor();
    }, [props]);

    const setColor = () => {
        if (props && props.completed >= 10 && props.completed <= 30) {
            setBarColor('#FF0000'); // Example color for completion_percentage less than 25%
        } else if (props && props.completed >= 30 && props.completed <= 60) {
            setBarColor('#FFA500');// Example color for completion_percentage between 25% and 50%
        } else if (props && props.completed >= 60 && props.completed <= 100) {
            setBarColor('#00FF00'); // Example color for completion_percentage between 50% and 75%
        } else {
            setBarColor('#ed6129'); // Example color for completion_percentage greater than or equal 
        }
    }


    function sendTo(name) {
        navigate("/UserStepForms", { state: { name } });
    }

    const Gotback = () => {
        navigate("/Dashboard");
    }


    return (
        <>
            <div class="col-lg-3 pe-lg-0 mb-3 mb-lg-0">
                <div class="box">
                    <div className="back_btn" onClick={() => Gotback()}>
                        <a><i class="bi bi-arrow-left"></i>Back</a>
                    </div>
                    <h3>Profile Completion Status</h3>
                    <div class="progress" style={{ height: '5px' }}>
                        <div class="progress-bar" role="progressbar" aria-valuenow="25" style={{ width: `${props.completed && props.completed}%`, backgroundColor: barColor }}

                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  
                    <span class="progress_span">{props.completed && (props.completed > 100 ? 100 : props.completed)}% Completed</span>

                    <ul>
                        <li className={props && props.data == 'Basic' ? 'step active' : 'step'} onClick={() => sendTo('Basic')} >
                            <label><img src="images/p1.png" />Basic Info</label><span><i
                                class="bi bi-check"></i></span></li>

                        <li className={props && props.data == 'Astro' ? 'step active' : 'step'} onClick={() => sendTo('Astro')} ><label><img src="images/p2.png" />Astro </label><span><i
                            class="bi bi-check"></i></span></li>

                        <li className={props && props.data == 'Family' ? 'step active' : 'step'} onClick={() => sendTo('Family')}><label><img src="images/p3.png" />Family </label><span><i
                            class="bi bi-check"></i></span></li>

                        <li className={props && props.data == 'Career' ? 'step active' : 'step'} onClick={() => sendTo('Career')}><label><img src="images/p4.png" />Career </label><span><i
                            class="bi bi-check"></i></span></li>

                        <li className={props && props.data == 'Spiritual' ? 'step active' : 'step'} onClick={() => sendTo('Spiritual')}><label><img src="images/p5.png" />Spiritual </label><span><i
                            class="bi bi-check"></i></span></li>

                        <li className={props && props.data == 'Preferences' ? 'step active' : 'step'} onClick={() => sendTo('Preferences')}><label><img src="images/p6.png" />Preferences </label><span><i
                            class="bi bi-check"></i></span></li>
                    </ul>

                </div>
            </div>

        </>
    )

}