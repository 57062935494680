import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { heightData } from "../../Data/content";

export default function
    Height({ data, handleChange }) {

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Height Start</label>
                            <select class="form-select"
                                aria-label="Default select example" name="height_start"  onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {heightData.length > 0 && heightData.map((item, i) => (
                                    <option selected={data && data.height_start == item.value} value={item.value}>{item.item}</option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Height End</label>
                            <select class="form-select"
                                aria-label="Default select example" name="height_end"  onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {heightData.length > 0 && heightData.map((item, i) => (
                                    <option selected={data && data.height_end == item.value} value={item.value} >{item.item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


