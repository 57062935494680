import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Contact(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id };

    }, []);


    return (
        <>


            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">

                <div class="box">
                    <h2><img src="/images/pro-6.png" alt="" />Contact Information</h2>
                    <div class="row">



                        <div class="col-sm-6">
                            <h3>Email Address</h3>
                            <p>{props.data && props.data.email}</p>
                        </div>


                        <div class="col-sm-6">
                            <h3>Mobile Number</h3>
                            <p>{props.data && props.data.mobile}</p>
                        </div>


                    </div>
                </div>
            </div >





        </>
    )

}