import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";



export default function Tab1() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);

    useEffect(() => {
        let dat = { uid: auth._id, type : "basic" };
        dispatch(GetTabsData(dat));
    }, []);


    return (
        <>
            <div class="set">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Name</h4>
                        <p>{data && data.name ? data.name : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Gender</h4>
                        <p>{data && data.gender ? data.gender : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Marital Status</h4>
                        <p>{data && data.Marital_status ? data.Marital_status : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Mother Tongue</h4>
                        <p>{data && data.Mother_tongue ? data.Mother_tongue : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Height</h4>
                        <p>{data && data.height ? data.height : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Weight</h4>
                        <p>{data && data.weight ? data.weight+' kg'  : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Health Status</h4>
                        <p>{data && data.health ? data.health : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Any Disability</h4>
                        <p>{data && data.Disability ? data.Disability : "-" }</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Country</h4>
                        <p>{data && data.country ? data.country : "-" }</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>State</h4>
                        <p>{data && data.state ? data.state : "-" }</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>City</h4>
                        <p>{data && data.city ? data.city : "-" }</p>
                    </div>
                </div>
            </div>

        </>

    )
}