import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Partner(props) {


    useEffect(() => {


    }, []);


    return (
        <>


            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">

                <div class="box">
                    <h2><img src="/images/pro-5.png" alt="" />Partner Preferences</h2>
                    <div class="row">
                        <div class="col-12">
                            <h3>How many children you wish to have after marriage?</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.children}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Preferred Age</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_age_start} - {props.data && props.data.preferences && props.data.preferences.prefered_age_end} years</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Preferred Marital Status</h3>
                            {props.data && props.data.preferences && props.data.preferences.prefered_marital_status &&
                                <ul>
                                    {props.data.preferences.prefered_marital_status.map((item, i) => (
                                        <li>{item.value}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>

                        <div class="col-sm-6">
                            <h3>Preferred Height</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_height_start} - {props.data && props.data.preferences && props.data.preferences.prefered_height_end}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Preferred Caste</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_caste}</p>

                        </div>

                        <div class="col-12">
                            <h3>Preferred Language</h3>
                            {props.data && props.data.preferences && props.data.preferences.prefered_language &&
                                <ul>
                                    {props.data.preferences.prefered_language.map((item, i) => (
                                        <li>{item.value}</li>
                                    ))
                                    }
                                </ul>
                            }
                        </div>

                        <div class="col-12">
                            <h3>Preferred City / Location</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_city}</p>
                        </div>

                        <div class="col-12">
                            <h3>Preferred Educational Qualifications</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_qualification}</p>

                        </div>

                        <div class="col-12">
                            <h3>Preferred Professional Background</h3>
                            <p>{props.data && props.data.preferences && props.data.preferences.prefered_Proffessional_bkgrd}</p>
                        </div>

                    </div>
                </div>
            </div>





        </>
    )

}