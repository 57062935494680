import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Services(props) {

    return (

        <section id="services_offered">
            <div class="container">
                <h1>Services Offered</h1>
                {props.data && props.data.length > 0 &&
                    <div class="row">
                        {props.data.map((item, i) => (
                            <div class="col-lg-4 col-sm-6 mb-3">
                                <div class="box">
                                    <h3>{item.head1}</h3>
                                    <h4>{item.head2}</h4>
                                    <p>{item.content}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                }
            </div>
        </section>
    )

}