import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetPendingVerificationList, ApproveProfile } from '../makerApi';


export const GetPendingVerifcation = createAsyncThunk('GetSelfiesPending', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetPendingVerificationList(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const Verify = createAsyncThunk('Verify', async (formData, { rejectWithValue }) => {
    try {
        const response = await ApproveProfile(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});





const pendingVerificationSlice = createSlice({
    name: 'pendingVerification',
    initialState: {
        data: [],
        success: false,
        error: null,
    },
    reducers: {
        clearSelfieverification: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetPendingVerifcation.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetPendingVerifcation.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetPendingVerifcation.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(Verify.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(Verify.fulfilled, (state, action) => {
                // state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(Verify.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })

    },
});
export const { clearSelfieverification } = pendingVerificationSlice.actions;

export default pendingVerificationSlice.reducer;

