import { NavLink, useNavigate, Link, useLocation, } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import { useDispatch } from "react-redux";
import { CheckMatch } from "../../../../Reducer/FrontendForms/kundaliSlice";

export default function Matching({ data, sendResponseToParent }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        mobile: "",
        email: "",
        boyName: "",
        girlName: "",
        boyDob: "",
        boyTime: "",
        girlDob: "",
        girlTime: "",
        boyBirthPlace: "",
        boyLatitute: "",
        boyLongitude: "",
        boyTimezone: "",
        girlBirthPlace: "",
        girlLatitute: "",
        girlLongitude: "",
        girlTimezone: "",
        boyMeridian: "",
        girlMeridian: ""
    });
    const [errors, setFormErrors] = useState({});
    const [location, setLocation] = useState(null);
    const [location2, setLocation2] = useState(null);


    const handleChangeInput = (e) => {
        const value = e.target.value;
        if (e.target.name === "boyTime") {
            const timeValue = value;
            const hour = parseInt(timeValue.split(':')[0]);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            setForm({
                ...form,
                [e.target.name]: value,
                ["boyMeridian"]: ampm
            });


        } else if (e.target.name === "girlTime") {
            const timeValue = value;
            const hour = parseInt(timeValue.split(':')[0]);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            setForm({
                ...form,
                [e.target.name]: value,
                ["girlMeridian"]: ampm
            });


        } else {
            setForm({
                ...form,
                [e.target.name]: value
            });
        }


    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation({
                value: value.value,
                label: value.label
            });

            // Get timezone
            let timeZone = await getTimezone(latLng.lat, latLng.lng);

            setForm({
                ...form,
                ["boyBirthPlace"]: value.label,
                ["boyLatitute"]: latLng.lat,
                ["boyLongitude"]: latLng.lng,
                ["boyTimezone"]: timeZone,

            });


        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    const handleSelect2 = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation2({
                value: value.value,
                label: value.label
            });

            // Get timezone
            let timeZone = await getTimezone(latLng.lat, latLng.lng);


            setForm({
                ...form,
                ["girlBirthPlace"]: value.label,
                ["girlLatitute"]: latLng.lat,
                ["girlLongitude"]: latLng.lng,
                ["girlTimezone"]: timeZone,
            });

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    async function getTimezone(latitude, longitude) {
        const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const apiKey = 'AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc'; // Replace 'YOUR_API_KEY' with your actual Google API key
        const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`);
        const data = await response.json();

        if (data.status === 'OK') {
            const timezoneOffsetSeconds = data.rawOffset + data.dstOffset; // Total offset from UTC in seconds
            const timezoneOffsetHours = timezoneOffsetSeconds / 3600; // Convert to hours
            return timezoneOffsetHours;
        } else {
            console.error('Failed to fetch timezone:', data.status);
            return null;
        }
    }


    const viewMatch = async () => {
        let values = validate(form);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;
        }
        if (chk) {
            let res = await dispatch(CheckMatch(form));
            if (res.payload && res.payload.data.data) {


                await data(res.payload.data);
                sendResponseToParent(true);
            }
        } else {
            setFormErrors(validate(form));
        }


        // props.sendResponseToParent(true);

    };

    const validate = (values) => {
        let error = {};
        if (!values.mobile) {
            error.mobile = "This field is required!";
        }
        if (!values.email) {
            error.email = "This field is required!";

        }
        if (!values.boyName) {
            error.boyName = "This field is required!";
        }
        if (!values.girlName) {
            error.girlName = "This field is required!";
        }
        if (!values.boyDob) {
            error.boyDob = "This field is required!";
        } if (values.boyDob) {
            const dob = new Date(values.boyDob);
            const eighteenYearsAgo = new Date();
            eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
            const maxAgeDate = new Date();
            maxAgeDate.setFullYear(maxAgeDate.getFullYear() - 80);
            if (dob >= eighteenYearsAgo) {
                error.boyDob = "Age must be at least 18 years old!";
            } else if (dob <= maxAgeDate) {
                error.boyDob = "Age must be less than 80 years old!";
            }
        }
        if (!values.girlDob) {
            error.girlDob = "This field is required!";
        } if (values.girlDob) {
            const dob = new Date(values.girlDob);
            const eighteenYearsAgo = new Date();
            eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
            const maxAgeDate = new Date();
            maxAgeDate.setFullYear(maxAgeDate.getFullYear() - 80);
            if (dob >= eighteenYearsAgo) {
                error.girlDob = "Age must be at least 18 years old!";
            } else if (dob <= maxAgeDate) {
                error.girlDob = "Age must be less than 80 years old!";
            }
        }
        if (location === null) {
            error.boyBirthPlace = "This field is required!";
        }
        if (location2 === null) {
            error.girlBirthPlace = "This field is required!";
        }
        if (!values.boyTime) {
            error.boyTime = "This field is required!";
        }
        if (!values.girlTime) {
            error.girlTime = "This field is required!";
        }
        return error;
    }
    return (

        <section id="kundali_matching">
            <div class="container">
                <h1>Kundali Matching for Marriage</h1>
                <form>
                    <div class="row">
                        <div class="col-sm-6 mb-3">
                            <div class="set">

                                <div class="form-group">
                                    <label class="form-label">Phone Number</label>
                                    <input type="text" class="form-control" placeholder="Enter your mobile number here" name="mobile" onChange={handleChangeInput} />
                                    {errors.mobile && <p className={"error__feedback"}>{errors.mobile}</p>}

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <div class="set">
                                <div class="form-group">
                                    <label class="form-label">Email Address</label>
                                    <input type="email" class="form-control" placeholder="Enter your email  here" name="email" onChange={handleChangeInput} />
                                    {errors.email && <p className={"error__feedback"}>{errors.email}</p>}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-lg-6 mb-lg-0 mb-3">
                            <div class="set">

                                <div class="profile_img_kundali">
                                    <img src="images/man-kundali.png" alt="" />
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Boy’s Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Boy's Name" name="boyName" onChange={handleChangeInput} />
                                    {errors.boyName && <p className={"error__feedback"}>{errors.boyName}</p>}

                                </div>

                                <div class="form-group">
                                    <label class="form-label">Date of Birth</label>
                                    <input type="date" class="form-control" placeholder="" name="boyDob" onChange={handleChangeInput} />
                                    {errors.boyDob && <p className={"error__feedback"}>{errors.boyDob}</p>}
                                    {/* <div class="row">
                                        <div class="col-sm-4 col-6 mb-sm-0 mb-2">

                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Date</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-4 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Month</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-4 col-12">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Year</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Time of Birth</label>
                                    <input type="time" class="form-control" name="boyTime" onChange={handleChangeInput} />
                                    {errors.boyTime && <p className={"error__feedback"}>{errors.boyTime}</p>}

                                    {/* <div class="row">



                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>HH</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>MM</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>AM</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Birth Place</label>
                                    <PlacesAutocomplete
                                        selectProps={{
                                            value: location,
                                            onChange: handleSelect,
                                        }}
                                        query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                        fetchDetails={true}
                                        onFail={error => console.log(error)}
                                    />
                                    {errors.boyBirthPlace && <p className={"error__feedback"}>{errors.boyBirthPlace}</p>}
                                    {/* <input type="text" class="form-control" placeholder="Enter Boy’s Place Of Birth" onChange={handleSelect}/> */}
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="set">

                                <div class="profile_img_kundali text-end">
                                    <img src="images/girl-kundali.png" alt="" />
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Girl’s Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Girl’s Name" name="girlName" onChange={handleChangeInput} />
                                    {errors.girlName && <p className={"error__feedback"}>{errors.girlName}</p>}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Date of Birth</label>
                                    <input type="date" class="form-control" placeholder="" name="girlDob" onChange={handleChangeInput} />
                                    {errors.girlDob && <p className={"error__feedback"}>{errors.girlDob}</p>}

                                    {/* 
                                    <div class="row">

                                        <div class="col-sm-4 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Date</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-4 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Month</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-4 col-12">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Year</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Time of Birth</label>
                                    <input type="time" class="form-control" name="girlTime" onChange={handleChangeInput} />
                                    {errors.girlTime && <p className={"error__feedback"}>{errors.girlTime}</p>}
                                    {/* <div class="row">

                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>HH</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>MM</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div class="col-xl-4 col-sm-3 col-6 mb-sm-0 mb-2">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>AM</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                    </div> */}
                                </div>

                                <div class="form-group">
                                    <label class="form-label">Birth Place</label>
                                    {/* <input type="text" class="form-control" placeholder="Enter Girl's Place Of Birth" /> */}
                                    <PlacesAutocomplete
                                        selectProps={{
                                            value: location2,
                                            onChange: handleSelect2,
                                        }}
                                        query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                        fetchDetails={true}
                                        onFail={error => console.log(error)}
                                    />
                                    {errors.girlBirthPlace && <p className={"error__feedback"}>{errors.boyBirthPlace}</p>}
                                </div>

                            </div>
                        </div>



                    </div>
                    {/*  */}
                    <div class="col-12 text-center" onClick={viewMatch}>
                        <button type="button">Match Your Kundali</button>
                    </div>

                </form>
            </div>
        </section>
    )

}