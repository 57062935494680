// apiSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { WeadingPlanner, home, kundaliPageApi, GetAboutPage, GetSinglResourcePage } from './api';


export const homePage = createAsyncThunk('home section', async (_, { rejectWithValue }) => {
  try {
    const response = await home();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const kundaliPage = createAsyncThunk('kundali', async (_, { rejectWithValue }) => {
  try {
    const response = await kundaliPageApi();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const weadingPlan = createAsyncThunk('weadingPlan', async (_, { rejectWithValue }) => {
  try {
    const response = await WeadingPlanner();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const AboutPage = createAsyncThunk('AboutPage', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAboutPage();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetSinglResource = createAsyncThunk('GetSinglResource', async (formdata, { rejectWithValue }) => {
  try {
    const response = await GetSinglResourcePage(formdata);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const apiSlice = createSlice({
  name: 'api',
  initialState: {
    data: null,
    loading: false,
    error: null,
    resource: null
  },
  reducers: {
    clearFront: state => {
      state.success = false;
      state.error = null;

    },

  },
  extraReducers: builder => {
    builder
      .addCase(homePage.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(homePage.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(homePage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(kundaliPage.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(kundaliPage.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        console.log('dddd', action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(kundaliPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(AboutPage.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AboutPage.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        // console.log('dddd', action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(AboutPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(weadingPlan.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(weadingPlan.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(weadingPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(GetSinglResource.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetSinglResource.fulfilled, (state, action) => {
        state.resource = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(GetSinglResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

  },
});

export default apiSlice.reducer;

