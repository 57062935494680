import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Spritual(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        let data = { uid: params.id, login_id: auth._id };

    }, []);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">
                <div class="box">
                    <h2><img src="/images/pro-1.png" alt="" />Spiritual</h2>
                    <div class="row">

                        <div class="col-sm-6">
                            <h3>Are you associated/affiliated with any spiritual organization?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.affiliated_with_spiritual_organization}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>How long are you affiliated with the organization?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.affiliated_duration ? props.data.spirituals.affiliated_duration : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h3>How much time you spend daily on your spiritual sadhana?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.sadhanaTime ? props.data.spirituals.sadhanaTime : ""}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Since when have you been practicing Krishna Consciousness? *</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.incorporating_Krishna_Consciousness ? props.data.spirituals.incorporating_Krishna_Consciousness : "-"}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>On a daily basis, how many rounds of the Hare Krishna Mahamantra do you chant?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.chanting_rounds ? props.data.spirituals.chanting_rounds : "-"}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>How often do you visit temple?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.temple_visit ? props.data.spirituals.temple_visit : "-"}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Spirituality's role in your life?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.spirituality_role ? props.data.spirituals.spirituality_role : "-"}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Who is your spiritual master?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.spiritual_master ? props.data.spirituals.spiritual_master : "-"}</p>
                        </div>

                        <div class="col-sm-12">
                            <h3>Are you initiated?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.initiated ? props.data.spirituals.initiated : "-"}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Do you follow Four Regulative Principles</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.four_Regulative_Principles ? props.data.spirituals.four_Regulative_Principles : "-"}</p>                             </div>

                        <div class="col-sm-6">
                            <h3>Do your parents practice Krishna Consciousness?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.parents_practice_Krishna ? props.data.spirituals.parents_practice_Krishna : "-"}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Select the list of Srila Prabhupada's books read by you</h3>
                            {props.data && props.data.spirituals && props.data.spirituals.selectedBooks.length > 0 ?
                                <ul>
                                    {props.data.spirituals.selectedBooks.map((item, i) => (
                                        <li>{item.value}</li>
                                    ))}

                                </ul>
                                : "-"}
                        </div>

                        <div class="col-sm-6">
                            <h3>Do you observe fasting on Ekadashi days?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.ekadashi_fasting ? props.data.spirituals.ekadashi_fasting : "-"}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Which ISKCON/HKM Centre are you associated with?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.ekadashi_fasting ? props.data.spirituals.ekadashi_fasting : "-"}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>How are you associated with the temple?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.iskcon_associted_with ? props.data.spirituals.iskcon_associted_with : "-"}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.attended_seminar ? props.data.spirituals.attended_seminar : "-"}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Are you open to engaging in devotional services or seva together as a couple?</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.engaged_devotional_services ? props.data.spirituals.engaged_devotional_services : "-"}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}