import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { API_BASE_URL, HeaderData } from "../../Pages/Data/content";
import { useDispatch, useSelector } from 'react-redux';




export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth.auth);



  useEffect(() => {
    console.log('localStorage', auth);
  }, []);



  return (
    <header>
      <section id="header">
        <div class="container">
          <nav class="navbar navbar-expand-lg">
            <Link to="/">
              <a class="navbar-brand" ><img src="images/Logo.png" alt="" /></a></Link>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div class="w-100">
                <ul class="navbar-nav">
                  {HeaderData && HeaderData.map((data, i) => (
                    <li class="nav-item" key={i}>
                      <Link to={data.link} className={`nav-link ${location.pathname === data.link ? 'active' : ''}`} aria-current="page" >{data.name}</Link>                    </li>
                  ))}

                </ul>
              </div>
            </div>

            <div class="login_reponsive">


              {auth && auth.userType ?
                <Link to={auth.userType === "User" ? "/UserDashboard" : "/MakerDashboard"}><a class="btn" ><img className="d-inline-block" src={API_BASE_URL + auth.profileImage} alt="" /> <label>    {auth.name && auth.name.length > 7 ? auth.name.substring(0, 7) : auth.name}
                </label> </a>
                </Link>
                :
                <Link to="/Login">
                  <a class="btn"><img src="images/Login-btn.png" alt="" /> <label>Login /
                    Register</label> </a></Link>}


              <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="bi bi-list"></i></span>
              </button>

            </div>

          </nav>
        </div>
      </section>
    </header>

  );
}
