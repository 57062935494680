import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetPendingRecomendations } from '../makerApi';


export const GetPendingReco = createAsyncThunk('GetFtmUsers', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetPendingRecomendations(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const pendingRecomendationSlice = createSlice({
    name: 'pendingRecomendation',
    initialState: {
        data: [],
        success: false,
        error: null,
    },
    reducers: {
        clearPendingReco: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetPendingReco.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(GetPendingReco.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetPendingReco.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })

    },
});
export const { clearPendingReco } = pendingRecomendationSlice.actions;

export default pendingRecomendationSlice.reducer;

