import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";

export default function Importance(props) {

    return (
        <section id="importance_sec">
            <div class="container">
                <h1>Importance of Kundali matching</h1>
                <h2>{props.data && props.data.title}</h2>
                {props.data &&
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="box">
                                <p>{props.data.content}
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="box_two mb-3">
                                <h3>{props.data.heading1}</h3>
                                <p>{props.data.text1}</p>
                            </div>

                            <div class="box_two mb-3">
                                <h3>{props.data.heading2}</h3>
                                <p>{props.data.text2}</p>
                            </div>

                            <div class="box_two">
                                <h3>{props.data.heading3}</h3>
                                <p>{props.data.text3}</p>
                            </div>
                        </div>

                    </div>
                }

            </div>
        </section>
    )

}