import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserProfileSide, GetUserTabsData, SaveProfilePhoto } from '../api';

export const GetSideProfile = createAsyncThunk('GetSideProfile', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUserProfileSide(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetTabsData = createAsyncThunk('GetUserTabsData', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUserTabsData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const UpdateProfilePic = createAsyncThunk('UpdateProfilePic', async (formData, { rejectWithValue }) => {
  try {
    const response = await SaveProfilePhoto(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});



const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState: {
    data: {},
    tabData : {},
    success: null,
    error: null,
    successPro : null,
    errorPro : null

  },
  reducers: {
    clearMYProData: state => {
      state.success = false;
      state.error = null;
      state.successPro = false;
      state.errorPro = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(GetSideProfile.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetSideProfile.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetSideProfile.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetTabsData.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetTabsData.fulfilled, (state, action) => {
        state.tabData = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetTabsData.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(UpdateProfilePic.pending, state => {
        state.successPro = false;
        state.errorPro = null;
      })
      .addCase(UpdateProfilePic.fulfilled, (state, action) => {
        state.successPro = action.payload;
        state.errorPro = null;
      })
      .addCase(UpdateProfilePic.rejected, (state, action) => {
        state.successPro = false;
        state.errorPro = action.payload;
      })
  },
});
export const { clearMYProData } = myProfileSlice.actions;

export default myProfileSlice.reducer;

