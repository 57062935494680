import React, { Component, useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_BASE_URL } from "../../Data/content";

export default function HomeSlider(props) {


    useEffect(() => {

    });

    const homeOptions = {
        loop: true,
        autoplay: true,
        nav: true,
        dots: false,
        navText: ['<div class="swiper-button-prev"></div>', '<div class="swiper-button-next"></div>'],
        autoplayTimeout: 5000,
        margin: 0,
        responsiveClass: true,
        responsive: {
            0: { items: 1, nav: false },
            576: { items: 1, nav: true },
            768: { items: 1, nav: true },
            992: { items: 1, nav: true },
            1200: { items: 1, nav: true }
        }
    };

    const closeSlider = () => {
        props.sendResponseToParent(false);
    };

    return (
        <>
            <div class="slider_modal">
                <div class="sh_modal">
                    <div class="text-end mb-3">
                        <button class="btn-close btn-close-home" onClick={closeSlider}></button>
                    </div>
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            {props.data &&

                                <OwlCarousel className='owl-theme' {...homeOptions}>
                                    {props.data.map((item, i) => (
                                        <div class="swiper-slide" style={{ backgroundImage: `url(${API_BASE_URL + item.image})` }}>
                                            <div className="para" >
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>

                            }
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
}


// style="transition-duration: 0ms; transform: translate3d(-4508px, 0px, 0px);"