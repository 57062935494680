import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AcceptRejcteRcd, AcceptRejectInterest, GetViewProfileSide, RemoveConnection, SendProfileInterrest, clearViewPro } from "../../../../Reducer/UserDashboardSlices/viewProfileSlice";
import { API_BASE_URL } from "../../Data/content";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import firebaseConfig from "../../../../Firebase";
import BlockUnblock from "../../Common/BlockUnblock";
import { clearChatStatus} from "../../../../Reducer/chatSlice";

export default function SideProfile(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [blockPopup, setBlockPopup] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.viewProfile.success);
    const error = useSelector(state => state.viewProfile.error);
    const success1 = useSelector(state => state.chat.success);
    const error1 = useSelector(state => state.chat.error);

    if (success1 && success1.status === 200) {
        if (success1.data.data) {
            dispatch();
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success1.data.message,
                icon: 'success'
            }).then(() => {
                // Reload the page
              navigate(-1);
            });


            setBlockPopup(false);
            dispatch(clearChatStatus());

        }
    }


    if (error1 && error1.status) {
        switch (error1.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error1.data.message,
                    icon: 'error'
                });
                dispatch(clearChatStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error1.data.message,
                    icon: 'error'
                });
                dispatch(clearChatStatus());
                break;
        }
    }


    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearViewPro());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearViewPro());

        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearViewPro());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearViewPro());
                break;
        }
    }


    // console.log('props', props.data);

    const removeMatch = (id) => {
        let dat = { uid: auth._id, removedId: id };
        dispatch(RemoveConnection(dat));

    }

    const Accept = (type, id) => {
        let dat = { sent_to: auth._id, sent_by: id, status: type };
        dispatch(AcceptRejectInterest(dat));

    }

    const Approval = (type, id, gender) => {
        if (gender === "Male") {
            let data = { girl_id: auth._id, type: type, boy_id: id, gender: "Male", loginId: auth._id };
            dispatch(AcceptRejcteRcd(data));
        } else {
            let data = { boy_id: auth._id, type: type, girl_id: id, gender: "Female", loginId: auth._id };
            dispatch(AcceptRejcteRcd(data));
        }
    }


    const SendInterest = (id) => {
        let data = { sent_by: auth._id, sent_to: id };
        dispatch(SendProfileInterrest(data));

    }

    const BlockUser = async (data) => {
        let dat = { _id: data._id, profileImage: data.profileImage, name: data.name, block: data.block, block_by: data.block_by, userType: data.userType };
        setSelectedUser(dat);
        setBlockPopup(true);
        // var ref = firebaseConfig.database().ref(`MessagesList/${auth._id}/${props.data._id}`)
        // await ref.once('value', function (snapshot) {
        //     if (snapshot.exists()) {
        //         ef.update({
        //             block: false,
        //             block_by: null
        //         });
        //     }
        // });

        // var ref2 = firebaseConfig.database().ref(`MessagesList/${props.data._id}/${auth._id}`);
        // await ref2.once('value', function (snapshot) {
        //     if (snapshot.exists()) {
        //         ref2.update({
        //             block: false,
        //             block_by: null
        //         });
        //     }
        // });
        // let dat = { block_by: auth._id, blocked_user: props.data._id, type: "block" };
        // dispatch(updateBlockStatus(dat));

    }


    useEffect(() => {


    }, []);

    return (
        <>
            <div class="col-xl-4 mb-3">
                <div class="box">

                    <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {props.data && props.data.gallryVerified && props.data.gallryVerified.photos && props.data.gallryVerified.photos.length > 0 && props.data.gallryVerified.photos.map((imageUrl, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators2"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                    aria-current={index === 0 ? 'true' : 'false'}
                                    aria-label={`Slide ${index + 1}`}
                                />
                            ))}
                        </div>
                        <div className="carousel-inner">
                            {props.data.gallryVerified && props.data.gallryVerified.photos && props.data.gallryVerified.photos.length > 0 && props.data.gallryVerified.photos.map((imageUrl, index) => (
                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                    {/* style={{ backgroundImage: `url(${API_BASE_URL + imageUrl})` }} */}
                                    <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl }/>
                                </div>
                            ))}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    {/* <!-- Content --> */}
                    <h2>{props.data && props.data.name}<img src="images/verify.png" alt="" /></h2>
                    <h3>Profile Match %
                        <div style={{ width: 40, height: 20 }}>
                            <CircularProgressbar value={props.data.OverAll} text={`${props.data.OverAll}%`}
                                styles={{
                                    path: {
                                        stroke: '#28A745', // You can replace '#FF6347' with 
                                    },
                                    text: {
                                        fill: '#000000', // You can replace '#000000' with 
                                    },
                                }} />
                        </div>

                    </h3>

                    <h4><span><img src="/images/k5.png" alt="" />Astro Match</span><span>
                        <div style={{ width: 40, height: 20, color: 'red' }}>
                            <CircularProgressbar value={props.data.HorscopePercentage} text={`${props.data.HorscopePercentage}%`}
                                styles={{
                                    path: {
                                        stroke: '#FF6347', // You can replace '#FF6347' with 
                                    },
                                    text: {
                                        fill: '#000000', // You can replace '#000000' with 
                                    },
                                }}
                            />
                        </div>

                    </span></h4>
                    <h4><span><img src="/images/k6.png" alt="" />Spiritual </span><span>

                        <div style={{ width: 40, height: 20 }}>
                            <CircularProgressbar value={props.data.SpritualPercentage} text={`${props.data.SpritualPercentage}%`}
                                styles={{
                                    path: {
                                        stroke: '#28A745', // You can replace '#FF6347' with 
                                    },
                                    text: {
                                        fill: '#000000', // You can replace '#000000' with 
                                    },
                                }} />
                        </div>
                    </span></h4>
                    <h4><span><img src="/images/k7.png" alt="" />Socio-Cultural </span><span>

                        <div style={{ width: 40, height: 20 }}>
                            <CircularProgressbar value={props.data.SocialPercenatge} text={`${props.data.SocialPercenatge}%`}
                                styles={{
                                    path: {
                                        stroke: '#F8BA41', // You can replace '#FF6347' with 
                                    },
                                    text: {
                                        fill: '#000000', // You can replace '#000000' with 
                                    },
                                }} />
                        </div>
                    </span></h4>
                    <h4><span><img src="/images/k8.png" alt="" />Economical</span><span>

                        <div style={{ width: 40, height: 20 }}>
                            <CircularProgressbar value={props.data.EconomicPercentage} text={`${props.data.EconomicPercentage}%`}
                                styles={{
                                    path: {
                                        stroke: '#FF6347', // You can replace '#FF6347' with 
                                    },
                                    text: {
                                        fill: '#000000', // You can replace '#000000' with 
                                    },
                                }} />
                        </div>
                    </span></h4>

                    <div class="d-flex justify-content-between">

                        {props.data.statusChecked && props.data.statusChecked === "Connected" &&
                            <>
                                <a class="btn shortlist" onClick={() => removeMatch(props.data._id)}>Remove</a>

                            </>
                        }
                        {props.data && props.data.statusChecked && props.data.statusChecked === "Request Pending" &&
                            <>
                                <a class="btn shortlist" onClick={() => Accept('Accepted', props.data._id)}>Accept Request</a>
                                <a class="btn shortlist" onClick={() => Accept('Rejected', props.data._id)}>Reject Request</a>

                            </>

                        }

                        {props.data && props.data.statusChecked && props.data.statusChecked === "Recomendation Pending" &&
                            <>
                                <a class="btn shortlist" onClick={() => Approval("Accepted", props.data._id, props.data.basic.gender)}>Accept Recommendation</a>
                                <a class="btn shortlist" onClick={() => Approval("Rejected", props.data._id, props.data.basic.gender)}>Reject Recommendation</a>

                            </>

                        }


                        {props.data && props.data.statusChecked && props.data.statusChecked === "Send Request" &&
                            <>
                                <a class="btn shortlist" onClick={() => SendInterest(props.data._id)}>Send request</a>

                            </>

                        }
                        {props.data && props.data.statusChecked && props.data.statusChecked === "Request Sent" &&
                            <>
                                <a class="btn shortlist" >Interest Sent</a>
                            </>

                        }
                        <a class="btn" onClick={() => BlockUser(props.data)}>Block Connection</a>

                    </div>
                </div>
            </div>

            {blockPopup === true &&
                <BlockUnblock data={props.data} setBlockPopup={setBlockPopup} />
            }
        </>
    )

}