import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { setUser } from './Reducer/authSlice';

 
const user = localStorage.getItem('user')!=='undefined' ? JSON.parse(localStorage.getItem('user')):"";


if (user) {
  store.dispatch(setUser(user));
 
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

