import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { familyCulture, prefix2, prefix1, familyStatus, ChildWish } from "../../Data/content";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import { GetFamilyInfo, SaveFamilyInfo, clearStateFamily } from "../../../../Reducer/StepForms/familySlice";
import Swal from 'sweetalert2';


export default function Family() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [family, setFamily] = useState({});
    const [location, setLocation] = useState(null);
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [errors, setFormErrors] = useState({});
    const error = useSelector(state => state.family.error);
    const success = useSelector(state => state.family.success);
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const [submitType, setSubmitType] = useState("");


    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetFamilyInfo(dat));
        if (res.payload && res.payload.data.data) {
            setFamily(res.payload.data.data);
            setLocation({
                value: "",
                label: res.payload.data.data.location
            });

        }
    }

    const navigationn = () => {
        setMsg("");
        let name = "Career";
        navigate("/UserStepForms", { state: { name } });
    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearStateFamily());
        } else {
            // setBtn(true);
            if(submitType === "draft"){
                setMsg(success.data.message);
                dispatch(clearStateFamily());
            }else{
                dispatch(clearStateFamily());
                navigationn();
            } 
        }
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStateFamily());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStateFamily());
                break;
        }
    }

    async function handleSubmit(e) {
        debugger
        setMsg("");
        e.preventDefault();
        let formData = new FormData();
        formData.append('uid', auth._id);
        formData.append('father_prefix', family && family.father_prefix ? family.father_prefix : "Mr");
        formData.append('father_name', family && family.father_name ? family.father_name : "");
        formData.append('mother_prefix', family && family.mother_prefix ? family.mother_prefix : "Ms");
        formData.append('mother_name', family && family.mother_name ? family.mother_name : "");
        formData.append('father_occcupation', family && family.father_occcupation ? family.father_occcupation : "");
        formData.append('mother_occcupation', family && family.mother_occcupation ? family.mother_occcupation : "");
        formData.append('familyCulture', family && family.familyCulture ? family.familyCulture : "Traditional");
        formData.append('location', location && location.label ? location.label : "");
        formData.append('latitude', lat ? lat : "");
        formData.append('longitude', long ? long : "");
        formData.append('family_status', family && family.family_status ? family.family_status : "Lower class");
        formData.append('brother_count', family && family.brother_count ? family.brother_count : 0);
        formData.append('brother_married', family && family.brother_married ? family.brother_married : 0);
        formData.append('sister_count', family && family.sister_count ? family.sister_count : 0);
        formData.append('sister_married', family && family.sister_married ? family.sister_married : 0);
        formData.append('younger_brother', family && family.younger_brother ? family.younger_brother : 0);
        formData.append('elder_brother', family && family.elder_brother ? family.elder_brother : 0);
        formData.append('younger_sister', family && family.younger_sister ? family.younger_sister : 0);
        formData.append('elder_sister', family && family.elder_sister ? family.elder_sister : 0);

        if (e.nativeEvent.submitter.name === 'draft') {
            setSubmitType('draft');
            formData.append('draft', 'true');
            dispatch(SaveFamilyInfo(formData));
        } else {
            let values = validate(family);
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            }
            if (chk) {
                setSubmitType('next');
                formData.append('draft', 'false');
                formData.append('saved', true);
                dispatch(SaveFamilyInfo(formData));
            } else {
                setFormErrors(validate(family));
            }
        }
    };

    const validate = (values) => {
        let error = {};
        if (!values.father_prefix || (values.father_prefix.trim() === "" && values.father_prefix !== "Mr")) {
            errors.father_prefix = "This field is required!";
        }

        if (!values.father_name || values.father_name.trim() === "") {
            error.father_name = "This field is required!";
        }

        if (!values.mother_prefix || (values.mother_prefix.trim() === "" && values.mother_prefix !== "Ms")) {
            errors.mother_prefix = "This field is required!";
        }

        if (!values.mother_name || values.mother_name.trim() === "") {
            error.mother_name = "This field is required!";
        }
        if (!values.father_occcupation || values.father_occcupation.trim() === "") {
            error.father_occcupation = "This field is required!";
        }
        if (!values.mother_occcupation || values.mother_occcupation.trim() === "") {
            error.mother_occcupation = "This field is required!";
        }
        if (!values.familyCulture || (values.familyCulture.trim() === "" && values.familyCulture !== "Traditional")) {
            errors.familyCulture = "This field is required!";
        }

        if (location.label === "") {
            error.location = "This field is required!";
        }


        if (!values.family_status || (values.family_status.trim() === "" && values.family_status !== "Lower class")) {
            errors.family_status = "This field is required!";
        }


        const brotherFields = ['brother_count', 'brother_married', 'younger_brother', 'elder_brother'];
        brotherFields.forEach(field => {
            if (values[field] === undefined || values[field] === null || values[field] === "") {
                error[field] = "This field is required!";
            }
        });
        const sisterFields = ['sister_count', 'sister_married', 'younger_sister', 'elder_sister'];
        sisterFields.forEach(field => {
            if (values[field] === undefined || values[field] === null || values[field] === "") {
                error[field] = "This field is required!";
            }
        });
        const totalBrothers = parseInt(values.younger_brother || 0, 10) + parseInt(values.elder_brother || 0, 10);
        if (parseInt(values.brother_count, 10) !== totalBrothers) {
            error.brother_count = "Brother count should be the sum of younger and elder brothers!";
        }
        if (parseInt(values.brother_married, 10) > parseInt(values.brother_count, 10)) {
            error.brother_married = "Married brother count cannot be greater than total brother count!";
        }
        const totalSisters = parseInt(values.younger_sister || 0, 10) + parseInt(values.elder_sister || 0, 10);
        if (parseInt(values.sister_count, 10) !== totalSisters) {
            error.sister_count = "Sister count should be the sum of younger and elder sisters!";
        }
        if (parseInt(values.sister_married, 10) > parseInt(values.sister_count, 10)) {
            error.sister_married = "Married sister count cannot be greater than total sister count!";
        }

        return error;
    }



    const handleChangeInput = (e) => {
        const value = e.target.value;
        setFamily({
            ...family,
            [e.target.name]: value
        });
    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation({
                value: value.value,
                label: value.label
            });
            setLat(latLng.lat);
            setLong(latLng.lng);

            console.log('value.label', value);

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="tab">
                    <h3>Family Background</h3>

                    <div class="row">

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4">
                                    <div class="form-group">
                                        <label class="form-label">Prefix*</label>
                                        <select class="form-select" aria-label="Default select example" name="father_prefix" onChange={handleChangeInput} >
                                            {/* <option selected disabled>Select</option> */}
                                            {prefix1.length > 0 && prefix1.map((item, i) => (
                                                <option selected={family && family.father_prefix === item} value={item}>{item}</option>
                                            ))}
                                        </select>
                                        {errors.father_prefix && <p className={"error__feedback"}>{errors.father_prefix}</p>}

                                    </div>
                                </div>
                                <div class="col-xl-9 col-md-8 col-sm-9 col-8">
                                    <div class="form-group">
                                        <label class="form-label">Father’s Name*</label>
                                        <input type="text" class="form-control" name="father_name" onChange={handleChangeInput} defaultValue={family && family.father_name} spellCheck={true} />
                                        {errors.father_name && <p className={"error__feedback"}>{errors.father_name}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-xl-3 col-md-4 col-sm-3 col-4">
                                    <div class="form-group">
                                        <label class="form-label">Prefix*</label>
                                        <select class="form-select" aria-label="Default select example" name="mother_prefix" onChange={handleChangeInput} >
                                            {/* <option selected disabled>Select</option> */}
                                            {prefix2.length > 0 && prefix2.map((item, i) => (
                                                <option selected={family && family.mother_prefix === item} value={item}>{item}</option>
                                            ))}
                                        </select>
                                        {errors.mother_prefix && <p className={"error__feedback"}>{errors.mother_prefix}</p>}
                                    </div>
                                </div>
                                <div class="col-xl-9 col-md-8 col-sm-9 col-8">
                                    <div class="form-group">
                                        <label class="form-label">Mother’s Name*</label>
                                        <input type="text" class="form-control" name="mother_name" onChange={handleChangeInput} defaultValue={family && family.mother_name} spellCheck={true} />
                                        {errors.mother_name && <p className={"error__feedback"}>{errors.mother_name}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Father's Occupation*</label>
                                <input type="text" class="form-control" name="father_occcupation" onChange={handleChangeInput} defaultValue={family && family.father_occcupation} spellCheck={true} />
                                {errors.father_occcupation && <p className={"error__feedback"}>{errors.father_occcupation}</p>}
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Mother's Occupation*</label>
                                <input type="text" class="form-control" name="mother_occcupation" onChange={handleChangeInput} defaultValue={family && family.mother_occcupation} spellCheck={true} />
                                {errors.mother_occcupation && <p className={"error__feedback"}>{errors.mother_occcupation}</p>}
                            </div>
                        </div>


                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Family Cultural Values or Practices*</label>
                                <select class="form-select" aria-label="Default select example" name="familyCulture" onChange={handleChangeInput} >
                                    {/* <option selected disabled>Select</option> */}
                                    {familyCulture.length > 0 && familyCulture.map((item, i) => (
                                        <option selected={family && family.familyCulture === item} value={item}>{item}</option>
                                    ))}

                                </select>
                                {errors.familyCulture && <p className={"error__feedback"}>{errors.familyCulture}</p>}
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Family Location*</label>
                                <PlacesAutocomplete
                                    selectProps={{
                                        value: location,
                                        onChange: handleSelect,
                                    }}
                                    query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                    fetchDetails={true}
                                    onFail={error => console.log(error)}
                                />
                                <input name="location" className="service-input" type="hidden" placeholder="Enter address" value={location} />
                                {errors.location && <p className={"error__feedback"}>{errors.location}</p>}
                            </div>
                        </div>


                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Family Status*</label>
                                <select class="form-select" aria-label="Default select example" name="family_status" onChange={handleChangeInput}>
                                    {/* <option selected disabled>Select</option> */}
                                    {familyStatus.length > 0 && familyStatus.map((item, i) => (
                                        <option selected={family && family.family_status === item} value={item}>{item}</option>
                                    ))}

                                </select>
                                {errors.family_status && <p className={"error__feedback"}>{errors.family_status}</p>}

                            </div>
                        </div>

                        {/* <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">How many children you wish to have after marriage?*</label>
                                <select class="form-select" aria-label="Default select example" name="children" onChange={handleChangeInput}>
                                    <option selected disabled>Select</option>
                                    {ChildWish.length > 0 && ChildWish.map((item, i) => (
                                        <option selected={family && family.children === item} value={item}>{item}</option>
                                    ))}
                                </select>   {errors.children && <p className={"error__feedback"}>{errors.children}</p>}

                            </div>
                        </div> */}



                        <div class="col-12 mt-2">
                            <h3>Siblings Details</h3>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">No. of Brothers*</label>
                                        <input type="number" class="form-control" name="brother_count" min="0" max="999" onChange={handleChangeInput} defaultValue={family && family.brother_count} />
                                        {errors.brother_count && <p className={"error__feedback"}>{errors.brother_count}</p>}
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">No of brother married*</label>
                                        <input type="number" class="form-control" name="brother_married" onChange={handleChangeInput} defaultValue={family && family.brother_married} min="0" max="999" />
                                        {errors.brother_married && <p className={"error__feedback"}>{errors.brother_married}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">No. of Sisters*</label>
                                        <input type="number" class="form-control" name="sister_count" onChange={handleChangeInput} defaultValue={family && family.sister_count} min="0" max="999" />
                                        {errors.sister_count && <p className={"error__feedback"}>{errors.sister_count}</p>}
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">No of sister married*</label>
                                        <input type="number" class="form-control" name="sister_married" onChange={handleChangeInput} defaultValue={family && family.sister_married} min="0" max="999" />
                                        {errors.sister_married && <p className={"error__feedback"}>{errors.sister_married}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Younger Brother*</label>
                                        <input type="number" class="form-control" name="younger_brother" onChange={handleChangeInput} defaultValue={family && family.younger_brother} min="0" max="999" />
                                        {errors.younger_brother && <p className={"error__feedback"}>{errors.younger_brother}</p>}
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Elder Brother*</label>
                                        <input type="number" class="form-control" name="elder_brother" onChange={handleChangeInput} defaultValue={family && family.elder_brother} min="0" max="999" />
                                        {errors.elder_brother && <p className={"error__feedback"}>{errors.elder_brother}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Younger Sister*</label>
                                        <input type="number" class="form-control" name="younger_sister" onChange={handleChangeInput} defaultValue={family && family.younger_sister} min="0" max="999" />
                                        {errors.younger_sister && <p className={"error__feedback"}>{errors.younger_sister}</p>}
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Elder Sister*</label>
                                        <input type="number" class="form-control" name="elder_sister" onChange={handleChangeInput} defaultValue={family && family.elder_sister} min="0" max="999" />
                                        {errors.elder_sister && <p className={"error__feedback"}>{errors.elder_sister}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div class="step_common_btn">
                    <div class="row">
                        <div class="col-sm-6" >
                            <button class="btn two" type="submit" name="draft" disabled={family && family.saved && family.saved === true}>Save Draft</button>
                        </div>

                        <div class="col-sm-6 text-end">
                            <a class="btn" onClick={() => navigationn()}>Skip</a>
                            {btnst === false ? <button class="btn" type="submit"
                            >Next</button> :
                            <button type="button"  class="btn"
                            onClick={() => navigationn()}>Next</button>}
                        </div>
                        {msg !== "" && <p class="SucessColor">{msg}</p>}
                    </div>

                </div>
            </form>

        </>
    )

}