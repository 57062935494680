import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetSideProfile } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";
import { API_BASE_URL } from "../../../Data/content";


export default function SideProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.data);
    const success = useSelector(state => state.myProfile.success);

    useEffect(() => {
        let dat = { uid: auth._id };
        dispatch(GetSideProfile(dat));
    }, []);


    // const openSettings =()=>{
    //     navigate("/AccountSettings");
    // }

    return (
        <>
            <div class="col-xl-4 mb-3">
                <div class="box">
                    {/* <!-- slider --> */}
                    <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {data && data.gallry && data.gallry.photos && data.gallry.photos.length > 0 && data.gallry.photos.map((imageUrl, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators2"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                    aria-current={index === 0 ? 'true' : 'false'}
                                    aria-label={`Slide ${index + 1}`}
                                />
                            ))}
                        </div>
                        <div className="carousel-inner">
                            {data && data.gallry && data.gallry.photos && data.gallry.photos.length > 0 && data.gallry.photos.map((imageUrl, index) => (
                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                    {/* style={{ backgroundImage: `url(${API_BASE_URL + imageUrl})` }} */}
                                     <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl }/>
                                </div>
                            ))}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    {/* <!-- Content --> */}
                    <h2>{data && data.basic && data.basic.name}<img src="images/verify.png" alt="" /></h2>
                    <div class="profile_state">
                        <h6><img src="images/Phone.png" />Phone <span>{data && data.mobile}</span></h6>
                        <h6><img src="images/Mail.png" />Email <span>{data && data.email}</span></h6>

                        <h5>Profile Statistics</h5>
                        <ul>
                            <li><span>{data && data.acceptCount}</span>Interest Accepted</li>
                            <li><span>{data && data.interestCount}</span>Interest Recieved</li>
                            <li><span>{data && data.profileView}</span>Viewed Your Profile</li>
                            <li><span>{data && data.shortCount}</span>Shortlisted You</li>
                        </ul>
                    </div>
                    {/* <div class="settings_option" onClick={()=> openSettings()}>
                        <h2><i class="fa fa-cog" aria-hidden="true"></i> <span>Account Settings</span></h2>
                    </div> */}
                </div>
            </div>

        </>
    )

}