import { NavLink, useNavigate, Link, useLocation, } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";

export default function Results({ uerData }) {
    const navigate = useNavigate();

    console.log("typeeeee", uerData.data2.conclusion);


    return (
        <section id="matching_results">
            <div class="container">
                <h1><span><img src="images/kundali-img.png" alt="" /></span>Kundali Matching Results</h1>

                <ul>
                    <li class="active"><span><img src="images/kundali-img.png" alt="" /></span>
                        <h5>Ashtakoot</h5>
                        <p>{uerData && uerData.data && uerData.data.total && uerData.data.total.received_points} / {uerData && uerData.data && uerData.data.total && uerData.data.total.total_points}</p>
                    </li>

                    <li><span><img src="images/kundali-img.png" alt="" /></span>
                        <h5>Mangleek Match</h5>
                        <p>{uerData && uerData.data2 && uerData.data2.conclusion && uerData.data2.conclusion.match === true ? "Yes" : "No"}</p>
                    </li>

                </ul>

            </div>
        </section>

    )

}