import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SingleFtmSideDetail, GetSingleFtmMatches } from '../makerApi';


export const GetUsrSideData = createAsyncThunk('GetListOfUsers', async (formData, { rejectWithValue }) => {
    try {
        const response = await SingleFtmSideDetail(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetFtmMatches = createAsyncThunk('GetFtmMatches', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetSingleFtmMatches(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const singleFTMSlice = createSlice({
    name: 'singleFtm',
    initialState: {
        data: null,
        loading: false,
        error: null,
        Matches: [],
    },
    reducers: {
        clearFtm: state => {
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(GetUsrSideData.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetUsrSideData.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.loading = false;
                state.error = null;
            })
            .addCase(GetUsrSideData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(GetFtmMatches.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetFtmMatches.fulfilled, (state, action) => {
                state.Matches = action.payload.data.data;
                state.loading = false;
                state.error = null;
            })
            .addCase(GetFtmMatches.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

    },
});

export const { clearFtm } = singleFTMSlice.actions;
export default singleFTMSlice.reducer;

