import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab4({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "carrer" };
        // dispatch(ViewProfileTabs(data));
    }, []);

    return (
        <>
            <div class="set">

                {data && data.privacysetups && data.privacysetups.carrer_privacy === "Everyone" &&
                    <div class="row">

                        <div class="col-12">
                            <h5>Career Details</h5>
                        </div>


                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Employer’s Name</h4>
                            <p>{data && data.carrers && data.carrers.company_name ? data.carrers.company_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Profession</h4>
                            <p>{data && data.carrers && data.carrers.occupation ? data.carrers.occupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Annual Income</h4>
                            <p>{data && data.carrers && data.carrers.income ? data.carrers.income : "-"}</p>
                        </div>

                        {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <h4>Working with</h4>
                <p>Non-profit</p>
            </div> */}

                        <div class="col-12">
                            <h4>Job Location</h4>
                            <p>{data && data.carrers && data.carrers.job_location ? data.carrers.job_location : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h5>Education Details</h5>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Highest Qualification</h4>
                            <p>{data && data.carrers && data.carrers.Ed_qalification ? data.carrers.Ed_qalification : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>College</h4>
                            <p>{data && data.carrers && data.carrers.Ed_institution ? data.carrers.Ed_institution : "-"}</p>
                        </div>

                    </div>
                }

                {data && data.privacysetups && data.privacysetups.carrer_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="row">

                        <div class="col-12">
                            <h5>Career Details</h5>
                        </div>


                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Employer’s Name</h4>
                            <p>{data && data.carrers && data.carrers.company_name ? data.carrers.company_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Profession</h4>
                            <p>{data && data.carrers && data.carrers.occupation ? data.carrers.occupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Annual Income</h4>
                            <p>{data && data.carrers && data.carrers.income ? data.carrers.income : "-"}</p>
                        </div>

                        {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
<h4>Working with</h4>
<p>Non-profit</p>
</div> */}

                        <div class="col-12">
                            <h4>Job Location</h4>
                            <p>{data && data.carrers && data.carrers.job_location ? data.carrers.job_location : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h5>Education Details</h5>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Highest Qualification</h4>
                            <p>{data && data.carrers && data.carrers.Ed_qalification ? data.carrers.Ed_qalification : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>College</h4>
                            <p>{data && data.carrers && data.carrers.Ed_institution ? data.carrers.Ed_institution : "-"}</p>
                        </div>

                    </div>
                }
            </div>

        </>
    )

}