import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { API_BASE_URL } from "../../Data/content";


export default function Approach(props) {

    return (
        <>
            <section id="our_approach">
                <div class="container">
                    <h1>Our Approach</h1>
                    <h2>{props.data && props.data.heading}</h2>
                    <h3>{props.data && props.data.content}</h3>

                    <div class="row">

                        <div class="col-lg-4 col-md-6 mb-lg-0 mb-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src={props.data && props.data.img1 && API_BASE_URL + props.data.img1} alt="" />
                                </div>
                                <h4>{props.data && props.data.head1}</h4>
                                <p>{props.data && props.data.text1}</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mb-lg-0 mb-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src={props.data && props.data.img2 && API_BASE_URL + props.data.img2} alt="" />
                                </div>
                                <h4>{props.data && props.data.head2}</h4>
                                <p>{props.data && props.data.text2}</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mb-lg-0 mb-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src={props.data && props.data.img3 && API_BASE_URL + props.data.img3} alt="" />
                                </div>
                                <h4>{props.data && props.data.head3}</h4>
                                <p>{props.data && props.data.text3}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )

}