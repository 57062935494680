import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetMatchingViewDetails, SendRecommendation } from '../makerApi';


export const GetMatchingDetails = createAsyncThunk('GetMatchingDetails', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetMatchingViewDetails(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const RecommendToUser = createAsyncThunk('RecommendToUser', async (formData, { rejectWithValue }) => {
    try {
        const response = await SendRecommendation(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



const matchingFtmSlice = createSlice({
    name: 'matchingFtm',
    initialState: {
        data: null,
        loading: false,
        error: null,
        ViewdIds: [],

    },
    reducers: {
        clearMatchFtm: state => {
            state.loading = false;
            state.error = null;
        },

        addViewdIds: (state, action) => {
            if (!state.ViewdIds.includes(action.payload)) {
                state.ViewdIds.push(action.payload);
            }
        },

        clearIds: state => {
            state.ViewdIds = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(GetMatchingDetails.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetMatchingDetails.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.loading = action.payload;
                state.error = null;
            })
            .addCase(GetMatchingDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(RecommendToUser.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(RecommendToUser.fulfilled, (state, action) => {
                // state.data = action.payload.data.data;
                state.loading = action.payload;
                state.error = null;
            })
            .addCase(RecommendToUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

    },
});

export const { clearMatchFtm, addViewdIds, clearIds } = matchingFtmSlice.actions;
export default matchingFtmSlice.reducer;

