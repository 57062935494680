import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';


export default function AstroMatch({ user1, user2 }) {

    useEffect(() => {


    }, []);


    return (
        <>

            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">


                <div class="row">


                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Astro</h2>
                            <div class="row">
                                <div class="col-sm-4 col-6">
                                    <h3>Date of Birth</h3>
                                    <p>{user1 && user1.astro && user1.astro.dob}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Time of Birth</h3>
                                    <p>{user1 && user1.astro && user1.astro.time} {user1 && user1.astro && user1.astro.ampm}</p>

                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Place of Birth</h3>
                                    <p>{user1 && user1.astro && user1.astro.birth_place}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Nakshatra</h3>
                                    <p>{user1 && user1.astro && user1.astro.nakshtra}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mangalik / Chevval Dosham</h3>
                                    <p>{user1 && user1.astro && user1.astro.manglik}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Religious Background</h4>
                                </div>


                                <div class="col-sm-4 col-6">
                                    <h3>Religion</h3>
                                    <p>{user1 && user1.astro && user1.astro.religion}</p>
                                </div>

                                {/* <div class="col-sm-4 col-6">
                                    <h3>Community</h3>
                                    <p>{user1 && user1.astro && user1.astro.community}</p>
                                </div> */}

                                <div class="col-sm-4 col-6">
                                    <h3>Gothram</h3>
                                    <p>{user1 && user1.astro && user1.astro.gotra}</p>
                                </div>

                            </div>


                        </div>


                    </div>

                    <div class="col-lg-6 mt-3">

                        <div class="set">
                            <h2>Astro</h2>
                            <div class="row">
                                <div class="col-sm-4 col-6">
                                    <h3>Date of Birth</h3>
                                    <p>{user2 && user2.astro && user2.astro.dob}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Time of Birth</h3>
                                    <p>{user2 && user2.astro && user2.astro.time} {user2 && user2.astro && user2.astro.ampm}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Place of Birth</h3>
                                    <p>{user2 && user2.astro && user2.astro.birth_place}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Nakshatra</h3>
                                    <p>{user2 && user2.astro && user2.astro.nakshtra}</p>
                                </div>

                                <div class="col-sm-4 col-6">
                                    <h3>Mangalik / Chevval Dosham</h3>
                                    <p>{user2 && user2.astro && user2.astro.manglik}</p>
                                </div>

                                <div class="col-12">
                                    <h4>Religious Background</h4>
                                </div>


                                <div class="col-sm-4 col-6">
                                    <h3>Religion</h3>
                                    <p>{user2 && user2.astro && user2.astro.religion}</p>
                                </div>

                                {/* <div class="col-sm-4 col-6">
                                    <h3>Community</h3>
                                    <p>{user2 && user2.astro && user2.astro.community}</p>
                                </div> */}

                                <div class="col-sm-4 col-6">
                                    <h3>Gothram</h3>
                                    <p>{user2 && user2.astro && user2.astro.gotra}</p>
                                </div>

                            </div>


                        </div>

                    </div>


                </div>
            </div>

        </>
    )

}