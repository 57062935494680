import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Instructors() {

    return (
        <>
            <section id="our_team" class="meet_our">
                <div class="container">
                    <h1>Meet Our Counsellors and Instructors </h1>
                    <h2>Nurturing Connections, Guiding Journeys 🌟</h2>
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-sm-6 mt-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src="images/team.png" alt="" />
                                </div>
                                <h3>Priya Sharma</h3>
                                <h4>Senior Vedic Counselor</h4>
                                <p>Priya brings a wealth of experience, offering compassionate guidance in navigating the
                                    spiritual and emotional aspects of relationships.</p>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-sm-6 mt-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src="images/team.png" alt="" />
                                </div>
                                <h3>Rajesh Kapoor</h3>
                                <h4>Communication Skills Instructor</h4>
                                <p>Rajesh is a seasoned instructor specializing in enhancing communication skills, fostering
                                    deeper connections within relationships.</p>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-sm-6 mt-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src="images/team.png" alt="" />
                                </div>
                                <h3>Dr. Siddharth Mehta</h3>
                                <h4>Spiritual Compatibility Advisor</h4>
                                <p>Dr. Siddharth provides insights into aligning spiritual values, ensuring couples walk a
                                    shared path of spiritual growth and understanding.</p>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-sm-6 mt-3">
                            <div class="box">
                                <div class="box_img">
                                    <img src="images/team.png" alt="" />
                                </div>
                                <h3>Vikram Singh</h3>
                                <h4>Program Facilitator</h4>
                                <p> VIkram brings warmth and enthusiasm to our programs, creating a supportive environment for
                                    couples to learn and grow together.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}