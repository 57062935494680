import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetDetailsTab, UpdateApproval, clearftmUsers } from "../../../../Reducer/MakerDashboardSlices/ftmUsersSlice";
import { API_BASE_URL } from "../../Data/content";
import Swal from 'sweetalert2';


export default function Documents() {
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setTabData] = useState(null);
    const success = useSelector(state => state.ftmUsers.success);
    const error = useSelector(state => state.ftmUsers.error);
    const auth = useSelector(state => state.auth.auth);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearftmUsers());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearftmUsers());
            getData();

        }
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearftmUsers());
                break;
        }
    }


    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: params.id, type: "Documents" };
        let res = await dispatch(GetDetailsTab(dat));
        if (res.payload && res.payload.data.data) {
            setTabData(res.payload.data.data);
        }
    }

    const ClickToVerify = () => {
        let res = { uid: params.id, type: "docs", Approved_by: "MatchMaker", Maker_id: auth._id };
        dispatch(UpdateApproval(res));

    }


    return (
        <div class="set">
            <div class="row">
                <h3>Uploaded Documents</h3>
                {data && data.front_img && data.back_img ?

                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <label class="form-label">Front Side</label>
                            {data && data.front_img && <img src={data && API_BASE_URL + data.front_img} alt="" width="100%" height="150px" />}

                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <label class="form-label">Back Side</label>
                            {data && data.back_img && <img src={data && API_BASE_URL + data.back_img} alt="" width="100%" height="150px" />}
                        </div>
                    </div>
                    : <p>Documents not uploaded</p>}
                {data && data.verified === true && <button class="btn" >Verified</button>} {data && data.verified === false && <button class="btn" onClick={() => ClickToVerify()}>Click to Verify</button>}


            </div>
        </div>
    )
}