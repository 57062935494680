import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { freeTime, movieBook } from "../../Data/content";


export default function Step2({ data, handleChnage, errors }) {
    const dispatch = useDispatch();




    useEffect(() => {

    });



    const selectOption = (value, name) => {
        if (name === "free_time") {

    
            let updatedSelection = [...data.free_time];
            const selectedIndex = updatedSelection.indexOf(value);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(value);
            }
            handleChnage({ target: { name: "free_time", value: updatedSelection } });


        } else {
  
            let updatedSelection = [...data.movies_books];
            const selectedIndex = updatedSelection.indexOf(value);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(value);
            }
            handleChnage({ target: { name: "movies_books", value: updatedSelection } });
        }

    }



    return (
        <>
            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3>Hobbies</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>What are your favorite ways to spend your free time?🌴*</h4>
                                <ul>
                                    {freeTime.length > 0 && freeTime.map((item, i) => (
                                        <li key={i} className={data.free_time && data.free_time.length > 0 && data.free_time.includes(item) ? 'active' : ''} onClick={() => selectOption(item, "free_time")}>{item}</li>
                                    ))}
                                    {errors.free_time && <p className={"error__feedback"}>{errors.free_time}</p>}

                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <h4>What kind of books or movies do you enjoy?*</h4>
                                <ul>
                                    {movieBook.length > 0 && movieBook.map((item, i) => (
                                        <li className={data.movies_books && data.movies_books.length > 0 && data.movies_books.includes(item) ? 'active' : ''}  onClick={() => selectOption(item, "movies_books")}>{item}</li>
                                    ))}
                                    {errors.movies_books && <p className={"error__feedback"}>{errors.movies_books}</p>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}