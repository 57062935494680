import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { API_BASE_URL } from "../../Data/content";


export default function Introduction(props) {

    return (

        <section id="introduction_sec">
            <div class="container">
                {props.data &&
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <div class="introduction_img">
                                <img class="w-100" src={API_BASE_URL + props.data.image} alt="" />
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="intro_cont">
                                <h1>Introduction to Kundali Matching</h1>
                                <p>{props.data.text}</p>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </section>
    )

}