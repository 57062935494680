import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab1({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        let data = { uid: params.id, type: "basic" };
        dispatch(ViewProfileTabs(data));

    }, []);

    return (
        <>

            {/* blur class*/}


            <div class="set">
                {data && data.privacysetups && data.privacysetups.basic_privacy === "Everyone" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Name</h4>
                            <p>{data && data.basic && data.basic.name ? data.basic.name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Gender</h4>
                            <p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Marital Status</h4>
                            <p>{data && data.basic && data.basic.Marital_status ? data.basic.Marital_status : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother Tongue</h4>
                            <p>{data && data.basic && data.basic.Mother_tongue ? data.basic.Mother_tongue : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Height</h4>
                            <p>{data && data.basic && data.basic.height ? data.basic.height : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Weight</h4>
                            <p>{data && data.basic && data.basic.weight ? data.basic.weight + ' kg' : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Health Status</h4>
                            <p>{data && data.basic && data.basic.health ? data.basic.health : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Any Disability</h4>
                            <p>{data && data.basic && data.basic.Disability ? data.basic.Disability : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Country</h4>
                            <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>State</h4>
                            <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>City</h4>
                            <p>{data && data.basic && data.basic.city ? data.basic.city : "-"}</p>
                        </div>

                    </div>
                }

                {data && data.privacysetups && data.privacysetups.basic_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Name</h4>
                            <p>{data && data.basic && data.basic.name ? data.basic.name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Gender</h4>
                            <p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Marital Status</h4>
                            <p>{data && data.basic && data.basic.Marital_status ? data.basic.Marital_status : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother Tongue</h4>
                            <p>{data && data.basic && data.basic.Mother_tongue ? data.basic.Mother_tongue : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Height</h4>
                            <p>{data && data.basic && data.basic.height ? data.basic.height : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Weight</h4>
                            <p>{data && data.basic && data.basic.weight ? data.basic.weight + ' kg' : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Health Status</h4>
                            <p>{data && data.basic && data.basic.health ? data.basic.health : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Any Disability</h4>
                            <p>{data && data.basic && data.basic.Disability ? data.basic.Disability : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Country</h4>
                            <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>State</h4>
                            <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>City</h4>
                            <p>{data && data.basic && data.basic.city ? data.basic.city : "-"}</p>
                        </div>

                    </div>
                }

                {/* {data && data.privacysetups && data.privacysetups.basic_privacy === "Matchmaker" && data.privacysetups.basic_privacy === "None" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Name</h4>
                            <p>{data && data.basic && data.basic.name ? data.basic.name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Gender</h4>
                            <p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Marital Status</h4>
                            <p>{data && data.basic && data.basic.Marital_status ? data.basic.Marital_status : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother Tongue</h4>
                            <p>{data && data.basic && data.basic.Mother_tongue ? data.basic.Mother_tongue : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Height</h4>
                            <p>{data && data.basic && data.basic.height ? data.basic.height : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Weight</h4>
                            <p>{data && data.basic && data.basic.weight ? data.basic.weight + ' kg' : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Health Status</h4>
                            <p>{data && data.basic && data.basic.health ? data.basic.health : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Any Disability</h4>
                            <p>{data && data.basic && data.basic.Disability ? data.basic.Disability : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Country</h4>
                            <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>State</h4>
                            <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>City</h4>
                            <p>{data && data.basic && data.basic.city ? data.basic.city : "-"}</p>
                        </div>

                    </div>
                } */}


                <div className="row">
                    {data && data.privacysetups && data.privacysetups.mobile_privacy === "Everyone" &&
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mobile Number</h4>
                            <p>{data && data.mobile ? data.mobile : "-"}</p>
                        </div>
                    }
                    {data && data.privacysetups && data.privacysetups.mobile_privacy === "My connection" && data.statusChecked === "Connected" &&
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mobile Number</h4>
                            <p>{data && data.mobile ? data.mobile : "-"}</p>
                        </div>
                    }

                    {data && data.privacysetups && data.privacysetups.email_privacy === "Everyone" &&
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Email</h4>
                            <p>{data && data.email ? data.email : "-"}</p>
                        </div>
                    }
                    {data && data.privacysetups && data.privacysetups.email_privacy === "My connection" && data.statusChecked === "Connected" &&
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Email</h4>
                            <p>{data && data.email ? data.email : "-"}</p>
                        </div>
                    }
                </div>

            </div >


        </>
    )

}