import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserMatches, shortListUser, RemoveFromShortList, SendProfileInterest, AcceptProfileInterest, RecomendationApproval, MatchRemove, GetMatchCounts} from '../api';

export const GetProfileMatches = createAsyncThunk('GetProfileMatches', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUserMatches(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetCounts = createAsyncThunk('GetCounts', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetMatchCounts(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const ShortList = createAsyncThunk('ShortList', async (formData, { rejectWithValue }) => {
  try {
    const response = await shortListUser(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const ShortListRemove = createAsyncThunk('RemoveFromShortList', async (formData, { rejectWithValue }) => {
  try {
    const response = await RemoveFromShortList(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const InterestSend = createAsyncThunk('InterestSend', async (formData, { rejectWithValue }) => {
  try {
    const response = await SendProfileInterest(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const AcceptInterest = createAsyncThunk('AcceptInterest', async (formData, { rejectWithValue }) => {
  try {
    const response = await AcceptProfileInterest(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const AcceptRejctRecomendation = createAsyncThunk('AcceptRejctRecomendation', async (formData, { rejectWithValue }) => {
  try {
    const response = await RecomendationApproval(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const RemoveFromMatches = createAsyncThunk('RemoveFromMatches', async (formData, { rejectWithValue }) => {
  try {
    const response = await MatchRemove(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});





const dashboardSlice = createSlice({
  name: 'dshbrd',
  initialState: {
    data: [],
    tabData: {},
    success: null,
    error: null,
    successPro: null,
    errorPro: null,
    recomended: [], 
    loader : false,
    countsData : {}, 
    updated : false


  },
  reducers: {
    clacDash: state => {
      state.success = false;
      state.error = null;
      state.successPro = false;
      state.errorPro = null;
    },
    clearDashboardData : state  => {
      state.data = [];
    }
  },
  extraReducers: builder => {
    builder
      .addCase(GetProfileMatches.pending, state => {
        state.loader = true;
        state.success = false;
        state.error = null;
      })
      .addCase(GetProfileMatches.fulfilled, (state, action) => {
        state.loader = false;
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetProfileMatches.rejected, (state, action) => {
        state.loader = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetCounts.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetCounts.fulfilled, (state, action) => {
        state.countsData = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetCounts.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(ShortList.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(ShortList.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(ShortList.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(ShortListRemove.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(ShortListRemove.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(ShortListRemove.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(InterestSend.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(InterestSend.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(InterestSend.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(AcceptInterest.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(AcceptInterest.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AcceptInterest.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(AcceptRejctRecomendation.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(AcceptRejctRecomendation.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AcceptRejctRecomendation.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(RemoveFromMatches.pending, state => {
        state.updated = false;
        state.success = false;
        state.error = null;
      })
      .addCase(RemoveFromMatches.fulfilled, (state, action) => {
        state.updated = true;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(RemoveFromMatches.rejected, (state, action) => {
        state.updated = false;
        state.success = false;
        state.error = action.payload;
      })

  },
});
export const { clacDash, clearDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;

