import { NavLink, useNavigate, Link, useLocation, } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";

export default function Points({ uerData }) {
    const navigate = useNavigate();


    return (

        <section id="ashtakoot_points">
            <div class="container">
                <h1>Match Ashtakoot Points</h1>

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Atribute</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>Out of</th>
                                <th>Received</th>
                                <th>Area of life</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Bhakut</td>
                                <td>{uerData.data && uerData.data.bhakut && uerData.data.bhakut.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.bhakut && uerData.data.bhakut.female_koot_attribute
                                }</td>
                                <td>{uerData.data && uerData.data.bhakut && uerData.data.bhakut.total_points
                                }</td>
                                <td>{uerData.data && uerData.data.bhakut && uerData.data.bhakut.received_points
                                }</td>
                                <td>{uerData.data && uerData.data.bhakut && uerData.data.bhakut.description}</td>
                            </tr>

                            <tr>
                                <td>Gan</td>
                                <td>{uerData.data && uerData.data.gan && uerData.data.gan.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.gan && uerData.data.gan.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.gan && uerData.data.gan.total_points}</td>
                                <td>{uerData.data && uerData.data.gan && uerData.data.gan.received_points}</td>
                                <td>{uerData.data && uerData.data.gan && uerData.data.gan.description}</td>
                            </tr>

                            <tr>
                                <td>Maitri</td>
                                <td>{uerData.data && uerData.data.maitri && uerData.data.maitri.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.maitri && uerData.data.maitri.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.maitri && uerData.data.maitri.total_points}</td>
                                <td>{uerData.data && uerData.data.maitri && uerData.data.maitri.received_points}</td>
                                <td>{uerData.data && uerData.data.maitri && uerData.data.maitri.description}</td>
                            </tr>

                            <tr>
                                <td>Nadi</td>
                                <td>{uerData.data && uerData.data.nadi && uerData.data.nadi.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.nadi && uerData.data.nadi.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.nadi && uerData.data.nadi.total_points}</td>
                                <td>{uerData.data && uerData.data.nadi && uerData.data.nadi.received_points}</td>
                                <td>{uerData.data && uerData.data.nadi && uerData.data.nadi.description}</td>
                            </tr>

                            <tr>
                                <td>Tara</td>
                                <td>{uerData.data && uerData.data.tara && uerData.data.tara.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.tara && uerData.data.tara.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.tara && uerData.data.tara.total_points}</td>
                                <td>{uerData.data && uerData.data.tara && uerData.data.tara.received_points}</td>
                                <td>{uerData.data && uerData.data.tara && uerData.data.tara.description}</td>
                            </tr>

                            <tr>
                                <td>Varna</td>
                                <td>{uerData.data && uerData.data.varna && uerData.data.varna.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.varna && uerData.data.varna.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.varna && uerData.data.varna.total_points}</td>
                                <td>{uerData.data && uerData.data.varna && uerData.data.varna.received_points}</td>
                                <td>{uerData.data && uerData.data.varna && uerData.data.varna.description}</td>
                            </tr>
                            <tr>
                                <td>Vashya</td>
                                <td>{uerData.data && uerData.data.vashya && uerData.data.vashya.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.vashya && uerData.data.vashya.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.vashya && uerData.data.vashya.total_points}</td>
                                <td>{uerData.data && uerData.data.vashya && uerData.data.vashya.received_points}</td>
                                <td>{uerData.data && uerData.data.vashya && uerData.data.vashya.description}</td>
                            </tr>
                            <tr>
                                <td>Yoni</td>
                                <td>{uerData.data && uerData.data.yoni && uerData.data.yoni.male_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.yoni && uerData.data.yoni.female_koot_attribute}</td>
                                <td>{uerData.data && uerData.data.yoni && uerData.data.yoni.total_points}</td>
                                <td>{uerData.data && uerData.data.yoni && uerData.data.yoni.received_points}</td>
                                <td>{uerData.data && uerData.data.yoni && uerData.data.yoni.description}</td>
                            </tr>

                            <tr>
                                <td><b>Total</b></td>
                                <td></td>
                                <td></td>
                                <td><b>{uerData.data && uerData.data.total && uerData.data.total.total_points}</b></td>
                                <td><b>{uerData.data && uerData.data.total && uerData.data.total.received_points}</b></td>
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
        </section>
    )

}