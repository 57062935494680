import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { profileFor } from "../../Data/content";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';

export default function Form() {
    const navigate = useNavigate();
    const [location, setLocation] = useState(null);


    useEffect(() => {
    }, []);

    
    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLocation({
                value: value.value,
                label: value.label
            });
            // setLat(latLng.lat);
            // setLong(latLng.lng);

            console.log('value.label', value);

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };


    const Submit = (e) => {
        e.preventDefault();
        navigate("/Signup");
    }


    return (
        <>
            <div class="container">
                <div class="matrimony_profile">
                    <h2>Create a Matrimony Profile</h2>

                    <form onSubmit={Submit}>
                        <div class="row">
                            <div class="col-lg-3 col-6">
                                <div class="form-group">
                                    <label class="form-label">Matrimony Profile for?*</label>
                                    <select class="form-select" aria-label="Default select example" required>
                                        {profileFor.length > 0 && profileFor.map((item, i) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="form-group">
                                    <label class="form-label">Name</label>
                                    <input type="text" name="name" class="form-control" required />
                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="form-group">
                                    <label class="form-label">Location</label>
                                    <PlacesAutocomplete
                                    selectProps={{
                                        value: location,
                                        onChange: handleSelect,
                                    }}
                                    query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                    fetchDetails={true}
                                    onFail={error => console.log(error)}
                                />

                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="form-group">
                                    <button type="submit">Let’s Begin <i class="bi bi-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )

}