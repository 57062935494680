import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddAboutDetails, GetAboutDetail } from '../api';


export const AddAboutYourself = createAsyncThunk('AddAboutYourself', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddAboutDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetAboutYourself = createAsyncThunk('GetAboutYourself', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetAboutDetail(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const aboutSlice = createSlice({
  name: 'abt',
  initialState: {
    data: null,
    success: false,
    error: null,
  
  },
  reducers: {

    clearAbt: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
  
      .addCase(AddAboutYourself.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(AddAboutYourself.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AddAboutYourself.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetAboutYourself.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetAboutYourself.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetAboutYourself.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })

      
  },
});
export const { clearAbt } = aboutSlice.actions;

export default aboutSlice.reducer;

