import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import SideProfile from "./SideProfile";
import Tabbing from "./Tabbing";
import { GetViewProfileSide } from "../../../../Reducer/UserDashboardSlices/viewProfileSlice";
import Loader from "../../Loader";

export default function ViewProfile() {
    const dispatch = useDispatch();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    // const dataddddd = useSelector(state => state.viewProfile.data);
    const [dataddddd, setData] = useState(null);
    const auth = useSelector(state => state.auth.auth);


    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        // Fetch data function
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                let data = { uid: params.id, login_id: auth._id };
                let res = await dispatch(GetViewProfileSide(data));
                if (isMounted && res && res.payload.data.data) {
                    console.log('ressss', res);
                    setData(res.payload.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                if (isMounted) {
                    setLoading(false); // Set loading to false
                }
            }
        };

        fetchData(); // Call fetchData function

        // Cleanup function to handle component unmount
        return () => {
            isMounted = false; // Set isMounted to false when component is unmounted
        };

    }, [dispatch, auth._id]);


    // async function getData() {
    //     let data = { uid: params.id, login_id: auth._id };
    //     dispatch(GetViewProfileSide(data));
    // }


    return (
        <>
         
            <section id="full_page_dashboard">
            {loading === true &&
                <Loader />
            }
                <div class="container-fluid">
                    <Sidebar />
                    <div class="view_profile">
                        {dataddddd &&
                            <div class="row">
                                <SideProfile data={dataddddd && dataddddd} />
                                <Tabbing data={dataddddd && dataddddd} />
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )

}