import { NavLink, useNavigate, Link, useLocation, Form } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { UpdateMatchmakingChoices, clearPriceState } from "../../../Reducer/StepForms/pricingSlice";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";

export default function ChoicesSelection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.price.success);
    const error = useSelector(state => state.price.error);
    const [type, setType] = useState("");


    if (success && success.status === 200) {
        dispatch(clearPriceState());
        // setTimeout(() => {
            navigate('/SelectPlans');
        // }, 1000);

    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPriceState());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPriceState());
                break;
        }
    }


    useEffect(() => {
        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(UserDetail(dat));
        if (res && res.payload && res.payload.data.data) {
            setType(res.payload.data.data.Personalized_Matchmaking_Choices ? res.payload.data.data.Personalized_Matchmaking_Choices : "");
        }
    }

    const onSelect = (type) => {
        setType(type);

    }
    const OnNext = () => {
        if (type === "" || type === "null") {
            alert("Please select one");
        } else {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('type', type);
            dispatch(UpdateMatchmakingChoices(formData));

        }

    }


    return (
        <section id="full_page_dashboard" class="p-0">
            <div class="container-fluid">

                {/* <!-- **** Inner Section Dashboard **** --> */}
                <div class="solo_quest">
                    <h2>Designing Your Spiritual Bond</h2>
                    <h3>Personalized Matchmaking Choices</h3>
                    <h4>Design your spiritual journey with tailored matchmaking options.</h4>

                    <div class="row">

                        <div class="col-lg-4 col-sm-6 mt-3" onClick={() => onSelect("Solo Quest")}>

                            <div className={type && type === "Solo Quest" ? "box active" : "box"}>
                                <h5>Solo Quest</h5>
                                <p>For those who prefer to explore the match independently </p>
                                <ul>
                                    <li>💌 Personal Matchmaker Assistance</li>
                                    <li>🎯 Curated Recommendations based on Compatibility</li>
                                    <li>🚀 Priority Placement for Profile Viewing</li>
                                    <li>🌟 Exclusive Access to Premium Matches</li>
                                    <li>🚫 No Browsing, Just Bonding!</li>

                                </ul>

                                {type && type === "Solo Quest" ? <a class="btn">Selected</a> : <a class="btn" >Select</a>}

                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 mt-3" onClick={() => onSelect("Matchmaker Curation")}>
                            <div className={type && type === "Matchmaker Curation" ? "box active" : "box"}>
                                <h5>Matchmaker's Curation</h5>
                                <p>For those who prefer connection through the matchmaker's guidance,</p>
                                <ul>
                                    <li>💌 Personal Matchmaker Assistance</li>
                                    <li>🎯 Curated Recommendations based on Compatibility</li>
                                    <li>🚀 Priority Placement for Profiles</li>
                                    <li>🌟 Exclusive Access to Premium Matches</li>
                                    <li>🚫 No Browsing, Just Bonding!</li>

                                </ul>
                                {type && type === "Matchmaker Curation" ? <a class="btn">Selected</a> : <a class="btn" >Select</a>}


                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 mt-3" onClick={() => onSelect("Perfect Mix")}>
                            <div className={type && type === "Perfect Mix" ? "box active" : "box"}>
                                <h5>Perfect Mix</h5>
                                <p>Guided matchmaking and independent exploration</p>
                                <ul>
                                    <li>🤝 Limited Matchmaker Assistance</li>
                                    <li>🔀 Curated Recommendations, Search Freedom</li>
                                    <li>🔄 Guided and Independent Interaction</li>
                                    <li>🔐 Customizable Privacy Settings</li>
                                    <li>🌐 Explore, Bond, Repeat!</li>

                                </ul>
                                {type && type === "Perfect Mix" ? <a class="btn">Selected</a> : <a class="btn" >Select</a>}

                            </div>
                        </div>

                        {/* <div class="col-12 mt-4"> */}
                        <div class="col-12 d-flex justify-content-between mt-5">
                            <Link to="/Dashboard" class="btn">Back to Dashboard</Link>
                            <div class="text-end">
                                <h6><a onClick={() => OnNext()} class="btn">Next</a></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )

}