import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import Header from "../Header";
import firebaseConfig from '../../../../Firebase';
import { API_BASE_URL } from "../../Data/content";
import { GetChatUsers, UploadChatMedia, clearChatStatus, updateBlockStatus } from "../../../../Reducer/chatSlice";
import EmojiPicker from 'emoji-picker-react';
import BlockUnblock from "../../Common/BlockUnblock";
import Swal from 'sweetalert2';
import BodyColor from "../../../Layouts/BodyColor";


export default function Messages() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const [blockPopup, setBlockPopup] = useState(false);
    const [messagList, setMessageList] = useState([]);
    const [chatMessages, setChatMessages] = useState({})
    const [selectedUser, setSelectedUser] = useState(null);
    const [media, setMedia] = useState(null);
    const options = {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit'
    };
    const [msgData, setMesageData] = useState({
        message: "",
        name: "",
        sender_id: auth._id,
        receiver_id: 0,
        timestamp: new Date().toISOString(),
        currDate: new Date().toLocaleDateString(),
        currTime: new Date().toLocaleTimeString([], options),
        read: false,
        type: "text",
        seen: 0,
        profileImage: "",
        files: null
    });
    const [showPicker, setShowPicker] = useState(false);
    const messagesFormRef = useRef(null); // Reference to the messages form
    const success = useSelector(state => state.chat.success);
    const error = useSelector(state => state.chat.error);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearChatStatus());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            }).then(() => {
                // Reload the page
                window.location.reload();
            });


            setBlockPopup(false);
            dispatch(clearChatStatus());

        }
    }


    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearChatStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearChatStatus());
                break;
        }
    }

    useEffect(() => {
        get_messageList();
        // getData();
        return (() => {
            firebaseConfig.database().ref(`MessagesList`).off('value')
        }
        )
    }, []);


    // async function getData(firebaseUsers) {
    //     let dat = { uid: auth._id };
    //     let res = await dispatch(GetChatUsers(dat));
    //     if (res.payload && res.payload.data.data) {
    //         const mergedUsers = [...firebaseUsers, ...res.payload.data.data].reduce((acc, curr) => {
    //             if (!acc.some((item) => item._id === curr._id)) {
    //                 acc.push(curr);
    //             }
    //             return acc;
    //         }, []);
    //         setMessageList(mergedUsers);
    //     }
    // }

    async function getData(firebaseUsers) {
        try {
            let dat = { uid: auth._id };
            let res = await dispatch(GetChatUsers(dat));

            if (res.payload && res.payload.data.data) {
                const fetchedUsers = res.payload.data.data;

                console.log('Fetched Users:', fetchedUsers);

                // Create a set of IDs for the fetched users
                const fetchedUserIds = new Set(fetchedUsers.map(user => user._id));

                // Merge the lists while maintaining unique users
                const mergedUsers = [...firebaseUsers, ...fetchedUsers].reduce((acc, curr) => {
                    if (!acc.some(item => item._id === curr._id)) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);

                // Add your existing condition here
                const filteredUsers = mergedUsers.map(user => {
                    if (!fetchedUserIds.has(user._id)) {
                        return { ...user, status: 'different_status' }; // Add your custom status here
                    }
                    return user;
                });

                console.log('Filtered Users:', filteredUsers);

                // Add online status to each user
                const firebaseUsersWithStatus = await Promise.all(filteredUsers.map(async user => {
                    let onlineStatus = 'offline'; // Default status is offline
                    try {
                        const userRef = firebaseConfig.database().ref(`/Online_Users/${user._id}`);
                        const snapshot = await userRef.once('value');
                        if (snapshot.exists()) {
                            onlineStatus = snapshot.val().status; // Get the online status from Firebase
                        }
                    } catch (error) {
                        console.error(`Error fetching online status for user ${user._id}:`, error);
                    }
                    return { ...user, onlineStatus: onlineStatus }; // Merge the online status with user details
                }));

                console.log('Firebase Users With Status:', firebaseUsersWithStatus);

                setMessageList(firebaseUsersWithStatus);
            }
        } catch (error) {
            console.error('Error in getData function:', error);
        }
    }


    const openChat = (userId, profileImg, name, userType, block, block_by, status) => {
        const getCountRef = firebaseConfig.database().ref(`MessagesList/${auth._id}/${userId}/`);
        getCountRef.once('value', (snapshot) => {
            if (snapshot.exists()) {
                const count = snapshot.val();
                let dat = { _id: userId, profileImage: profileImg, name: name, count: count.count, block: block, block_by: block_by, userType: userType, status: status };
                setSelectedUser(dat);
                get_message(userId);
            } else {
                let dat = { _id: userId, profileImage: profileImg, name: name, count: 0, block: block, block_by: block_by, userType: userType, status: status };
                setSelectedUser(dat);
                get_message(userId);
            }
        });
        getCountRef.on('value', (snapshot) => {
            if (snapshot.exists()) {
                const count = snapshot.val();
                getCountRef.update({ count: 0 })
            }
        });
    };


    function get_message(userId) {
        const db = firebaseConfig.database();
        const chatRef = db.ref(`Chat/${auth._id}_${userId}`);
        chatRef.on("value", (snapshot) => {
            setChatMessages(snapshot.val());

        });
    }

    function handleChangeInput(evt) {
        evt.preventDefault();

        if (evt.target.name === "file") {
            let file = evt.target.files[0];
            const extension = file.name.split(".").pop().toLowerCase();
            let type = "";
            if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
                type = "img"

            }
            if (["mp4", "avi", "mov", "wmv"].includes(extension)) {
                type = "video"

            }
            if (["pdf"].includes(extension)) {
                type = "pdf"
            }

            setMedia(
                URL.createObjectURL(file)
            )
            setMesageData({
                ...msgData,
                ["type"]: type,
                ["files"]: file
            });


        } else {
            setMesageData({
                ...msgData,
                [evt.target.name]: evt.target.value
            });

        }

    }


    const get_messageList = async (e) => {
        const dbRef = await firebaseConfig.database().ref(`MessagesList/${auth._id}/`);
        dbRef.on("value", snap => {
            let chatItems = [];
            snap.forEach(childSnap => {
                const response = childSnap;
                const dd = response.val().lastmessage.substring(0, 10);
                chatItems.push({
                    _id: response.key,
                    name: response.val().name,
                    profileImage: response.val().profileImage,
                    date: response.val().currDate,
                    lastMsg: dd,
                    count: response.val().count,
                    type: response.val().type,
                    timestamp: response.val().timestamp,
                    userType: response.val().userType,
                    block: response.val().block,
                    block_by: response.val().block_by
                }
                );
            });
            if (chatItems.length > 0) {
                chatItems.sort((a, b) => {
                    const dateTimeA = new Date(a.timestamp); // Combine date and time for item A
                    const dateTimeB = new Date(b.timestamp); // Combine date and time for item B
                    return dateTimeB - dateTimeA; // Sort in descending order
                });
                getData(chatItems);
                // setMessageList(chatItems);
            } else {
                getData([]);
            }
        });
    }

    // console.log('msglist', UserArr);

    async function handleSubmit(e) {
        e.preventDefault();
        if (msgData.type === "text") {
            if (msgData.message != "") {
                sendMsg(msgData.message);
            }
        } else {
            let formData = new FormData();
            if (msgData.type === "img") {
                formData.append("img", msgData.files);

            }
            if (msgData.type === "video") {
                formData.append("video", msgData.files);

            }
            if (msgData.type === "pdf") {
                formData.append("pdf", msgData.files);
            }
            let res = await dispatch(UploadChatMedia(formData));
            if (res.payload && res.payload.data.data) {
                let responseData = res.payload.data.data;
                sendMsg(responseData);
            }
        }

    }

    const sendMsg = (data) => {
        firebaseConfig.database().ref(`Chat/${auth._id}_${selectedUser._id}`).push({
            // msgData
            message: data,
            name: selectedUser.name,
            sender_id: auth._id,
            receiver_id: selectedUser._id,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            read: msgData.read,
            type: msgData.type,

        });


        firebaseConfig.database().ref(`Chat/${selectedUser._id}_${auth._id}`).push({
            // msgData
            message: data,
            name: selectedUser.name,
            sender_id: auth._id,
            receiver_id: selectedUser._id,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            read: msgData.read,
            type: msgData.type,

        });


        firebaseConfig.database().ref(`MessagesList/${auth._id}/${selectedUser._id}`).set({
            name: selectedUser.name,
            profileImage: selectedUser.profileImage,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: data,
            _id: selectedUser._id,
            type: msgData.type,
            count: 0,
            block: false,
            timestamp: msgData.timestamp,
        });


        firebaseConfig.database().ref(`MessagesList/${selectedUser._id}/${auth._id}`).set({
            name: auth.name,
            profileImage: auth.profileImage,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: data,
            _id: auth._id,
            type: msgData.type,
            count: selectedUser.count++,
            block: false,
            timestamp: msgData.timestamp,
            userType: "User"

        });

        get_message(selectedUser._id);
        get_messageList();
        setMedia(null)
        setMesageData({
            ...msgData,
            ["type"]: 'text',
            ["files"]: null,
            ["message"]: "",
        });
        setShowPicker(false);

    }

    const removeMedia = () => {
        setMesageData({
            ...msgData,
            ["type"]: 'text',
            ["files"]: null,
            ["message"]: "",
        });
        setMedia(null);
    }

    // function searchUser(evt) {
    //     const searchValue = evt.target.value.toLowerCase();
    //     if (searchValue) {
    //         const filtered = UserArr.filter(item =>
    //             item.name.toLowerCase().includes(searchValue)
    //         );
    //         setMessageList(filtered);
    //     } else {
    //         setMessageList(messagList);
    //     }
    // }

    const onEmojiClick = (emoji) => {
        setMesageData({
            ...msgData,
            ["message"]: msgData.message + emoji.emoji,
        });
    };

    const openBlock = () => {
        setBlockPopup(true);
    }

    const unBlockUser = async () => {
        var ref = firebaseConfig.database().ref(`MessagesList/${auth._id}/${selectedUser && selectedUser._id}`)
        await ref.once('value', function (snapshot) {
            if (snapshot.exists()) {
                ref.update({
                    block: false,
                    block_by: null
                });
            }
        });

        var ref2 = firebaseConfig.database().ref(`MessagesList/${selectedUser && selectedUser._id}/${auth._id}`);
        await ref2.once('value', function (snapshot) {
            if (snapshot.exists()) {
                ref2.update({
                    block: false,
                    block_by: null
                });
            }
        });


        let dat = { block_by: auth._id, blocked_user: selectedUser._id, type: "unblock" };
        dispatch(updateBlockStatus(dat));
        // firebaseConfig.database().ref(`MessagesList/${auth._id}/${selectedUser && selectedUser._id}`).update({
        //     block: false,
        //     block_by: null
        // });
        // firebaseConfig.database().ref(`MessagesList/${selectedUser && selectedUser._id}/${auth._id}`).update({
        //     block: false,
        //     block_by: null
        // });
    }

    const Back = () => {
        setSelectedUser(null);
    }



    return (
        <>
            <BodyColor />
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <Header />
                    <section id="messages_sec">
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="box">
                                    {/* <h2>Start a conversation</h2> */}
                                    {/* <ul class="online_list">
                                        <li><img src="images/team.png" /><span></span></li>
                                        <li><img src="images/v1.png" /><span></span></li>
                                        <li><img src="images/v2.png" /><span></span></li>
                                        <li><img src="images/v3.png" /><span></span></li>
                                        <li><img src="images/v4.png" /><span></span></li>
                                    </ul> */}
                                    <h2>Messages</h2>

                                    <ul class="offline_list">
                                        {messagList && messagList.length > 0 ? messagList.map((item, i) => (
                                            <li class="closed_chat" key={i} onClick={() => openChat(item._id, item.profileImage, item.name, item.userType, item.block, item.block_by, item.status ? item.status : "")}><label>
                                                <h3><img src="/images/team.png" />{item.name}
                                                    {item.type !== "text" ?
                                                        <>
                                                            {item.type === "img" && <p>Photo</p>}
                                                            {item.type === "video" && <p>Video</p>}
                                                            {item.type === "pdf" && <p>Document</p>}
                                                        </>
                                                        : <p>{item.lastMsg ? item.lastMsg : "Start chat"}</p>}
                                                    {item.onlineStatus && item.onlineStatus == 'online' &&
                                                        <i class="bi bi-dot dot_color"></i>}
                                                </h3>
                                            </label>
                                                <label>
                                                    <p>{item.date ? item.date : ""}</p>

                                                    {item.count > 0 &&
                                                        <span>{item.count}</span>
                                                    }
                                                    {item.userType && item.userType === "Maker" &&
                                                        <p><i class="bi bi-flag-fill" style={{ "color": "red" }}></i></p>}
                                                </label>
                                            </li>
                                        )) : "No users found."}
                                    </ul>
                                </div>
                            </div>


                            <div class="col-xl-8 ps-xl-0" ref={messagesFormRef}>
                                <div className={selectedUser ? "box_two shawdo main" : "box_two shawdo"} style={{ display: selectedUser ? 'block' : 'none' }}>
                                    <h2><span><i class="bi bi-chevron-left closed_chat" onClick={() => Back()}></i><img src={selectedUser && API_BASE_URL + selectedUser.profileImage} />{selectedUser && selectedUser.name}</span>
                                        {selectedUser && selectedUser.userType == "User" &&

                                            <span>
                                                <div class="dropdown">
                                                    <button type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a class="dropdown-item" onClick={() => openBlock()}>Block User</a></li>
                                                    </ul>
                                                </div>
                                            </span>}
                                    </h2>
                                    <div class="sms_modal" >
                                        <div class="msg-body">
                                            <ul>

                                                {chatMessages && Object.keys(chatMessages).map((key) => {
                                                    const message = chatMessages[key];
                                                    return (
                                                        <>
                                                            {message.receiver_id === auth._id ?
                                                                <li class="sender">
                                                                    {message.type === "text" &&
                                                                        <p>{message.message} <span class="time">
                                                                            {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "img" &&

                                                                        <p><img src={API_BASE_URL + message.message} /> <span class="time">
                                                                            {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "video" &&

                                                                        <p>  <video controls>
                                                                            <source src={API_BASE_URL + message.message} />
                                                                        </video>

                                                                            <span class="time">
                                                                                {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "pdf" &&

                                                                        <p>{message.message}<a href={API_BASE_URL + message.message} target="_blank" download><i class="bi bi-download"></i></a> <span class="time">
                                                                            {message.currTime}</span></p>
                                                                    }
                                                                </li>
                                                                :
                                                                <li class="repaly">
                                                                    {message.type === "text" &&
                                                                        <p>{message.message} <span class="time">
                                                                            {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "img" &&

                                                                        <p><img src={API_BASE_URL + message.message} /> <span class="time">
                                                                            {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "video" &&

                                                                        <p>  <video controls>
                                                                            <source src={API_BASE_URL + message.message} />
                                                                        </video>

                                                                            <span class="time">
                                                                                {message.currTime}</span></p>
                                                                    }
                                                                    {message.type === "pdf" &&

                                                                        <p>{message.message}<a href={API_BASE_URL + message.message} target="_blank" download><i class="bi bi-download"></i></a> <span class="time">
                                                                            {message.currTime}</span></p>

                                                                    }

                                                                </li>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="send_box">
                                        {media != null &&
                                            <div className="attached">
                                                <i class="bi bi-x-lg" onClick={() => removeMedia()}></i>
                                                {media && msgData.type === "img" &&
                                                    <img src={media} />
                                                }
                                                {media && msgData.type === "video" &&
                                                    <video controls>
                                                        <source src={media} type="video/mp4" />
                                                    </video>
                                                }
                                                {media && msgData.type === "pdf" &&
                                                    <iframe src={media} title="PDF"></iframe>
                                                }
                                            </div>
                                        }
                                        {showPicker && (
                                            <div class="emoji_select">

                                                <i class="bi bi-x-lg" onClick={() => setShowPicker(false)}></i>                                                <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClick} />

                                            </div>
                                        )}

                                        {selectedUser && selectedUser.block === true ? (selectedUser.block_by === auth._id) ? <div className="block_user" onClick={() => unBlockUser()}>
                                            <h4>You blocked this contact. Click to unblock</h4>
                                        </div> : (
                                            <div className="block_user">
                                                <h4>You cannot send message to this user.</h4>
                                            </div>
                                        )
                                            :

                                            (selectedUser && selectedUser.status && selectedUser.status === "different_status") ?

                                                (<div className="block_user" >
                                                    You cannot send message to this user.
                                                </div>)

                                                :

                                                (<div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Type a Message" name="message" onChange={handleChangeInput} value={msgData.message} />
                                                    <button type="button" onClick={handleSubmit}><img src="/images/Send.png" /></button>
                                                    <div className="upload_vid">
                                                        <span>
                                                            <i class="bi bi-paperclip"></i>
                                                            <input type="file" class="form-control" accept="application/pdf, video/*, image/*" name="file" onChange={handleChangeInput} />
                                                        </span>
                                                        <span onClick={() => setShowPicker(true)}>  <i class="bi bi-emoji-smile"></i></span>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                </div>

                                <div class="box_two shawdo" style={{ display: selectedUser ? 'none' : 'block' }}>
                                    <div class="sms_modal">
                                        <div class="msg-body">
                                            <div class="chatbox heading"><h1>Welcome, {auth.name}!</h1><p>Ready? Set. Chat! Let's jump right into things.</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </section >
            {blockPopup === true &&
                <BlockUnblock data={selectedUser} setBlockPopup={setBlockPopup} />
            }
        </>
    )
}