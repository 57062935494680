import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { motivateOption, strength, weakness } from "../../Data/content";


export default function Step3({ data, handleChnage, errors }) {
    const dispatch = useDispatch();


    useEffect(() => {

    });
    // const selectOption = (value, name) => {
    //     if (name === "motivate_insipre") {
    //         handleChnage({ target: { name: "motivate_insipre", value: value } });

    //     } else if (name === "strength") {
    //         handleChnage({ target: { name: "strength", value: value } });

    //     } else {
    //         handleChnage({ target: { name: "weakness", value: value } });

    //     }
    // }

    const selectOption = (value, name) => {
        if (name === "motivate_insipre") {
            let updatedSelection = [...data.motivate_insipre];
            const selectedIndex = updatedSelection.indexOf(value);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(value);
            }
            handleChnage({ target: { name: "motivate_insipre", value: updatedSelection } });


        } else if (name === "strength") {
            let updatedSelection = [...data.strength];
            const selectedIndex = updatedSelection.indexOf(value);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(value);
            }
            handleChnage({ target: { name: "strength", value: updatedSelection } });
        }
        else {
            let updatedSelection = [...data.weakness];
            const selectedIndex = updatedSelection.indexOf(value);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(value);
            }
            handleChnage({ target: { name: "weakness", value: updatedSelection } });
        }

    }





    return (
        <>

            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3>Strengths and Weaknesses</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>What are your most significant strengths and weaknesses?💪🙃*</h4>
                                <h5>Strength</h5>
                                <ul>
                                    {strength.length > 0 && strength.map((item, i) => (
                                        <li key={i} className={data.strength && data.strength.length > 0 && data.strength.includes(item) ? 'active' : ''}  onClick={() => selectOption(item, "strength")}>{item}</li>
                                    ))}
                                    {errors.strength && <p className={"error__feedback"}>{errors.strength}</p>}


                                </ul>
                                <h5>Weakness</h5>
                                <ul>
                                    {weakness.length > 0 && weakness.map((item, i) => (
                                        <li key={i} className={data.weakness && data.weakness.length > 0 && data.weakness.includes(item) ? 'active' : ''}  onClick={() => selectOption(item, "weakness")}>{item}</li>
                                    ))}
                                    {errors.weakness && <p className={"error__feedback"}>{errors.weakness}</p>}

                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <div class="form-group">
                                    <div class="box">
                                        <h4>What motivates and inspires you in life?*</h4>

                                        <ul>
                                            {motivateOption.length > 0 && motivateOption.map((item, i) => (
                                                <li key={i} className={data.motivate_insipre && data.motivate_insipre.length > 0 && data.motivate_insipre.includes(item) ? 'active' : ''} onClick={() => selectOption(item, "motivate_insipre")}>{item}</li>
                                            ))}
                                            {errors.motivate_insipre && <p className={"error__feedback"}>{errors.motivate_insipre}</p>}

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}