import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetTabsData } from "../../../../../Reducer/UserDashboardSlices/myProfileSlice";



export default function Tab3() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.myProfile.tabData);


    useEffect(() => {
        let dat = { uid: auth._id, type: "family" };
        dispatch(GetTabsData(dat));
    }, []);


    return (
        <div class="set">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Father’s Name</h4>
                    <p>{data && data.father_name ? data.father_prefix + " " + data.father_name : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Father’s Employment</h4>
                    <p>{data && data.father_occcupation ? data.father_occcupation : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Mother’s Name</h4>
                    <p>{data && data.mother_name ? data.mother_prefix + " " + data.mother_name : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Mother’s Employment </h4>
                    <p>{data && data.mother_occcupation ? data.mother_occcupation : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Family Values</h4>
                    <p>{data && data.familyCulture ? data.familyCulture : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Family Affluence</h4>
                    <p>{data && data.family_status ? data.family_status : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Family Location</h4>
                    <p>{data && data.location ? data.location : "-"}</p>
                </div>

                <div class="col-12">
                    <h5>Sibling Details</h5>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Brothers</h4>
                    <p>{data && data.brother_count ? data.brother_count : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Married</h4>
                    <p>{data && data.brother_married ? data.brother_married : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Sisters</h4>
                    <p>{data && data.sister_count ? data.sister_count : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Married</h4>
                    <p>{data && data.sister_married ? data.sister_married : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Younger Brother</h4>
                    <p>{data && data.younger_brother ? data.younger_brother : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Elder Brother</h4>
                    <p>{data && data.elder_brother ? data.elder_brother : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Younger Sister</h4>
                    <p>{data && data.younger_sister ? data.younger_sister : "-"}</p>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <h4>Elder Sister</h4>
                    <p>{data && data.elder_sister ? data.elder_sister : "-"}</p>
                </div>

            </div>
        </div>

    )
}