import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CommunityName, Relgion } from "../../Data/content";

export default function
    Community({ data, handleChange }) {
    const ComNames = CommunityName.sort();

    return (
        <>
            <div class="set_box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Religion</label>
                            <select class="form-select" aria-label="Default select example" name="religion" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {Relgion.length > 0 && Relgion.map((rel, i) => (
                                    <option selected={data && data.religion === rel} value={rel}>{rel}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Community</label>
                            <select class="form-select" aria-label="Default select example" name="community" onChange={handleChange}>
                                <option selected disabled>Select</option>
                                {ComNames.length > 0 && ComNames.map((com, i) => (
                                    <option selected={data && data.community === com} value={com}>{com}</option>

                                ))}

                            </select>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}