import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { UpdatePwd, clacAcRes } from "../../../../../Reducer/UserDashboardSlices/userAccountSlice";
const schema = Yup.object().shape({
    currentPassword: Yup.string()
        .required('Current password is required'),
    newPassword: Yup.string()
        .min(8, 'New password must be at least 8 characters')
        .required('New password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

export default function UpdatePassword({ setOpn1 }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.userAccount.upPwd);
    const error = useSelector(state => state.userAccount.erPwd);


    if (success && success.status === 200) {

        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clacAcRes());

    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());

        }
    }


    useEffect(() => {


    }, []);

    const handleCloseModal = () => {
        setOpn1(false); // Update the state in the Header component
    }

    const onError = (errors) => {
        console.log(errors);
    };

    const onSubmit = async (datas) => {
        let formData = new FormData();
        formData.append('uid', auth._id);
        formData.append('currentPassword', datas.currentPassword);
        formData.append('newPassword', datas.newPassword);
        dispatch(UpdatePwd(formData));

    }

    return (
        <>
            {/* <!-- Change Password Popup --> */}

            <div class="modal fade show change_pass_popup" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"><img src="images/lock.png" alt="" />Change Password</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                        </div>
                        <div class="modal-body">
                            <div class="box">
                                <h3>Please enter your Passwords here</h3>

                                <form onSubmit={handleSubmit(onSubmit, onError)}>

                                    <div className="form-group">
                                        <label className="form-label">Current Password</label>
                                        <input type="password" className="form-control" {...register('currentPassword')} />
                                        {errors.currentPassword && <div className="text-danger">{errors.currentPassword.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">New Password</label>
                                        <input type="password" className="form-control" {...register('newPassword')} />
                                        {errors.newPassword && <div className="text-danger">{errors.newPassword.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Confirm password</label>
                                        <input type="password" className="form-control" {...register('confirmPassword')} />
                                        {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword.message}</div>}
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a onClick={handleCloseModal} class="btn">Cancel</a>
                                        <button type="submit" class="btn color">Update</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}