import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { API_BASE_URL } from "../../Data/content";

export default function SuccessStories(props) {
    const totalItems = props.data && props.data.length;
    useEffect(() => {


    }, []);


    const homeOptions = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 20,
        dots : false,
        responsiveClass: true,
        responsive: {
            0: { items: 1, nav: false },
            576: { items: 1.5, nav: true },
            768: { items: 1.5, nav: true },
            992: { items: 1.5, nav: true },
            1200: { items: 1.5, nav: true }
        },
      
    };


    return (
        <>

            <section id="success_stories">
                <div class="container pe-0">

                    <h1>Success Stories</h1>
                    {props.data &&
                        <OwlCarousel className='owl-carousel owl-stories owl-theme' {...homeOptions}>

                            {props.data.map((item, i) => (
                                <div>
                                    <div class="flex_stories">
                                        <div class="stories-img" style={{ backgroundImage: `url(${API_BASE_URL+item.image})` }} >
                                        </div>

                                        <div class="stories-text">
                                            <p>{item.text}</p>
                                            <h3>{item.name}</h3>
                                            <h4>{item.designation}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            

                        </OwlCarousel>
                    }

                </div>
            </section>
        </>
    )

}