import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HomeSlider from '../../Tools/HomeSlider';
import { API_BASE_URL } from "../../Data/content";

export default function Banner(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);


    useEffect(() => {


    }, []);

    function openSlider() {
        setOpen(true);
    }

    
    const receiveResponseFromChild = (response) => {
        setOpen(response);
    };

    return (
        <>
            <section id="home_banner" style={{ backgroundImage: `url(${props.data && API_BASE_URL + props.data.background_img})` }}>

                <div class="container">
                    <div class="man_women_img">
                        <img src={props.data && API_BASE_URL + props.data.banner_image} alt="" />
                    </div>
                    <div class="box-1">
                        <img src={props.data && API_BASE_URL + props.data.img1} alt="" />
                        <h3>{props.data && props.data.text1}</h3>
                    </div>
                    <div class="box-2">
                        <img src={props.data && API_BASE_URL + props.data.img2} alt="" />
                        <h3>{props.data && props.data.text2}</h3>
                    </div>
                    <div class="box-3">
                        <img src={props.data && API_BASE_URL + props.data.img3} alt="" />
                        <h3>{props.data && props.data.text3}</h3>
                    </div>
                    <div class="box-4">
                        <img src={props.data && API_BASE_URL + props.data.img4} alt="" />
                        <h3>{props.data && props.data.text4}</h3>
                    </div>
                    <a class="btn btn-close-home" onClick={openSlider} ><img src="/images/play_btn.gif" alt="" /></a>
                </div>
            </section>
            {open == true &&
                <div>
                  <HomeSlider data={props.slider && props.slider} sendResponseToParent={receiveResponseFromChild}/>
                </div>
            }

        </>
    )

}