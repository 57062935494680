import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";


export default function Mission(props) {

    return (
        <>
             <section id="mission_sec">
        <div class="container">
            <div class="row">

                <div class="col-md-6 mb-lg-0 mb-3">
                    <div class="box">
                        <h3>{props.data && props.data.h1}</h3>
                        <p>{props.data && props.data.text1}</p>
                    </div>
                </div>

                <div class="col-md-6 mb-lg-0 mb-3">
                    <div class="box">
                    <h3>{props.data && props.data.h2}</h3>
                        <p>{props.data && props.data.text2}</p>
                    </div>
                </div>

                <div class="col-md-6 mb-lg-0 mb-3">
                    <div class="box">
                    <h3>{props.data && props.data.h3}</h3>
                        <p>{props.data && props.data.text3}</p>
                    </div>
                </div>

                <div class="col-md-6 mb-lg-0 mb-3">
                    <div class="box">
                    <h3>{props.data && props.data.h4}</h3>
                        <p>{props.data && props.data.text4}</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
        </>
    )

}