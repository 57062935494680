// apiSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ContactFormSubmit } from '../api';


export const SubmitContactForm = createAsyncThunk('SubmitContactForm', async (formdata, { rejectWithValue }) => {
    try {
        const response = await ContactFormSubmit(formdata);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const contactFormSlice = createSlice({
    name: 'contactForm',
    initialState: {
        success: false,
        error: null,
    },
    reducers: {
        clearContactForm: state => {
            state.success = false;
            state.error = null;

        },

    },
    extraReducers: builder => {
        builder
            .addCase(SubmitContactForm.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(SubmitContactForm.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SubmitContactForm.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});


export const { clearContactForm } = contactFormSlice.actions;
export default contactFormSlice.reducer;

