import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewProfileTabs } from "../../../../../Reducer/UserDashboardSlices/viewProfileSlice";


export default function ViewProfileTab3({ data }) {
    const dispatch = useDispatch();
    const params = useParams();
    // const data = useSelector(state => state.viewProfile.tabData);


    useEffect(() => {
        // let data = { uid: params.id, type: "family" };
        // dispatch(ViewProfileTabs(data));
    }, []);
    return (
        <>

            <div class="set">
                {data && data.privacysetups && data.privacysetups.family_privacy === "Everyone" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Father’s Name</h4>
                            <p>{data && data.families && data.families.father_name ? data.families.father_prefix + " " + data.families.father_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Father’s Employment</h4>
                            <p>{data && data.families && data.families.father_occcupation ? data.families.father_occcupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother’s Name</h4>
                            <p>{data && data.families && data.families.mother_name ? data.families.mother_prefix + " " + data.families.mother_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother’s Employment </h4>
                            <p>{data && data.families && data.families.mother_occcupation ? data.families.mother_occcupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Values</h4>
                            <p>{data && data.families && data.families.familyCulture ? data.families.familyCulture : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Affluence</h4>
                            <p>{data && data.families && data.families.family_status ? data.families.family_status : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Location</h4>
                            <p>{data && data.families && data.families.location ? data.families.location : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h5>Sibling Details</h5>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Brothers</h4>
                            <p>{data && data.families && data.families.brother_count ? data.families.brother_count : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Married</h4>
                            <p>{data && data.families && data.families.brother_married ? data.families.brother_married : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Sisters</h4>
                            <p>{data && data.families && data.families.sister_count ? data.families.sister_count : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Married</h4>
                            <p>{data && data.families && data.families.sister_married ? data.families.sister_married : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Younger Brother</h4>
                            <p>{data && data.families && data.families.younger_brother ? data.families.younger_brother : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Elder Brother</h4>
                            <p>{data && data.families && data.families.elder_brother ? data.families.elder_brother : "-"}</p>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Younger Sister</h4>
                            <p>{data && data.families && data.families.younger_sister ? data.families.younger_sister : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Elder Sister</h4>
                            <p>{data && data.families && data.families.elder_sister ? data.families.elder_sister : "-"}</p>
                        </div>

                    </div>
                }
                {data && data.privacysetups && data.privacysetups.family_privacy === "My connection" && data.statusChecked === "Connected" &&
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Father’s Name</h4>
                            <p>{data && data.families && data.families.father_name ? data.families.father_prefix + " " + data.families.father_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Father’s Employment</h4>
                            <p>{data && data.families && data.families.father_occcupation ? data.families.father_occcupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother’s Name</h4>
                            <p>{data && data.families && data.families.mother_name ? data.families.mother_prefix + " " + data.families.mother_name : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Mother’s Employment </h4>
                            <p>{data && data.families && data.families.mother_occcupation ? data.families.mother_occcupation : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Values</h4>
                            <p>{data && data.families && data.families.familyCulture ? data.families.familyCulture : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Affluence</h4>
                            <p>{data && data.families && data.families.family_status ? data.families.family_status : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Family Location</h4>
                            <p>{data && data.families && data.families.location ? data.families.location : "-"}</p>
                        </div>

                        <div class="col-12">
                            <h5>Sibling Details</h5>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Brothers</h4>
                            <p>{data && data.families && data.families.brother_count ? data.families.brother_count : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Married</h4>
                            <p>{data && data.families && data.families.brother_married ? data.families.brother_married : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Sisters</h4>
                            <p>{data && data.families && data.families.sister_count ? data.families.sister_count : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Married</h4>
                            <p>{data && data.families && data.families.sister_married ? data.families.sister_married : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Younger Brother</h4>
                            <p>{data && data.families && data.families.younger_brother ? data.families.younger_brother : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Elder Brother</h4>
                            <p>{data && data.families && data.families.elder_brother ? data.families.elder_brother : "-"}</p>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Younger Sister</h4>
                            <p>{data && data.families && data.families.younger_sister ? data.families.younger_sister : "-"}</p>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                            <h4>Elder Sister</h4>
                            <p>{data && data.families && data.families.elder_sister ? data.families.elder_sister : "-"}</p>
                        </div>

                    </div>
                }
            </div>

        </>
    )

}