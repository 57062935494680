import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Marital, MotherTounge, ISKCONHKMCentre, initiationNames } from "../../Data/content";
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";
import Sidebar from "../Sidebar";
import { MakerDetail } from "../../../../Reducer/MakerDashboardSlices/makerSlice";
import { UpdateMakerBasic, clearEditMaker } from "../../../../Reducer/MakerDashboardSlices/editMakerSlice";

export default function BasicInfoEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [countryId, setCountryId] = useState(null);
    const [CountryName, setCountry] = useState(null);
    const [stateId, setstateId] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [data, setData] = useState(null);
    const [errors, setFormErrors] = useState({});
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.makerEdit.success);
    const error = useSelector(state => state.makerEdit.error);

    if (success && success.status === 200) {

        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearEditMaker());
        setTimeout(() => {
            navigate("/MatchMakerEditProfile");
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearEditMaker());
                break;
        }
    }

    useEffect(() => {

        getData();

    }, []);

    async function getData() {
        let dtaa = { uid: auth._id };
        let res = await dispatch(MakerDetail(dtaa));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            setData(responseData.basic);
            setCountry(responseData.basic.fullCountry);
            setState(responseData.basic.fullState);
            setCity(responseData.basic.fullCity);
        }
    }


    const handleChangeInput = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let values = validate(data);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;
        }
        if (chk) {
            // console.log('after edit resss', data);

            let upDtaa = { uid: auth._id, name: data.name, gender: data.gender, marital_status: data.marital_status, mother_tounge: data.mother_tounge, associated_temple: data.associated_temple, Iskon_center: data.Iskon_center, initiatedName: data.initiatedName, fullCountry: CountryName, fullState: state, fullCity: city }

            dispatch(UpdateMakerBasic(upDtaa));

            // onNext(basic);
        } else {
            setFormErrors(validate(data));
        }

    }

    const validate = (data) => {
        let err = {};
        if (!data.name) {
            err.name = "This field is required!";
        } if (!data.gender) {
            err.gender = "This field is required!";
        }
        if (!data.marital_status) {
            err.marital_status = "This field is required!";
        }
        if (!data.mother_tounge) {
            err.mother_tounge = "This field is required!";
        }
        if (!data.associated_temple) {
            err.associated_temple = "This field is required!";
        }
        if (!data.Iskon_center) {
            err.Iskon_center = "This field is required!";
        }
        if (!data.initiatedName) {
            err.initiatedName = "This field is required!";
        }
        if (CountryName == null) {
            err.fullCountry = "This field is required!";
        }
        if (state == null) {
            err.fullState = "This field is required!";
        }
        if (city == null) {
            err.fullCity = "This field is required!";
        }
        return err;
    }


    return (

        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <section id="matchmaker_dashboard">
                        <div class="box-two h-100">
                            <form onSubmit={handleSubmit}>
                                <div class="text-center">
                                    <a href="index.html">  <img class="logo_login" src="images/Logo.png" alt="" /></a>
                                </div>
                                <h2>Basic Details</h2>

                                <div class="row mt-5">

                                    <div class="col-lg-6">
                                        <div class="form-group mess">
                                            <label class="form-label">Name</label>
                                            <input type="text" class="form-control" name="name" value={data && data.name} onChange={handleChangeInput} />
                                            {errors.name && <p className={"error__feedback"}>{errors.name}</p>}

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Gender</label>
                                            <select class="form-select" aria-label="Default select example" name="gender" onChange={handleChangeInput}>

                                                <option selected={data && data.gender === "Male"} value="Male">Male</option>
                                                <option selected={data && data.gender === "Female"} value="Female">Female</option>
                                                <option selected={data && data.gender === "Other"} value="Other">Other</option>
                                            </select>
                                            {errors.gender && <p className={"error__feedback"}>{errors.gender}</p>}
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Marital Status</label>
                                            <select class="form-select" aria-label="Default select example" name="marital_status" onChange={handleChangeInput}>

                                                {Marital.length > 0 && Marital.map((item, i) => (
                                                    <option selected={data && data.marital_status === item} value={item}>{item}</option>
                                                ))}

                                            </select>
                                            {errors.Marital_status && <p className={"error__feedback"}>{errors.Marital_status}</p>}

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Mother Tongue</label>
                                            <select class="form-select" aria-label="Default select example" name="mother_tounge" onChange={handleChangeInput}>

                                                {MotherTounge.length > 0 && MotherTounge.map((item, i) => (
                                                    <option selected={data && data.mother_tounge === item} value={item}>{item}</option>
                                                ))}
                                            </select>
                                            {errors.mother_tounge && <p className={"error__feedback"}>{errors.mother_tounge}</p>}

                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group mb-2">
                                            <label class="form-label">Are you associated with ISKCON Bangalore and HKM Group
                                                of Temples </label>
                                        </div>
                                        <div class="row mb-3">

                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        id="flexRadioDefault1" value="Yes" name="associated_temple" checked={data && data.associated_temple === "Yes"} onChange={handleChangeInput} />
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="associated_temple"
                                                        id="flexRadioDefault2" value="No" checked={data && data.associated_temple === "No"} onChange={handleChangeInput} />
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            {errors.associated_temple && <p className={"error__feedback"}>{errors.associated_temple}</p>}


                                        </div>


                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Center</label>
                                            <select class="form-select" aria-label="Default select example" name="Iskon_center" onChange={handleChangeInput}>
                                                {ISKCONHKMCentre.length > 0 && ISKCONHKMCentre.map((item, i) => (
                                                    <option selected={data && data.Iskon_center === item} value={item}>{item}</option>
                                                ))}
                                            </select>
                                            {errors.Iskon_center && <p className={"error__feedback"}>{errors.Iskon_center}</p>}

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Initiated Name</label>

                                            <input type="text" class="form-control" name="initiatedName" defaultValue={data && data.initiatedName} onChange={handleChangeInput} />

                                            {errors.initiatedName && <p className={"error__feedback"}>{errors.initiatedName}</p>}

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group flag_sing">
                                                    <label class="form-label">Country</label>
                                                    <CountrySelect
                                                        showFlag={false}
                                                        inputClassName="form-control"
                                                        class="form-select ps-5"
                                                        onChange={(e) => {
                                                            setCountryId(e.id);
                                                            setCountry(e);
                                                            setstateId(null);
                                                            setCity(null);
                                                            setState(null);
                                                        }}

                                                        defaultValue={CountryName}
                                                        placeHolder="Select Country"
                                                        name="fullCountry"

                                                    />
                                                </div>
                                                {errors.fullCountry && <p className={"error__feedback"}>{errors.fullCountry}</p>}

                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label class="form-label">State</label>
                                                    <StateSelect
                                                        showFlag={false}
                                                        inputClassName="form-control"

                                                        countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                                        onChange={(e) => {
                                                            console.log('eeeee', e);
                                                            if (e && e.id && e.name) {
                                                                setstateId(e.id);
                                                                setState(e);
                                                                setCity("");
                                                            } else {
                                                                console.log('else ');
                                                                setState(null)
                                                            }
                                                        }}
                                                        defaultValue={state}
                                                        placeHolder="Select State"

                                                        name="fullState"
                                                    />
                                                    {errors.fullState && <p className={"error__feedback"}>{errors.fullState}</p>}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">City</label>
                                            <CitySelect
                                                showFlag={false}
                                                inputClassName="form-control"

                                                countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                                stateid={state && state.id ? state.id : stateId}

                                                onChange={(e) => {

                                                    if (e && e.id && e.name) {
                                                        console.log(e);
                                                        setCity(e)
                                                    } else {
                                                        setCity(null)
                                                    }
                                                }}
                                                defaultValue={city}
                                                placeHolder="Select City"
                                                name="fullCity"

                                            />
                                            {errors.fullCity && <p className={"error__feedback"}>{errors.fullCity}</p>}

                                        </div>
                                    </div>


                                    <div class="col-12">

                                        <div class="sign_up_width">

                                            <div class="text-center">
                                                <button type="submit">Update</button>
                                                {/* <a class="btn" type="submit">Next</a> */}
                                            </div>

                                        </div>
                                    </div>

                                </div>



                            </form>

                        </div>

                    </section>
                </div>
            </section>
        </>



    )

}