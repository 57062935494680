import { combineReducers } from '@reduxjs/toolkit';
import apiSlice from './Reducer/apiSlice';
import authSlice from './Reducer/authSlice';
import userSlice from './Reducer/UserDashboardSlices/userSlice';
import familySlice from './Reducer/StepForms/familySlice';
import basicSlice from './Reducer/StepForms/basicSlice';
import astroSlice from './Reducer/StepForms/astroSlice';
import carrerSlice from './Reducer/StepForms/carrerSlice';
import spritualSlice from './Reducer/StepForms/spritualSlice';
import preferenceSlice from './Reducer/StepForms/preferenceSlice';
import personalitySlice from './Reducer/StepForms/personalitySlice';
import aboutSlice from './Reducer/StepForms/aboutSlice';
import gallerySlice from './Reducer/StepForms/gallerySlice';
import selfiSlice from './Reducer/StepForms/selfiSlice';
import govtIdslice from './Reducer/StepForms/govtIdslice';
import privacySlice from './Reducer/StepForms/privacySlice';
import myProfileSlice from './Reducer/UserDashboardSlices/myProfileSlice';
import pricingSlice from './Reducer/StepForms/pricingSlice';
import dashboardSlice from './Reducer/UserDashboardSlices/dashboardSlice';
import viewProfileSlice from './Reducer/UserDashboardSlices/viewProfileSlice';
import filterSlice from './Reducer/UserDashboardSlices/filterSlice';
import notificationSlice from './Reducer/UserDashboardSlices/notificationSlice';
import makerSlice from './Reducer/MakerDashboardSlices/makerSlice';
import listUser from './Reducer/MakerDashboardSlices/listUser';
import contactFormSlice from './Reducer/FrontendForms/contactFormSlice';
import userAccountSlice from './Reducer/UserDashboardSlices/userAccountSlice';
import singleFTMSlice from './Reducer/MakerDashboardSlices/singleFTMSlice';
import matchingFtmSlice from './Reducer/MakerDashboardSlices/matchingFtmSlice';
import homeSlice from './Reducer/MakerDashboardSlices/homeSlice';
import editMakerSlice from './Reducer/MakerDashboardSlices/editMakerSlice';
import notiSlice from './Reducer/MakerDashboardSlices/notiSlice';
import chatSlice from './Reducer/chatSlice';
import ftmUsersSlice from './Reducer/MakerDashboardSlices/ftmUsersSlice';
import pendingRecomendationSlice from './Reducer/MakerDashboardSlices/pendingRecomendationSlice';
import newProfilesSlice from './Reducer/MakerDashboardSlices/newProfilesSlice';
import pendingVerificationSlice from './Reducer/MakerDashboardSlices/pendingVerificationSlice';
import orderSummarySlice from './Reducer/StepForms/orderSummarySlice';
import historySlice from './Reducer/MakerDashboardSlices/historySlice';
const rootReducer = combineReducers({
    api: apiSlice,
    auth: authSlice,
    user: userSlice,
    family: familySlice,
    basic: basicSlice,
    astro: astroSlice,
    carrer: carrerSlice,
    spritual: spritualSlice,
    prefernce: preferenceSlice,
    personality: personalitySlice,
    abt: aboutSlice,
    gllry: gallerySlice,
    selfy: selfiSlice,
    govtId: govtIdslice,
    prvcy: privacySlice,
    myProfile: myProfileSlice,
    price: pricingSlice,
    dshbrd: dashboardSlice,
    viewProfile: viewProfileSlice,
    filter: filterSlice,
    noti: notificationSlice,
    maker: makerSlice,
    userlist: listUser,
    contactForm: contactFormSlice,
    userAccount: userAccountSlice,
    singleFtm: singleFTMSlice,
    matchingFtm: matchingFtmSlice,
    makerHome: homeSlice,
    makerEdit: editMakerSlice,
    makerNoti: notiSlice,
    chat: chatSlice,
    ftmUsers: ftmUsersSlice,
    pendingRecomendation: pendingRecomendationSlice,
    newProfiles: newProfilesSlice,
    pendingVerification: pendingVerificationSlice,
    ordrSmry: orderSummarySlice,
    histry: historySlice
});

export default rootReducer;