import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFilteredData, getSerachResults } from '../api';

export const FilterSearch = createAsyncThunk('FilterSearch', async (formData, { rejectWithValue }) => {
    try {
        const response = await getFilteredData(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const SearchProfiles = createAsyncThunk('SearchProfiles', async (formData, { rejectWithValue }) => {
    try {
        const response = await getSerachResults(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        data: [],
        data2: [],
        success: null,
        error: null,
        UserType: "",
        Results: JSON.parse(localStorage.getItem('results')) || [],
        filterData: JSON.parse(localStorage.getItem('filters')) || {},
        Searched: JSON.parse(localStorage.getItem('search')) || {},
        record: JSON.parse(localStorage.getItem('record')) || [],
    },
    reducers: {
        clearFilter: state => {
            state.success = false;
            state.error = null;
            // state.UserType = "";
            localStorage.removeItem('results');
            localStorage.removeItem('filters');
            // localStorage.removeItem('search');
            // localStorage.removeItem('record');
        },

        clearSearchRecord: state => {
            state.record = [];
            localStorage.removeItem('search');
            localStorage.removeItem('record');
        },

        saveSearchResults: (state, action) => {
            state.Results = action.payload;
            localStorage.setItem('results', JSON.stringify(action.payload));
        },
        saveSearchFilters: (state, action) => {
            state.filterData = action.payload;
            localStorage.setItem('filters', JSON.stringify(action.payload));
        },

        saveSearched: (state, action) => {
            state.Searched = action.payload;
            localStorage.setItem('search', JSON.stringify(action.payload));
        },
        saveSearchedRecord: (state, action) => {
            state.record = action.payload;
            localStorage.setItem('record', JSON.stringify(action.payload));
        }



    },
    extraReducers: builder => {
        builder
            .addCase(FilterSearch.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(FilterSearch.fulfilled, (state, action) => {
                state.UserType = action.payload.data.UserType;
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(FilterSearch.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
            .addCase(SearchProfiles.pending, state => {
                state.success = false;
                state.error = null;
            })
            .addCase(SearchProfiles.fulfilled, (state, action) => {
                state.UserType = action.payload.data.UserType;
                state.data2 = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SearchProfiles.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })

    },
});
export const { clearFilter, saveSearchResults, saveSearchFilters, saveSearched, saveSearchedRecord, clearSearchRecord } = filterSlice.actions;

export default filterSlice.reducer;

