import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import { MakerDetail } from "../../../../Reducer/MakerDashboardSlices/makerSlice";
import { GetListOfUsers } from "../../../../Reducer/MakerDashboardSlices/listUser";
export default function Profile() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.maker.data);
    const UsersCount = useSelector(state => state.userlist.data);

    useEffect(() => {
        let data = { uid: auth._id };
        dispatch(MakerDetail(data));
        dispatch(GetListOfUsers(data));

    }, []);

    console.log('dddddd', data);

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <div class="view_profile">
                        <div class="row">

                            <div class="col-xl-4 mb-3">
                                <div class="box">

                                    <div class="match_head_pro">
                                        <h3>My Profile</h3>
                                        <div class="img">
                                            <img src="/images/beads.png" alt="" />
                                        </div>
                                    </div>

                                    {/* <!-- Content --> */}
                                    <h2>{data && data.basic.name}<img src="images/Selfie-Verified.png" alt="" /></h2>
                                    <div class="profile_state">
                                        <h6><img src="images/Phone.png" />Phone <span>{data && data.mobile}</span></h6>
                                        <h6><img src="images/Mail.png" />Email <span>{data && data.email}</span></h6>

                                        <h5>Profile Statistics</h5>
                                        <ul>
                                            <li><span>{UsersCount && UsersCount.length}</span>Profiles Manages</li>
                                            <li><span>16</span>Successful Matches</li>
                                            <li><span>{(new Date(data && data.created_at)).getFullYear()}</span>year joined as match maker</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-8 mb-3">
                                <div class="box_two">
                                    <h2>{data && data.basic && data.basic.name}<Link to="/MatchMakerEditProfile"><img src="/images/Edit.png" alt="" /></Link></h2>
                                    <h3>{data && data.basic && data.basic.about}</h3>

                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-basic-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-basic" type="button" role="tab"
                                                aria-controls="pills-basic" aria-selected="true"><img src="images/pro-1.png"
                                                    alt="" /><label>Basic Info</label></button>
                                        </li>


                                    </ul>

                                    <div class="tab-content" id="pills-tabContent">

                                        <div class="tab-pane fade show active" id="pills-basic" role="tabpanel"
                                            arWWWia-labelledby="pills-basic-tab">

                                            <div class="set">
                                                <div class="row">
                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Name</h4>
                                                        <p>{data && data.basic && data.basic.name}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Gender</h4>
                                                        <p>{data && data.basic && data.basic.gender}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Marital Status</h4>
                                                        <p>{data && data.basic && data.basic.marital_status}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Mother Tongue</h4>
                                                        <p>{data && data.basic && data.basic.mother_tounge}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Centre</h4>
                                                        <p>{data && data.basic && data.basic.Iskon_center}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Initiated name</h4>
                                                        <p>{data && data.basic && data.basic.initiatedName
                                                        }</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>Country</h4>
                                                        <p>{data && data.basic && data.basic.country}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>State</h4>
                                                        <p>{data && data.basic && data.basic.state}</p>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <h4>City</h4>
                                                        <p>{data && data.basic && data.basic.city}</p>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}