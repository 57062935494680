import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


export default function Personal(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {

    }, []);

    console.log('props', props);


    return (
        <>
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab">

                <div class="box">
                    <h2><img src="/images/pro-1.png" alt="" />Personal Information</h2>
                    <div class="row">
                        <div class="col-12">
                            <h3>Name</h3>
                            <p>{props.data && props.data.name}</p>
                        </div>
                        <div class="col-sm-6">
                            <h3>Initiated</h3>
                            <p>{props.data && props.data.spirituals && props.data.spirituals.initiated}</p>
                        </div>
                        {props.data && props.data.spirituals && props.data.spirituals.initiated === "Yes" &&
                            <div class="col-sm-6">
                                <h3>Initiated Name</h3>
                                <p>{props.data && props.data.spirituals && props.data.spirituals.Initiation_name}</p>
                            </div>

                        }
                        <div class="col-12">
                            <h3>Associated with</h3>
                            <p>{props.data && props.data && props.data.spirituals && props.data.spirituals.affiliated_with_spiritual_organization
                            }</p>
                        </div>


                        <div class="col-sm-6">
                            <h3>Date of Birth</h3>
                            <p>{props.data && props.data.astro && props.data.astro.dob}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Time of Birth</h3>
                            <p>{props.data && props.data.astro && props.data.astro.time + ' ' + props.data.astro.ampm}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Rāśi</h3>
                            <p>{props.data && props.data.astro && props.data.astro.rashi}</p>
                        </div>


                        <div class="col-sm-6">
                            <h3>Nakṣatra</h3>
                            <p>{props.data && props.data.astro && props.data.astro.nakshtra}</p>
                        </div>

                        <div class="col-12">
                            <h3>Mangalik</h3>
                            <p>{props.data && props.data.astro && props.data.astro.manglik}</p>
                        </div>

                        <div class="col-sm-6">
                            <h3>Current City</h3>
                            <p>{props.data && props.data.basic && props.data.basic.city}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}