import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";



export default function Introduction(props) {

    return (
        <section id="wedding_venues">
            <div class="container">
                {props.data &&
                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-7 order_sec">
                            <h2>{props.data.title}</h2>
                            <p>{props.data.description}</p>

                            <a href="#" class="btn">Book your Venue</a>
                            <a href="explore_destination.html" class="btn ms-2">Explore Destination</a>
                        </div>

                        <div class="col-lg-6 col-md-5">
                            <div class="venues_img">
                                <img class="w-100" src={props.data.image} alt="" />
                            </div>
                        </div>

                    </div>
                }
            </div>
        </section>
    )

}