import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { personalityOp, socialgathering } from "../../Data/content";


export default function Step4({ data, handleChnage, errors }) {
    const dispatch = useDispatch();

    useEffect(() => {

    });

    const selectOption = (item, name) => {
        handleChnage({ target: { name: name, value: item } });

    };


    return (
        <>
            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3><span>Social</span> Interaction</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                        
                            <div class="box">
                                <h4>How would you characterize your personality?</h4>
                                <h4>Select your personality from 4 categories</h4>
                                <h4>How you focus your attention: on the outer world or your inner world?*</h4>
                                <ul>
                                    <li className={data.focus_your_attention === "Extraversion:😄" ? 'active' : ''} onClick={() => selectOption("Extraversion:😄", "focus_your_attention")}>Extraversion:😄</li> or
                                    <li className={data.focus_your_attention === "Introversion:😌" ? 'active' : ''} onClick={() => selectOption("Introversion:😌", "focus_your_attention")}>Introversion:😌</li>
                                </ul>
                                {errors.focus_your_attention && <p className={"error__feedback"}>{errors.focus_your_attention}</p>}

                                <h4>How you take in information: using your physical senses or your instincts and impressions?*
                                </h4>
                                <ul>
                                    <li className={data.take_in_information === "Intuition:🌟" ? 'active' : ''} onClick={() => selectOption("Intuition:🌟", "take_in_information")}>Intuition:🌟</li> or
                                    <li className={data.take_in_information === "Sensing:🌿" ? 'active' : ''} onClick={() => selectOption("Sensing:🌿", "take_in_information")}>Sensing:🌿</li>
                                </ul>
                                {errors.take_in_information && <p className={"error__feedback"}>{errors.take_in_information}</p>}
                                <h4>How you make decisions: solely using logic or by weighing how it will affect the people involved
                                    ?*
                                </h4>
                                <ul>
                                    <li className={data.decisions === "Thinking:🤔" ? 'active' : ''} onClick={() => selectOption("Thinking:🤔", "decisions")}>Thinking:🤔</li> or
                                    <li className={data.decisions === "Feeling:❤️" ? 'active' : ''} onClick={() => selectOption("Feeling:❤️", "decisions")}>Feeling:❤️</li>
                                </ul>
                                {errors.decisions && <p className={"error__feedback"}>{errors.decisions}</p>}
                                <h4>How you deal with the world around you: if you prefer routines and structure or are more flexible and spontaneous?*
                                </h4>
                                <ul>
                                    <li className={data.deal_with_world_around === "Judging:❤️" ? 'active' : ''} onClick={() => selectOption("Judging:❤️", "deal_with_world_around")}>Judging:❤️</li> or
                                    <li className={data.deal_with_world_around === "Perceiving:🔍" ? 'active' : ''} onClick={() => selectOption("Perceiving:🔍", "deal_with_world_around")}>Perceiving:🔍</li>
                                </ul>
                                {errors.deal_with_world_around && <p className={"error__feedback"}>{errors.deal_with_world_around}</p>}
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">

                                <h4>Are you more comfortable in social gatherings?*</h4>
                                <ul>
                                    {socialgathering.length > 0 && socialgathering.map((item, i) => (
                                        <li key={i} className={data.social_Gatherings === item ? 'active' : ''} onClick={() => selectOption(item, "social_Gatherings")}>{item}</li>
                                    ))}
                                    {errors.social_Gatherings && <p className={"error__feedback"}>{errors.social_Gatherings}</p>}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}