import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Sidebar from "../Sidebar";

export default function EditProfile() {
    const navigate = useNavigate();

    useEffect(() => {

    }, []);



    const editInfo = (type) => {
        if (type === "basic") {
            navigate("/MatchMakerEditProfile/Edit");
        }
        else if (type === "gallry") {

            navigate("/MatchMakerEditProfile/UploadGallery");

        } else {

            navigate("/MatchMakerEditProfile/About");

        }


    }

    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <div class="header_dashboard">
                        <h2>Welcome to your Dashboard!</h2>
                    </div>
                    <div class="complete_profile">
                        {/* <h2>🌟 Unlock the Full Experience: Complete Your Profile</h2> */}
                        <div class="row">

                            <div class="col-xl-8 mb-xl-0 mb-3">
                                <div class="box">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="set">
                                                <h4 onClick={() => editInfo("basic")}><span><img src="/images/p1.png" />Basic Info</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-18" checked />
                                                                <label for="checkbox-18"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => editInfo("abt")}><span><img src="/images/p8.png" />About Me</span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-25" checked />
                                                                <label for="checkbox-25"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>
                                                <h4 onClick={() => editInfo("gallry")}><span><img src="/images/p9.png" />Upload Photos </span>
                                                    <span>
                                                        <div class="checkbox-wrapper-18">
                                                            <div class="round">
                                                                <input type="checkbox" id="checkbox-26" checked />
                                                                <label for="checkbox-26"></label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </h4>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="better_matches">
                                    <h3>✨ Why Complete Your Profile?</h3>
                                    {/* <ul>
                                        <li>Better Matches: The more you share, the more accurate your matches become.</li>
                                        <li>Show Your Personality: Let your unique qualities shine for others to see.</li>
                                        <li>Build Trust: A complete profile builds trust and credibility.</li>
                                    </ul> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}