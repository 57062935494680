import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { lifestyle, dietOp } from "../../Data/content";


export default function Step5({ data, handleChnage, errors }) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    const selectOption = (item, name) => {
        if (name === "lifestyle") {
            let updatedSelection = [...data.lifestyle];
            const selectedIndex = updatedSelection.indexOf(item);
            if (selectedIndex !== -1) {
                // If selected, remove it from the array
                updatedSelection.splice(selectedIndex, 1);
            } else {
                // If not selected, add it to the array
                updatedSelection.push(item);
            }
            handleChnage({ target: { name: "lifestyle", value: updatedSelection } });

        } else {
            handleChnage({ target: { name: "diet", value: item } });

        }

    };

    return (
        <>

            <div class="tab_box_new">
                <h2>Personality Questions</h2>
                <h3><span>Social</span> Interaction 2</h3>
                <div class="height_box">
                    <div class="row">
                        <div class="col-md-6 pe-xl-5">
                            <div class="box">
                                <h4>Select your lifestyle*
                                </h4>
                                <ul>

                                    {lifestyle.length > 0 && lifestyle.map((item, i) => (
                                        <li key={i} className={data.lifestyle && data.lifestyle.length > 0 && data.lifestyle.includes(item) ? 'active' : ''} onClick={() => selectOption(item, "lifestyle")}>
                                            {item}
                                        </li>
                                    ))}

                                    {errors.lifestyle && <p className={"error__feedback"}>{errors.lifestyle}</p>}
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="box">
                                <h4>Diet*</h4>
                                <ul>
                                    {dietOp.length > 0 && dietOp.map((item, i) => (
                                        <li key={i} className={data.diet === item ? 'active' : ''} onClick={() => selectOption(item, "diet")}>
                                            {item}
                                        </li>
                                    ))}
                                    {errors.diet && <p className={"error__feedback"}>{errors.diet}</p>}


                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}