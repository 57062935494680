import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { spritualhours } from "../../../Data/content";



export default function Form1({ data, handleChange, errors }) {
    const dispatch = useDispatch();


    useEffect(() => {

    });

    return (

        <>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">How long are you affiliated with the organization?[answer in year]*</label>
                        <input type="number" class="form-control" name="affiliated_duration" value={data.affiliated_duration} onChange={handleChange} />
                        {errors.affiliated_duration && <p className={"error__feedback"}>{errors.affiliated_duration}</p>}
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">How much time you spend daily on your spiritual sadhana?*</label>
                        <select class="form-select" aria-label="Default select example" name="sadhanaTime" value={data.sadhanaTime} onChange={handleChange}>
                            <option selected disabled>Select</option>
                            {spritualhours.length > 0 && spritualhours.map((hr, i) => (
                                <option value={hr}>{hr}</option>
                            ))}
                        </select>
                        {errors.sadhanaTime && <p className={"error__feedback"}>{errors.sadhanaTime}</p>}
                    </div>
                </div>

            </div>
        </>
    )

}