import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetDetailsTab } from "../../../../Reducer/MakerDashboardSlices/ftmUsersSlice";

export default function Basic() {
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setTabData] = useState(null);
    // const users = useSelector(state => state.ftmUsers.data);



    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let dat = { uid: params.id, type: "Basic" };
        let res = await dispatch(GetDetailsTab(dat));
        if (res.payload && res.payload.data.data) {
            setTabData(res.payload.data.data);
        }


    }
    console.log('dddddaaataaa', data);

    return (
        <>
            <div class="set">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Name</h4>
                        <p>{data && data.name ? data.name : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Gender</h4>
                        <p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Marital Status</h4>
                        <p>{data && data.basic && data.basic.Marital_status ? data.basic.Marital_status : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Mother Tongue</h4>
                        <p>{data && data.basic && data.basic.Mother_tongue ? data.basic.Mother_tongue : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Height</h4>
                        <p>{data && data.basic && data.basic.height ? data.basic.height : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Weight</h4>
                        <p>{data && data.basic && data.basic.weight ? data.basic.weight + ' kg' : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Health Status</h4>
                        <p>{data && data.basic && data.basic.health ? data.basic.health : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Any Disability</h4>
                        <p>{data && data.basic && data.basic.Disability ? data.basic.Disability : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>Country</h4>
                        <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>State</h4>
                        <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <h4>City</h4>
                        <p>{data && data.basic && data.basic.city ? data.basic.city : "-"}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
